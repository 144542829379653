import 'leaflet.markercluster';

import * as L from 'leaflet';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useMap } from 'react-leaflet';

import { IndicatorTier, StatusIndicator, SystemDisplayState } from '../../ComponentLibrary/src';
import { Text, TextType } from '../../ComponentLibrary/src/Text';
import { SystemsContext } from '../../context/Systems';
import { useMobile, usePrevious } from '../../hooks';
import { System } from '../../types';
import ClusterIndicator from './ClusterIndicator';
import SystemIcon from './SystemIcon';
import SystemPopup from './SystemPopup';
import { getSystemsWithCoordinates } from './util';

interface Props {
  setShowControls?: Dispatch<SetStateAction<boolean>>;
  setSystemsFound: Dispatch<SetStateAction<boolean>>;
  setDragged: Dispatch<SetStateAction<boolean>>;
  hideStates?: boolean;
  bounds?: L.LatLngBounds;
  dragged: boolean;
}

export default function MarkerCluster({
  setShowControls,
  setSystemsFound,
  setDragged,
  dragged,
  hideStates,
  bounds,
}: Props): null {
  const { getSystem, systemsSummary } = useContext(SystemsContext);
  const map = useMap();
  const isMobile = useMobile();
  const previousBounds: L.LatLngBounds | undefined = usePrevious(bounds) as L.LatLngBounds;

  const systemsWithCoordinates = getSystemsWithCoordinates(systemsSummary?.systems);
  const previousSystems = usePrevious(systemsWithCoordinates) as System[];

  const mcg = useMemo(() => {
    const tooltip = L.tooltip({
      className: 'bg-blue-800 border-blue-800',
      sticky: true,
    });
    return L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      spiderLegPolylineOptions: { weight: 0, color: '#222', opacity: 0.5 },
      maxClusterRadius: hideStates ? 12 : 40,
      showCoverageOnHover: false,
      // disableClusteringAtZoom: 5,
      iconCreateFunction: hideStates
        ? (cluster) => {
            cluster.options.riseOnHover = true;
            let iconSize = 22;
            const childCount = cluster.getChildCount();
            if (childCount > 99) {
              iconSize = 30;
            }
            return L.divIcon({
              className: `single-map-marker`,
              html: `<div>${childCount}</div>`,
              iconSize: [iconSize, iconSize],
            });
          }
        : (cluster) => {
            cluster.options.riseOnHover = true;
            const allMarkerClasses = cluster
              .getAllChildMarkers()
              .map((marker) => marker.options?.icon?.options?.className);
            const warningCount = allMarkerClasses?.reduce((warningCount, markerClass) => {
              if (markerClass?.includes('warning')) {
                return warningCount + 1;
              }
              return warningCount;
            }, 0);
            const faultCount = allMarkerClasses?.reduce((warningCount, markerClass) => {
              if (markerClass?.includes(SystemDisplayState.faulted)) {
                return warningCount + 1;
              }
              return warningCount;
            }, 0);

            if (warningCount || faultCount) {
              cluster.bindTooltip(tooltip, {});
              cluster.on('tooltipopen', () => {
                tooltip.setContent(
                  ReactDOMServer.renderToString(
                    <div className="flex flex-col">
                      {faultCount > 0 && (
                        <span className="text-sm text-white font-medium flex gap-1 items-center">
                          <StatusIndicator state={SystemDisplayState.faulted} tier={IndicatorTier.three} isSecondary />
                          Fault <span className="font-semibold">({faultCount})</span>
                        </span>
                      )}
                      {warningCount > 0 && (
                        <span className="text-sm text-white font-medium flex gap-1 items-center">
                          <StatusIndicator state={SystemDisplayState.warning} tier={IndicatorTier.three} isSecondary />
                          Warning <span className="font-semibold">({warningCount})</span>
                        </span>
                      )}
                    </div>,
                  ),
                );
              });
            }

            return L.divIcon({
              className: 'custom-cluster-marker',
              html: ReactDOMServer.renderToString(
                <>
                  <ClusterIndicator cluster={cluster} />
                </>,
              ),
            });
          },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideStates, systemsSummary]);

  useEffect(() => {
    // TODO: Throws an error on mobile for some reason
    if (!isMobile && map) {
      L.control
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .fullscreen({
          position: 'bottomright', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
          title: 'View map fullscreen', // change the title of the button, default Full Screen
          titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
          content: null, // change the content of the button, can be HTML, default null
          // forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
          // forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
          fullscreenElement: document.getElementById('page-content'), // Dom element to render in full screen, false by default, fallback to map._container
        })
        .addTo(map);
    }
    if (map) {
      map.on('dragstart', () => setDragged(true));
      // map.on('zoomstart', () => setDragged(true));
    }
    if (mcg) {
      mcg.on('clusterclick', () => {
        setDragged(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // zoom to systems bounds
    if (
      bounds?.isValid() &&
      previousBounds?.isValid() &&
      Object.keys(bounds).length &&
      !dragged &&
      !previousBounds?.equals(bounds)
    ) {
      map.flyToBounds(bounds, {
        duration: 0.5,
        maxZoom: 12,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  const drawMarkers = () => {
    const existingLayers = mcg.getLayers();
    if (!existingLayers?.length)
      map.eachLayer((layer) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!!layer._markerCluster) {
          layer.remove();
        }
      });

    const newSystems =
      previousSystems?.length > 0 && existingLayers?.length
        ? systemsWithCoordinates.filter((sys) => {
            const previousSystem = previousSystems?.find((s: System) => s.sysId === sys.sysId);
            if (!previousSystem) return true;
            return false;
          })
        : systemsWithCoordinates;

    // update systems which have changed
    for (const sys of systemsWithCoordinates) {
      const previousSystem = previousSystems?.find((s: System) => s.sysId === sys.sysId);

      if (JSON.stringify(previousSystem) !== JSON.stringify(sys)) {
        const existingLayer = existingLayers?.find((layer) => (layer as L.Marker).options.title === sys.sysId);
        if (existingLayer) {
          // update icon
          (existingLayer as L.Marker).setIcon(SystemIcon(sys, hideStates));
        }
      }
    }

    // remove systems which are gone
    if (previousSystems?.length > 0) {
      for (const previousSystem of previousSystems) {
        const currentSystem = systemsWithCoordinates.find((s: System) => s.sysId === previousSystem.sysId);
        if (!currentSystem) {
          const existingLayer = existingLayers?.find(
            (layer) => (layer as L.Marker).options.alt === previousSystem.sysId,
          );
          if (existingLayer) mcg.removeLayer(existingLayer);
        }
      }
    }

    newSystems?.forEach((system: System) => {
      const latLng = new L.LatLng(system.site?.coords?.latitude ?? 0, system.site?.coords?.longitude ?? 0);

      const popup = L.popup({});
      const tooltip = L.tooltip({
        className: 'bg-blue-800 border-blue-800',
        sticky: true,
      });

      const marker = L.marker(latLng, {
        icon: SystemIcon(system, hideStates),
        alt: system.sysId,
        riseOnHover: true,
      }).addTo(mcg);

      if (!hideStates) {
        marker
          ?.bindTooltip(
            tooltip.setContent(
              ReactDOMServer.renderToString(
                <Text type={TextType.custom} overrideColor className="text-sm text-white font-medium">
                  {system.sysId}
                </Text>,
              ),
            ),
          )
          .bindPopup(popup)
          .on('popupopen', async () => {
            if (setShowControls) setShowControls(false);
            popup.setContent(ReactDOMServer.renderToString(<SystemPopup system={undefined} />));
            const systemResponse = await getSystem({
              sysId: system.sysId,
              project: [
                'sysId',
                'site.name',
                'org.name',
                'distributor.name',
                'stats.state',
                'stats.activeFaults',
                'model',
              ],
            });
            popup.setContent(ReactDOMServer.renderToString(<SystemPopup system={systemResponse ?? undefined} />));
          })
          .on('popupclose', () => {
            if (setShowControls) setShowControls(true);
          });
      }

      return marker;
    });

    // add the marker cluster group to the map
    if (previousSystems?.length === 0 || systemsWithCoordinates.length > 0 || !existingLayers?.length) {
      map.addLayer(mcg);
    }

    // add message if there are no systems
    try {
      if (systemsSummary?.count === 0) {
        setSystemsFound(false);
      } else {
        mcg.refreshClusters();
        setSystemsFound(true);
      }
    } catch {}
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(drawMarkers, [systemsSummary, mcg]);

  return null;
}
