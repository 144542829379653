import React from 'react';

import { appendClassProps } from '../util';
import { ErrorProps } from './index.types';

/**
 - Use text for any text on the screen to conform to typography styles
 */

export const Error = ({ errorMessage, className, showErrorMessage }: ErrorProps): JSX.Element => {
  return (
    <div
      className={`text-red-400 text-left pl-2 w-full text-sm min-h-[1.25rem] pb-2${
        showErrorMessage ? '' : ' text-opacity-0'
      } ${appendClassProps(className)}`}
      data-testid="error"
      data-id="error-message"
    >
      <span style={{ visibility: showErrorMessage ? 'visible' : 'hidden' }}>{errorMessage ?? 'success'}</span>
    </div>
  );
};
