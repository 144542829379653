import { DateTime } from 'luxon';

import api from '../../adapters/api';
import cap3m from '../../image/CAP3M-thumbnail-cc.png';
import cap3Nano from '../../image/CAP3Nano-thumbnail-cc.png';
import cap3Nano2 from '../../image/CAP3Nano2-thumbnail-cc.png';
import cap3V from '../../image/CAP3V-thumbnail-cc.png';
import capPico from '../../image/CAPPico-thumbnail.png';
import pg from '../../image/PG5650-thumbnail-cc.png';
import { CapSystem, GenSystem, SummitInfo, System } from '../../types';

export const getVariants = (code: number): string[] =>
  ['GHT', 'LTS', 'GCU', 'CAP', 'SGM'].filter((_, i) => code & (1 << i));

export const hasCap = (system?: GenSystem): boolean => {
  return getVariants(system?.fpcConfig?.variantCode ?? 0).includes('CAP') && !!system?.capSN;
};
export const hasGht = (system?: GenSystem): boolean => {
  return getVariants(system?.fpcConfig?.variantCode ?? 0).includes('GHT');
};
export const hasLts = (system?: GenSystem): boolean => {
  return getVariants(system?.fpcConfig?.variantCode ?? 0).includes('LTS');
};
export const hasGcu = (system?: GenSystem): boolean => {
  return getVariants(system?.fpcConfig?.variantCode ?? 0).includes('GCU');
};
export const isCap = (system?: System): boolean => {
  return ((system as CapSystem)?.model?.includes('CAP') || system?.sysId?.startsWith('C')) ?? false;
};
export const hasSgm = (system?: GenSystem): boolean => {
  return getVariants(system?.fpcConfig?.variantCode ?? 0).includes('SGM');
};

/**
 * Parses the signal strength based on summit information and server time.
 *
 * @param {SummitInfo} summitInfo - The summit information.
 * @return {number | null | undefined} The parsed signal strength, or null if the summit data is not recent, or undefined if summit info or server time is missing.
 */
export const parseSignalStrength = (summitInfo?: SummitInfo): number | null | undefined => {
  const serverTime = api.getServerTime();
  if (!serverTime || !summitInfo || !summitInfo.lastCheckInTime) return undefined;
  const isSummitRecent = serverTime.diff(DateTime.fromMillis(summitInfo.lastCheckInTime)).as('minutes') < 60;
  if (!isSummitRecent) return null;
  const parsedSignalStrength = parseInt(summitInfo.signalStrength?.split(' ')?.[0]);

  return isNaN(parsedSignalStrength) ? undefined : parsedSignalStrength;
};

export const getThumbnail = (system?: System): string | null => {
  if (!system) return null;
  let thumbnail: string | null = null;
  if (
    (system as GenSystem)?.model === 'PowerGen 1200' ||
    (system as GenSystem)?.model === 'PowerGen 5650' ||
    (system as GenSystem)?.model === 'PowerGen'
  ) {
    thumbnail = pg;
  } else if ((system as CapSystem)?.model === 'CAP M') {
    thumbnail = cap3m;
  } else if ((system as CapSystem)?.model === 'CAP Nano2') {
    thumbnail = cap3Nano2;
  } else if ((system as CapSystem)?.model === 'CAP V3') {
    thumbnail = cap3V;
  } else if ((system as CapSystem)?.model === 'CAP V5') {
    thumbnail = cap3V;
  } else if ((system as CapSystem)?.model === 'CAP V10') {
    thumbnail = cap3V;
  } else if ((system as CapSystem)?.model === 'CAP Nano') {
    thumbnail = cap3Nano;
  } else if ((system as CapSystem)?.model === 'CAP Pico') {
    thumbnail = capPico;
  }
  return thumbnail;
};
