import '../../style/release-notes.scss';

import jwt from 'jsonwebtoken';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { fetchUserNotificationsUnsubscribe, patchUserNotificationsUnsubscribe } from '../../adapters/api/user';
import { Button, FormStatus, Link, Text, TextType, TopNav } from '../../ComponentLibrary/src';
import { useQuery, useSetDocumentTitle } from '../../hooks';
import logo from '../../image/QD.png';
import { NewNotifications } from '../../types';
import Options, { UnsubscribeSelection } from './Options';
import { UnsubscribeTokenPayload } from './types';

export default function Unsubscribe(): JSX.Element {
  useSetDocumentTitle('Unsubscribe');
  const { t } = useTranslation('unsubscribe');
  const [loading, setLoading] = useState<boolean>(true);
  const [formStatus, setFormStatus] = useState<FormStatus | undefined>();
  const [selection, setSelection] = useState<UnsubscribeSelection>();
  const [originalSettings, setOriginalSettings] = useState<NewNotifications>();
  const params = useQuery();
  const token = params.get('token');

  const decoded = useMemo(() => (token ? (jwt.decode(token) as UnsubscribeTokenPayload) : undefined), [token]);

  const tokenExpired = useMemo(() => {
    const now = new Date().getTime() / 1000;
    return decoded?.exp ? decoded.exp <= now : true;
  }, [decoded?.exp]);

  useEffect(() => {
    if (token && decoded?.email && !tokenExpired)
      fetchUserNotificationsUnsubscribe({
        token,
        onFail: () => {
          setLoading(false);
        },
      }).then((notificationSettings) => {
        if (notificationSettings) {
          setOriginalSettings(notificationSettings);
        }
        setLoading(false);
      });
  }, [decoded?.email, t, token, tokenExpired]);

  const handleSave = () => {
    setLoading(true);
    if (selection && token) {
      patchUserNotificationsUnsubscribe({
        selection,
        token,
        onFail: (err) => {
          toast(t('settings_error'), {
            type: toast.TYPE.ERROR,
          });
          throw err;
        },
      }).then(() => {
        setFormStatus('success');
        setLoading(false);
      });
    }
  };

  const handleSelectionChange = useCallback((selection: UnsubscribeSelection) => {
    setSelection(selection);
    setFormStatus('dirty');
  }, []);

  if (loading && !tokenExpired)
    return <Skeleton count={3} height={40} containerClassName="flex-1 px-2" className="my-2" />;

  const greeting =
    'Hi' + (decoded?.profile?.firstName ? ` ${decoded?.profile?.firstName}` : '') + ` (${decoded?.email})`;

  return (
    <div className="responsive-h-screen">
      <TopNav
        hideHelp
        hideAccount
        logo={<img src={logo} className="h-full" />}
        title="Unsubscribe"
        notificationsHref="/notifications"
        releaseNotesHref="/releasenotes"
        swVer={process.env.REACT_APP_RELEASE_VERSION}
        phone={process.env.REACT_APP_SUPPORT_TEL}
        termsHref={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com'}/terms`}
        privacyHref={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com'}/privacy`}
        userAccountUrl={`${
          process.env.REACT_APP_SMARTVIEW_ACCOUNTS_URL ?? 'https://accounts.qnergy.com'
        }/users/current`}
      />
      <div className="center-all fill p-2">
        <div className="flex flex-col gap-2 w-full md:w-auto">
          <Text type={TextType.h1}>Unsubscribe</Text>
          {token && !tokenExpired && formStatus !== 'success' ? (
            <>
              <Text wrap>
                <b>{greeting}</b>
              </Text>
              <Text wrap>{t('how')}</Text>

              <Options
                token={decoded}
                onSelectionChange={handleSelectionChange}
                notificationSettings={originalSettings}
              />

              <Button onClick={handleSave} disabled={formStatus !== 'dirty'}>
                Save
              </Button>
            </>
          ) : formStatus === 'success' ? (
            <Text>{t('saved')}</Text>
          ) : (
            <Text overrideColor className="text-amber-700">
              {t('expired')}
            </Text>
          )}
          <hr className="h-px bg-gray-300 border-0" />
          <Link color="green" href="/notifications">
            {t('manage')}
          </Link>
        </div>
      </div>
    </div>
  );
}
