import { IndicatorTier, SystemDisplayState } from '../../ComponentLibrary/src';
import { Fault, System } from '../../types';

// REMOVEME: This needs to be in the db aggregation
export const getSystemsWithCoordinates = (systems?: System[]): System[] => {
  return (
    systems?.filter(
      (s: System) => !isNaN(s.site?.coords?.latitude ?? NaN) && !isNaN(s.site?.coords?.longitude ?? NaN),
    ) ?? []
  );
};

export const getIndicatorTier = (childCount: number): IndicatorTier => {
  if (childCount > 29) {
    return IndicatorTier.seven;
  } else if (childCount > 19) {
    return IndicatorTier.six;
  } else if (childCount > 9) {
    return IndicatorTier.five;
  } else if (childCount > 4) {
    return IndicatorTier.four;
  } else if (childCount > 1) {
    return IndicatorTier.three;
  }
  return IndicatorTier.two;
};

export const systemHasWarnings = (system: System): boolean => {
  return !!(system.stats?.activeFaults as Fault[])?.filter((fault) => fault.severity !== 'Info')?.length;
};

export const mapStateToColorClass = (
  state: SystemDisplayState,
): { markerColorClass: string; markerBgColorClass: string } => {
  const colorBgClassDict = {
    [SystemDisplayState.enabled]: 'bg-emerald-500',
    [SystemDisplayState.standby]: 'bg-emerald-500',
    [SystemDisplayState.disabled]: 'bg-blue-400',
    [SystemDisplayState.faulted]: 'bg-red-600',
    [SystemDisplayState.warning]: 'bg-amber-700',
    [SystemDisplayState.unknown]: 'bg-gray-400',
  };

  const markerBgColorClass = colorBgClassDict[state as keyof typeof colorBgClassDict] || 'bg-gray-400';

  const colorClassDict = {
    [SystemDisplayState.enabled]: 'text-emerald-500',
    [SystemDisplayState.standby]: 'text-emerald-500',
    [SystemDisplayState.disabled]: 'text-blue-400',
    [SystemDisplayState.faulted]: 'text-red-600',
    [SystemDisplayState.warning]: 'text-amber-800',
    [SystemDisplayState.unknown]: 'text-gray-400',
  };

  const markerColorClass = colorClassDict[state as keyof typeof colorClassDict] || 'text-gray-400';

  return {
    markerColorClass,
    markerBgColorClass,
  };
};
