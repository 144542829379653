import { FilterKey, MenuItem } from '../../ComponentLibrary/src/Filter/index.types';
import { JsonApiQuery, queryToUrl } from '../../JsonApi/src';
import { Fault, ProductFamily } from '../../types';
import { Method } from '../../util/types';
import Api from '.';

export async function fetchUniqueFaultFieldValues(
  key: string,
  filter: FilterKey[],
  searchTerm?: string,
  onlySelected?: boolean,
): Promise<{
  data: MenuItem[];
  count: number;
} | void> {
  // ignore filters on the current key
  const sanitizedFilter = filter.filter((item) => (onlySelected ? item.id === key : item.id !== key));

  const query: JsonApiQuery = {
    filterKeys: sanitizedFilter,
  };

  if (!onlySelected) {
    query.page = {
      number: 1,
      size: 25,
    };
  }

  const params = queryToUrl(query);

  if (searchTerm) {
    params.textSearch = searchTerm;
  }

  return Api.request({ method: Method.get, path: `faults/filterKeys/${key}`, params }) as Promise<{
    data: MenuItem[];
    count: number;
  }>;
}

export async function fetchFault(faultCode: number, productFamily: ProductFamily): Promise<Fault | void> {
  return Api.request({ method: Method.get, path: `faults/${productFamily}/${faultCode}` }) as Promise<Fault>;
}

export async function fetchFaults(params: Record<string, unknown>): Promise<{ data: Fault[]; count: number } | void> {
  return Api.request({ method: Method.get, path: 'faults', params }) as Promise<{
    data: Fault[];
    count: number;
  }>;
}

export async function putFault(faultCode: number, productFamily: ProductFamily, fault: Fault): Promise<void> {
  return Api.request({
    method: Method.put,
    path: `faults/${productFamily}/${faultCode}`,
    data: fault,
  }) as Promise<void>;
}

export async function postFault(fault: Fault): Promise<void> {
  return Api.request({ method: Method.post, path: 'faults', data: fault }) as Promise<void>;
}

export async function deleteFault(faultCode: number): Promise<void> {
  return Api.request({ method: Method.delete, path: `faults/${faultCode}` }) as Promise<void>;
}
