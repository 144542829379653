import React from 'react';

import { DualStatusIndicator, IndicatorTier, SystemDisplayState } from '../../ComponentLibrary/src';
import { getSystemDisplayState } from '../../context/util';
import { Fault, System } from '../../types';
import { systemHasWarnings } from '../Overview/util';

export default function StatusIndicator({
  system,
  tier,
  isLoading,
}: {
  system?: System;
  tier?: IndicatorTier;
  isLoading?: boolean;
}): JSX.Element | null {
  if (!system) return null;
  const systemDisplayState = getSystemDisplayState(system);
  const online = systemDisplayState !== SystemDisplayState.unknown;
  const hasWarnings = online ? systemHasWarnings(system) : false;
  const tooltip = system.stats?.activeFaults
    ? `<div class="flex flex-col gap-1"><strong>${getSystemDisplayState(system)}</strong>${(
        system.stats?.activeFaults as Fault[]
      )?.reduce(
        (descriptions, fault) => (descriptions += '<div>[' + fault.faultCode + '] ' + fault.description + '</div>'),
        '',
      )}</div>`
    : getSystemDisplayState(system);

  return (
    <div data-tip={isLoading ? '' : tooltip} data-place="right" data-class="" data-multiline={false} data-html>
      <DualStatusIndicator
        primaryIndicatorState={isLoading ? SystemDisplayState.unknown : systemDisplayState}
        secondaryIndicatorState={
          isLoading
            ? undefined
            : hasWarnings && systemDisplayState !== SystemDisplayState.faulted
            ? SystemDisplayState.warning
            : undefined
        }
        tier={tier ?? IndicatorTier.one}
        online={false}
        data-pwid={system.sysId}
      />
    </div>
  );
}
