import '../style/release-notes.scss';

import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { fetchReleaseNotes } from '../adapters/api/releaseNotes';
import { Link, Text, TextType } from '../ComponentLibrary/src';
import { usePageContext } from '../components/Page';
import { useSetDocumentTitle } from '../hooks';
import { ReleaseNote } from '../types';

export default function ReleaseNotes(): JSX.Element {
  useSetDocumentTitle('Release Notes');
  const { t } = useTranslation('release_notes');
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [notes, setNotes] = useState<ReleaseNote[]>([]);

  useEffect(() => {
    setTitle(t('title'));
    setBreadcrumbs([]);
    setScrollable(true);
  }, [setTitle, setBreadcrumbs, setScrollable, t]);

  useEffect(() => {
    fetchReleaseNotes().then((notes: ReleaseNote[]) => {
      if (notes) {
        setNotes(notes);
      }

      setLoading(false);
    });
  }, []);

  return (
    <div className="notes-list-container">
      {loading ? (
        <Skeleton count={20} height={40} containerClassName="flex-1 px-2" className="my-2" />
      ) : (
        notes?.map((note: ReleaseNote) => (
          <Link key={note.releaseDate} className="note-item" href={`/releasenotes/${note.releaseDate}`}>
            <Text
              textClassName="min-w-[7rem] md:min-w-[7.5rem] lg:min-w-[9.2rem]"
              overflow=""
              type={TextType.h4}
              inline
            >
              {DateTime.fromISO(note.releaseDate).toFormat('DD')}
            </Text>
            <Text type={TextType.body} overflow="" wrap>
              {note.overview}
            </Text>
          </Link>
        ))
      )}
      {!loading && !notes?.length && (
        <div className="empty-results">
          <Text type={TextType.h4} overrideColor className="text-red-600">
            {t('no_release_notes_found')}
          </Text>
        </div>
      )}
    </div>
  );
}
