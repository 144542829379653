import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { fetchSystemFieldKeys } from '../../adapters/api/systems';
import { Filter, FilterKey } from '../../ComponentLibrary/src';
import { useMobile } from '../../hooks';
import { loadOptionLabels } from '../../util';

interface Props {
  filter: FilterKey[];
  show?: boolean;
  setEditingFilter: Dispatch<SetStateAction<boolean>>;
  onLoading: Dispatch<SetStateAction<boolean>>;
  onChangeShow: (show: boolean) => void;
  onChangeFilter: (filter: FilterKey[]) => void;
}

export const OverviewFilterContext = React.createContext(null);

export default function OverviewFilter({
  filter,
  show,
  setEditingFilter,
  onLoading,
  onChangeShow,
  onChangeFilter,
}: Props): JSX.Element {
  const [_show, _setShow] = useState(show);
  const isMobile = useMobile();

  useEffect(() => {
    _setShow(show);
  }, [_setShow, show]);

  const handleOnChangeShow = () => {
    _setShow(!_show);
    if (_show) {
      onChangeShow(!show);
    } else {
      onChangeShow(!show);
    }
  };

  const translation = useMemo(() => (isMobile ? '-translate-y-[4.25rem]' : '-translate-y-14'), [isMobile]);
  return (
    <div className={`flex flex-col items-center transition duration-500 relative ${_show ? '' : translation} mt-4`}>
      <Filter
        className={`p-2 rounded-sm shadow${isMobile ? ' h-fit' : ''} bg-white bg-opacity-50`}
        filter={filter}
        onChangeFilter={onChangeFilter}
        loadOptions={(key, searchTerm, onlySelected) =>
          fetchSystemFieldKeys({ filterKeys: filter, key, searchTerm, forMap: true, onlySelected })
        }
        onLoading={onLoading}
        autofocusSearch
        loadOptionLabels={loadOptionLabels}
        onChangeFilterEditing={setEditingFilter}
      />
      <div
        className={`bg-white bg-opacity-50 backdrop-blur-sm w-max px-4 rounded-b cursor-pointer shadow z-50`}
        onClick={handleOnChangeShow}
      >
        {_show ? (
          <ChevronUpIcon className="h-6 w-6 text-blue-800" />
        ) : (
          <ChevronDownIcon className="h-6 w-6 text-blue-800" />
        )}
      </div>
    </div>
  );
}
