import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Text, TextType } from '../../Text';
import { MenuItem } from '../index.types';

interface SingleProps {
  options?: MenuItem[];
  loading?: boolean;
  count?: number;
  onSelectItem: (result: MenuItem) => void;
}

const Single: React.FC<SingleProps> = ({ options, loading, count = 0, onSelectItem }: SingleProps) => {
  return (
    <div className="flex flex-col text-base font-normal">
      <ul className={`flex flex-col overflow-y-auto max-h-64 p-2 gap-2`}>
        {loading
          ? new Array(5).fill(0).map((_, i) => (
              <li key={i} className={`flex flex-row gap-2 items-center`}>
                <Skeleton containerClassName="w-full" />
              </li>
            ))
          : options
              ?.filter((option) => !option.selected)
              .map((menuItem, i) => (
                <li
                  data-testid={i}
                  data-pwid={menuItem.label ?? menuItem.id?.toString() ?? ''}
                  key={menuItem.key ?? menuItem.id?.toString() ?? ''}
                  className={`cursor-pointer highlight-color flex flex-row gap-2 items-center`}
                  onClick={() => onSelectItem(menuItem)}
                >
                  <Text type={TextType.custom}>{menuItem.label?.toString() ?? ''}</Text>
                </li>
              ))}
        {count > (options?.length ?? 0) && (
          <li key="more" className={`highlight-color flex flex-row gap-2 items-center`}>
            <Text type={TextType.custom} overrideColor className="disabled-text">{`And ${
              count - (options?.length ?? 0)
            } more...`}</Text>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Single;
