export type OptionId = string | number | boolean | null

export interface IdRange {
  from?: OptionId
  to?: OptionId
}

export interface Range {
  from?: number
  to?: number
}

export interface DateRange {
  from?: string | Date
  to?: string | Date
}

export enum Mode {
  multi = 'multi',
  range = 'range',
  single = 'single',
  dateRange = 'dateRange'
}
export interface MenuItem {
  key?: string
  id: OptionId
  label: string | number | null
  selected?: boolean
  openOnSelect?: boolean
}

export interface MultiKey extends MenuItem {
  mode?: Mode.multi | Mode.range
  selectedValues?: MenuItem[]
  exclusionMode?: boolean
  count?: number
  textSearch?: string
  textSearchId?: string
  range?: IdRange
  searchable?: boolean
}

export interface SingleKey extends MenuItem {
  mode?: Mode.single
  selectedValues?: MenuItem[]
  exclusionMode?: boolean
  textSearch?: string
  textSearchId?: string
  searchable?: boolean

  // disallowed properties
  range?: undefined
  count?: undefined
}
export interface RangeKey extends MenuItem {
  mode?: Mode.range | Mode.dateRange
  range?: Range | DateRange

  // disallowed properties
  selectedValues?: undefined
  exclusionMode?: undefined
  textSearch?: undefined
  textSearchId?: undefined
  searchable?: undefined
}

export type FilterKey = MultiKey | SingleKey | RangeKey
