import { BanIcon } from '@heroicons/react/outline';
import { ExclamationIcon } from '@heroicons/react/solid';
import * as L from 'leaflet';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MapContainer, ZoomControl } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import { gmApiKey } from '../../adapters/googleMaps';
import { FilterKey, Loading } from '../../ComponentLibrary/src';
import colors from '../../ComponentLibrary/src/style/colors';
import { AuthContext } from '../../context/Auth';
import { SystemsContext, useSystemsContextValue } from '../../context/Systems';
import { useMounted } from '../../hooks';
import { PERMISSIONS } from '../../util/constants';
import { usePolling } from '../../WebUtils';
import Footer from './Footer';
import MarkerCluster from './MarkerCluster';
import Metrics from './Metrics';
import { getSystemsWithCoordinates } from './util';

// const owmApiKey = '832a309d1014fb9f5423a39989aa11a9';

const earthBounds = new L.LatLngBounds([
  [-60, -210],
  [80, 180],
]);

export const addLeafletFilterStyle = (style: string | null): void => {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/filter
  const leafletTilePanes = document.getElementsByClassName('leaflet-tile-pane');

  for (let i = 0; i < leafletTilePanes.length; i++) {
    const el = leafletTilePanes[i] as HTMLElement;
    el.style.filter = style ?? '';
  }
};
interface Props {
  filter: FilterKey[];
  showMetrics: boolean;
  isMobile: boolean;
  hideStates?: boolean;
  searchTerm?: string;
  systemFilterLoading: boolean;
  editingFilter: boolean;
  setHideStates: Dispatch<SetStateAction<boolean>>;
  setShowControls: Dispatch<SetStateAction<boolean>>;
  'data-pwid'?: string;
}

const SystemsInformation = ({
  filter,
  showMetrics,
  isMobile,
  hideStates,
  searchTerm,
  systemFilterLoading,
  editingFilter,
  setShowControls,
  setHideStates,
  'data-pwid': dataPwId = 'systems-information',
}: Props): JSX.Element => {
  const [systemsFound, setSystemsFound] = useState(true);
  const [showSatellite, setShowSatellite] = useState(false);
  const [loading, setLoading] = useState(true);
  const { systemsSummary, getSystems } = useContext(SystemsContext);
  const { hasPermissions } = useContext(AuthContext);
  const [firstFetchComplete, setFirstFetchComplete] = useState(false);
  const [dragged, setDragged] = useState(false);
  const [bounds, setBounds] = useState(earthBounds);
  const mounted = useMounted();

  const updateSystems = async (): Promise<void> => {
    return getSystems({
      filterKeys: filter,
      systemsStats: true,
      count: true,
      textSearch: searchTerm,
      project: [
        'sysId',
        'site.coords',
        'site._id',
        'site.name',
        'stats.state',
        'stats.systemHours',
        'stats.engP',
        'stats.outE1',
        'stats.outE2',
        'stats.outE3',
        'stats.flow1Hours',
        'stats.activeFaults',
        'orgId',
        'org.name',
        'lastSeen',
        'latest',
        'distributorId',
        'distributor.name',
        'model',
        'qecOutConfig.aux', // to allow filtering unused power in stats
        ...filter
          .filter((item) => item.selected && item.id !== 'site._id' && !item.id?.toString()?.includes('latest.'))
          .map((filterKey) => filterKey.id?.toString() ?? ''),
      ],
      includeStats: true,
    }).then(() => {
      setLoading(false);
      if (!firstFetchComplete) setFirstFetchComplete(true);
    });
  };

  usePolling(() => {
    if (!editingFilter) {
      return updateSystems();
    }
  }, [filter, searchTerm, editingFilter]);

  useEffect(() => {
    setLoading(true);
    setDragged(false);
  }, [filter, searchTerm]);

  useEffect(() => {
    if (systemFilterLoading) setLoading(true);
  }, [systemFilterLoading]);

  // calculate the map view zoom boundaries
  useEffect(() => {
    let systemsBounds = earthBounds;
    const systemsWithCoordinates = getSystemsWithCoordinates(systemsSummary?.systems);
    // if there are no systems, zoom to entire earth
    if (!systemsWithCoordinates.length && !editingFilter) {
      setBounds(systemsBounds);
    } else {
      const systemsInNorthAmerica = systemsWithCoordinates?.filter(
        (system) =>
          (system.site?.coords?.longitude ?? 0) < -35 &&
          (system.site?.coords?.longitude ?? 0) > -170 &&
          (system.site?.coords?.latitude ?? 0) < 55 &&
          (system.site?.coords?.latitude ?? 0) > 15,
      );

      // if there is no filter applied (default filter) and there are any systems in N.A., then zoom to the N.A. systems
      if (systemsInNorthAmerica.length && !filter.some((item) => item.selected) && !searchTerm) {
        systemsBounds = new L.LatLngBounds(
          systemsInNorthAmerica.map((system) => [
            system.site?.coords?.latitude ?? 0,
            system.site?.coords?.longitude ?? 0,
          ]),
        );
      } else {
        // if there is a filter applied, then zoom to fit systems
        systemsBounds = new L.LatLngBounds(
          systemsWithCoordinates.map((system) => [
            system.site?.coords?.latitude ?? 0,
            system.site?.coords?.longitude ?? 0,
          ]),
        );
      }
    }
    if (!editingFilter) {
      setBounds(systemsBounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemsSummary?.systems]);

  const handleToggleControls = useCallback(() => {
    if (showSatellite) {
      addLeafletFilterStyle('');
    }
    setShowSatellite(!showSatellite);
  }, [showSatellite]);

  const handleToggleDemoMode = useCallback(() => {
    setHideStates(!hideStates);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideStates]);

  const layerControl = useMemo(
    () => (
      <div
        className="z-900 bottom-4 left-4 bg-white bg-opacity-50 p-1 backdrop-blur-sm rounded-sm text-lg shadow cursor-pointer"
        onClick={handleToggleControls}
        data-tip={!isMobile ? 'Toggle satellite/road map' : undefined}
      >
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="32px"
          height="32px"
          viewBox="0 0 48.698 48.698"
          // style="enable-background:new 0 0 48.698 48.698;"
          xmlSpace="preserve"
        >
          <g color="#002255">
            <polygon points="47.784,13.309 24.349,0 0.914,13.309 24.349,26.698" fill="currentColor" />
            <polygon
              points="24.349,29.002 8.548,19.974 0.914,24.309 24.349,37.698 47.784,24.309 40.151,19.974"
              fill="currentColor"
            />
            <polygon
              points="24.349,40.002 8.548,30.974 0.914,35.309 24.349,48.698 47.784,35.309 40.151,30.974"
              fill="currentColor"
            />
          </g>
        </svg>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile, handleToggleControls],
  );

  const hideStatesControl = useMemo(
    () => (
      <div
        className={`z-900 bg-white bg-opacity-50 p-1 backdrop-blur-sm rounded-sm text-lg shadow cursor-pointer flex items-center justify-center ${
          isMobile ? 'self-end' : ''
        }`}
        onClick={handleToggleDemoMode}
        data-tip={!isMobile ? 'Show map of install base (without system states)' : undefined}
        data-pwid={`${dataPwId}-stateless-map-toggle`}
      >
        {!hideStates && <BanIcon className={`w-8 h-8 text-red-500 absolute`} />}
        <ExclamationIcon className={`w-8 h-8 text-blue-800`} />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile, hideStates],
  );

  return (
    <div className="items-center w-full h-full flex flex-col justify-center">
      {!systemsFound && (
        <div className="z-600 flex flex-row items-center justify-center text-red-400">
          <p className="bg-white bg-opacity-50 p-4 backdrop-blur-sm rounded-sm text-lg shadow">No Systems Found</p>
        </div>
      )}
      {loading && (
        <div className={`absolute left-0 right-0 top-0 bottom-0 z-600 flex items-center justify-center`}>
          <Loading type="small" sizeClasses="h-12 w-12" className={`transition-all duration-250`} />
        </div>
      )}

      <div className="absolute bottom-2 left-2 flex flex-col gap-2">
        {hasPermissions(PERMISSIONS.dashboard.systems.hideStates) && hideStatesControl}
        {layerControl}
      </div>

      <div className={`absolute bottom-4 z-500 flex flex-col gap-4 items-center${isMobile ? ' w-2/3' : ''}`}>
        <div className="w-full">{!hideStates && showMetrics && <Metrics className="w-full" />}</div>
        {!isMobile && <Footer hideStates={hideStates} />}
      </div>

      {firstFetchComplete && (
        <MapContainer
          bounds={bounds}
          zoomControl={false}
          className="w-full h-full absolute"
          scrollWheelZoom={!isMobile}
          maxBounds={earthBounds}
          minZoom={2}
          maxZoom={20}
          maxBoundsViscosity={1}
          zoomSnap={1}
          attributionControl={false}
        >
          {mounted && showSatellite && (
            <ReactLeafletGoogleLayer
              apiKey={gmApiKey}
              type="hybrid"
              eventHandlers={{
                add: () => {
                  addLeafletFilterStyle('saturate(60%) brightness(130%) contrast(60%)');
                },
              }}
              googleMapsLoaderConf={{
                apiKey: gmApiKey,
                libraries: ['places'],
              }}
            />
          )}
          {!showSatellite && (
            <ReactLeafletGoogleLayer
              apiKey={gmApiKey}
              type="roadmap"
              styles={[
                // https://developers.google.com/maps/documentation/javascript/style-reference
                { featureType: 'all', stylers: [{ saturation: -100 }] },
                {
                  featureType: 'water',
                  stylers: [{ color: colors.blue['800'] }, { saturation: -60 }, { lightness: 70 }],
                },
                { elementType: 'labels', stylers: [{ visibility: 'off' }] },
                { featureType: 'poi', stylers: [{ visibility: 'off' }] },
                { featureType: 'administrative', elementType: 'geometry', stylers: [{ visibility: 'off' }] },
                {
                  featureType: 'administrative.country',
                  elementType: 'geometry',
                  stylers: [{ visibility: 'on' }],
                },
                {
                  featureType: 'administrative.province',
                  elementType: 'geometry',
                  stylers: [{ visibility: 'on' }],
                },
                {
                  featureType: 'administrative.locality',
                  elementType: 'geometry',
                  stylers: [{ visibility: 'on' }],
                },
                {
                  featureType: 'administrative.neighborhood',
                  elementType: 'geometry',
                  stylers: [{ visibility: 'on' }],
                },
                {
                  featureType: 'administrative.land_parcel',
                  elementType: 'geometry',
                  stylers: [{ visibility: 'on' }],
                },
                { featureType: 'administrative.locality', elementType: 'labels', stylers: [{ visibility: 'on' }] },
                { featureType: 'administrative.country', elementType: 'labels', stylers: [{ visibility: 'on' }] },
              ]}
              googleMapsLoaderConf={{
                apiKey: gmApiKey,
                libraries: ['places'],
              }}
            />
          )}
          <MarkerCluster
            setShowControls={setShowControls}
            setSystemsFound={setSystemsFound}
            hideStates={hideStates}
            bounds={bounds}
            setDragged={setDragged}
            dragged={dragged}
          />
          {/* <MarkerCluster> */}
          {/* TODO: Update when the react-leaflet-markercluster is updated to support react-leaflet v4 */}
          {/* TODO: Maybe replace with https://github.com/akursat/react-leaflet-cluster */}
          {/* {systemsWithCoordinates.map((system) => (
            <Marker
              key={system.sysId}
              icon={SystemIcon(system as System)}
              position={[system.site?.coords?.latitude as number, system.site?.coords?.longitude as number]}
            >
              <Popup>Test</Popup>
              <Tooltip className="bg-blue-800 border-blue-800">
                <Text type={TextType.custom} overrideColor className="text-sm text-white font-medium">
                  {system.sysId}
                </Text>
              </Tooltip>
            </Marker>
          ))} */}
          {/* </MarkerCluster> */}
          {<ZoomControl position="bottomright" data-pwid={`${dataPwId}-zoom-control`} />}
        </MapContainer>
      )}
    </div>
  );
};

export default function SystemsInformationContainer(props: Props): JSX.Element {
  const systemsContextValue = useSystemsContextValue();

  return (
    <SystemsContext.Provider value={systemsContextValue}>
      <SystemsInformation {...props} />
    </SystemsContext.Provider>
  );
}
