import { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';

import { fetchNestedAssets } from '../adapters/api/assets';
import { Sort } from '../JsonApi/src';
import { Organization } from '../types';

export interface AssetsContextData {
  assets: Organization[];
  assetsCount: number;
  setAssets: Dispatch<SetStateAction<Organization[]>>;
  getAssets: (params?: {
    sort?: Sort;
    countPerPage?: number;
    pageNumber?: number;
    project?: string[];
    addEmbeds?: boolean;
    searchTerm?: string;
  }) => Promise<void>;
}

const assetsContextDefaultValue: AssetsContextData = {
  assets: [],
  assetsCount: 0,
  getAssets: async () => undefined,
  setAssets: () => undefined,
};

export const useAssetsContextValue = (): AssetsContextData => {
  const [assets, setAssets] = useState<Organization[]>([]);
  const [assetsCount, setAssetsCount] = useState(0);

  const getAssets = useCallback(
    async (args?: {
      sort?: Sort;
      countPerPage?: number;
      pageNumber?: number;
      project?: string[];
      searchTerm?: string;
    }) => {
      return fetchNestedAssets(args).then((response) => {
        if (!response) return;
        const { orgs, count } = response;
        setAssets(orgs);
        setAssetsCount(count);
      });
    },
    [],
  );

  return {
    assets,
    assetsCount,
    getAssets,
    setAssets,
  };
};

export const AssetsContext = createContext<AssetsContextData>(assetsContextDefaultValue);
