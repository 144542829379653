import * as HeroIconsSolid from '@heroicons/react/solid';
import React, { SVGProps, SyntheticEvent } from 'react';

import colors from '../style/colors';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { PillProps, PillVariant } from './index.types';

export const Pill: React.FC<PillProps> = ({
  variant = PillVariant.primary,
  children,
  className,
  color,
  leftIcon,
  rightIcon,
  propagateRightIconClickEvent,
  propagateLeftIconClickEvent,
  tooltip,
  testId,
  onClick,
  onClickLeftIcon,
  onClickRightIcon,
  'data-pwid': dataPwId = 'pill',
}: PillProps) => {
  let LeftIcon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined = undefined;
  let RightIcon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined = undefined;
  if (leftIcon) LeftIcon = HeroIconsSolid[leftIcon];
  if (rightIcon) RightIcon = HeroIconsSolid[rightIcon];

  const handleClickRightIcon = (event: SyntheticEvent) => {
    if (!propagateRightIconClickEvent) event.stopPropagation();
    if (onClickRightIcon) onClickRightIcon();
  };

  const handleClickLeftIcon = (event: SyntheticEvent) => {
    if (!propagateLeftIconClickEvent) event.stopPropagation();
    if (onClickLeftIcon) onClickLeftIcon();
  };

  if (!color) {
    switch (variant) {
      case PillVariant.primary:
        color = colors.blue['800'];
        break;
      case PillVariant.secondary:
        color = colors.gray['400'];
        break;
      case PillVariant.warning:
        color = colors.amber['800'];
        break;
      case PillVariant.danger:
        color = colors.red['500'];
        break;
      case PillVariant.highlighted:
        color = colors.gray['50'];
    }
  }

  let content;
  let padding = '';
  if (LeftIcon && !RightIcon && !children) {
    content = <LeftIcon data-testid="left-icon" className={`h-4 w-4`} />;
    padding = ' px-1';
  } else if (!LeftIcon && RightIcon && !children) {
    content = <RightIcon data-testid="right-icon" className={`h-4 w-4`} />;
    padding = ' px-1';
  } else if (!LeftIcon && !RightIcon && children) {
    content = (
      <Text
        type={TextType.custom}
        overrideColor
        className={`text-sm font-medium flex-grow text-center w-full`}
        overflow="ellipsis"
      >
        {children}
      </Text>
    );
    padding = ' px-2';
  } else if (LeftIcon && children && !RightIcon) {
    content = (
      <>
        <span
          data-testid="icon"
          className={`absolute top-0 bottom-0 inset-y-0 left-2 flex items-center justify-center${
            onClickLeftIcon ? ' cursor-pointer ' : ''
          }`}
        >
          <LeftIcon data-testid="left-icon" className={`h-4 w-4`} onClick={handleClickLeftIcon} />
        </span>
        <Text
          type={TextType.custom}
          overrideColor
          className={`text-sm font-medium flex-grow text-center w-full pl-8 pr-2`}
          overflow="ellipsis"
        >
          {children}
        </Text>
      </>
    );
  } else if (!LeftIcon && children && RightIcon) {
    content = (
      <>
        <Text
          type={TextType.custom}
          overrideColor
          className={`text-sm font-medium flex-grow text-center w-full pr-8 pl-2`}
          overflow="ellipsis"
        >
          {children}
        </Text>
        <span
          data-testid="icon"
          data-pwid="pill-right-icon"
          className={`absolute top-0 bottom-0 inset-y-0 right-2 flex items-center justify-center${
            onClickRightIcon ? ' cursor-pointer ' : ''
          }`}
        >
          <RightIcon data-testid="right-icon" className={`h-4 w-4`} onClick={handleClickRightIcon} />
        </span>
      </>
    );
  } else if (LeftIcon && RightIcon && children) {
    content = (
      <>
        <span
          data-testid="icon"
          className={`absolute top-0 bottom-0 inset-y-0 left-2 flex items-center justify-center${
            onClickLeftIcon ? ' cursor-pointer ' : ''
          }`}
        >
          <LeftIcon
            data-testid="left-icon"
            className={`${onClickLeftIcon ? 'cursor-pointer ' : ''}h-4 w-4`}
            onClick={handleClickLeftIcon}
          />
        </span>
        <Text
          type={TextType.custom}
          overrideColor
          className={`text-sm font-medium flex-grow text-center w-full px-8`}
          overflow="ellipsis"
        >
          {children}
        </Text>
        <span
          data-testid="icon"
          className={`absolute top-0 bottom-0 inset-y-0 right-2 flex items-center justify-center${
            onClickRightIcon ? ' cursor-pointer ' : ''
          }`}
        >
          <RightIcon
            data-testid="right-icon"
            data-pwid={`${dataPwId}-right-icon`}
            className={`${onClickRightIcon ? 'cursor-pointer ' : ''}h-4 w-4`}
            onClick={handleClickRightIcon}
          />
        </span>
      </>
    );
  }

  let hoverStyle = ' ';
  if (onClick) {
    hoverStyle = variant === PillVariant.primary ? ' hover:bg-blue-800 ' : ' hover:bg-gray-433 ';
  }

  return (
    <div
      data-testid={testId ?? 'pill'}
      data-pwid={dataPwId}
      data-tip={tooltip}
      className={`${onClick ? `cursor-pointer ${hoverStyle}` : ''} ${
        variant === PillVariant.highlighted ? 'text-blue-800' : 'text-white'
      } border-0 h-5 rounded-sm text-sm relative flex flex-row items-center gap-1 ${padding}${appendClassProps(
        className,
      )}`}
      onClick={onClick}
      style={{
        backgroundColor: color,
      }}
    >
      {content}
    </div>
  );
};

export * from './index.types';
