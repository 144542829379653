import DOMPurify from 'dompurify';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Button, Variant } from '../../Button';
import { Checkbox } from '../../Checkbox';
import { Text } from '../../Text';
import { MenuItem } from '../index.types';

interface MultiProps {
  options?: MenuItem[];
  exclusionMode?: boolean;
  count?: number;
  loading?: boolean;
  selectedCount?: number;
  searchTerm?: string;
  onSelectApplySearchTerm: () => void;
  onClearApplySearchTerm: () => void;
  onChange?: (changedItem: MenuItem) => void;
  onChangeSelectionMode?: (exclusionMode: boolean) => void;
}

const Multi: React.FC<MultiProps> = ({
  options,
  exclusionMode,
  count = 0,
  selectedCount = 0,
  loading,
  searchTerm,
  onSelectApplySearchTerm,
  onClearApplySearchTerm,
  onChange,
  onChangeSelectionMode,
}: MultiProps) => {
  const handleClickItem = (item: MenuItem) => {
    const updatedItem = {
      ...item,
      selected: !item?.selected ?? true,
    };
    if (onChange) onChange(updatedItem);
  };

  const toggleSelectionMode = () => {
    if (onChangeSelectionMode) onChangeSelectionMode(!exclusionMode);
    if (searchTerm && exclusionMode) onClearApplySearchTerm();
  };

  return (
    <div className="flex flex-col text-base font-normal">
      <div
        data-testid={'(de)select all'}
        key={'(de)select all'}
        className={`flex flex-row w-full justify-between px-2`}
      >
        {searchTerm && !exclusionMode ? (
          <Button
            size="small"
            variant={Variant.secondaryFilled}
            onClick={onSelectApplySearchTerm}
          >{`Apply "${searchTerm.substring(0, 12)}${searchTerm.length > 12 ? '...' : ''}"`}</Button>
        ) : (
          <span className="clickable-text-opacity cursor-pointer" onClick={toggleSelectionMode}>
            {exclusionMode ? 'clear all' : 'select all'}
          </span>
        )}
        <span>
          {selectedCount}/{count ?? 0}
        </span>
      </div>
      <ul className={`flex flex-col overflow-y-auto max-h-64 p-2`}>
        {loading
          ? new Array(5).fill(0).map((_, i) => (
              <li key={i} className={`flex flex-row gap-2 items-center`}>
                <Checkbox
                  id=""
                  checked={false}
                  onChangeValue={() => {
                    // noop
                  }}
                />
                <Skeleton containerClassName="w-full" />
              </li>
            ))
          : options?.map((option, i) => (
              <li
                data-testid={i}
                data-pwid={option.id?.toString() ?? ''}
                key={(option.key || option.id?.toString()) ?? ''}
                className="cursor-pointer highlight-color flex flex-row gap-2 items-center"
                onClick={() => handleClickItem(option)}
              >
                {/* TODO: detect shift click to select more than 1 at a time */}
                <Checkbox
                  id={i.toString()}
                  checked={exclusionMode ? !option.selected : option.selected ?? false}
                  onChangeValue={() => handleClickItem(option)}
                  skipRegister
                />
                {searchTerm ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(option.label?.toString() ?? '', {
                        ALLOWED_TAGS: ['b'],
                      }),
                    }}
                  ></span>
                ) : (
                  option.label
                )}
              </li>
            ))}
        {count > (options?.length ?? 0) && !(searchTerm && exclusionMode) && (
          <li key="more" className={`highlight-color flex flex-row gap-2 items-center`}>
            <Text overrideColor className="disabled-text">{`And ${count - (options?.length ?? 0)} more...`}</Text>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Multi;
