import React, { ChangeEvent, useContext, useMemo } from 'react';

import { AssistiveText } from '../AssistiveText';
import FormGroupContext from '../FormGroup/FormGroupContext';
import { InputType } from '../Input/index.types';
import { Label } from '../Label';
import { appendClassProps } from '../util';
import { useFormControlValidation } from '../util/hooks';
import { CheckboxProps } from './index.types';

export const Checkbox = function Checkbox({
  id,
  label,
  labelColor,
  children,
  tooltip,
  className,
  checked,
  disabled,
  showOptional,
  indeterminate = false,
  required = false,
  skipRegister = false,
  onChangeValue,
  onBlur,
  validator,
  'data-pwid': dataPwId,
  'data-testid': dataTestId,
}: CheckboxProps): JSX.Element {
  const { group, selected, disabled: groupDisabled, onChange, onBlur: groupBlur } = useContext(FormGroupContext);
  const { error, formDisabled, formStateValue, handleOnBlur, handleOnChange } = useFormControlValidation<boolean>({
    id,
    required,
    onBlur: (e, newVal) => onBlur?.(e, !!newVal),
    onChange: (newVal, formCtx) => onChangeValue?.(!!newVal, formCtx),
    validator,
    skipRegister: !!group || skipRegister,
  });

  const handleRefIndeterminate = (el: HTMLInputElement) => {
    if (el) el.indeterminate = indeterminate;
  };

  const handleLocalOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (group) return onChange(id, undefined, e.target.checked);

    handleOnChange(e.target.checked);
  };

  const handleLocalOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const event = e as React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>;
    if (group) return groupBlur(event);

    handleOnBlur(event, e.target.checked);
  };

  const checkboxVal = useMemo(() => {
    if (group) {
      return (selected as string[])?.includes(id);
    }
    return checked ?? formStateValue ?? false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStateValue, selected, checked]);

  const checkboxDisabled = useMemo(() => {
    return disabled ?? (group ? groupDisabled : formDisabled);
  }, [disabled, groupDisabled, formDisabled, group]);

  return (
    <div className={`flex flex-col${appendClassProps(className)}`}>
      <div className="flex flex-row gap-2 items-center">
        <input
          id={id}
          className={`cursor-pointer text-blue-800 focus:ring-0 focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-800 focus-visible:ring-offset-0 focus-visible:ring-offset-white${
            checkboxDisabled ? ' !bg-gray-150 cursor-not-allowed' : ''
          }`}
          type={InputType.checkbox}
          data-testid={dataTestId ?? group ? `${group}-${id}` : 'checkbox'}
          onChange={handleLocalOnChange}
          onBlur={handleLocalOnBlur}
          // This is to prevent nested checkboxes from bubbling click event
          onClick={(e) => e.stopPropagation()}
          checked={checkboxVal}
          disabled={checkboxDisabled}
          name={group}
          data-pwid={dataPwId ?? 'checkbox'}
          ref={handleRefIndeterminate}
        />
        {label ? (
          <Label
            htmlFor={id}
            className={`${checkboxDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            tooltip={tooltip}
            textColor={labelColor}
            showOptional={showOptional ?? (!group && !required && !checkboxDisabled)}
            wrap
          >
            {label}
          </Label>
        ) : (
          // This is to prevent nested checkboxes from bubbling click event also allow links and other html elements to render
          <label htmlFor={id} onClick={(e) => e.stopPropagation()}>
            {children}
          </label>
        )}
      </div>
      <AssistiveText id={id} error={error} alwaysDisplay={required} />
    </div>
  );
};

export * from './index.types';
