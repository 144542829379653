import React, { useContext } from 'react';

import { Button, Type as ButtonType, Variant } from '../Button';
import FormContext from '../Form/FormContext';
import { Loading } from '../Loading';
import { appendClassProps } from '../util';
import { FormSaveProps } from './index.types';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const FormSave: React.FC<FormSaveProps> = ({
  className,
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
  cancelBtnDisable,
  submitBtnDisable,
}: FormSaveProps) => {
  const { dirty, isValidating, formDisabled, saving } = useContext(FormContext);

  return (
    <div className={`flex flex-row gap-2${appendClassProps(className)}`} data-pwid="form-save" data-testid="form-save">
      <Button
        type={ButtonType.reset}
        variant={Variant.secondary}
        className="min-w-content"
        disabled={cancelBtnDisable === undefined ? false : !dirty || formDisabled || isValidating}
        data-pwid="form-save-cancel"
        data-testid="form-save-cancel"
      >
        {cancelLabel}
      </Button>
      <Button
        type={ButtonType.submit}
        className="min-w-content"
        disabled={submitBtnDisable === undefined ? formDisabled || !dirty || isValidating : submitBtnDisable}
        data-pwid="form-save-submit"
        data-testid="form-save-submit"
      >
        {saving ? <Loading type="small" /> : saveLabel}
      </Button>
    </div>
  );
};
