import React from 'react';

import { Border, Button, Card, Error, HeroIcons, KeyValue, Text, TextType, Variant } from '../../ComponentLibrary/src';
import { SummitInfo } from '../../types';
import { copyTextToClipboard } from '../../util';

interface Props {
  summitInfo?: SummitInfo;
  modemError?: string;
  optConnectModemSN?: string | null;
  isLoading: boolean;
}

export default function Modem({ summitInfo, modemError, optConnectModemSN, isLoading }: Props): JSX.Element {
  const handleClickCopyModemIp = () => {
    copyTextToClipboard(summitInfo?.dynamicFields?.find(({ key }) => key === 'lastConnectedIp')?.value || '');
  };

  return (
    <Card className="flex flex-col gap-2 w-full" data-pwid="system-modem-card">
      <Text type={TextType.h3}>Qnergy Modem</Text>
      <KeyValue
        tooltip="Opt Connect Modem Serial Number"
        label="Serial"
        value={summitInfo?.serialNumber ?? optConnectModemSN ?? ''}
        loading={isLoading}
        border={Border.none}
      />
      {summitInfo?.serialNumber ? (
        <>
          <div className="flex justify-between gap-2 w-full">
            <KeyValue
              className="flex-1"
              tooltip="Modem IP Address"
              label="IP"
              value={`${summitInfo?.dynamicFields?.find(({ key }) => key === 'lastConnectedIp')?.value || ''}`}
              loading={isLoading}
              border={Border.none}
            />

            <Button
              size="small"
              onClick={handleClickCopyModemIp}
              icon={HeroIcons.ClipboardCopyIcon}
              variant={Variant.secondaryFilled}
            />
          </div>
          <KeyValue
            tooltip="Cellular Carrier"
            label="Carrier"
            value={`${summitInfo?.carrier || ''}`}
            loading={isLoading}
            border={Border.none}
          />
        </>
      ) : (
        modemError && <Error showErrorMessage errorMessage={modemError} />
      )}
    </Card>
  );
}
