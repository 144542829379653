import { Dispatch, ReactElement, useEffect, useReducer, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export interface Pagination {
  before?: string;
  after?: string;
}

export enum LoadingDirection {
  UP = 'up',
  DOWN = 'down',
}

export enum ActionType {
  APPEND = 'append',
  PREPEND = 'prepend',
  CLEANUP = 'cleanup',
  EMPTY = 'empty',
}

export interface LoadItemsParams {
  loadItems: (query: Pagination) => Promise<ReactElement[]>;
  maxBufferSize: number;
  focusedItem?: string;
  searchParams: URLSearchParams;
  triggerLoadItems?: unknown; // trigger load items when this variable changes
}

export function useItemsReducer({
  previousScroll,
  scrollableContainerRef,
  maxBufferSize,
}: {
  previousScroll: React.MutableRefObject<{
    height?: number;
    yLocation?: number;
    rendering: boolean;
  }>;
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  maxBufferSize: number;
}): [
  ReactElement[],
  Dispatch<{
    type: ActionType;
    items?: ReactElement[] | undefined;
  }>,
] {
  return useReducer(
    (
      items: ReactElement[],
      action: {
        type: ActionType;
        items?: ReactElement[];
      },
    ) => {
      switch (action.type) {
        case ActionType.PREPEND: {
          const newItems = [...(action?.items ?? []), ...items];
          if (previousScroll.current && scrollableContainerRef.current) {
            previousScroll.current.height = scrollableContainerRef.current.scrollHeight;
            previousScroll.current.yLocation = scrollableContainerRef.current.scrollTop;
          }
          previousScroll.current.rendering = true;
          return newItems;
        }
        case ActionType.APPEND: {
          let newItems = [...items, ...(action?.items ?? [])];
          newItems = newItems.slice(Math.max(newItems.length - maxBufferSize, 0));
          return newItems;
        }
        case ActionType.CLEANUP: {
          // clean up after prepending
          const newItems = items.slice(0, maxBufferSize);
          return newItems;
        }
        case ActionType.EMPTY: {
          return [];
        }
        default:
          return items;
      }
    },
    [],
  );
}

export function useInfiniteScroll({ loadItems, maxBufferSize, searchParams, triggerLoadItems }: LoadItemsParams): {
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  loading: LoadingDirection | undefined;
  items: ReactElement[];
  errorLoadingItems: boolean;

  getItems: (pagination: Pagination) => void;
  preLastItem: React.RefCallback<HTMLDivElement>;
  lastItem: React.RefCallback<HTMLDivElement>;
  preFirstItem: React.RefCallback<HTMLDivElement>;
  firstItem: React.RefCallback<HTMLDivElement>;
} {
  const getItemsCancelledRef = useRef<boolean>(false);
  const cursor = useRef<Pagination>({
    after: searchParams.get('after') ?? undefined,
  });

  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const previousScroll = useRef<{
    height?: number;
    yLocation?: number;
    rendering: boolean;
  }>({
    height: undefined,
    yLocation: undefined,
    rendering: false,
  });
  const [loading, setLoading] = useState<LoadingDirection | undefined>(undefined);
  const [errorLoadingItems, setErrorLoadingItems] = useState(false);
  // this useReducer maintains a buffer of items that will make sure it will not exceed MAX_BUFFER_SIZE in length by popping out old items
  // when new items are added
  const [items, dispatchItems] = useItemsReducer({
    previousScroll,
    scrollableContainerRef,
    maxBufferSize,
  });

  useEffect(() => {
    cursor.current.after = items[items.length - 1]?.key?.toString() ?? undefined;
    cursor.current.before = items[0]?.key?.toString() ?? undefined;
  }, [items]);

  // scroll to last item if scrolling up
  useEffect(() => {
    if (previousScroll.current.rendering) {
      if (
        scrollableContainerRef.current &&
        previousScroll.current.height !== undefined && // these are undefined if scrolling down
        previousScroll.current.yLocation !== undefined // these are undefined if scrolling down
      ) {
        const addedHeight = scrollableContainerRef.current.scrollHeight - previousScroll.current.height;

        const newLocation = addedHeight + previousScroll.current?.yLocation;

        scrollableContainerRef.current.scroll({
          top: newLocation,
        });
      }

      dispatchItems({
        type: ActionType.CLEANUP,
      });
      previousScroll.current.rendering = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, scrollableContainerRef]);

  const getItems = async (pagination: Pagination) => {
    setLoading(pagination.before ? LoadingDirection.UP : LoadingDirection.DOWN);
    let newItems = null;
    try {
      newItems = await loadItems(pagination);
      if (!newItems?.length) {
        if (pagination.after && !items.length) {
          // fetch items before the cursor if there are no items after and no items were loaded yet
          newItems = await loadItems({
            before: pagination.after,
          });
        }
      }

      if (!getItemsCancelledRef.current && newItems.length)
        dispatchItems({
          type: pagination.before ? ActionType.PREPEND : ActionType.APPEND,
          items: newItems,
        });
    } catch {}
    if (!newItems) {
      if (!getItemsCancelledRef.current) setErrorLoadingItems(true);
    } else {
      if (!getItemsCancelledRef.current) setErrorLoadingItems(false);
    }
    if (!getItemsCancelledRef.current) setLoading(undefined);
  };

  // call loadItems when triggerLoadItems changes
  useEffect(() => {
    dispatchItems({ type: ActionType.EMPTY });
    if (scrollableContainerRef.current) scrollableContainerRef.current.scrollTop = 0;
    cursor.current.after = searchParams.get('after') ?? undefined;
    getItems({
      after: cursor.current.after,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerLoadItems]);

  useEffect(() => {
    // cancel pending fetches on unmount
    return () => {
      getItemsCancelledRef.current = true;
    };
  }, []);

  const [lastItem] = useInView({
    onChange: (inView) => {
      if (!inView) return;
      lastItem(null);
      preLastItem(null);
      getItems({
        after: cursor.current.after,
      });
    },
  });
  const [preLastItem] = useInView({
    onChange: (inView) => {
      if (!inView) return;
      lastItem(null);
      preLastItem(null);
      getItems({
        after: cursor.current.after,
      });
    },
  });
  const [firstItem] = useInView({
    onChange: (inView) => {
      if (!inView) return;
      firstItem(null);
      preFirstItem(null);
      getItems({
        before: cursor.current.before,
      });
    },
  });
  const [preFirstItem] = useInView({
    onChange: (inView) => {
      if (!inView) return;
      firstItem(null);
      preFirstItem(null);
      getItems({
        before: cursor.current.before,
      });
    },
  });

  return {
    scrollableContainerRef,
    loading,
    items,
    errorLoadingItems,
    getItems,
    preLastItem,
    lastItem,
    preFirstItem,
    firstItem,
  };
}
