import { PlusIcon } from '@heroicons/react/solid';
import cloneDeep from 'lodash.clonedeep';
import React from 'react';

import { Button, Type, Variant } from '../Button';
import { HeroIcons } from '../style/heroicons';
import { Text, TextType } from '../Text';
import { useMobile } from '../util/hooks';
import FilterMenu from './FilterMenu';
import { FilterKey, MenuItem, Mode } from './index.types';

interface Props {
  filter: FilterKey[];
  setFilter: (filter: FilterKey[]) => void;
}

export default function UnselectedFilterKey({ filter, setFilter }: Props): JSX.Element {
  const isMobile = useMobile();

  const handleChangeFilterKeys = ({ selectedValues }: { selectedValues?: MenuItem[] }) => {
    const newFilter = cloneDeep(filter);
    for (let i = 0; i < newFilter.length; i++) {
      newFilter[i] = {
        ...newFilter[i],
        ...{
          ...selectedValues?.find((item: MenuItem) => item.id === newFilter[i].id),
          openOnSelect: true,
        },
      };
    }
    setFilter(newFilter);
  };

  return (
    <FilterMenu
      optionsTestId="category-selector"
      dropdownTestId="unselected-filter"
      searchable={false}
      mode={Mode.single}
      onChange={handleChangeFilterKeys}
      selectedValues={[]}
      loadOptions={async (searchTerm?: string) => {
        return {
          data: filter.filter((key) =>
            !key.selected &&
            // if search term exists, filter by search term
            searchTerm
              ? (key.label?.toString() ?? '').toLowerCase().includes(searchTerm?.toLowerCase())
              : true,
          ),
        };
      }}
    >
      {isMobile && !filter.some((item) => item.selected) ? (
        <Button type={Type.button} icon={HeroIcons.FilterIcon} variant={Variant.secondaryFilled} />
      ) : (
        <div className="flex flex-row items-center text-blue-800 clickable-text-opacity">
          <PlusIcon className={`h-4 w-4 text-blue-800`} />
          <Text type={TextType.custom} className={`font-medium cursor-pointer text-blue-800`} overrideColor>
            Filter
          </Text>
        </div>
      )}
    </FilterMenu>
  );
}
