import * as HeroIconsSolid from '@heroicons/react/solid';
import React, { useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { HeroIcons } from '../style/heroicons';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { ListItemProps, ListProps } from './index.types';

const ListItem: React.FC<ListItemProps> = ({
  thumbnail,
  indicator,
  indicator2,
  title,
  subtitle,
  description,
  properties,
  linkUrl,
  tooltip,
  state,
  loading,
  clickHandler,
  className,
  'data-pwid': pwId = 'list',
}: ListItemProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  let Thumbnail;
  if (thumbnail) {
    if (Object.values(HeroIcons).includes(thumbnail as HeroIcons)) {
      Thumbnail = HeroIconsSolid[thumbnail as keyof typeof HeroIconsSolid];
    } else if (typeof thumbnail === 'string') {
      Thumbnail = function Thumbnail() {
        return <img className="h-full" src={thumbnail} alt={thumbnail} />;
      };
    } else if (thumbnail) {
      Thumbnail = (): JSX.Element => thumbnail;
    }
  }

  const handleClickItem = () => {
    if (linkUrl) navigate(linkUrl, { state });
    if (clickHandler) clickHandler();
  };

  return (
    <div
      className={`flex flex-col gap-2 border-gray-200 border-b p-2${appendClassProps(className)}`}
      onClick={handleClickItem}
      data-pwid={pwId}
    >
      <div className="flex flex-row items-center gap-2">
        {indicator && <div className="w-6 h-6 flex items-center justify-center">{indicator ? indicator : null}</div>}
        {Thumbnail && (
          <div
            ref={tooltipRef}
            className="w-12 h-12 flex justify-center"
            data-tip={tooltip}
            onClick={() => {
              if (tooltipRef.current) ReactTooltip.show(tooltipRef.current);
            }}
          >
            <Thumbnail className="text-blue-800 w-full h-full" />
          </div>
        )}
        <div className="flex-1 flex flex-col overflow-hidden">
          <Text type={TextType.h4} className="font-semibold">
            {loading ? <Skeleton containerClassName="w-full" /> : title}
          </Text>
          <Text type={TextType.h6} inline>
            {loading ? <Skeleton containerClassName="w-full" /> : subtitle}
          </Text>
        </div>
        {indicator2 && <div>{indicator2}</div>}
      </div>
      <Text type={TextType.h6} inline wrap>
        {loading ? <Skeleton containerClassName="w-full" /> : description}
      </Text>
      <div className="flex flex-row flex-wrap gap-2">
        {loading ? <Skeleton containerClassName="w-full" /> : properties}
      </div>
    </div>
  );
};

/**
 - Use card as a generic UI wrapper for visual organization
 */
export const List: React.FC<ListProps> = ({ items, loadingCount, className }: ListProps) => {
  let itemComponents = items?.map((props: ListItemProps, i) => {
    return <ListItem key={props.id ?? i} {...props} />;
  });

  if (loadingCount) {
    itemComponents = [];
    for (let i = 0; i < loadingCount; i++) {
      itemComponents.push(<ListItem key={i} loading />);
    }
  }

  return <div className={`relative flex-1 flex flex-col ${appendClassProps(className)}`}>{itemComponents}</div>;
};

export * from './index.types';
