import { DocumentTextIcon, LinkIcon } from '@heroicons/react/outline';
import { Duration } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Api from '@/adapters/api';
import { Button, HeroIcons, Link, Text, TextType, Variant } from '@/ComponentLibrary/src';
import TypeIndicator from '@/components/System/TypeIndicator';
import { ITimelineItem } from '@/context/Systems';
import { copyTextToClipboard } from '@/util';
import { getDisplayTime } from '@/WebUtils';

import { getContentText, getTitleText } from '../util';

interface Props {
  item: ITimelineItem;
  focused?: boolean;
  hasFilter?: boolean;
  hovered?: boolean;
  onExportFaultDump: (item: ITimelineItem) => void;
}

export default function EventItem({ item, focused, hasFilter, hovered, onExportFaultDump }: Props): JSX.Element {
  const { t } = useTranslation('events');
  const apiDown = Api.isApiDown();
  const relativeTime = getDisplayTime(item.ts, Api.getServerTime());
  const exactTime = getDisplayTime(item.ts, Api.getServerTime(), Duration.fromObject({ days: 0 }));

  const focusKey = useMemo(() => {
    return `${new Date(item.ts).getTime()}-${item._id}`;
  }, [item]);

  const eventPath = `${window.location.pathname}?focusedItem=${focusKey}`;

  return (
    <div className={`flex flex-row p-2 gap-2 ${focused ? 'focused' : ''} w-full`}>
      <div className="h-full w-48" data-pwid="date-time">
        <Text wrap inline>
          {exactTime}
        </Text>
        {!apiDown && <Text wrap>{relativeTime}</Text>}
      </div>
      <div className="h-full flex flex-row flex-1 justify-center items-center" data-pwid="type-indicator">
        <TypeIndicator item={item} />
      </div>
      <div className="flex flex-col flex-10 gap-3">
        <div>
          <Text
            type={TextType.custom}
            className="text-lg leading-none"
            textClassName="flex gap-3"
            data-pwid="event-title"
            inline
          >
            {getTitleText(item)}
            <div className="flex gap-2">
              <Text
                type={TextType.custom}
                className={`text-gray-500 cursor-pointer text-sm hover:text-gray-600 transition-all ${
                  hovered ? 'opacity-100' : 'opacity-0 -translate-x-3'
                }`}
                textClassName="flex flex-row gap-1"
                data-pwid="link-event"
                onClick={(e) => {
                  e.stopPropagation();
                  copyTextToClipboard(`${window.location.origin}${eventPath}`);
                }}
                overrideColor
                inline
              >
                <LinkIcon className="h-4 w-4" />
                {t('copy_link')}
              </Text>
              {hasFilter && (
                <Link
                  href={eventPath}
                  className={`flex flex-row gap-1 text-gray-500 cursor-pointer text-sm hover:text-gray-600 transition-all ${
                    hovered ? 'delay-[50ms] opacity-100' : 'opacity-0 -translate-x-3'
                  }`}
                  data-pwid="go-to-context"
                  state={{ initialQuery: true }}
                >
                  <DocumentTextIcon className="h-4 w-4" />
                  {t('view_in_context')}
                </Link>
              )}
            </div>
          </Text>
        </div>
        <Text data-pwid="description" wrap>
          {getContentText(item)}
        </Text>
      </div>

      <div className="flex gap-2 justify-center flex-1 items-center" data-pwid="system-hours">
        {item.data.systemHoursTs !== undefined && (
          <>
            <Text className="font-semibold" inline>
              {item.data.systemHoursTs}
            </Text>
            <Text inline>hrs</Text>
          </>
        )}
      </div>
      <div className="flex flex-row justify-end gap-2 items-center flex-1">
        {item.data?.faultDump && (
          <Button
            icon={HeroIcons.DownloadIcon}
            variant={Variant.secondaryFilled}
            onClick={(e) => {
              e.stopPropagation();
              onExportFaultDump(item);
            }}
            size="small"
            tooltip={t('export')}
            alignment="center"
            data-pwid="fault-dump"
          />
        )}
      </div>
    </div>
  );
}
