import * as L from 'leaflet';
import { uniq } from 'lodash';
import countBy from 'lodash.countby';
import React from 'react';

import { DualStatusIndicator, SystemDisplayState } from '../../ComponentLibrary/src';
import { getIndicatorTier } from './util';

interface Props {
  cluster: L.MarkerCluster;
}

const getMostCriticalState = (states: SystemDisplayState[], hasWarnings: boolean): SystemDisplayState | undefined => {
  if (!Array.isArray(states) || states.length < 1) {
    if (hasWarnings) return SystemDisplayState.warning;
    return;
  }
  if (states.some((state) => state === SystemDisplayState.faulted)) return SystemDisplayState.faulted;
  if (hasWarnings) return SystemDisplayState.warning;
  if (states.some((state) => state === SystemDisplayState.warning)) return SystemDisplayState.warning;
  if (states.some((state) => state === SystemDisplayState.standby)) return SystemDisplayState.standby;
  if (states.some((state) => state === SystemDisplayState.enabled)) return SystemDisplayState.enabled;
  if (states.some((state) => state === SystemDisplayState.disabled)) return SystemDisplayState.disabled;
  if (states.every((state) => state === SystemDisplayState.unknown)) return SystemDisplayState.unknown;
};

const getPrimaryIndicatorState = (states: SystemDisplayState[]): SystemDisplayState => {
  // 1. return the state that is most common (except for Unknown)
  // 2. in case of a tie, return the most critical state
  const count = countBy(
    states.every((state) => state === SystemDisplayState.unknown)
      ? states
      : states.filter((state) => state !== SystemDisplayState.unknown),
  );

  const highestOccurrance = Object.values(count).reduce((highest, val) => {
    if (val > highest) highest = val;
    return highest;
  }, 0);

  const statesWithHighestOccurrance = Object.entries(count)
    .filter(([, occurances]) => {
      if (occurances === highestOccurrance) return true;
    })
    .map(([state]) => state) as SystemDisplayState[];

  if (statesWithHighestOccurrance.length === 1) return statesWithHighestOccurrance[0] as SystemDisplayState;
  return getMostCriticalState(statesWithHighestOccurrance, false) as SystemDisplayState;
};

export default function ClusterIndicator({ cluster }: Props): JSX.Element {
  const getSecondaryIndicatorState = (
    states: SystemDisplayState[],
    primaryIndicatorState: SystemDisplayState,
    hasWarnings: boolean,
  ) => {
    let uniqueStatesUpdated = uniq(states);
    uniqueStatesUpdated = uniqueStatesUpdated.filter((state) => state !== primaryIndicatorState);
    const mostCriticalState = getMostCriticalState(uniqueStatesUpdated, hasWarnings);
    if (mostCriticalState === primaryIndicatorState) {
      return getMostCriticalState(
        uniqueStatesUpdated.filter((state) => state !== primaryIndicatorState),
        hasWarnings,
      );
    }
    return mostCriticalState;
  };

  const getIndicatorStates = (
    states: SystemDisplayState[],
    hasWarnings: boolean,
  ): { primaryIndicatorState: SystemDisplayState; secondaryIndicatorState?: SystemDisplayState } => {
    const primaryIndicatorState = getPrimaryIndicatorState(states);
    const secondaryIndicatorState = getSecondaryIndicatorState(states, primaryIndicatorState, hasWarnings);
    return { primaryIndicatorState, secondaryIndicatorState };
  };

  const allMarkerClasses = cluster.getAllChildMarkers().map((marker) => marker.options?.icon?.options?.className);
  const online = !allMarkerClasses.every((markerClass) => markerClass?.includes(SystemDisplayState.unknown));
  const hasWarnings = allMarkerClasses.some((markerClass) => markerClass?.split('-')[1] === 'warning');
  const { primaryIndicatorState, secondaryIndicatorState } = getIndicatorStates(
    allMarkerClasses.map((markerClass) => markerClass?.split('-')[0] as SystemDisplayState),
    hasWarnings,
  );

  const tier = getIndicatorTier(cluster.getChildCount());

  const clusterCount = cluster.getChildCount();

  return (
    <DualStatusIndicator
      primaryIndicatorState={primaryIndicatorState}
      secondaryIndicatorState={secondaryIndicatorState}
      tier={tier}
      online={online}
      clusterCount={clusterCount}
      data-pwid={`cluster-indicator-${clusterCount}`}
    />
  );
}
