import React, { useContext } from 'react';

import { Carousel, Metric } from '../../ComponentLibrary/src';
import { useMobile } from '../../ComponentLibrary/src/util/hooks';
import { SystemsContext, SystemsSummary } from '../../context/Systems';
import { isCap } from '../../pages/System/util';
import { GenSystem } from '../../types';
import { appendClassProps } from '../../util';

export interface MetricsProps {
  className?: string | string[];
}

export interface MetricsChildProps extends MetricsProps {
  energyPrefix?: string;
  systemsSummary?: SystemsSummary;
}

export default function Metrics({ className }: MetricsProps): JSX.Element {
  const isMobile = useMobile();
  const { systemsSummary } = useContext(SystemsContext);

  const energyPrefix =
    systemsSummary?.systemsStats?.energy !== undefined &&
    !systemsSummary?.systems.every(
      (sys) =>
        (sys as GenSystem).stats?.outE1 !== undefined ||
        (sys as GenSystem).stats?.outE2 !== undefined ||
        (sys as GenSystem).stats?.outE3 !== undefined,
    )
      ? '>'
      : '';

  const someAreCap = systemsSummary?.systems?.some((system) => isCap(system));
  const someAreNotCap = systemsSummary?.systems?.some((system) => !isCap(system));

  if (!systemsSummary?.systems?.length) return <></>;

  const metrics = [];

  if (someAreNotCap)
    metrics.push(
      ...[
        <Metric
          key="runtime"
          className="min-w-[16rem] mx-2"
          value={systemsSummary?.systemsStats?.runtime}
          numberFormatOptions={{
            notation: (systemsSummary?.systemsStats?.runtime ?? 0) < 999999 ? 'standard' : 'compact',
            maximumFractionDigits: (systemsSummary?.systemsStats?.runtime ?? 0) < 999999 ? 0 : 2,
          }}
          units="h"
          windowMode
          description="Total Run Time"
          tooltip="Sum of run time from all systems for all time"
        />,
        <Metric
          key="energy"
          className="min-w-[16rem] mx-2"
          prefix={energyPrefix}
          // (units are in kWh coming from FPC)
          value={systemsSummary?.systemsStats?.energy ? systemsSummary?.systemsStats?.energy * 1000 : undefined}
          units="Wh*"
          windowMode
          description="Total Energy"
          tooltip="Sum of energy delivered from all systems for all time. *Only systems with updated firmware are included in the metric"
        />,
        <Metric
          key="userLoadP"
          className="min-w-[16rem] mx-2"
          value={systemsSummary?.systemsStats?.userLoadP}
          units="W"
          windowMode
          description="Electrical Load"
          tooltip="Total electrical power currently delivered from all systems"
        />,
      ],
    );

  if (someAreCap) {
    metrics.push(
      ...[
        <Metric
          key="accAir"
          className="min-w-[16rem] mx-2"
          // (units are in kWh coming from FPC)
          value={systemsSummary?.systemsStats?.accAir}
          units="scf"
          windowMode
          description="Total Air"
          tooltip="Sum of air volume delivered from all CAP systems for all time."
        />,
        <Metric
          key="flow"
          className="min-w-[16rem] mx-2"
          value={systemsSummary?.systemsStats?.flow}
          units="scfm"
          windowMode
          description="Total Air Flow"
          tooltip="Total air flow currently delivered from all CAP systems"
        />,
      ],
    );
    metrics.splice(
      2,
      0,
      <Metric
        key="accCh4"
        className="min-w-[16rem] mx-2"
        value={systemsSummary?.systemsStats?.accCh4}
        units="t"
        windowMode
        description="Total Methane Abated"
        tooltip={`<b>How abated methane (m<sub>CH<sub>4</sub></sub>) is calculated:</b>
<div>&nbsp;</div>
<div>m<sub>CH<sub>4</sub></sub> = [V<sub>air</sub>] x [GER] x [%<sub>CH<sub>4</sub></sub>] x [p<sub>CH<sub>4</sub></sub>]</div>
<div>&nbsp;</div>
<div>m<sub>CH<sub>4</sub></sub>: mass of CH<sub>4</sub> abated</div>
<div>V<sub>air</sub>: volume of supplied air at 1 atm, 15℃</div>
<div>GER: Gas Equivalency Ratio</div>
<div>%<sub>CH<sub>4</sub></sub>: Percentage of CH<sub>4</sub> in gas composition</div>
<div>p<sub>CH<sub>4</sub></sub>: Methane density at 1 atm, 15℃ (0.68 kg/m<sup>3</sup>)</div>
<div>&nbsp;</div>
<div><b>Note:</b> The gas equivalency ratio (GER) and methane composition (%<sub>CH<sub>4</sub></sub>) are set on a per site basis in the Dashboard.</div>`}
      />,
    );
  }
  if (!systemsSummary?.systems?.length) return <></>;

  return (
    <Carousel className={`${appendClassProps(className)}`} slidesToShow={isMobile ? 1 : 3} swipe={isMobile}>
      {metrics}
    </Carousel>
  );
}
