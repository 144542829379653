import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';
import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import i18n from '../../i18n/index';
import { OnFailRequest, User } from '../../types';
import { Method } from '../../util/types';
import { getApiUrl, getAuthorizationCode } from './auth';

const phoneNumber = process.env.REACT_APP_SALES_TEL || '+1-801-752-0100';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Closures#emulating_private_methods_with_closures
export default (function () {
  // public variables
  let apiDown: boolean | undefined = undefined; // undefined means we don't know yet
  let serverTime: DateTime;
  let user: User;

  const setApiDown = (value: boolean) => {
    apiDown = value;
    window.dispatchEvent(new Event('apiDownChanged'));
  };

  // const tokenChangedEvent = new TokenChangeEvent();
  return {
    isApiDown: () => apiDown,
    useRenderOnApiChange: () => {
      // You have to change state to get a re-render (e.g. for page access control)
      const [toggler, forceRender] = useState(false);

      useEffect(() => {
        const handleTokenChanged = () => {
          forceRender(!toggler);
        };

        window.addEventListener('apiDownChanged', handleTokenChanged);

        return () => window.removeEventListener('apiDownChanged', handleTokenChanged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    },
    getServerTime: () => serverTime,
    getUser: () => user,
    setUser: (info: User) => {
      user = info;
      window.dispatchEvent(new Event('userChanged'));
    },
    /** This allows react components to trigger renders when token changes */
    useRenderOnUserChange: () => {
      // You have to change state to get a re-render (e.g. for page access control)
      const [toggler, forceRender] = useState(false);

      useEffect(() => {
        const handleTokenChanged = () => {
          forceRender(!toggler);
        };
        window.addEventListener('userChanged', handleTokenChanged);

        return () => window.removeEventListener('userChanged', handleTokenChanged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    },
    request: async ({
      method,
      path,
      data,
      params,
      headers,
      onFail,
    }: {
      method?: Method;
      path: string;
      data?: unknown;
      auth?: boolean;
      params?: unknown;
      headers?: AxiosRequestConfig['headers'];
      onFail?: OnFailRequest;
    }): Promise<unknown | void> => {
      try {
        if (!headers) headers = {};

        const apiUrl = getApiUrl();
        const response = await axios({
          method,
          url: `${apiUrl}/${path}`,
          withCredentials: true,
          headers,
          data,
          params,
          paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
        });

        const responseData = response.data;

        if (response.headers['x-server-time'] && typeof responseData === 'object' && !Array.isArray(responseData)) {
          serverTime = DateTime.fromMillis(parseInt(response.headers['x-server-time']));
        }
        setApiDown(false);
        toast.dismiss('api'); // dismiss api down toast if it's up
        return responseData;
      } catch (error: unknown) {
        const networkError = (error as AxiosError).message.includes('Network Error');
        setApiDown(networkError);

        const statusCode = (error as AxiosError).response?.status ?? 0;

        // handles 4xx errors that aren't 401, 402, 403
        if (statusCode >= 400 && ![401, 402, 403].includes(statusCode) && statusCode < 500) {
          // Call onFail cb and not throw the error
          if (onFail) return onFail(error as AxiosError);
          const errorMessage = (error as AxiosError)?.response?.data?.error ?? (error as AxiosError).message;
          toast(errorMessage, { type: 'error', toastId: errorMessage });
          return;
        } else if (statusCode === 401) {
          getAuthorizationCode();
        } else if (statusCode === 402) {
          const message = i18n.t('system:subscription_error', {
            phone: phoneNumber,
          });
          toast(message, {
            type: toast.TYPE.WARNING,
            toastId: 'subscription',
            autoClose: false,
          });
          // Call onFail with error
          if (onFail) return onFail(error as AxiosError);
          return;
        } else if (statusCode === 403) {
          toast(i18n.t('Access Forbidden'), {
            type: toast.TYPE.ERROR,
            toastId: 'access',
            autoClose: false,
          });
          // Call onFail with error
          if (onFail) return onFail(error as AxiosError);
          // Redirect back to overview
          if (window.location.pathname !== '/') window.location.href = '/';
          return;
        } else if (!onFail && user && !path.includes('/summit')) {
          // if user exists, that means we're not on server error page

          toast(i18n.t('Api Down'), {
            type: 'error',
            toastId: 'api',
            autoClose: false,
          });
          return;
        } else if (onFail) {
          return onFail(error as AxiosError);
        }
      }
    },
  };
})();
