import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  deleteDistributor,
  deleteOrgFromDistributor,
  patchOrgOnDistributor,
  putDistributor,
} from '../../adapters/api/distributors';
import { deleteOrg, putOrg } from '../../adapters/api/organizations';
import { screenSizeMediaQuery } from '../../ComponentLibrary/src/util/constants';
import { usePageContext } from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import { DistributorsContext, useDistributorsContextValue } from '../../context/Distributors';
import { OrganizationsContext, useOrganizationsContextValue } from '../../context/Organizations';
import { useSetDocumentTitle } from '../../hooks';
import { PERMISSIONS } from '../../util/constants';
import DistributorsDesktop from './Desktop';
import DistributorsMobile from './Mobile';

function Distributors(): JSX.Element {
  const { hasPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  useSetDocumentTitle('Distributors');
  const { getAllOrganizations, allOrganizations, organizations, getOrganizationsForDistributor } =
    useContext(OrganizationsContext);
  const { allDistributors, getAllDistributors } = useContext(DistributorsContext);
  const isSmall = useMediaQuery(screenSizeMediaQuery.small);
  const [isLoadingDistributors, setIsLoadingDistributors] = useState(true);
  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(false);
  const { setBreadcrumbs } = usePageContext();

  const enforcePermissions = useCallback(() => {
    const kickUserOut = () => {
      toast('You do not have access to this page.', {
        type: toast.TYPE.ERROR,
      });
      navigate('/');
    };
    if (
      !hasPermissions(PERMISSIONS.dashboard.distributors.add) ||
      !hasPermissions(PERMISSIONS.dashboard.distributors.read) ||
      !hasPermissions(PERMISSIONS.dashboard.distributors.update)
    ) {
      kickUserOut();
    }
  }, [hasPermissions, navigate]);

  useEffect(() => {
    setBreadcrumbs();
    getAllDistributors()
      .then(() => {
        enforcePermissions();
        setIsLoadingDistributors(false);
      })
      .catch(() => {
        enforcePermissions();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetOrganizationsForDistributor = (distributorId: string) => {
    setIsLoadingOrganizations(true);
    getOrganizationsForDistributor(distributorId).then(() => setIsLoadingOrganizations(false));
  };

  const handleDeleteDistributor = (distId: string) => {
    if (confirm('Are you sure you want to delete this distributor?'))
      deleteDistributor(distId).then(() => getAllDistributors());
  };

  const handleDeleteOrganization = (orgId: string) => {
    if (confirm('Are you sure you want to delete this organization?'))
      deleteOrg(orgId).then(() => getAllOrganizations());
  };

  const handleUpdateDistributor = (distId: string, name: string) => {
    putDistributor(distId, name).then(() => {
      getAllDistributors();
    });
  };

  const handleUpdateOrganization = async (orgId: string, name: string) => {
    return putOrg(orgId, { name })
      .then(() => {
        getAllOrganizations();
      })
      .catch((err) => {
        toast(err?.response?.data?.error ?? err.message, { type: 'error' });
      });
  };

  const handleAddOrgToDist = (orgId: string, distId: string) => {
    patchOrgOnDistributor(distId, orgId).then(() => getOrganizationsForDistributor(distId));
  };

  const handleRemoveOrgFromDist = (orgId: string, distId: string) => {
    if (confirm('Are you sure you want to unassign this org from this distributor?'))
      deleteOrgFromDistributor(orgId, distId).then(() => getOrganizationsForDistributor(distId));
  };

  const props = {
    distributors: allDistributors,
    organizations,
    allOrganizations,
    isLoadingDistributors,
    isLoadingOrganizations,
    onDeleteDistributor: handleDeleteDistributor,
    onUpdateDistributor: handleUpdateDistributor,
    onUpdateOrganization: handleUpdateOrganization,
    onDeleteOrganization: handleDeleteOrganization,
    onAddOrgToDist: handleAddOrgToDist,
    onRemoveOrgFromDist: handleRemoveOrgFromDist,
    onGetOrganizationsForDistributor: handleGetOrganizationsForDistributor,
  };

  return isSmall ? <DistributorsMobile {...props} /> : <DistributorsDesktop {...props} />;
}

export default function DistributorsContainer(): JSX.Element {
  const organizationsContextValue = useOrganizationsContextValue();
  const distributorsContextValue = useDistributorsContextValue();

  return (
    <OrganizationsContext.Provider value={organizationsContextValue}>
      <DistributorsContext.Provider value={distributorsContextValue}>
        <Distributors />
      </DistributorsContext.Provider>
    </OrganizationsContext.Provider>
  );
}
