import countBy from 'lodash.countby';
import React, { ReactNode, useContext, useMemo } from 'react';

import { IndicatorTier, Metric, StatusIndicator, SystemDisplayState, Text, TextType } from '../../ComponentLibrary/src';
import { SystemsContext } from '../../context/Systems';
import { getSystemDisplayState } from '../../context/util';
import { appendClassProps } from '../../util';
import { systemHasWarnings } from './util';

interface FooterProps {
  className?: string | string[];
  hideStates?: boolean;
}

const FooterItem = ({
  invertColors,
  label,
  value,
  icon,
}: {
  label: string;
  invertColors?: boolean;
  value?: string;
  icon?: ReactNode;
}): JSX.Element => {
  const content = (
    <>
      {icon}
      <span className={`${invertColors ? 'bg-blue-800 p-2 rounded-l-sm' : ''} text-bold`}>
        <Text
          type={TextType.custom}
          overrideColor
          className={`${invertColors ? 'text-white font-bold' : 'text-blue-800'} text-sm`}
          inline
        >
          {label}
        </Text>
        <Text
          type={TextType.custom}
          overrideColor
          className={`${invertColors ? 'text-white' : 'text-blue-800'} text-sm font-bold`}
          inline
        >
          ({value})
        </Text>
      </span>
    </>
  );

  return invertColors ? content : <div className={`p-2 px-4 flex flex-row gap-2 items-center`}>{content}</div>;
};

const Footer = ({ className, hideStates }: FooterProps): JSX.Element => {
  const { systemsSummary } = useContext(SystemsContext); // Note: this requires getSystems to have been called elsewhere (i.e. MarkerCluster.tsx)
  const {
    systemsWithFaultCount,
    systemsWithWarningCount,
    systemsWithUnknownCount,
    systemsWithDisabledCount,
    systemsWithStandbyCount,
    systemsWithEnabledCount,
  } = useMemo(() => {
    const systemStates = systemsSummary?.systems?.map((system) => getSystemDisplayState(system));
    const counts = countBy(systemStates);
    const systemsWithWarningCount = systemsSummary?.systems?.reduce((count, system) => {
      if (getSystemDisplayState(system) === SystemDisplayState.unknown) {
        return count;
      }
      if (systemHasWarnings(system)) {
        count++;
      }
      return count;
    }, 0);
    return {
      systemsWithFaultCount: counts[SystemDisplayState.faulted],
      systemsWithWarningCount,
      systemsWithUnknownCount: counts[SystemDisplayState.unknown],
      systemsWithDisabledCount: counts[SystemDisplayState.disabled],
      systemsWithStandbyCount: counts[SystemDisplayState.standby],
      systemsWithEnabledCount: counts[SystemDisplayState.enabled],
    };
  }, [systemsSummary]);

  return (
    <div
      className={`bg-white bg-opacity-50 flex flex-row flex-wrap items-center backdrop-blur-sm rounded-sm shadow ${appendClassProps(
        className,
      )}`}
    >
      {hideStates ? (
        <Metric
          className="min-w-[16rem]"
          value={systemsSummary?.systems?.length}
          numberFormatOptions={{
            notation: (systemsSummary?.systemsStats?.runtime ?? 0) < 999999 ? 'standard' : 'compact',
            maximumFractionDigits: (systemsSummary?.systemsStats?.runtime ?? 0) < 999999 ? 0 : 2,
          }}
          units=""
          windowMode
          description="Total"
        />
      ) : (
        <>
          <FooterItem invertColors label="Total " value={systemsSummary?.systems?.length.toString()} />
          <FooterItem
            icon={
              <div className="relative">
                <StatusIndicator state={SystemDisplayState.faulted} tier={IndicatorTier.three} isSecondary />
              </div>
            }
            label="Fault "
            value={(systemsWithFaultCount ?? 0).toString()}
          />
          <FooterItem
            icon={
              <div className="relative">
                <StatusIndicator state={SystemDisplayState.warning} tier={IndicatorTier.three} isSecondary />
              </div>
            }
            label="Warning "
            value={(systemsWithWarningCount ?? 0).toString()}
          />
          <FooterItem
            icon={
              <div className="relative">
                <StatusIndicator state={SystemDisplayState.unknown} tier={IndicatorTier.three} isSecondary />
              </div>
            }
            label="Unknown "
            value={(systemsWithUnknownCount ?? 0).toString()}
          />
          <FooterItem
            icon={
              <div className="relative">
                <StatusIndicator state={SystemDisplayState.disabled} tier={IndicatorTier.three} isSecondary />
              </div>
            }
            label="Disabled "
            value={(systemsWithDisabledCount ?? 0).toString()}
          />
          <FooterItem
            icon={
              <div className="relative">
                <StatusIndicator state={SystemDisplayState.standby} tier={IndicatorTier.three} isSecondary />
              </div>
            }
            label="Standby "
            value={(systemsWithStandbyCount ?? 0).toString()}
          />
          <FooterItem
            icon={
              <div className="relative">
                <StatusIndicator state={SystemDisplayState.enabled} tier={IndicatorTier.three} isSecondary />
              </div>
            }
            label="Enabled "
            value={(systemsWithEnabledCount ?? 0).toString()}
          />
        </>
      )}
    </div>
  );
};

export default Footer;
