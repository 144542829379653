import { Duration } from 'luxon';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import Api from '../../adapters/api';
import { Text, TextType } from '../../ComponentLibrary/src';
import { ITimelineItem } from '../../context/Systems';
import { getContentText, getTitleText } from '../../pages/System/Events/util';
import { Fault } from '../../types';
import { getDisplayTime } from '../../WebUtils';
import TypeIndicator from './TypeIndicator';
import { getFaultTooltip } from './util';

interface TimelineItemProps {
  item: ITimelineItem;
}

export default function TimelineItem({ item }: TimelineItemProps): JSX.Element {
  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  let tooltip = '';

  if (item.data?.fault) {
    tooltip = getFaultTooltip(item.data?.fault as Fault, true);
  }

  return (
    <>
      <div className="flex flex-col justify-center w-full">
        <Text type={TextType.body} overflow="none" wrap>
          {getDisplayTime(item.ts, Api.getServerTime(), Duration.fromObject({ days: 1 }))}
        </Text>
      </div>
      <div className="flex flex-col justify-center items-center">
        <TypeIndicator item={item} />
      </div>
      <div className="flex flex-col overflow-hidden" data-tip={tooltip} data-html={true}>
        <Text type={TextType.custom} className="text-base font-medium">
          {getTitleText(item)}
        </Text>
        <Text type={TextType.body} overrideColor className="w-full text-blue-500" overflow="ellipsis">
          {getContentText(item)}
        </Text>
      </div>
    </>
  );
}
