import get from 'lodash.get';
import React from 'react';

import { Card, Text, TextType } from '../../ComponentLibrary/src';
import { Border, KeyValue } from '../../ComponentLibrary/src';
import { CurrentSystemData } from '../../context/Systems';
import { useMobile } from '../../hooks';
import { CapStats } from '../../types';
import { displayNumber } from '../../WebUtils';
import { getLabel, getSystemRowData } from './constants';
import { labelSort } from './util';
export interface PerformanceDataProps {
  currentSystemData?: CurrentSystemData;
  systemStats?: CapStats;
  isLoading: boolean;
  isCap?: boolean;
}

export default function PerformanceData(props: PerformanceDataProps): JSX.Element {
  const { currentSystemData, isCap, systemStats } = props;
  const isMobile = useMobile();
  if (typeof currentSystemData !== 'object' || !Object.keys(currentSystemData).length) return <></>;
  const data: Record<string, unknown> = {
    ...currentSystemData,
    stats: systemStats,
  };

  let fieldsToDisplay = PGDisplayFields;
  if (isCap) {
    fieldsToDisplay = CapDisplayFields;
  }
  const formatDisplayNumber = (value: number | undefined): string => {
    if (value === undefined) return '';
    const num = displayNumber({ num: value, numberFormatOptions: { maximumFractionDigits: 2 } });
    return num.value + num.suffix;
  };

  const valueFormatter = (field: string) => {
    let value = '';
    if (isCap) {
      value = formatDisplayNumber(get(data, field) as number);
    } else {
      value = formatDisplayNumber(currentSystemData?.[field as keyof CurrentSystemData] as number);
    }
    return value;
  };

  const classes = 'grid gap-x-8 gap-y-2 w-full ' + (isMobile ? 'grid-cols-1' : 'grid-cols-2');
  return (
    <Card className="flex flex-row gap-2" data-pwid="system-performance-data-card">
      <div className="flex flex-col w-full gap-2">
        <Text type={TextType.h3}>Performance Data</Text>
        <div className="flex flex-row flex-wrap gap-y-2 gap-x-8">
          <div className={classes}>
            {fieldsToDisplay.sort(labelSort).map((field) => {
              if (
                (isCap && get(data, field) !== undefined) ||
                currentSystemData?.[field as keyof CurrentSystemData] !== undefined
              ) {
                return (
                  <KeyValue
                    key={field}
                    label={getLabel(field, true)}
                    value={valueFormatter(field)}
                    tooltip={getSystemRowData(field)?.tooltip}
                    loading={props.isLoading}
                    border={Border.none}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}

const CapDisplayFields = ['tDryer', 'tCab', 'tOut', 'pFlowMeter', 'stats.tCpu', 'tBldg', 'tFlowMeter', 'tBatt'];

const PGDisplayFields = [
  'tEngBay1',
  'tEngBay2',
  'tEngBayBot',
  'tHruTop',
  'tDuctJacket',
  'tQec',
  'gasPress',
  'tFpcCpu',
  'tFpcBrd',
  'tHead',
  'tHeadTc1',
  'tHeadTc2',
  'pumpFb',
  'clntFlwQec',
  'pumpPwm',
  'fanPwm',
  'clntLvl',
  'blwrPwm',
  'blwrRpm',
  'pstAmp',
  'pstBias',
  'pstFreq',
  'hvdcAvgV',
  'engV',
  'engI',
  'engP',
  'lambda',
];
