import React, { useState } from 'react';

import { Accordion, Card, Link } from '../ComponentLibrary/src';
import { Text, TextType } from '../ComponentLibrary/src/Text';
import Chat from '../components/Chat';

interface Props {
  error: unknown;
  errorInfo: unknown;
}

export default function Error({ error, errorInfo }: Props): JSX.Element {
  const [openError, setOpenError] = useState(false);

  return (
    <div className="responsive-h-screen flex items-center justify-center">
      <Chat />
      <Card className="flex flex-col gap-4 items-center justify-center p-12">
        <Text type={TextType.custom} className="text-9xl">
          😢
        </Text>
        <Text type={TextType.h3}>Sorry, an error has ocurred.</Text>
        <Link href={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com'}`} color="green">
          Return to main page
        </Link>
        <Accordion
          open={openError}
          onChange={() => setOpenError(!openError)}
          header={
            <Text type={TextType.custom} className="text-sm">
              Error Message
            </Text>
          }
        >
          {/* <ClipboardCopyIcon className="text-blue-800 h-6 w-6" /> */}
          <Text type={TextType.custom} className="text-sm" wrap>
            {error?.toString()}
            <br />
            <br />
            {JSON.stringify(errorInfo)}
          </Text>
        </Accordion>
      </Card>
    </div>
  );
}
