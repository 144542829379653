import React from 'react';
import { Route, Routes as RRoutes } from 'react-router-dom';

import ErrorHandler from './components/ErrorHandler';
import Page from './components/Page';
import { AuthContext, useAuthContextValue } from './context/Auth';
import { SystemsContext, useSystemsContextValue } from './context/Systems';
import Assets from './pages/Assets/index';
import Org from './pages/Assets/Org';
import Site from './pages/Assets/Site';
import AddDistributor from './pages/Distributors/AddDistributor';
import Distributors from './pages/Distributors/index';
import EditFault from './pages/Faults/Edit';
import Faults from './pages/Faults/index';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Notifications from './pages/Notifications';
import Overview from './pages/Overview';
import Privacy from './pages/Privacy';
import ReleaseNote from './pages/ReleaseNote/ReleaseNote';
import ReleaseNotes from './pages/ReleaseNotes';
import ActionLogs from './pages/System/ActionLogs';
import EditActionLog from './pages/System/ActionLogs/EditActionLog';
import Data from './pages/System/Data/index';
import EditSystem from './pages/System/EditSystem';
import Timeline from './pages/System/Events';
import System from './pages/System/System';
import Systems from './pages/Systems';
import Terms from './pages/Terms';
import Unsubscribe from './pages/Unsubscribe/index';

const Routes = (): JSX.Element => {
  return (
    <ErrorHandler>
      <RRoutes>
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="unsubscribe" element={<Unsubscribe />} />
        <Route path="/" element={<Page />}>
          <Route index element={<Overview />} />
          <Route path="login">
            <Route index element={<NotFound />} />
            <Route path=":uid/callback" element={<Login />} />
          </Route>
          <Route path="/systems/*" element={<SystemRoutes />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="distributors">
            <Route index element={<Distributors />} />
            <Route path="add" element={<AddDistributor />} />
          </Route>
          <Route path="faults">
            <Route index element={<Faults productFamily="PowerGen" />} />
            <Route path="PowerGen" element={<Faults productFamily="PowerGen" />} />
            <Route path="CAP" element={<Faults productFamily="CAP" />} />
            <Route path="new" element={<EditFault />} />
            <Route path=":productFamily/:faultCode" element={<EditFault />} />
          </Route>
          <Route path="assets">
            <Route index element={<Assets />} />
            <Route path=":orgId" element={<Assets />} />
            <Route path=":orgId/:siteId" element={<Assets />} />
            <Route path="editSite/:siteId" element={<Site edit />} />
            <Route path="createSite/:orgId" element={<Site />} />
            <Route path="createSite" element={<Site />} />
            <Route path="editOrg/:orgId" element={<Org edit />} />
            <Route path="createOrg" element={<Org />} />
          </Route>
          <Route path="releasenotes">
            <Route index element={<ReleaseNotes />} />
            <Route path=":releaseNoteDate" element={<ReleaseNote />} />
          </Route>
        </Route>
        <Route path="*" element={<Page />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </RRoutes>
    </ErrorHandler>
  );
};

export default function RoutesContainer(): JSX.Element {
  const authContextValue = useAuthContextValue();

  return (
    <AuthContext.Provider value={authContextValue}>
      <Routes />
    </AuthContext.Provider>
  );
}

const SystemRoutes = (): JSX.Element => {
  const systemsContextValue = useSystemsContextValue();

  return (
    <SystemsContext.Provider value={systemsContextValue}>
      <RRoutes>
        <Route index element={<Systems />} />
        <Route path=":sysId">
          <Route index element={<System />} />
          <Route path="data" element={<Data />} />
          <Route path="edit" element={<EditSystem />} />
          <Route path="events" element={<Timeline />} />
          <Route path="actionlogs">
            <Route index element={<ActionLogs />} />
            <Route path=":actionLogId" element={<EditActionLog />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </RRoutes>
    </SystemsContext.Provider>
  );
};
