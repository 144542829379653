import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Slider from 'infinite-react-carousel';
import React from 'react';

import { appendClassProps } from '../util';
import { CarouselProps } from './index.types';

export const Carousel = ({ children, slidesToShow, className, swipe }: CarouselProps): JSX.Element => {
  return (
    <div data-testid="carousel" data-pwid="carousel" className="w-full flex justify-center">
      <Slider
        virtualList
        className={appendClassProps(className)}
        dots
        slidesToShow={slidesToShow ?? 1}
        prevArrow={
          <div className="w-10 h-10 ">
            <ChevronLeftIcon className="w-10 h-10 text-gray-500" />
          </div>
        }
        nextArrow={
          <div className="w-10 h-10 ">
            <ChevronRightIcon className="w-10 h-10 text-gray-500" />
          </div>
        }
        swipe={swipe}
      >
        {children}
      </Slider>
    </div>
  );
};
