import { cloneDeep } from 'lodash';
import QueryString from 'qs';

import { FilterKey, MenuItem, SystemDisplayState } from '../../../ComponentLibrary/src';
import { SystemsSummary } from '../../../context/Systems';
import { extractFilterStateParam } from '../../../context/util';
import i18n from '../../../i18n';
import { JsonApiQuery, queryToUrl, Sort } from '../../../JsonApi/src';
import { EditSystem, OnFailRequest, SummitInfo, System } from '../../../types';
import { Method } from '../../../util/types';
import Api from '..';
import { getApiUrl } from '../auth';

const stateOptions = {
  count: 5,
  data: [
    {
      id: SystemDisplayState.unknown,
      label: 'Unknown',
    },
    {
      id: SystemDisplayState.enabled,
      label: 'Enabled',
    },
    {
      id: SystemDisplayState.disabled,
      label: 'Disabled',
    },
    {
      id: SystemDisplayState.standby,
      label: 'Standby',
    },
    {
      id: SystemDisplayState.faulted,
      label: 'Faulted',
    },
  ],
};

export async function fetchSearchSystems(options?: {
  sort?: Sort;
  countPerPage?: number;
  pageNumber?: number;
  systemsStats?: boolean;
  count?: boolean;
  project?: string[];
  searchTerm?: string;
  hasSite?: boolean;
  excludeSystems?: string[];
}): Promise<{
  systems: System[];
  count: number;
} | void> {
  const query: JsonApiQuery = options
    ? {
        project: options.project,
        sort: options.sort,
      }
    : {};

  if (options?.countPerPage && options?.pageNumber) {
    query.page = {
      number: options?.pageNumber,
      size: options?.countPerPage,
    };
  }

  const params = query ? queryToUrl(query) : {};

  if (options?.searchTerm) {
    params['textSearch'] = options?.searchTerm;
  }
  if (options?.excludeSystems) {
    params[`filter[sysId_${options?.excludeSystems.length > 1 ? 'nin' : 'ne'}]`] = options.excludeSystems;
  }
  if (options?.count) {
    params.count = options?.count;
  }
  return fetchSystemSummary(params);
}

export async function fetchSystemFieldKeys({
  filterKeys,
  key,
  searchTerm,
  forMap = false,
  onlySelected = false,
}: {
  filterKeys: FilterKey[];
  key: string;
  searchTerm?: string;
  forMap?: boolean;
  onlySelected?: boolean;
}): Promise<{
  data: MenuItem[];
  count: number;
} | void> {
  if (key === 'stats.state') return stateOptions;

  const filter = cloneDeep(filterKeys);
  const stateFilterParam = extractFilterStateParam(filter);

  // deselect the original state entry in the filters
  const stateFilterIndex = filter.findIndex((item) => item.id === 'stats.state' && item.selected === true);
  if (stateFilterIndex > -1 && filter[stateFilterIndex].selectedValues?.some((value) => value.selected))
    filter[stateFilterIndex].selected = false;

  // ignore filters on the current key unless we want to fetch only the selected ones
  const sanitizedFilter = filter.filter((item) => (onlySelected ? item.id === key : item.id !== key));

  const query: JsonApiQuery = {
    filterKeys: sanitizedFilter,
    page: {
      number: 1,
      size: 25,
    },
  };

  let params = queryToUrl(query);

  params.count = true;

  if (forMap) params.siteExists = true;

  if (stateFilterParam)
    params = {
      ...params,
      ...stateFilterParam,
    };

  if (searchTerm) {
    params.textSearch = searchTerm;
  }

  const defaultMenuItems = {
    data: [],
    count: 0,
  };

  return Api.request({ method: Method.get, path: `systems/filterKeys/${key}`, params }).then((response) => {
    if (!response) return defaultMenuItems;
    const menuItems = response as { data: MenuItem[]; count: number };
    menuItems.data = menuItems?.data.map((item) => {
      if (typeof item.id === 'boolean') item.id = item.id.toString();

      if (!item.id) {
        item.label = i18n.t('system:no_active_event_codes') as string;
      } else if (!item.label) {
        const idComponents = item.id?.toString().split('_') ?? '_No Code';
        item.label = `${idComponents[1]} [${idComponents[0]}]`;
      }

      return item;
    });
    return menuItems;
  });
}

export async function fetchSystemSummary(params: Record<string, unknown>): Promise<SystemsSummary | void> {
  return Api.request({
    method: Method.get,
    path: 'systems/summary',
    params,
  }) as Promise<SystemsSummary>;
}

export async function fetchSystem(
  sysId: string,
  params: Record<string, unknown>,
  onFail?: OnFailRequest,
): Promise<System | void> {
  const system = (await Api.request({ method: Method.get, path: `systems/${sysId}`, params, onFail })) as System;

  return system;
}

export async function fetchUnallocatedSystems(): Promise<System[] | void> {
  return Api.request({ method: Method.get, path: 'systems/unallocated' }) as Promise<System[]>;
}

export async function fetchSystemSummitInfo(sysId: string, onFail?: OnFailRequest): Promise<SummitInfo | void> {
  return Api.request({ method: Method.get, path: `systems/${sysId}/summit`, onFail }) as Promise<SummitInfo>;
}

export function downloadSystems(filterKeys: FilterKey[], sort?: Sort, textSearch?: string): void {
  const filter = cloneDeep(filterKeys);

  // convert state filter from shortened to db format
  const stateFilterParam = extractFilterStateParam(filter);
  // deselect the original state entry in the filters
  const stateFilterIndex = filter.findIndex((item) => item.id === 'stats.state' && item.selected === true);
  if (stateFilterIndex > -1 && filter[stateFilterIndex].selectedValues?.some((value) => value.selected))
    filter[stateFilterIndex].selected = false;
  const query: JsonApiQuery = {
    filterKeys: filter,
  };
  if (sort) {
    query.sort = sort;
  }
  let params = queryToUrl(query);

  if (stateFilterParam) {
    params = {
      ...params,
      ...stateFilterParam,
    };
  }

  if (textSearch) {
    params.textSearch = textSearch;
  }

  const apiUrl = getApiUrl();
  const downloadUrl = `${apiUrl}/systems/download/csv?${QueryString.stringify(params, {
    arrayFormat: 'repeat',
  })}`;
  window.open(downloadUrl, '_blank');
}

export async function patchSystem(system: EditSystem, onFail?: OnFailRequest): Promise<unknown | void> {
  return Api.request({
    method: Method.patch,
    path: `systems/${system.sysId}`,
    data: system,
    onFail,
  });
}

export async function fetchSystemFilterLabels(labels: Record<string, string[]>): Promise<
  | {
      id: string;
      key: string;
      label: string;
    }[]
  | void
> {
  return Api.request({
    method: Method.get,
    path: 'systems/filterKeys/multi',
    params: labels,
  }) as Promise<
    {
      id: string;
      key: string;
      label: string;
    }[]
  >;
}
