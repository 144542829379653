import { IndicatorClasses, IndicatorTier, SystemDisplayState } from './index.types';

export const mapStateToColorClass = (
  state: SystemDisplayState,
): { markerColorClass: string; markerBgColorClass: string } => {
  const colorBgClassDict = {
    [SystemDisplayState.enabled]: 'bg-emerald-500',
    [SystemDisplayState.standby]: 'bg-emerald-500',
    [SystemDisplayState.disabled]: 'bg-blue-600',
    [SystemDisplayState.faulted]: 'bg-red-600',
    [SystemDisplayState.warning]: 'bg-amber-700',
    [SystemDisplayState.unknown]: 'bg-gray-500',
  };

  const markerBgColorClass = colorBgClassDict[state as keyof typeof colorBgClassDict] || 'bg-gray-500';

  const colorClassDict = {
    [SystemDisplayState.enabled]: 'text-emerald-500',
    [SystemDisplayState.standby]: 'text-emerald-500',
    [SystemDisplayState.disabled]: 'text-blue-600',
    [SystemDisplayState.faulted]: 'text-red-600',
    [SystemDisplayState.warning]: 'text-amber-700',
    [SystemDisplayState.unknown]: 'text-gray-500',
  };

  const markerColorClass = colorClassDict[state as keyof typeof colorClassDict] || 'text-gray-500';

  return {
    markerColorClass,
    markerBgColorClass,
  };
};

export const getIndicatorClasses = (classTier: IndicatorTier, isSecondary: boolean): IndicatorClasses => {
  let sizeClass = 'h-3 w-3';

  if (isSecondary) {
    switch (classTier as IndicatorTier) {
      case IndicatorTier.one:
        sizeClass = 'h-2.5 w-2.5';
        break;
      case IndicatorTier.two:
        sizeClass = 'h-3 w-3';
        break;
      case IndicatorTier.three:
        sizeClass = 'h-3.5 w-3.5';
        break;
      case IndicatorTier.four:
        sizeClass = 'h-4 w-4';
        break;
      case IndicatorTier.five:
        sizeClass = 'h-4 w-4';
        break;
      case IndicatorTier.six:
        sizeClass = 'h-4 w-4';
        break;
      case IndicatorTier.seven:
        sizeClass = 'h-5 w-5';
        break;
      case IndicatorTier.eight:
        sizeClass = 'h-5 w-5';
        break;
      case IndicatorTier.nine:
        sizeClass = 'h-6 w-6';
        break;
      case IndicatorTier.ten:
        sizeClass = 'h-7 w-7';
        break;
      default:
        break;
    }
  } else {
    switch (classTier as IndicatorTier) {
      case IndicatorTier.one:
        sizeClass = 'h-4 w-4';
        break;
      case IndicatorTier.two:
        sizeClass = 'h-7 w-7';
        break;
      case IndicatorTier.three:
        sizeClass = 'h-8 w-8';
        break;
      case IndicatorTier.four:
        sizeClass = 'h-9 w-9';
        break;
      case IndicatorTier.five:
        sizeClass = 'h-10 w-10';
        break;
      case IndicatorTier.six:
        sizeClass = 'h-11 w-11';
        break;
      case IndicatorTier.seven:
        sizeClass = 'h-12 w-12';
        break;
      case IndicatorTier.eight:
        sizeClass = 'h-14 w-14';
        break;
      case IndicatorTier.nine:
        sizeClass = 'h-16 w-16';
        break;
      case IndicatorTier.ten:
        sizeClass = 'h-20 w-20';
        break;
      default:
        break;
    }
  }

  return {
    sizeClass,
  };
};
