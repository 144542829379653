import React from 'react';

import { Border } from '../../ComponentLibrary/src';
import { KeyValue } from '../../ComponentLibrary/src/KeyValue';
import { Metric } from '../../ComponentLibrary/src/Metric';
import { Text, TextType } from '../../ComponentLibrary/src/Text';
import { CompressorState, CurrentSystemData, TransferSwitchPosition } from '../../context/Systems';
import { useMobile } from '../../hooks';
import { CapStats } from '../../types';

interface Props {
  currentSystemData?: CurrentSystemData;
  stats?: CapStats;
  isLoading: boolean;
}

function getStatusColor(state: CompressorState): 'green' | 'gray' | 'yellow' {
  switch (state) {
    case CompressorState.Disabled:
      return 'gray';
    case CompressorState.ActiveAlert:
      return 'yellow';
    default:
    case CompressorState.Running:
    case CompressorState.Ready:
    case CompressorState.Delay:
      return 'green';
  }
}

export default function Compressors({ currentSystemData, stats, isLoading }: Props): JSX.Element {
  const isMobile = useMobile();
  if (currentSystemData?.compASt === undefined && currentSystemData?.compBSt === undefined) return <></>;

  let warningA = null,
    warningB = null;

  if (currentSystemData.xfrSw === TransferSwitchPosition.Off) {
    warningA = 'Transfer Sw Off';
    warningB = warningA;
  }
  const classes = 'flex gap-4 xl:gap-6 flex-wrap ' + (isMobile ? 'flex-col' : 'flex-row');
  return (
    <div className={classes}>
      {currentSystemData?.compASt != undefined && (
        <Metric
          key="a"
          className="flex-1"
          value={currentSystemData.compASt}
          description="Compressor A"
          data-pwid="Compressor A"
          loading={isLoading}
          topBorderColor={getStatusColor(currentSystemData.compASt)}
          content={
            <div className="flex flex-col gap-2 w-full">
              {warningA && (
                <div className="flex justify-center">
                  <Text type={TextType.custom} overrideColor className="text-red-500">
                    {warningA}
                  </Text>
                </div>
              )}
              {!isNaN(parseFloat(stats?.runtimeA?.toString() as string)) && (
                <KeyValue
                  label="Run Time (hr)"
                  value={stats?.runtimeA?.toFixed(0)}
                  loading={isLoading}
                  border={Border.none}
                  wrap
                />
              )}
              {!isNaN(parseFloat(stats?.serviceDueA?.toString() as string)) && (
                <KeyValue
                  label="Service Due (hr)"
                  value={stats?.serviceDueA?.toFixed(0)}
                  loading={isLoading}
                  border={Border.none}
                  wrap
                />
              )}
              {!isNaN(parseFloat(currentSystemData.tCompA?.toString() as string)) && (
                <KeyValue
                  label="Temperature (℃)"
                  value={currentSystemData.tCompA?.toFixed(2)}
                  loading={isLoading}
                  border={Border.none}
                  wrap
                />
              )}
              {!isNaN(parseFloat(currentSystemData.freqVFDA?.toString() as string)) && (
                <KeyValue
                  label="VFD Freq (Hz)"
                  value={currentSystemData.freqVFDA?.toFixed(2)}
                  loading={isLoading}
                  border={Border.none}
                  wrap
                />
              )}
            </div>
          }
        />
      )}
      {currentSystemData?.compBSt != undefined && (
        <Metric
          key="b"
          className="flex-1"
          value={currentSystemData.compBSt}
          description="Compressor B"
          data-pwid="Compressor B"
          loading={isLoading}
          topBorderColor={getStatusColor(currentSystemData.compBSt)}
          content={
            <div className="flex flex-col gap-2 w-full">
              {warningB && (
                <div className="flex justify-center">
                  <Text type={TextType.custom} overrideColor className="text-red-500">
                    {warningB}
                  </Text>
                </div>
              )}
              {!isNaN(parseFloat(stats?.runtimeB?.toString() as string)) && (
                <KeyValue
                  label="Run Time (hr)"
                  value={stats?.runtimeB?.toFixed(0)}
                  loading={isLoading}
                  border={Border.none}
                  wrap
                />
              )}
              {!isNaN(parseFloat(stats?.serviceDueB?.toString() as string)) && (
                <KeyValue
                  label="Service Due (hr)"
                  value={stats?.serviceDueB?.toFixed(0)}
                  loading={isLoading}
                  border={Border.none}
                  wrap
                />
              )}
              {!isNaN(parseFloat(currentSystemData.tCompB?.toString() as string)) && (
                <KeyValue
                  label="Temperature (℃)"
                  value={currentSystemData.tCompB?.toFixed(2)}
                  loading={isLoading}
                  border={Border.none}
                  wrap
                />
              )}
              {!isNaN(parseFloat(currentSystemData.freqVFDA?.toString() as string)) && (
                <KeyValue
                  label="VFD Freq (Hz)"
                  value={currentSystemData.freqVFDB?.toFixed(2)}
                  loading={isLoading}
                  border={Border.none}
                  wrap
                />
              )}
            </div>
          }
        />
      )}
    </div>
  );
}
