import React, { useContext } from 'react';

import { Card, Text, TextType } from '../../ComponentLibrary/src';
import { AuthContext } from '../../context/Auth';
import { Fault, System } from '../../types';
import { sortActiveFaults } from '../../util';
import { PERMISSIONS } from '../../util/constants';
import ActiveAlert from '../ActiveAlert';

interface Props {
  system?: System;
  isLoading: boolean;
}

export default function ActiveAlerts({ system, isLoading }: Props): JSX.Element {
  const { hasPermissions } = useContext(AuthContext);

  if (isLoading || !system?.stats?.activeFaults?.length) return <></>;

  return (
    <Card className="flex flex-col gap-2" data-pwid="system-alert-card">
      <Text type={TextType.h3}>Active Alerts</Text>
      <div className="active-alerts">
        {(system?.stats?.activeFaults as Fault[]).sort(sortActiveFaults).map((fault: Fault, i: number) => (
          <ActiveAlert
            key={i}
            fault={fault}
            advancedRead={hasPermissions(PERMISSIONS.dashboard.systems.advancedRead)}
          />
        ))}
      </div>
    </Card>
  );
}
