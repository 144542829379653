import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { ReactNode } from 'react';
import SwipeableViews from 'react-swipeable-views';

import colors from '../style/colors';
import { appendClassProps } from '../util';
import { InternalTabProps, TabItem } from './index.types';
import { tabStyles } from './styles';

export const TabMobile: React.FC<InternalTabProps> = ({
  className,
  tabs,
  value,
  tabContent,
  onChange,
  'data-pwid': dataPwId,
}: InternalTabProps) => {
  return (
    <div className={`bg-white max-h-fit${appendClassProps(className)}`} data-testid="tabs" data-pwid={dataPwId}>
      <Tabs
        value={value}
        onChange={onChange}
        centered
        TabIndicatorProps={{ style: { background: colors.blue['800'] } }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            sx={{
              ...tabStyles,
              padding: '0.5rem',
              minWidth: '3rem',
              color: tab.label === value ? colors.blue['800'] : colors.gray['400'],
            }}
            className="font-base"
            icon={tab.mobileIcon}
            iconPosition="start"
            value={tab.label}
            data-pwid={tab.label}
          />
        ))}
      </Tabs>
      <SwipeableViews
        index={tabs.findIndex((tab: TabItem) => tab.label === value)}
        onChangeIndex={(i) => {
          onChange(null, tabs[i].label);
        }}
      >
        {React.Children.map(tabContent, (child: ReactNode) => (
          <div className="flex justify-center">{child}</div>
        ))}
      </SwipeableViews>
    </div>
  );
};
