import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { AlarmIcon, DisabledIcon, StandbyIcon, UnknownIcon } from './Icons';
import { IndicatorTier, StatusIndicatorProps, SystemDisplayState } from './index.types';
import { getIndicatorClasses, mapStateToColorClass } from './util';

// Allow inert HTML property
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    inert?: string;
  }
}

/**
 - Use signal to display an icon indicating signal strength
 */
export const StatusIndicator = ({
  state = SystemDisplayState.enabled,
  tier = IndicatorTier.one,
  isSecondary,
  online,
  style,
  sizeClassOverride,
  className,
  clusterCount,
  tooltip,
}: StatusIndicatorProps): JSX.Element => {
  const { markerBgColorClass, markerColorClass } = mapStateToColorClass(state);
  let { sizeClass } = getIndicatorClasses(tier, isSecondary ?? false);
  if (sizeClassOverride) sizeClass = sizeClassOverride;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  let icon: JSX.Element | undefined = (
    <div
      className={`absolute left-0 right-0 top-0 bottom-0 rounded-full flex items-center justify-center ${
        tier !== IndicatorTier.one || state === SystemDisplayState.unknown
          ? 'border-2 border-white border-opacity-70'
          : ''
      } ${sizeClass} ${markerBgColorClass}`}
    >
      <Text type={TextType.custom} overrideColor className="text-sm text-white font-semibold">
        {clusterCount}
      </Text>
    </div>
  );

  if (isSecondary || state === SystemDisplayState.standby) {
    switch (state) {
      case SystemDisplayState.faulted:
      case SystemDisplayState.warning:
        icon = <AlarmIcon markerColorClass={markerColorClass} />;
        break;
      case SystemDisplayState.unknown:
        icon = <UnknownIcon markerColorClass={markerColorClass} />;
        break;
      case SystemDisplayState.disabled:
        icon = <DisabledIcon markerColorClass={markerColorClass} />;
        break;
      case SystemDisplayState.standby:
        icon = (
          <div className={`absolute left-0 right-0 top-0 bottom-0 rounded-full ${sizeClass} ${markerBgColorClass}`}>
            <StandbyIcon markerColorClass={markerColorClass} />
          </div>
        );
        break;

      default:
        break;
    }
  }

  return (
    <div
      className={`map-static-icon ${
        !className?.includes('absolute') ? 'relative ' : ' '
      } ${sizeClass}${appendClassProps(className)}`}
      data-testid="statusIndicator"
      style={style}
      data-tip={tooltip}
    >
      {online && !isSecondary && tier === IndicatorTier.one && (
        <div
          // eslint-disable-next-line react/no-unknown-property
          inert="true"
          className={`opacity-75 pointer-events-none rounded-full animate-online ${sizeClass} ${markerBgColorClass}`}
        />
      )}
      {icon}
    </div>
  );
};

export * from './index.types';
