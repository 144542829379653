import { ExternalLinkIcon } from '@heroicons/react/outline';
import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card, Link, Text, TextType } from '../../ComponentLibrary/src';
import { SystemsContext } from '../../context/Systems';
import { ITimelineItem } from '../../context/Systems';
import TimelineItem from './TimelineItem';

interface Props {
  sysId?: string;
  isLoading: boolean;
}

export default function Events({ sysId, isLoading }: Props): JSX.Element {
  const { systemTimeline } = useContext(SystemsContext);

  if (isLoading || !systemTimeline?.length) return <></>;
  return (
    <Card className="flex flex-col gap-2 w-full" data-pwid="system-event-card">
      <div className="flex flex-row justify-between">
        <Text type={TextType.h3}>Events</Text>
        <Link href={`/systems/${sysId}/events`}>
          <ExternalLinkIcon className="cursor-pointer h-6 w-6 text-blue-800" />
        </Link>
      </div>
      {systemTimeline?.length ?? 0 > 0 ? (
        <div
          className="grid gap-3 w-full"
          style={{
            gridTemplateColumns: `1fr auto 2fr`,
          }}
        >
          {systemTimeline?.map(
            (item: ITimelineItem) => item.ts && <TimelineItem key={new Date(item.ts).getTime()} item={item} />,
          )}
        </div>
      ) : (
        <Skeleton count={3} height={28} />
      )}
    </Card>
  );
}
