import { filterKeysToJson, jsonToUrl } from '../../../JsonApi/src';
import { Mode } from '../../../JsonApi/src/types';
import { DateRange, FilterKey, MenuItem, MultiKey, Range, RangeKey, SingleKey } from '.';

export const alphanumericSort = (a: unknown, b: unknown): number =>
  new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base',
  }).compare((a as MenuItem).label as string, (b as MenuItem).label as string);

export const selectedFirstSort =
  (exclusionMode?: boolean) =>
  (a: unknown, b: unknown): number => {
    if ((a as MenuItem).selected && !(b as MenuItem).selected) {
      return exclusionMode ? 1 : -1;
    } else if ((b as MenuItem).selected && !(a as MenuItem).selected) {
      return exclusionMode ? -1 : 1;
    } else {
      return 0;
    }
  };

export const noneOptionFirst = (a: unknown, b: unknown): number => {
  if (!(a as MenuItem).id) return -1;
  if (!(b as MenuItem).id) return 1;
  return 0;
};

/**
 * Converts a filter array into a URLSearchParams and combines with existing search params.
 * Note this replaces any existing filter params
 * @param newFilter
 * @param existingSearchParams
 * @returns URLSearchParams
 */
export const filterToSearchParams = (
  newFilter: FilterKey[],
  existingSearchParams?: URLSearchParams,
): URLSearchParams => {
  const json = filterKeysToJson(newFilter);
  const searchParams = existingSearchParams ? existingSearchParams : new URLSearchParams();

  // delete filter items
  const keys: string[] = [];
  searchParams.forEach((value, key) => {
    if (key.includes('filter[')) keys.push(key);
  });
  keys.forEach((key) => {
    searchParams.delete(key);
  });

  return new URLSearchParams({
    ...Object.fromEntries(searchParams),
    ...jsonToUrl(json),
  } as Record<string, string>);
};

/**
 * Converts search params to a filter, or updates existing filter if provided
 * @param search
 * @param filter
 * @returns FilterKey[]
 */
export const searchParamsToFilter = (search: URLSearchParams, filter?: FilterKey[]): FilterKey[] => {
  const newFilter = structuredClone(filter ?? []);

  function valueIsHydrated(value: MenuItem['id'], filterKey: FilterKey) {
    return (filterKey as MultiKey).selectedValues?.some((val) => val.id === value);
  }

  function parseKey(key: string) {
    let keyItem = key.replace('filter[', '').replace(']', '');
    const components = keyItem.split('_');
    const op =
      components.length > 1 && (!keyItem.includes('._id') || components.length > 2)
        ? components[components.length - 1]
        : undefined;
    if (op) keyItem = keyItem.replace(`_${op}`, '');
    return { keyItem, op };
  }

  // unselect items not in search
  const searchParamsArr = Array.from(search.keys());
  for (let i = 0; i < newFilter.length; i++) {
    if (
      !searchParamsArr.some((key) => {
        const { keyItem } = parseKey(key);
        return keyItem === newFilter[i].id;
      })
    ) {
      newFilter[i].selected = false;
      delete (newFilter[i] as MultiKey).exclusionMode;
      if ((newFilter[i] as MultiKey).selectedValues) delete (newFilter[i] as MultiKey).selectedValues;
    }
  }

  const lastCombinationOp: { [key: string]: string | undefined } = {};

  // select items in search
  for (const [key, value] of search.entries()) {
    if (value === 'undefined') continue;
    const { keyItem, op } = parseKey(key);

    for (let i = 0; i < newFilter.length; i++) {
      if (key.includes('filter') && (newFilter[i].id === keyItem || newFilter[i].textSearchId === keyItem)) {
        newFilter[i].selected = true;
        newFilter[i].openOnSelect = false;
        delete (newFilter[i] as MultiKey).exclusionMode;
        const mode = newFilter[i].mode;

        let parsedValue: MenuItem['id'] = value;
        if (value === 'false') parsedValue = false;
        else if (value === 'true') parsedValue = true;

        if (!op || op === 'eq' || op === 'ne') {
          const filterSingleKey = newFilter[i] as SingleKey;
          if (op === 'ne') filterSingleKey.exclusionMode = true;

          // unselect values that were previously selected and are not anymore
          filterSingleKey.selectedValues?.map((val) => {
            val.selected = val.id === parsedValue;
          });

          if (!valueIsHydrated(parsedValue, filterSingleKey)) {
            filterSingleKey.selectedValues = [
              {
                id: parsedValue,
                label: '...',
                selected: true,
              },
            ];
          }
        } else if (op === 'regex') {
          newFilter[i].textSearch = value;
        } else if (op === 'in' || op === 'nin') {
          const filterMultiKey = newFilter[i] as MultiKey;
          if (op === 'nin') filterMultiKey.exclusionMode = true;
          const values = value.split(',');

          if (!Array.isArray(filterMultiKey.selectedValues)) filterMultiKey.selectedValues = [];

          // unselect values that were previously selected and are not anymore
          filterMultiKey.selectedValues?.map((val) => {
            val.selected = values.includes(val.id?.toString() ?? '');
          });

          for (const value of values) {
            if (!valueIsHydrated(value, filterMultiKey)) {
              filterMultiKey.selectedValues?.push({
                id: value,
                label: '...',
                selected: true,
              });
            }
          }
        } else if (op === 'lte' || op === 'gte') {
          const filterRangeKey = newFilter[i] as RangeKey;
          let foundItem = false;
          if (mode === Mode.range) {
            filterRangeKey.range = {
              ...filterRangeKey.range,
              ...(op === 'lte' ? { to: parseFloat(value) } : { from: parseFloat(value) }),
            } as Range;
          } else if (mode === Mode.dateRange) {
            filterRangeKey.range = {
              ...filterRangeKey.range,
              ...(op === 'lte' ? { to: new Date(value) } : { from: new Date(value) }),
            } as DateRange;
          } else {
            const filterMultiKey = newFilter[i] as MultiKey;

            const isGreaterWithPrevLess =
              (lastCombinationOp[keyItem] === 'lte' || lastCombinationOp[keyItem] === 'lt') && op === 'gte';

            const isLessWithPrevGreater =
              (lastCombinationOp[keyItem] === 'gte' || lastCombinationOp[keyItem] === 'gt') && op === 'lte';

            filterMultiKey.selectedValues = filterMultiKey.selectedValues?.map((val) => {
              if (
                !lastCombinationOp[keyItem] ||
                (isGreaterWithPrevLess && !foundItem) ||
                (isLessWithPrevGreater && foundItem)
              ) {
                // assuming items are ordered, mark items before ($lte) or after ($gte) (and including) the item as selected
                val.selected = op === 'lte' ? !foundItem : foundItem || val.id === parsedValue;
              }
              if (val.id === parsedValue) foundItem = true;
              return val;
            });
            lastCombinationOp[keyItem] = op;
          }
        } else if (op === 'lt' || op === 'gt') {
          const filterMultiKey = newFilter[i] as MultiKey;

          const isGreaterWithPrevLess =
            (lastCombinationOp[keyItem] === 'lte' || lastCombinationOp[keyItem] === 'lt') && op === 'gt';

          const isLessWithPrevGreater =
            (lastCombinationOp[keyItem] === 'gte' || lastCombinationOp[keyItem] === 'gt') && op === 'lt';

          let foundItem = false;
          filterMultiKey.selectedValues = filterMultiKey.selectedValues?.map((val) => {
            if (op === 'lt' && val.id === parsedValue) foundItem = true;

            if (
              !lastCombinationOp[keyItem] ||
              (isGreaterWithPrevLess && !foundItem) ||
              (isLessWithPrevGreater && foundItem)
            ) {
              val.selected = op === 'lt' ? !foundItem : foundItem;
            }

            if (op === 'gt' && val.id === parsedValue) foundItem = true;
            return val;
          });
          lastCombinationOp[keyItem] = op;
        } else {
          // invalid op, unselect filter key
          newFilter[i].selected = false;
        }
      }
    }
  }
  return newFilter;
};

export const searchTermFromUrl = (searchParams: URLSearchParams): string => {
  for (const [key, value] of searchParams) {
    if (key === 'q') return value;
  }
  return '';
};

export const searchTermToUrl = (searchTerm: string | null, searchParams: URLSearchParams): URLSearchParams => {
  searchParams.forEach((_, key) => {
    if (key === 'q') searchParams.delete(key);
  });
  return new URLSearchParams({
    // add existing params from url
    ...Object.fromEntries(searchParams),
    ...(searchTerm ? { q: searchTerm } : {}),
  });
};
