import React from 'react';

import { appendClassProps } from '../util';
import { SignalProps } from './index.types';

/**
 * Generate a Signal component based on the signal strength provided.
 *
 * @param {number} signalStrength - The strength of the signal in dBm.
 *   null signals are treated as offline. Undefined is not displayed.
 * @param {boolean} isLoading - Whether the component is loading.
 * @param {string} className - Additional CSS classes for styling.
 * @return {JSX.Element} The rendered Signal component.
 */
export const Signal = ({ signalStrength, isLoading, className }: SignalProps): JSX.Element => {
  if (signalStrength === undefined) return <></>;

  const inactiveColor = 'text-gray-300';
  let activeColor = 'text-emerald-500';

  if (signalStrength && signalStrength < -100) {
    activeColor = 'text-red-500';
  } else if (signalStrength && signalStrength < -90) {
    activeColor = 'text-amber-800';
  }

  const signal1 = `transition-all ${isLoading ? 'animate-bounce' : ''} fill-current ${
    !isLoading && signalStrength && signalStrength >= -75 ? activeColor : inactiveColor
  }`;
  const signal2 = `transition-all ${isLoading ? 'animation-delay-[-250ms] animate-bounce' : ''} fill-current ${
    !isLoading && signalStrength && signalStrength >= -90 ? activeColor : inactiveColor
  }`;
  const signal3 = `transition-all ${isLoading ? 'animation-delay-[-500ms] animate-bounce' : ''} fill-current ${
    !isLoading && signalStrength && signalStrength >= -100 ? activeColor : inactiveColor
  }`;
  const signal4 = `transition-all ${isLoading ? 'animation-delay-[-750ms] animate-bounce' : ''} fill-current ${
    !isLoading && signalStrength && signalStrength >= -110 ? activeColor : inactiveColor
  }`;

  return (
    <div
      data-tip={isLoading ? null : signalStrength ? signalStrength + 'dBm' : 'Modem Offline'}
      className={`min-h-[24px] min-w-[24px]${appendClassProps(className)}`}
    >
      <svg viewBox="0 0 32 32" data-testid="signal">
        <rect className={signal4} x="0" y={isLoading ? '30' : '24'} width="6" height="32" mask="url(#Mask)" />
        <rect className={signal3} x="8" y={isLoading ? '30' : '16'} width="6" height="32" mask="url(#Mask)" />
        <rect className={signal2} x="16" y={isLoading ? '30' : '8'} width="6" height="32" mask="url(#Mask)" />
        <rect className={signal1} x="24" y={isLoading ? '30' : '0'} width="6" height="32" mask="url(#Mask)" />
        {!isLoading && !signalStrength && (
          <g>
            <defs>
              <mask id="Mask">
                <rect x="0" y="0" width="32" height="32" fill="white" />
                <ellipse cx="24" cy="24" rx="10" ry="10" fill="black" />
              </mask>
            </defs>
            <ellipse cx="24" cy="24" rx="7" ry="7" className={`fill-current ${inactiveColor}`} />
            <line x1="21" y1="21" x2="27" y2="27" style={{ stroke: '#fff', strokeWidth: 1 }} />
            <line x1="27" y1="21" x2="21" y2="27" style={{ stroke: '#fff', strokeWidth: 1 }} />
          </g>
        )}
      </svg>
    </div>
  );
};

export * from './index.types';
