import debounce from 'debounce-promise';
import { createContext, useCallback, useState } from 'react';

import { fetchFault, fetchFaults } from '../adapters/api/faults';
import { JsonApiQuery, queryToUrl, Sort } from '../JsonApi/src';
import { Fault, ProductFamily } from '../types';

export interface FaultsContextData {
  allFaults: Fault[];
  fault?: Fault;
  total: number;
  getFaults: (args: GetFaultsArgs) => Promise<unknown>;
  getFault: (faultCode: number, productFamily: ProductFamily) => Promise<unknown>;
}

interface GetFaultsArgs {
  countPerPage: number;
  pageNumber: number;
  sort?: Sort;
  productFamily: ProductFamily;
  textSearch?: string;
}

const faultsContextDefaultValue: FaultsContextData = {
  allFaults: [],
  fault: undefined,
  total: 0,
  getFaults: async () => undefined,
  getFault: async () => undefined,
};

export const useFaultsContextValue = (): FaultsContextData => {
  const [allFaults, setAllFaults] = useState<Fault[]>([]);
  const [fault, setFault] = useState<Fault>();
  const [total, setTotal] = useState(0);

  const getFault = useCallback(async (faultCode: number, productFamily: ProductFamily) => {
    return fetchFault(faultCode, productFamily).then((response) => {
      if (response) setFault(response);
    });
  }, []);

  const getFaults = debounce(async (args: GetFaultsArgs) => {
    const { countPerPage, pageNumber, sort, productFamily, textSearch } = args;
    const query: JsonApiQuery = {};

    if (textSearch) {
      query.textSearch = textSearch;
    }

    if (countPerPage && pageNumber) {
      query.page = {
        number: pageNumber,
        size: countPerPage,
      };
    }

    if (sort) {
      query.sort = sort;
    }

    const params = queryToUrl(query);
    params.productFamily = productFamily;

    return fetchFaults(params).then((response) => {
      if (response) {
        setAllFaults(response.data);
        setTotal(response.count);
      } else if (!allFaults) {
        setAllFaults([]);
        setTotal(0);
      }
      return (
        response || {
          data: [],
          count: 0,
        }
      ); // https://github.com/bjoerge/debounce-promise/issues/25
    });
  }, 300);

  return {
    allFaults,
    fault,
    total,
    getFault,
    getFaults,
  };
};

export const FaultsContext = createContext<FaultsContextData>(faultsContextDefaultValue);
