import get from 'lodash.get';
import React from 'react';

import { Metric } from '../../ComponentLibrary/src';
import { CurrentSystemData } from '../../context/Systems';
import { hasCap, hasGht } from '../../pages/System/util';
import { GenSystem, System } from '../../types';
import { metricsConfig } from './constants';

interface Props {
  system?: System;
  isLoading: boolean;
  data: Record<
    string,
    {
      x?: string[];
      y?: number[];
    }
  >;
  currentSystemData?: CurrentSystemData;
}
export default function Metrics({ system, isLoading, data, currentSystemData }: Props): JSX.Element {
  return (
    <div className="flex flex-row gap-4 xl:gap-6 flex-wrap" data-pwid="system-metric-card">
      {system?.dashboardConfig?.metrics?.map((valuePath) => {
        const config = metricsConfig[valuePath as keyof typeof metricsConfig];
        if (!config) return null;
        let value;
        const valueParent = valuePath.split('.')[0];
        const valuePathChild = valuePath.split('.').slice(1).join('.');

        const chartData: {
          x: string[];
          y: number[];
        } = {
          x: [],
          y: [],
        };
        let externalLink = '';
        if (valueParent === 'system') {
          value = get(system, valuePathChild);
          if (valuePathChild === 'stats.energy' && value !== undefined) value = value * 1000; // convert from kWh
        } else if (valueParent === 'currentSystemData') {
          value = get(currentSystemData, valuePathChild);
          externalLink = `/systems/${system?.sysId}/data?${valuePathChild}=1`;
        } else if (valueParent === 'data') {
          value = get(data, valuePathChild)?.y
            ? // typescript doesn't detect the ternary here as a valid verification
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              get(data, valuePathChild)?.y?.[get(data, valuePathChild)!.y!.length - 1]
            : undefined;
        }

        if (valueParent === 'data' || valueParent === 'currentSystemData') {
          externalLink = `/systems/${system?.sysId}/data?${valuePathChild}=1`;
          chartData.x = get(data, valuePathChild)?.x || [];
          chartData.y = get(data, valuePathChild)?.y || [];
          if (valuePathChild === 'flow') {
            value = get(system, 'stats.flow1Hours');
          } else if (valuePathChild === 'pressure') {
            value = currentSystemData?.pressure;
          } else if (valuePathChild === 'pBk') {
            value = currentSystemData?.pBk;
          } else if (valuePathChild === 'accCh4') {
            externalLink += '&interval=day';
          }
        }

        // CAP features
        if (
          (valuePath === 'system.stats.capTotalVol' ||
            valuePath === 'currentSystemData.pCap' ||
            valuePath === 'system.stats.capHoursA' ||
            valuePath === 'system.stats.capHoursB') &&
          !hasCap(system as GenSystem)
        )
          return null;

        // GHT features
        if (
          (valuePath === 'system.stats.tGhtOut' ||
            valuePath === 'currentSystemData.tGhtIn' ||
            valuePath === 'currentSystemData.ghtPumpOn') &&
          !hasGht(system as GenSystem)
        )
          return null;

        if (valuePath === 'currentSystemData.ghtPumpOn') {
          value = currentSystemData?.ghtPumpOn ? 'On' : 'Off';
        }

        if (value === undefined) return null;

        return (
          <Metric
            data-pwid={`${config.description} Metric`}
            key={valuePath}
            className="flex-1 h-[16rem] min-w-[16rem]"
            value={value}
            numberFormatOptions={config.numberFormatOptions}
            units={config.units}
            description={config.description}
            tooltip={config.tooltip}
            loading={isLoading}
            dataIcon={config.dataIcon}
            chartData={chartData}
            externalLink={externalLink}
            isCumulative={config.isCumulative}
          />
        );
      })}
    </div>
  );
}
