import React from 'react';

import { DashboardMetricConfig } from '../../types';

interface SystemField {
  id: string;
  label: string;
  tooltip?: string;
  units?: string;
  productType: 'CAP' | 'PowerGen' | 'SmartBoiler' | 'All';
}

// synced with api at 2023-04-26T15:59:00Z
export const systemFields: SystemField[] = [
  {
    id: 'sysId',
    label: 'Serial Number',
    productType: 'All',
  },
  {
    id: 'model',
    label: 'Model',
    productType: 'All',
  },
  {
    id: 'site',
    label: 'Site',
    productType: 'All',
  },
  {
    id: 'org',
    label: 'Organization',
    productType: 'All',
  },
  {
    id: 'distributor',
    label: 'Distributor',
    productType: 'All',
  },
  {
    id: 'commissionDate',
    label: 'Commission Date',
    productType: 'All',
  },
  {
    id: 'shipDate',
    label: 'Ship Date',
    productType: 'All',
  },
  {
    id: 'warrantyExpirationDate',
    label: 'Warranty End',
    productType: 'All',
  },
  {
    id: 'subscriptionExpirationDate',
    label: 'Subscription End',
    productType: 'All',
  },
  {
    id: 'lastSeen',
    label: 'Last Seen',
    productType: 'All',
  },
  {
    id: 'latest.accAir',
    label: 'Total Accumulated Air',
    productType: 'CAP',
    units: 'scf',
  },
  {
    id: 'development',
    label: 'For Development',
    productType: 'All',
  },
  {
    id: 'distributorId',
    label: 'Distributor Id',
    productType: 'All',
  },
  {
    id: 'engId',
    label: 'Engine Id',
    productType: 'PowerGen',
  },
  {
    id: 'capSN',
    label: 'CAP Serial Number',
    productType: 'PowerGen',
  },
  {
    id: 'fpcConfig.bcuType',
    label: 'BCU Type',
    productType: 'PowerGen',
  },
  {
    id: 'fpcConfig.ctrlMode',
    label: 'Control Mode',
    productType: 'PowerGen',
  },
  {
    id: 'fpcConfig.hhtOffset',
    label: 'HHT Offset',
    productType: 'PowerGen',
  },
  {
    id: 'fpcConfig.pumpType',
    label: 'Pump Type',
    productType: 'PowerGen',
  },
  {
    id: 'fpcConfig.pwmBlwrMax',
    label: 'Pump Blower Max',
    productType: 'PowerGen',
  },
  {
    id: 'fpcConfig.systemRev',
    label: 'System Revision',
    productType: 'PowerGen',
  },
  {
    id: 'fpcConfig.systemType',
    label: 'System Type',
    productType: 'PowerGen',
  },
  {
    id: 'fpcConfig.tempSource',
    label: 'Temperature Source',
    productType: 'PowerGen',
  },
  {
    id: 'fpcConfig.variantCode',
    label: 'Variant Code',
    productType: 'PowerGen',
  },
  {
    id: 'fpcFwVer',
    label: 'FPC Firmware Version',
    productType: 'PowerGen',
  },
  {
    id: 'installDescription',
    label: 'Install Description',
    productType: 'All',
  },
  {
    id: 'lastAnnualInspectionDate',
    label: 'Last Annual Inspection Date',
    productType: 'All',
  },
  {
    id: 'latest.engP',
    label: 'Latest Engine Power',
    productType: 'PowerGen',
    units: 'W',
  },
  {
    id: 'latest.outP1',
    label: 'Latest Output Power 1',
    units: 'W',
    productType: 'PowerGen',
  },
  {
    id: 'latest.outP2',
    label: 'Latest Output Power 2',
    units: 'W',
    productType: 'PowerGen',
  },
  {
    id: 'latest.outP3',
    label: 'Latest Output Power 3',
    units: 'W',
    productType: 'PowerGen',
  },
  {
    id: 'latest.userLoadP',
    label: 'Electrical Load',
    units: 'W',
    productType: 'PowerGen',
    tooltip:
      'Latest 1-hour average power currently being supplied. NOTE: this may not be accurate if the QEC output configuration was changed in the last data time period.',
  },
  {
    id: 'latest.ts',
    label: 'Latest Timestamp',
    productType: 'PowerGen',
  },
  {
    id: 'lockKey',
    label: 'Lock Key',
    productType: 'All',
  },
  {
    id: 'locked',
    label: 'FPC Locked',
    productType: 'All',
  },
  {
    id: 'networkConfig.dhcp',
    label: 'DHCP',
    productType: 'PowerGen',
  },
  {
    id: 'networkConfig.gw',
    label: 'Gateway',
    productType: 'PowerGen',
  },
  {
    id: 'networkConfig.ip',
    label: 'IP',
    productType: 'PowerGen',
  },
  {
    id: 'networkConfig.netmask',
    label: 'Netmask',
    productType: 'PowerGen',
  },
  {
    id: 'offlineNotified',
    label: 'Offline Notification Sent',
    productType: 'All',
  },
  {
    id: 'optConnectModemSN',
    label: 'OptConnect Modem Serial Number',
    productType: 'All',
  },
  {
    id: 'orgId',
    label: 'Organization Id',
    productType: 'All',
  },
  {
    id: 'qecFwVer',
    label: 'QEC Firmware Version',
    productType: 'PowerGen',
  },
  {
    id: 'qecHwVer',
    label: 'QEC Hardware Version',
    productType: 'PowerGen',
  },
  {
    id: 'qecId',
    label: 'QEC Id',
    productType: 'PowerGen',
  },
  {
    id: 'qecOutConfig.alw',
    label: 'QEC Output Config: ALW',
    productType: 'PowerGen',
  },
  {
    id: 'qecOutConfig.aux',
    label: 'QEC Output Config: Auxiliary',
    productType: 'PowerGen',
  },
  {
    id: 'qecOutConfig.en',
    label: 'QEC Output Config: Enable',
    productType: 'PowerGen',
  },
  {
    id: 'qecOutConfig.freq',
    label: 'QEC Output Config: Frequency',
    productType: 'PowerGen',
    units: 'Hz',
  },
  {
    id: 'qecOutConfig.iMax',
    label: 'QEC Output Config: iMax',
    productType: 'PowerGen',
  },
  {
    id: 'qecOutConfig.mirr',
    label: 'QEC Output Config: Mirr',
    productType: 'PowerGen',
  },
  {
    id: 'qecOutConfig.phase',
    label: 'QEC Output Config: Phase',
    productType: 'PowerGen',
  },
  {
    id: 'qecOutConfig.pri',
    label: 'QEC Output Config: Pri',
    productType: 'PowerGen',
  },
  {
    id: 'qecOutConfig.type',
    label: 'QEC Output Config: Type',
    productType: 'PowerGen',
  },
  {
    id: 'qecOutConfig.vPeak',
    label: 'QEC Output Config: vPeak',
    productType: 'PowerGen',
    units: 'V',
  },
  {
    id: 'siteId',
    label: 'Site Id',
    productType: 'All',
  },
  {
    id: 'stats.activeFaults',
    label: 'Active Faults',
    productType: 'All',
  },
  {
    id: 'stats.capHoursA',
    label: 'Compressor A Hours',
    productType: 'PowerGen',
    tooltip: 'Total compressor A run time for all time',
    units: 'h',
  },
  // TODO: update labels
  {
    id: 'stats.capHoursB',
    label: 'Compressor B Hours',
    tooltip: 'Total compressor B run time for all time',
    productType: 'PowerGen',
    units: 'h',
  },
  {
    id: 'stats.runtimeA',
    label: 'Compressor A Run Time',
    tooltip: 'Total compressor A run time for all time',
    productType: 'CAP',
    units: 'h',
  },
  {
    id: 'stats.runtimeB',
    label: 'Compressor B Run Time',
    tooltip: 'Total compressor B run time for all time',
    productType: 'CAP',
    units: 'h',
  },
  {
    id: 'stats.runtimeDryer',
    label: 'Dryer Run Time',
    productType: 'CAP',
    units: 'h',
  },
  {
    id: 'stats.overTempCtr',
    label: 'Overtemp Run Time',
    tooltip:
      'The amount of time the unit has operated above its recommended operating conditions, increasing its servicing requirements.',
    productType: 'CAP',
    units: 'h',
  },
  {
    id: 'stats.tCpu',
    label: 'QDL CPU Temperature',
    productType: 'CAP',
    units: '°C',
  },
  {
    id: 'stats.serviceDueA',
    label: 'Compressor A Service Due Hours',
    productType: 'CAP',
  },
  {
    id: 'stats.serviceDueB',
    label: 'Compressor B Service Due Hours',
    productType: 'CAP',
  },
  {
    id: 'config.pLowSp',
    label: 'Pressure Cut-in Setpoint',
    productType: 'CAP',
    units: 'psi',
  },
  {
    id: 'config.pHighSp',
    label: 'Pressure Cut-off Setpoint',
    productType: 'CAP',
    units: 'psi',
  },
  // Pico
  {
    id: 'config.pLowSpBk',
    label: 'Backup Pressure Cut-in Setpoint',
    productType: 'CAP',
    units: 'psi',
  },
  {
    id: 'config.pHighSpBk',
    label: 'Backup Pressure Cut-off Setpoint',
    productType: 'CAP',
    units: 'psi',
  },
  {
    id: 'config.pAlSp',
    label: 'Primary Pressure Alarm Setpoint',
    productType: 'CAP',
    units: 'psi',
  },
  {
    id: 'config.pAlSpBk',
    label: 'Backup Pressure Alarm Setpoint',
    productType: 'CAP',
    units: 'psi',
  },
  // TODO: update labels
  {
    id: 'stats.capStates',
    label: 'Cap States',
    productType: 'PowerGen',
  },
  // TODO: update labels
  {
    id: 'stats.capTotalVol',
    label: 'Total Accumulated Air',
    productType: 'PowerGen',
    tooltip: 'Total air supplied for all time',
    units: 'scf',
  },
  {
    id: 'stats.coldRunHours',
    label: 'Cold Run Hours',
    productType: 'PowerGen',
    units: 'h',
  },
  {
    id: 'stats.coldStarts',
    label: 'Cold Starts',
    productType: 'PowerGen',
  },
  // TODO: update labels
  {
    id: 'stats.engE',
    label: 'Gross Engine Energy',
    productType: 'PowerGen',
    units: 'Wh',
  },
  {
    id: 'stats.engP',
    label: 'Gross Engine Power',
    productType: 'PowerGen',
    units: 'W',
  },
  // TODO: update labels
  {
    id: 'stats.fpcHours',
    label: 'FPC Hours',
    productType: 'PowerGen',
    units: 'h',
  },
  {
    id: 'stats.hotRunHours',
    label: 'Hot Run Hours',
    productType: 'PowerGen',
    units: 'h',
  },
  {
    id: 'stats.ignitionCycles',
    label: 'Ignition Cycles',
    productType: 'PowerGen',
  },
  {
    id: 'stats.ignitionFailures',
    label: 'Re-ignition Cycles',
    productType: 'PowerGen',
  },
  // TODO: update labels
  {
    id: 'stats.netDebug',
    label: 'Network Debug',
    productType: 'PowerGen',
  },
  {
    id: 'stats.outE1',
    label: 'Output 1 Energy',
    productType: 'PowerGen',
    units: 'kWh',
  },
  {
    id: 'stats.outE2',
    label: 'Output 2 Energy',
    productType: 'PowerGen',
    units: 'kWh',
  },
  {
    id: 'stats.outE3',
    label: 'Output 3 Energy',
    productType: 'PowerGen',
    units: 'kWh',
  },
  {
    id: 'stats.energy',
    label: 'User Output Energy',
    productType: 'PowerGen',
    tooltip: 'Total user output energy for all time',
    units: 'Wh',
  },
  // TODO: update labels
  {
    id: 'stats.qecHours',
    label: 'QEC Hours',
    productType: 'PowerGen',
    units: 'h',
  },
  {
    id: 'stats.state',
    label: 'System State',
    productType: 'All',
  },
  {
    id: 'stats.systemHours',
    label: 'Total Run Time',
    tooltip: 'Total run time for all time',
    productType: 'PowerGen',
    units: 'h',
  },
  {
    id: 'stats.tCyclesFast',
    label: 'Fast Cycles',
    productType: 'PowerGen',
  },
  {
    id: 'stats.tCyclesSlow',
    label: 'Slow Cycles',
    productType: 'PowerGen',
  },
  {
    id: 'stats.flow1Hours',
    label: 'Air Flow Last Hour',
    productType: 'CAP',
    units: 'scfm',
  },
  {
    id: 'latest.pressure',
    label: 'Air Pressure',
    productType: 'CAP',
    units: 'psi',
  },
  {
    id: 'subscriptionNotice',
    label: 'Subscription Notice Sent',
    productType: 'All',
  },
  {
    id: 'subscription',
    label: 'Subscription Setting',
    productType: 'All',
  },
  {
    id: 'summitInfo',
    label: 'Summit Info',
    productType: 'All',
  },
  {
    id: 'systemDescription',
    label: 'System Description',
    productType: 'All',
  },
  {
    id: 'warrantyStandardDate',
    label: 'Standard Warranty End',
    productType: 'All',
  },
  {
    id: 'warrantyNotice',
    label: 'Warranty Notice Sent',
    productType: 'All',
  },
  {
    id: 'warranty',
    label: 'Warranty Setting',
    productType: 'All',
  },
  {
    id: 'subscriptionStatus',
    label: 'Subscription Status',
    productType: 'All',
  },
  {
    id: 'plcVer',
    label: 'PLC Software Version',
    productType: 'CAP',
  },
  {
    id: 'qdlSwVer',
    label: 'QDL Software Version',
    productType: 'CAP',
  },
  {
    id: 'qdlModel',
    label: 'QDL Model',
    productType: 'CAP',
  },
  {
    id: 'productFamily',
    label: 'Product Family',
    productType: 'All',
  },
  {
    id: 'altitude',
    label: 'Altitude',
    productType: 'PowerGen',
    units: 'ft',
  },
  {
    id: 'stats.volGasMeter',
    label: 'Gas Meter Volume',
    productType: 'PowerGen',
    tooltip: 'Total gas consumed for all time',
    units: 'scf',
  },
  {
    id: 'config.flowHighLim',
    label: 'High Flow Limit',
    productType: 'CAP',
    units: 'scfm',
  },
];

export function getSystemField(field: string): SystemField | undefined {
  return systemFields.find((item) => item.id === field);
}

interface RowData {
  id: string;
  label: string;
  chart?: boolean;
  tooltip?: string;
  units?: string;
  productType?: 'CAP' | 'PowerGen' | 'SmartBoiler';
}

// TODO: get these fields from backend
export const rowDataTypes: RowData[] = [
  // FPC
  { id: 'alarmsCap', label: 'CAP Alarms', productType: 'PowerGen' },
  { id: 'battI', label: 'Battery Current', productType: 'PowerGen', units: 'A' },
  {
    id: 'battNegI',
    label: 'QEC Negative BBI Current',
    units: 'A',
    productType: 'PowerGen',
  },
  {
    id: 'battPosI',
    label: 'QEC Positive BBI Current',
    units: 'A',
    productType: 'PowerGen',
  },
  { id: 'battV', label: 'Battery Voltage', units: 'V' },
  {
    id: 'blwrPwm',
    label: 'Blower Control Signal',
    productType: 'PowerGen',
    units: '%',
  },
  { id: 'blwrRpm', label: 'Blower Speed', productType: 'PowerGen', units: 'RPM' },
  { id: 'brkP', label: 'Brake Power', chart: true, productType: 'PowerGen', units: 'W' },
  {
    id: 'clntFlwQec',
    label: 'Internal Coolant Flow Rate',
    productType: 'PowerGen',
    units: 'L/min',
  },
  { id: 'clntLvl', label: 'Coolant Level', productType: 'PowerGen', units: '%' },
  { id: 'clntPress', label: 'Internal Coolant Pressure', productType: 'PowerGen', units: 'mBar' },
  { id: 'coolingCap', label: 'Cooling Capacity', chart: true, productType: 'PowerGen' },
  {
    id: 'doorOpen',
    label: 'Electrical Cabinet Door State',
    productType: 'PowerGen',
  },
  {
    id: 'engE',
    label: 'Gross Engine Energy',
    productType: 'PowerGen',
    units: 'Wh',
  },
  { id: 'engI', label: 'Engine Current', chart: true, productType: 'PowerGen', units: 'A' },
  { id: 'engP', label: 'Gross Engine Power', chart: true, productType: 'PowerGen', units: 'W' },
  { id: 'engV', label: 'Engine Voltage', chart: true, productType: 'PowerGen', units: 'V' },
  { id: 'extEstop', label: 'External Emergency Stop', productType: 'PowerGen' },
  {
    id: 'fanPwm',
    label: 'Cooling Fan Control Signal',
    productType: 'PowerGen',
    units: '%',
  },
  { id: 'fanRpm', label: 'Cooling Fan Speed', productType: 'PowerGen', units: 'RPM' },
  { id: 'febGPI', label: 'FEB GPI States', productType: 'PowerGen' },
  { id: 'febGPO', label: 'FEB GPO States', productType: 'PowerGen' },
  // REFACTORME: duplicated by QDL Api
  {
    id: 'flwCap',
    label: 'Air Flow',
    productType: 'PowerGen',
    tooltip: 'Air flow rate at the output of the compressor',
    units: 'scfm',
  },
  {
    id: 'flwGasMeter',
    label: 'Gas Meter Flow',
    productType: 'PowerGen',
    tooltip: 'Gas meter flow rate',
    units: 'scfm',
  },
  { id: 'volGasMeter', label: 'Gas Meter Volume', productType: 'PowerGen', units: 'scf' },
  {
    id: 'gasPress',
    label: 'System Gas Pressure',
    productType: 'PowerGen',
    tooltip: 'Gas pressure at the burner',
    units: 'mBar',
  },
  {
    id: 'ghtPumpOn',
    label: 'GHT Pump State',
    productType: 'PowerGen',
    tooltip: 'Glycol heat trace pump status (On/Off)',
    units: '',
  },
  {
    id: 'hvdcAvgV',
    label: 'Internal HVDC Voltage',
    productType: 'PowerGen',
    units: 'V',
  },
  { id: 'lambda', label: 'Lambda', chart: true, productType: 'PowerGen' },
  { id: 'mon1', label: 'Monitor 1', chart: true, productType: 'CAP' },
  { id: 'mon2', label: 'Monitor 2', chart: true, productType: 'CAP' },
  { id: 'mon3', label: 'Monitor 3', chart: true, productType: 'CAP' },
  { id: 'mon4', label: 'Monitor 4', chart: true, productType: 'CAP' },
  { id: 'mon5', label: 'Monitor 5', chart: true, productType: 'CAP' },
  { id: 'mon6', label: 'Monitor 6', chart: true, productType: 'CAP' },
  { id: 'mon7', label: 'Monitor 7', chart: true, productType: 'CAP' },
  { id: 'mon8', label: 'Monitor 8', chart: true, productType: 'CAP' },
  { id: 'mon9', label: 'Monitor 9', chart: true, productType: 'CAP' },
  { id: 'mon10', label: 'Monitor 10', chart: true, productType: 'CAP' },
  { id: 'mon11', label: 'Monitor 11', chart: true, productType: 'CAP' },
  { id: 'mon12', label: 'Monitor 12', chart: true, productType: 'CAP' },
  { id: 'mon13', label: 'Monitor 13', chart: true, productType: 'CAP' },
  { id: 'mon14', label: 'Monitor 14', chart: true, productType: 'CAP' },
  { id: 'mon15', label: 'Monitor 15', chart: true, productType: 'CAP' },
  { id: 'mon16', label: 'Monitor 16', chart: true, productType: 'CAP' },
  { id: 'outE1', label: 'Output 1 Energy', productType: 'PowerGen', units: 'kWh' },
  { id: 'outE2', label: 'Output 2 Energy', productType: 'PowerGen', units: 'kWh' },
  { id: 'outE3', label: 'Output 3 Energy', productType: 'PowerGen', units: 'kWh' },
  { id: 'outI1', label: 'Output 1 Current', productType: 'PowerGen', units: 'A' },
  { id: 'outI2', label: 'Output 2 Current', productType: 'PowerGen', units: 'A' },
  { id: 'outI3', label: 'Output 3 Current', productType: 'PowerGen', units: 'A' },
  { id: 'outOL1', label: 'Output 1 Overload', productType: 'PowerGen' },
  { id: 'outOL2', label: 'Output 2 Overload', productType: 'PowerGen' },
  { id: 'outOL3', label: 'Output 3 Overload', productType: 'PowerGen' },
  { id: 'outP1', label: 'Output 1 Power', productType: 'PowerGen', units: 'W' },
  { id: 'outP2', label: 'Output 2 Power', productType: 'PowerGen', units: 'W' },
  { id: 'outP3', label: 'Output 3 Power', productType: 'PowerGen', units: 'W' },
  { id: 'outV1', label: 'Output 1 Voltage', productType: 'PowerGen', units: 'V' },
  { id: 'outV2', label: 'Output 2 Voltage', productType: 'PowerGen', units: 'V' },
  { id: 'outV3', label: 'Output 3 Voltage', productType: 'PowerGen', units: 'V' },
  // REFACTORME: duplicated by QDL Api
  {
    id: 'pCap',
    label: 'Air Pressure',
    productType: 'PowerGen',
    tooltip: 'Air pressure at the output of the compressor',
    units: 'psi',
  },
  {
    id: 'pstAmp',
    label: 'Engine Piston Amplitude',
    productType: 'PowerGen',
    units: 'mm',
  },
  {
    id: 'pstBias',
    label: 'Engine Piston Bias',
    productType: 'PowerGen',
    units: 'mm',
  },
  {
    id: 'pstFreq',
    label: 'Engine Frequency',
    productType: 'PowerGen',
    units: 'Hz',
  },
  { id: 'pstPhase', label: 'Engine Piston Phase', chart: true, productType: 'PowerGen' },
  { id: 'pumpFb', label: 'Pump Feedback Value', chart: true, productType: 'PowerGen' },
  { id: 'pumpPwm', label: 'Pump Control Signal', chart: true, productType: 'PowerGen' },
  { id: 'remoteMode', label: 'Remote Mode', productType: 'PowerGen' },
  { id: 'statesCap', label: 'CAP States', productType: 'PowerGen' },
  {
    id: 'tAux1',
    label: 'Auxiliary Temperature 1',
    productType: 'PowerGen',
    units: '°C',
  },
  {
    id: 'tAux2',
    label: 'Auxiliary Temperature 2',
    productType: 'PowerGen',
    units: '°C',
  },
  { id: 'tBatt', label: 'Battery Temperature', productType: 'PowerGen', units: '°C' },
  { id: 'tCap1', label: 'Comp A Temperature', productType: 'PowerGen', units: '°C' },
  { id: 'tCap2', label: 'Comp B Temperature', productType: 'PowerGen', units: '°C' },
  { id: 'tCap3', label: 'Building Temperature', productType: 'PowerGen', units: '°C' },
  {
    id: 'tClntIn',
    label: 'Internal Coolant Temperature (Cold)',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tClntOut',
    label: 'Internal Coolant Temperature (Hot)',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tDuctJacket',
    label: 'Duct Jacket Temperature',
    tooltip: 'Max Coolant Temperature',
    productType: 'PowerGen',
    units: '°C',
  },
  {
    id: 'tEnclosure',
    label: 'Internal Enclosure Temperature',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tEngBay1',
    label: 'Engine Bay Top 1 Temperature',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tEngBay2',
    label: 'Engine Bay Top 2 Temperature',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tEngBayBot',
    label: 'Engine Bay Bottom Temperature',
    units: '°C',
    productType: 'PowerGen',
  },
  { id: 'tExhaust', label: 'Exhaust Temperature', productType: 'PowerGen', units: '°C' },
  { id: 'tExt', label: 'External Supply Water Temperature', productType: 'PowerGen', units: '°C' },
  {
    id: 'tFpcBrd',
    label: 'FPC Board Temperature',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tFpcCpu',
    label: 'FPC CPU Temperature',
    units: '°C',
    productType: 'PowerGen',
  },
  { id: 'tGasValve', label: 'Gas Valve Temperature', productType: 'PowerGen', units: '°C' },
  {
    id: 'tGhtIn',
    label: 'GHT Return',
    units: '°C',
    productType: 'PowerGen',
    tooltip: 'Glycol heat trace return (input) temperature',
  },
  {
    id: 'tGhtOut',
    label: 'GHT Supply',
    units: '°C',
    productType: 'PowerGen',
    tooltip: 'Glycol heat trace supply (output) temperature',
  },
  {
    id: 'tHead',
    label: 'Heater Head Temperature',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tHeadSp',
    label: 'Head Temperature Setpoint',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tHeadTc1',
    label: 'Heater Head Temperature 1',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tHeadTc2',
    label: 'Heater Head Temperature 2',
    units: '°C',
    productType: 'PowerGen',
  },
  {
    id: 'tHruTop',
    label: 'HRU Top Temperature',
    units: '°C',
    productType: 'PowerGen',
  },
  { id: 'totalVolCap', label: 'Total Accumulated Air', productType: 'PowerGen', units: 'scf' },
  {
    id: 'tQec',
    label: 'System Coolant Temperature',
    tooltip: 'QEC Coolant Temperature',
    productType: 'PowerGen',
    units: '°C',
  },
  { id: 'valveHeater', label: 'Gas Valve Heater State', productType: 'PowerGen' },
  { id: 'userLoadP', label: 'Electrical Load', productType: 'PowerGen', units: 'W' },
  // QDL
  {
    id: 'accAir',
    label: 'Total Accumulated Air',
    productType: 'CAP',
    tooltip: 'Total air supplied for all time',
    units: 'scf',
  },
  {
    id: 'accCh4',
    label: 'Total Methane Abated',
    productType: 'CAP',
    tooltip: `<b>How abated methane (m<sub>CH<sub>4</sub></sub>) is calculated:</b>
<div>&nbsp;</div>
<div>m<sub>CH<sub>4</sub></sub> = [V<sub>air</sub>] x [GER] x [%<sub>CH<sub>4</sub></sub>] x [p<sub>CH<sub>4</sub></sub>]</div>
<div>&nbsp;</div>
<div>m<sub>CH<sub>4</sub></sub>: mass of CH<sub>4</sub> abated</div>
<div>V<sub>air</sub>: volume of supplied air at 1 atm, 15℃</div>
<div>GER: Gas Equivalency Ratio</div>
<div>%<sub>CH<sub>4</sub></sub>: Percentage of CH<sub>4</sub> in gas composition</div>
<div>p<sub>CH<sub>4</sub></sub>: Methane density at 1 atm, 15℃ (0.68 kg/m<sup>3</sup>)</div>
<div>&nbsp;</div>
<div><b>Note:</b> The gas equivalency ratio (GER) and methane composition (%<sub>CH<sub>4</sub></sub>) are set on a per site basis in the Dashboard.</div>`,
    units: 't',
  },
  {
    id: 'flow',
    label: 'Air Flow',
    productType: 'CAP',
    tooltip: 'Air flow rate at the output of the compressor',
    units: 'scfm',
  },
  {
    id: 'freqVFDA',
    label: 'VFD A Frequency',
    productType: 'CAP',
    units: 'Hz',
  },
  {
    id: 'freqVFDB',
    label: 'VFD B Frequency',
    productType: 'CAP',
    units: 'Hz',
  },
  {
    id: 'pFlowMeter',
    label: 'Flow Meter Pressure',
    productType: 'CAP',
    units: 'psi',
  },
  {
    id: 'pressure',
    label: 'Air Pressure',
    productType: 'CAP',
    tooltip: 'Air pressure at the output of the compressor',
    units: 'psi',
  },
  {
    id: 'pBk',
    label: 'Backup Air Pressure',
    productType: 'CAP',
    tooltip: 'Air pressure at the backup supply port',
    units: 'psi',
  },
  { id: 'runtimeA', label: 'Compressor A Runtime', productType: 'CAP', units: 'h' },
  { id: 'runtimeB', label: 'Compressor B Runtime', productType: 'CAP', units: 'h' },
  { id: 'runtimeDryer', label: 'Dryer Runtime', productType: 'CAP', units: 'h' },
  {
    id: 'tFlowMeter',
    label: 'Flow Meter Temperature',
    productType: 'CAP',
    units: '°C',
  },
  {
    id: 'xfrSw',
    label: 'Transfer Switch Position',
    productType: 'CAP',
  },
  {
    id: 'compASt',
    label: 'Compressor A State',
    productType: 'CAP',
  },
  {
    id: 'compBSt',
    label: 'Compressor B State',
    productType: 'CAP',
  },
  { id: 'tCompA', label: 'Comp A Temperature', productType: 'CAP', units: '°C' },
  { id: 'tDryer', label: 'Dryer Temperature', productType: 'CAP', units: '°C' },
  { id: 'tCompB', label: 'Comp B Temperature', productType: 'CAP', units: '°C' },
  { id: 'tOut', label: 'Outside Temperature', productType: 'CAP', units: '°C' },
  { id: 'tBldg', label: 'Building Temperature', productType: 'CAP', units: '°C' },
  { id: 'tCab', label: 'Cabinet Temperature', productType: 'CAP', units: '°C' },
  { id: 'climCtrl', label: 'Climate Control Running', productType: 'CAP' },
  { id: 'drainValve', label: 'Drain Valve State', productType: 'CAP' },
  { id: 'solP', label: 'Solar Charge Power', productType: 'CAP', units: 'W' },
  {
    id: 'dayE',
    label: 'Daily Generated Energy',
    productType: 'CAP',
    units: 'Wh',
  },
];

export function getSystemRowData(field: string): RowData | undefined {
  return rowDataTypes.find((type) => type.id === field);
}

export const metricsConfig: Record<string, DashboardMetricConfig> = {
  'system.stats.systemHours': {
    units: getSystemField('stats.systemHours')?.units,
    description: getLabel('stats.systemHours') ?? '',
    tooltip: getSystemField('stats.systemHours')?.tooltip,
    dataIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-clock-history min-w-[3.5rem] min-h-[3.5rem] w-14 h-14"
        viewBox="0 0 16 16"
      >
        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
      </svg>
    ),
  },
  'system.stats.runtimeA': {
    units: getSystemField('stats.runtimeA')?.units,
    description: getLabel('stats.runtimeA') ?? '',
    tooltip: getSystemField('stats.runtimeA')?.tooltip,
    dataIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-clock-history min-w-[3.5rem] min-h-[3.5rem] w-14 h-14"
        viewBox="0 0 16 16"
      >
        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
      </svg>
    ),
  },
  'system.stats.runtimeB': {
    units: getSystemField('stats.runtimeB')?.units,
    description: getLabel('stats.runtimeB') ?? '',
    tooltip: getSystemField('stats.runtimeB')?.tooltip,
    dataIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-clock-history min-w-[3.5rem] min-h-[3.5rem] w-14 h-14"
        viewBox="0 0 16 16"
      >
        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
      </svg>
    ),
  },
  'system.stats.capHoursA': {
    units: getSystemField('stats.capHoursA')?.units,
    description: getLabel('stats.capHoursA') ?? '',
    tooltip: getSystemField('stats.capHoursA')?.tooltip,
    dataIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-clock-history min-w-[3.5rem] min-h-[3.5rem] w-14 h-14"
        viewBox="0 0 16 16"
      >
        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
      </svg>
    ),
  },
  'system.stats.capHoursB': {
    units: getSystemField('stats.capHoursB')?.units,
    description: getLabel('stats.capHoursB') ?? '',
    tooltip: getSystemField('stats.capHoursB')?.tooltip,
    dataIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-clock-history min-w-[3.5rem] min-h-[3.5rem] w-14 h-14"
        viewBox="0 0 16 16"
      >
        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
      </svg>
    ),
  },
  'system.stats.energy': {
    units: getSystemField('stats.energy')?.units,
    description: getLabel('stats.energy') ?? '',
    tooltip: getSystemField('stats.energy')?.tooltip,
    dataIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-speedometer2 min-w-[3.5rem] min-h-[3.5rem] w-14 h-14"
        viewBox="0 0 16 16"
      >
        <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
        <path
          fillRule="evenodd"
          d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
        />
      </svg>
    ),
  },
  'system.stats.capTotalVol': {
    units: getSystemField('stats.capTotalVol')?.units,
    description: getLabel('stats.capTotalVol') ?? '',
    tooltip: getSystemField('stats.capTotalVol')?.tooltip,
    dataIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-cloud-fog2 min-w-[3.5rem] min-h-[3.5rem] w-14 h-14"
        viewBox="0 0 16 16"
      >
        <path d="M8.5 4a4.002 4.002 0 0 0-3.8 2.745.5.5 0 1 1-.949-.313 5.002 5.002 0 0 1 9.654.595A3 3 0 0 1 13 13H.5a.5.5 0 0 1 0-1H13a2 2 0 0 0 .001-4h-.026a.5.5 0 0 1-.5-.445A4 4 0 0 0 8.5 4zM0 8.5A.5.5 0 0 1 .5 8h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
      </svg>
    ),
  },
  'currentSystemData.accAir': {
    units: getSystemRowData('accAir')?.units,
    description: getLabel('accAir') ?? '',
    tooltip: getSystemRowData('accAir')?.tooltip,
    dataIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-cloud-fog2 min-w-[3.5rem] min-h-[3.5rem] w-14 h-14"
        viewBox="0 0 16 16"
      >
        <path d="M8.5 4a4.002 4.002 0 0 0-3.8 2.745.5.5 0 1 1-.949-.313 5.002 5.002 0 0 1 9.654.595A3 3 0 0 1 13 13H.5a.5.5 0 0 1 0-1H13a2 2 0 0 0 .001-4h-.026a.5.5 0 0 1-.5-.445A4 4 0 0 0 8.5 4zM0 8.5A.5.5 0 0 1 .5 8h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
      </svg>
    ),
  },
  'currentSystemData.accCh4': {
    units: getSystemRowData('accCh4')?.units,
    description: getLabel('accCh4') ?? '',
    tooltip: getSystemRowData('accCh4')?.tooltip,
    isCumulative: true,
  },
  'data.userLoadP': {
    units: getSystemRowData('userLoadP')?.units,
    description: getLabel('userLoadP') ?? '',
    tooltip: getSystemRowData('userLoadP')?.tooltip,
  },
  'currentSystemData.pCap': {
    units: getSystemRowData('pCap')?.units,
    description: getLabel('pCap') ?? '',
    tooltip: getSystemRowData('pCap')?.tooltip,
  },
  'data.pressure': {
    units: getSystemRowData('pressure')?.units,
    description: getLabel('pressure') ?? '',
    tooltip: getSystemRowData('pressure')?.tooltip,
    numberFormatOptions: {
      maximumFractionDigits: 1,
    },
  },
  'data.pBk': {
    units: getSystemRowData('pBk')?.units,
    description: getLabel('pBk') ?? '',
    tooltip: getSystemRowData('pBk')?.tooltip,
    numberFormatOptions: {
      maximumFractionDigits: 1,
    },
  },
  'currentSystemData.solP': {
    units: getSystemRowData('solP')?.units,
    description: getLabel('solP') ?? '',
    tooltip: getSystemRowData('solP')?.tooltip,
  },
  'currentSystemData.battV': {
    units: getSystemRowData('battV')?.units,
    description: getLabel('battV') ?? '',
    tooltip: getSystemRowData('battV')?.tooltip,
  },
  'currentSystemData.flwCap': {
    units: getSystemRowData('flwCap')?.units,
    description: getLabel('flwCap') ?? '',
    tooltip: getSystemRowData('flwCap')?.tooltip,
  },
  'data.flow': {
    units: getSystemRowData('flow')?.units,
    description: getLabel('flow') ?? '',
    tooltip: getSystemRowData('flow')?.tooltip,
  },
  'currentSystemData.gasPress': {
    units: getSystemRowData('gasPress')?.units,
    description: getLabel('gasPress') ?? '',
    tooltip: getSystemRowData('gasPress')?.tooltip,
  },
  'currentSystemData.tGhtOut': {
    units: getSystemRowData('tGhtOut')?.units,
    description: getLabel('tGhtOut') ?? '',
    tooltip: getSystemRowData('tGhtOut')?.tooltip,
  },
  'currentSystemData.tGhtIn': {
    units: getSystemRowData('tGhtIn')?.units,
    description: getLabel('tGhtIn') ?? '',
    tooltip: getSystemRowData('tGhtIn')?.tooltip,
  },
  'currentSystemData.ghtPumpOn': {
    units: getSystemRowData('ghtPumpOn')?.units,
    description: getLabel('ghtPumpOn') ?? '',
    tooltip: getSystemRowData('ghtPumpOn')?.tooltip,
  },
  'currentSystemData.flwGasMeter': {
    units: getSystemRowData('flwGasMeter')?.units,
    description: getLabel('flwGasMeter') ?? '',
    tooltip: getSystemRowData('flwGasMeter')?.tooltip,
  },
  'system.stats.volGasMeter': {
    units: getSystemRowData('volGasMeter')?.units,
    description: getLabel('stats.volGasMeter') ?? '',
    tooltip: getSystemField('stats.volGasMeter')?.tooltip,
    dataIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-fire min-w-[3.5rem] min-h-[3.5rem] w-14 h-14"
        viewBox="0 0 16 16"
      >
        <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
      </svg>
    ),
  },
};

export function getLabel(field: string, withUnits?: boolean): string {
  const systemField = getSystemRowData(field) ?? getSystemField(field);
  const name = systemField?.label;
  const units = systemField?.units;

  let label = name ?? '';
  if (units && withUnits) label += ` (${units})`;
  return label;
}
