import { Border } from '../Input/index.types';
import { appendClassProps } from '../util';

interface ApplyInputStylesProps {
  showErrorMessage?: boolean;
  inputClassName?: string | string[];
  disabled?: boolean;
  overrideCursor?: boolean;
  border?: Border;
  transparent?: boolean;
  isMobile?: boolean;
  multiLine?: boolean;
}

export const applyInputStyles = ({
  showErrorMessage,
  inputClassName,
  disabled,
  overrideCursor,
  border,
  transparent,
  isMobile,
  multiLine,
}: ApplyInputStylesProps): string => {
  let backgroundColor = 'bg-white';
  let textColor = 'text-blue-800';

  if (transparent) {
    backgroundColor = 'bg-transparent';
  } else if (disabled) {
    backgroundColor = 'bg-gray-150';
    textColor = 'text-gray-600';
  }

  let borderClass = showErrorMessage ? 'border-red-400' : 'border-gray-300 hover:border-gray-400 focus:border-blue-800';

  if (border === Border.bottom) {
    borderClass = `border-b ${borderClass}`;
  } else if (border === Border.none) {
    borderClass = `border-0 ${borderClass}`;
  } else if (border === Border.outline) {
    borderClass = `border ${borderClass}`;
  } else {
    borderClass = `border ${borderClass}`;
  }
  return `rounded-none py-2 sm:py-1 ${
    !multiLine && 'h-12'
  } outline-none placeholder-gray-400 w-full ${backgroundColor} ${textColor} rounded-sm ${borderClass} ${
    overrideCursor ? '' : disabled ? 'cursor-not-allowed' : 'cursor-text'
  }${isMobile ? ' flex-1' : ''} ${appendClassProps(inputClassName)}`;
};
