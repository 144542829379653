import React, { useCallback, useEffect, useState } from 'react';

import { Distributor, System } from '../../../types';
import SystemListItem from './SystemListItem';

interface SystemProps {
  system: System;
  systemClasses?: string;
  showRemove: boolean;
  checkedSystems?: System[];
  disabled?: boolean;
  bulkMove?: boolean;
  error?: string;
  parentDistributors?: Distributor[];
  onChecked?: (checked: boolean, system?: System) => void;
  handleUndoSystem?: (system: System) => void;
  refreshData: () => void;
}

export default function StaticSystem({
  system,
  systemClasses = '',
  showRemove,
  checkedSystems,
  disabled,
  bulkMove,
  error,
  onChecked,
  handleUndoSystem,
  parentDistributors,
  refreshData,
}: SystemProps): JSX.Element {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const handleOnChangeValue = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
      if (onChecked) onChecked(checked, system);
    },
    [system, onChecked],
  );

  useEffect(() => {
    setIsChecked(!!checkedSystems?.some(({ sysId }: System) => sysId === system.sysId));
  }, [checkedSystems, system, setIsChecked]);

  return (
    <div className={`flex flex-1 flex-row space-between items-center hover:bg-gray-100 ${systemClasses}`}>
      <SystemListItem
        system={system}
        error={error}
        checked={isChecked || disabled}
        showRemove={showRemove}
        disabled={disabled}
        bulkMove={bulkMove}
        onChecked={onChecked ? handleOnChangeValue : undefined}
        refreshData={refreshData}
        handleUndoSystem={handleUndoSystem}
        parentDistributors={parentDistributors}
      />
    </div>
  );
}
