import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchSearchDistributors } from '../../../adapters/api/distributors';
import { fetchOrg, fetchSearchOrgs, SearchOrgsResponse } from '../../../adapters/api/organizations';
import { fetchSearchSites } from '../../../adapters/api/sites';
import { patchSystem } from '../../../adapters/api/systems';
import {
  AsyncSelect,
  Card,
  Checkbox,
  DatePicker,
  Form,
  FormContextData,
  FormSave,
  FormState,
  Input,
  KeyValue,
  ReactSelectOption,
  Select,
  Text,
  TextArea,
  TextType,
} from '../../../ComponentLibrary/src';
import { usePageContext } from '../../../components/Page';
import { AuthContext } from '../../../context/Auth';
import { DistributorsContext, useDistributorsContextValue } from '../../../context/Distributors';
import { OrganizationsContext, useOrganizationsContextValue } from '../../../context/Organizations';
import { SitesContext, useSitesContextValue } from '../../../context/Sites';
import { SystemsContext } from '../../../context/Systems';
import { useMobile, useSetDocumentTitle } from '../../../hooks';
import { GenSystem, SystemForEdit } from '../../../types';
import { toLocalTimezone, warrantySelectionToDate } from '../../../util';
import { PERMISSIONS } from '../../../util/constants';
import { getThumbnail } from '../util';
import { SystemDates } from './Dates';
import { UnlinkBtn } from './UnlinkBtn';
import { formToSystemUpdate, systemToForm } from './util';

export interface EditSystemForm extends Record<string, unknown> {
  systemDescription?: string;
  model?: string;
  installDescription?: string;
  shipDate?: Date | string;
  commissionDate?: Date | string;
  warrantySelection?: string | number | null;
  warrantyExpirationDate?: Date | string;
  subscriptionExpirationDate?: Date | string;
  overrideSubscription: boolean;
  unlimited: boolean;
  distributorId?: string;
  orgId?: string;
  siteId?: string;
  optConnectModemSN?: string | null;
  lastAnnualInspectionDate?: Date | string;
}

function EditSystem(): JSX.Element {
  const navigate = useNavigate();
  const systemsContext = useContext(SystemsContext);
  const system = systemsContext.system as SystemForEdit;
  const getSystem = systemsContext.getSystem;
  const { hasPermissions } = useContext(AuthContext);
  const { sysId } = useParams();
  useSetDocumentTitle(`Edit System${sysId ? ' ' + sysId : ''}`);
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const isMobile = useMobile();
  const loading = !system;
  const thumbnail = getThumbnail(system);

  const enforcePermissions = useCallback(() => {
    const kickUserOut = () => {
      toast('You do not have access to this page.', {
        type: toast.TYPE.ERROR,
      });
      navigate(`/systems/${sysId}`);
    };
    if (
      !hasPermissions(
        [
          PERMISSIONS.dashboard.systems.update,
          PERMISSIONS.dashboard.systems.updateShipDate,
          PERMISSIONS.dashboard.systems.advancedUpdate,
          PERMISSIONS.dashboard.systems.updateDistributor,
          PERMISSIONS.dashboard.systems.updateOrg,
          PERMISSIONS.dashboard.systems.updateSite,
          PERMISSIONS.dashboard.systems.updateProductAccess,
        ],
        'or',
      )
    ) {
      kickUserOut();
    }
  }, [hasPermissions, navigate, sysId]);

  useEffect(() => {
    if (sysId) (getSystem({ sysId: sysId + '?edit=true' }) as Promise<SystemForEdit>).then(enforcePermissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sysId]);

  useEffect(() => {
    setTitle('');
    setBreadcrumbs([
      { text: 'Systems', href: '/systems' },
      { text: `${sysId || 'System'}`, href: `/systems/${sysId}` },
      { text: 'Edit' },
    ]);
    setScrollable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveSystem = async (form: EditSystemForm) => {
    if (!sysId) return;
    const update = formToSystemUpdate(sysId, system, form);

    if (update.shipDate) {
      if (
        !confirm(
          `Are you sure you want to set the ship date to ${DateTime.fromISO(update.shipDate as string).toLocaleString(
            DateTime.DATE_FULL,
          )}?`,
        )
      ) {
        return;
      }
    }
    if (update.commissionDate) {
      if (
        !confirm(
          `Are you sure you want to set the commission date to ${DateTime.fromISO(
            update.commissionDate as string,
          ).toLocaleString(DateTime.DATE_FULL)}?`,
        )
      ) {
        return;
      }
    }

    const res = await patchSystem(update, (err: AxiosError) => {
      toast(err?.response?.data?.error ?? err.message, { type: 'error' });
    });

    if (res === undefined) return;

    toast('System updated', { type: 'success' });
    navigate(`/systems/${sysId}`);
  };

  const handleSelectOrganization = (_?: string | string[], formContext?: FormContextData) => {
    formContext?.updateFormState('siteId', undefined);
  };

  const handleSelectDistributor = (_?: string | string[], formContext?: FormContextData) => {
    formContext?.updateFormState('siteId', undefined);
    formContext?.updateFormState('orgId', undefined);
  };

  const handleSelectWarranty = (
    warrantySelection?: string | number | null | (string | number | null)[],
    formContext?: FormContextData,
  ) => {
    // Will not change if commissionDate is edited at the same time
    const newWarrantyDate = warrantySelectionToDate(
      warrantySelection as string | number,
      toLocalTimezone(system?.warrantyExpirationDate),
      toLocalTimezone(system?.warrantyStandardDate),
      toLocalTimezone(system?.commissionDate),
    );

    formContext?.updateFormState('warrantyExpirationDate', newWarrantyDate);

    if (!formContext?.getFromFormState<boolean>('overrideSubscription')?.value) {
      formContext?.updateFormState('subscriptionExpirationDate', newWarrantyDate ? newWarrantyDate : undefined);
    }
  };

  const handleSelectSubscriptionOverride = (override: boolean, formContext?: FormContextData) => {
    if (!override) {
      formContext?.updateFormState('unlimited', false);
    }
    const warranty = formContext?.getFromFormState<Date>('warrantyExpirationDate')?.value;
    formContext?.updateFormState('subscriptionExpirationDate', warranty);
  };

  const handleSelectUnlimitedSubscription = (unlimited: boolean, formContext?: FormContextData) => {
    const date = unlimited ? 'unlimited' : formContext?.getFromFormState<Date>('warrantyExpirationDate')?.value;
    formContext?.updateFormState('subscriptionExpirationDate', date);
  };

  const handleChangeWarrantyExpirationDate = (date?: Date, formContext?: FormContextData) => {
    formContext?.updateFormState('warrantyExpirationDate', date);
    formContext?.updateFormState('warranty', 'manual');

    if (date && !formContext?.getFromFormState<boolean>('overrideSubscription')?.value) {
      formContext?.updateFormState('subscriptionExpirationDate', date);
    }
  };

  const updateSearchOrgs = async (searchTerm: string, orgId?: string, distributorId?: string) => {
    const response: unknown = await fetchSearchOrgs({
      project: ['name'],
      pageNumber: 1,
      countPerPage: 10,
      sort: {
        name: 1,
      },
      distributorId,
      searchTerm,
      count: true,
    });

    if (!response) return [];

    const { orgs, count } = response as SearchOrgsResponse;
    if (orgId) {
      if (!orgs.find(({ _id }) => orgId === _id)) {
        const foundOrg = await fetchOrg(orgId);

        if (foundOrg) orgs.push(foundOrg);
      }
    }
    const options: ReactSelectOption<string>[] = orgs.map((org) => {
      return {
        value: org._id,
        label: org.label ?? org.name,
      };
    });
    if (count > orgs.length) {
      options.push({
        value: 'more',
        label: `and ${count - orgs.length} more...`,
        isDisabled: true,
      });
    }
    return options;
  };

  const updateSearchSites = async (searchTerm: string, orgId?: string, distributorId?: string) => {
    return fetchSearchSites({
      project: ['site.name'],
      pageNumber: 1,
      countPerPage: 10,
      sort: {
        name: 1,
      },
      distributorId,
      orgId,
      searchTerm,
      count: true,
    })
      .then((response) => {
        if (!response) return [];

        const { sites, count } = response;
        const options: ReactSelectOption<string>[] = response.sites?.map((site) => ({
          value: site._id,
          label: site.label ?? site.name,
        }));

        if (count > sites.length) {
          options.push({
            value: 'more',
            label: `and ${count - sites.length} more...`,
            isDisabled: true,
          });
        }
        return options;
      })
      .catch(() => []);
  };

  const updateSearchDistributors = async (searchTerm?: string, orgId?: string) => {
    if (!hasPermissions(PERMISSIONS.dashboard.distributors.read)) return [];
    return fetchSearchDistributors({
      project: ['name'],
      pageNumber: 1,
      countPerPage: 10,
      sort: {
        name: 1,
      },
      orgId,
      searchTerm,
      count: true,
    });
  };
  // complex permissions
  const canUpdateProductAccess = useMemo(() => {
    if (!!system?.commissionDate && !!system?.shipDate) {
      return hasPermissions(PERMISSIONS.dashboard.systems.updateProductAccess);
    }
    return false;
  }, [system?.commissionDate, system?.shipDate, hasPermissions]);

  const initVals = useMemo(() => systemToForm(system), [system]);

  return (
    <Form<EditSystemForm>
      className={`flex flex-col gap-4 w-full ${isMobile ? 'p-4' : 'px-4 pb-4'}`}
      onSubmit={handleSaveSystem}
      onCancel={() => navigate(`/systems/${sysId}`)}
      initialValues={initVals}
    >
      {(formState: FormState<EditSystemForm>) => (
        <>
          <FormSave className="self-end" data-pwid="system-form-save-1" />
          <div className="grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
            <Card className="lg:col-span-2 flex flex-col gap-2 flex-wrap md:flex-nowrap items-center md:items-start">
              <Text type={TextType.h3}>Info</Text>
              <div className="w-full flex flex-row">
                {thumbnail && <img src={thumbnail} className="h-36 md:h-48 self-center mx-8" />}

                <div className="flex flex-col w-full gap-2">
                  <Input
                    id="systemDescription"
                    className={`${isMobile ? 'w-full' : 'flex-1'}`}
                    tooltip="A short description of the system. For more information, see action logs."
                    label="Description"
                    loading={loading}
                    disabled={!hasPermissions(PERMISSIONS.dashboard.systems.update)}
                    data-pwid="system-description-input"
                    max={100}
                  />
                  <Input
                    id="model"
                    className={`${isMobile ? 'w-full' : 'flex-1'}`}
                    tooltip="System type (set in firmware)"
                    label="Type"
                    loading={loading}
                    data-pwid="system-type-input"
                    disabled
                    showOptional={false}
                  />
                  <TextArea
                    id="installDescription"
                    className={`${isMobile ? 'w-full' : 'flex-1'}`}
                    tooltip="A short description of the installation. For more information, see action logs."
                    label="Install Description"
                    loading={loading}
                    disabled={!hasPermissions(PERMISSIONS.dashboard.systems.update)}
                    data-pwid="system-install-description-input"
                  />
                </div>
              </div>
            </Card>
            <SystemDates
              isMobile={isMobile}
              isLoading={loading}
              hasWarrantyExpirationDate={!!formState.warrantyExpirationDate?.value}
              hasSubscriptionExpirationDate={!!formState.subscriptionExpirationDate?.value}
              hasShipDate={!!system?.shipDate}
              hasCommissionDate={!!system?.commissionDate}
            />
            <Card className="flex-1 flex flex-col gap-2 flex-wrap mb-4 md:mb-0">
              <Text type={TextType.h3} wrap>
                Warranty and Subscription
              </Text>
              {!formState.shipDate?.value || !formState.commissionDate?.value ? (
                <Text type={TextType.body} overrideColor wrap className="text-gray-400 italic">
                  <ExclamationCircleIcon className="mr-1 pb-[0.1rem] w-5 h-5 inline" />
                  Ship and commission dates must be set first
                </Text>
              ) : (
                <>
                  <Select<string | number | null>
                    id="warrantySelection"
                    className="flex-1"
                    label="Warranty"
                    tooltip="The warranty expiration on the unit"
                    options={[
                      {
                        value: null,
                        label: 'Standard',
                      },
                      {
                        value: 1,
                        label: '1 Year Extended',
                      },
                      {
                        value: 2,
                        label: '2 Year Extended',
                      },
                      {
                        value: 3,
                        label: '3 Year Extended',
                      },
                      {
                        value: 'manual',
                        label: 'Manual Override',
                      },
                    ]}
                    onChangeValue={handleSelectWarranty}
                    clearable={false}
                    loading={loading}
                    disabled={!canUpdateProductAccess}
                    data-pwid="warranty-type-select"
                    showOptional={false}
                  />

                  {formState.warrantyExpirationDate?.value && (
                    <DatePicker
                      id="warrantyExpirationDate"
                      className="flex-1"
                      label="Warranty Expiration Date"
                      tooltip="Effective warranty expiration on the unit"
                      onChange={handleChangeWarrantyExpirationDate}
                      disabled={formState.warrantySelection?.value !== 'manual' || !canUpdateProductAccess}
                      data-pwid="warranty-date-picker"
                      showOptional={false}
                    />
                  )}

                  <Checkbox
                    id="overrideSubscription"
                    onChangeValue={handleSelectSubscriptionOverride}
                    label="Override Subscription"
                    disabled={!canUpdateProductAccess}
                    showOptional={false}
                  />
                  <Checkbox
                    id="unlimited"
                    onChangeValue={handleSelectUnlimitedSubscription}
                    label="Unlimited Subscription"
                    disabled={!canUpdateProductAccess || !formState.overrideSubscription?.value}
                    showOptional={false}
                  />
                  {(formState.subscriptionExpirationDate?.value || formState.overrideSubscription?.value) && (
                    <DatePicker
                      id="subscriptionExpirationDate"
                      label="Subscription Expiration Date"
                      tooltip={`Effective ${process.env.REACT_APP_NAME} subscription expiration on the unit`}
                      disabled={
                        !formState.overrideSubscription?.value ||
                        !canUpdateProductAccess ||
                        formState.subscriptionExpirationDate?.value === 'unlimited'
                      }
                      data-pwid="subscription-date-picker"
                      placeholder={formState.subscriptionExpirationDate?.value === 'unlimited' ? 'Unlimited' : ''}
                      required
                    />
                  )}
                </>
              )}
            </Card>
            <Card className="xl:col-span-2 2xl:col-span-1 flex flex-col md:flex-row gap-2 flex-wrap md:flex-nowrap items-center md:items-start">
              <div className="flex flex-col w-full gap-2">
                <div className="flex flex-row gap-2 items-center">
                  <Text type={TextType.h3}>Allocation</Text>
                </div>
                {/* // TODO: Memoize to avoid unecessary fetches */}
                <AsyncSelect<string>
                  id="distributorId"
                  // workaround to trigger loading options when dist, org or site changes
                  callLoadOptionsOn={`dist-${formState.orgId?.value ?? ''}-${system?.sysId}`}
                  className={`${isMobile ? 'w-full' : 'flex-1'}`}
                  label="Distributor"
                  tooltip="Distributor that sold the unit"
                  onLoadOptions={(searchTerm: string) => updateSearchDistributors(searchTerm, formState.orgId?.value)}
                  onChange={handleSelectDistributor}
                  loading={loading}
                  clearable
                  disabled={
                    !hasPermissions(PERMISSIONS.dashboard.systems.updateDistributor) ||
                    !hasPermissions(PERMISSIONS.dashboard.distributors.read)
                  }
                  data-pwid="system-distributor-select"
                  showOptional={false}
                />
                {/* // TODO: Memoize to avoid unecessary fetches */}
                <AsyncSelect<string>
                  id="orgId"
                  // workaround to trigger loading options when org, distributor or site changes
                  callLoadOptionsOn={`org-${formState.distributorId?.value ?? ''}-${sysId}`}
                  className={`${isMobile ? 'w-full' : 'flex-1'}`}
                  label="Organization"
                  tooltip="The organization or customer entity name"
                  onLoadOptions={(searchTerm) => {
                    return updateSearchOrgs(searchTerm, formState.orgId?.value, formState.distributorId?.value);
                  }}
                  onChange={handleSelectOrganization}
                  loading={loading}
                  clearable
                  disabled={!hasPermissions(PERMISSIONS.dashboard.systems.updateOrg)}
                  data-pwid="system-org-select"
                  showOptional={false}
                />
                {/* // TODO: Memoize to avoid unecessary fetches */}
                <AsyncSelect
                  id="siteId"
                  // workaround to trigger loading options when dist, site, or org changes
                  callLoadOptionsOn={`site-${formState.distributorId?.value ?? ''}-${
                    formState.orgId?.value ?? ''
                  }-${sysId}`}
                  className="flex-1"
                  label="Site"
                  tooltip="The name of the site"
                  onLoadOptions={(searchTerm) => {
                    return updateSearchSites(searchTerm, formState.orgId?.value, formState.distributorId?.value);
                  }}
                  loading={loading}
                  disabled={!formState.orgId?.value || !hasPermissions(PERMISSIONS.dashboard.systems.updateSite)}
                  clearable
                  data-pwid="system-site-select"
                  showOptional={false}
                />
                {/* TODO: Use https://react-select.com/creatable instead */}
                {/* {organization?.value && (
              <Button onClick={() => navigate(`/assets/createSite/${organization.value}`)}>Allocate</Button>
            )} */}
              </div>
            </Card>

            <Card className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center">
                <Text type={TextType.h3}>Other</Text>
              </div>
              <div className="flex flex-row w-full gap-2 flex-wrap md:flex-nowrap">
                {hasPermissions([PERMISSIONS.dashboard.systems.advancedUpdate]) && (
                  <div className="flex-1 flex flex-col gap-2 justify-center">
                    <div className="flex flex-row justify-between">
                      <KeyValue
                        label="Dashboard Link"
                        value={(system as GenSystem)?.locked ? 'Linked' : 'Unlinked'}
                        valueColor={(system as GenSystem)?.locked ? 'text-emerald-500' : 'text-red-500'}
                      />
                      <UnlinkBtn
                        updateLock={async () => {
                          const res = await patchSystem({ sysId: sysId, lockKey: null });
                          if (sysId && res !== undefined) await getSystem({ sysId: sysId + '?edit=true' });
                        }}
                        disable={!(system as GenSystem)?.locked}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-row w-full gap-2 items-end flex-wrap md:flex-nowrap">
                <Input
                  id="optConnectModemSN"
                  className={`${isMobile ? 'w-full' : 'flex-1'}`}
                  tooltip="Opt Connect Modem Serial Number"
                  label="Modem Serial"
                  loading={loading}
                  disabled={!hasPermissions(PERMISSIONS.dashboard.systems.update)}
                  data-pwid="system-modem-serial-input"
                  clearable
                  showOptional={false}
                />
              </div>
            </Card>
          </div>
        </>
      )}
    </Form>
  );
}

export default function EditSystemContainer(): JSX.Element {
  const organizationsContextValue = useOrganizationsContextValue();
  const distributorsContextValue = useDistributorsContextValue();
  const sitesContextValue = useSitesContextValue();

  return (
    <OrganizationsContext.Provider value={organizationsContextValue}>
      <DistributorsContext.Provider value={distributorsContextValue}>
        <SitesContext.Provider value={sitesContextValue}>
          <EditSystem />
        </SitesContext.Provider>
      </DistributorsContext.Provider>
    </OrganizationsContext.Provider>
  );
}
