import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';

import { Distributor, System } from '../../../types';
import SystemListItem from './SystemListItem';

interface SystemProps {
  system: System;
  list: string;
  savingSystems: Set<string>;
  systemClasses?: string;
  showRemove: boolean;
  parentDistributors?: Distributor[];
  isAlreadyDragging?: boolean;
  handleDragging: (dragging: boolean) => void;
  draggingFromAllocated?: (allocated: boolean) => void;
  setCurrentDragging?: (sys: System | undefined) => void;
  handleUndoSystem?: (system: System) => void;
  refreshData: () => void;
  'data-pwid'?: string;
}

export default function DraggableSystem({
  system,
  list,
  systemClasses,
  showRemove,
  isAlreadyDragging,
  handleDragging,
  draggingFromAllocated,
  handleUndoSystem,
  parentDistributors,
  refreshData,
  savingSystems,
  setCurrentDragging,
  'data-pwid': dataPwid = 'draggable-system',
}: SystemProps): JSX.Element {
  const [isSaving, setIsSaving] = useState<boolean>(savingSystems.has(system.sysId));
  const [{ isDragging }, drag] = useDrag(() => {
    return {
      type: 'System',
      item: { system, list },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    };
  });

  useEffect(() => {
    setIsSaving(savingSystems.has(system.sysId));
  }, [savingSystems, system, setIsSaving]);

  useEffect(() => {
    if (draggingFromAllocated && isDragging) {
      draggingFromAllocated(list === 'allocated');
    }
    handleDragging(isDragging);
  }, [handleDragging, isDragging, draggingFromAllocated, list]);

  useEffect(() => {
    if (setCurrentDragging) {
      if (isDragging) {
        setCurrentDragging(system);
      } else {
        setCurrentDragging(undefined);
      }
    }
  }, [isDragging, system, setCurrentDragging]);
  // Fixes bug that updates dragging to false on init when dragging to site or other org page
  useEffect(() => {
    if (isAlreadyDragging !== undefined && isAlreadyDragging && !isSaving) {
      handleDragging(isAlreadyDragging);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={drag}
      className={`flex flex-1 flex-row space-between items-center hover:bg-gray-100 ${systemClasses} ${
        isSaving ? 'opacity-50' : ''
      }`}
    >
      <SystemListItem
        system={system}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        showRemove={showRemove}
        refreshData={refreshData}
        handleUndoSystem={handleUndoSystem}
        parentDistributors={parentDistributors}
        data-pwid={dataPwid}
      />
    </div>
  );
}
