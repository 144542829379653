import { LocationMarkerIcon, OfficeBuildingIcon, TruckIcon } from '@heroicons/react/solid';
import { DateTime } from 'luxon';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Api from '../../adapters/api';
import { IndicatorTier, Link, MenuItem, Text, TextType } from '../../ComponentLibrary/src';
import { screenSizeMediaQuery } from '../../ComponentLibrary/src/util/constants';
import { CapSystem, GenSystem, System } from '../../types';
import { formatValue } from '../../util';
import { getDisplayTime } from '../../WebUtils';
import SubscriptionExpirationIndicator from '../System/ExpirationIndicator/SubscriptionExpirationIndicator';
import StatusIndicator from './StatusIndicator';
import { SystemItemMetric } from './SystemItemMetric';

interface SystemItemProps {
  system?: System;
  lastSeenTs?: string;
  latestTs?: string;
  userLoadP?: number;
  totalAirCfm?: number;
  lastHrFlow?: number;
  latestPressure?: number;
  thumbnail?: string;
  onUpdateFilter?: (key: string, value: MenuItem) => void;
  'data-pwid'?: string;
}

/**
 * Primary UI component for user interaction
 */
export const SystemItem = ({
  system,
  lastSeenTs,
  latestTs,
  userLoadP,
  totalAirCfm,
  lastHrFlow,
  latestPressure,
  thumbnail,
  onUpdateFilter,
  'data-pwid': dataPwId = 'system-item',
}: SystemItemProps): JSX.Element => {
  const isHd = useMediaQuery(screenSizeMediaQuery.hd);
  const isSmallScreen = useMediaQuery(screenSizeMediaQuery.small);
  // Displays absolute time if the api is down
  const lastSeen =
    lastSeenTs && getDisplayTime(lastSeenTs as string, Api.isApiDown() ? undefined : Api.getServerTime());

  const upToDate =
    DateTime.fromISO(latestTs ?? '').isValid &&
    Api.getServerTime() &&
    Api.getServerTime()
      .diff(DateTime.fromISO(latestTs ?? ''), 'minutes')
      .as('minutes') < parseInt(process.env.REACT_APP_DATA_STALE_MIN ?? '20');

  return (
    <Link className="system-item cursor-pointer" href={`/systems/${system?.sysId}`} data-pwid={dataPwId}>
      <div className="mx-2">
        <StatusIndicator system={system} tier={IndicatorTier.one} />
      </div>
      <div className="flex-1 h-[30px] min-w-[50px] max-w-[50px] justify-center flex">
        {thumbnail && <img src={thumbnail} className="h-full" />}
      </div>
      <div className="flex-1 min-w-[110px]">
        <Text>
          <strong>{system?.sysId}</strong>
        </Text>
        <Text type={TextType.custom} className={`text-base text-blue-500`} wrap>
          {(system as GenSystem | CapSystem)?.model}
        </Text>
      </div>

      {!isSmallScreen && (
        <div className="flex-2 flex flex-col text-blue-800 justify-center max-w-full overflow-hidden flex-wrap">
          <div
            className="flex flex-row gap-1 items-center justify-start overflow-hidden w-fit max-w-full hover:text-blue-400 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              if (onUpdateFilter)
                onUpdateFilter('org._id', {
                  id: system?.org?._id ?? '',
                  label: system?.org?.name ?? '',
                });
            }}
          >
            {system?.org?.name && (
              <>
                <OfficeBuildingIcon className="min-w-[1.25rem] w-5 h-5 text-blue-500" />
                <Text className="whitespace-nowrap overflow-hidden" wrap overrideColor>
                  {system?.org?.name}
                </Text>
              </>
            )}
          </div>
          <div
            className="flex flex-row gap-1 items-center justify-start overflow-hidden w-fit hover:text-blue-400 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              if (onUpdateFilter)
                onUpdateFilter('site._id', {
                  id: system?.site?._id ?? '',
                  label: system?.site?.name ?? '',
                });
            }}
          >
            {system?.site?.name && (
              <>
                <LocationMarkerIcon className="min-w-[1.25rem] w-5 h-5 text-blue-500" />

                <Text className="whitespace-nowrap overflow-hidden" overrideColor wrap>
                  {system?.site?.name}
                </Text>
              </>
            )}
          </div>
        </div>
      )}

      {!isHd && (
        <div className="flex-1 flex flex-col text-blue-800 justify-center max-w-full overflow-hidden flex-wrap">
          {system?.distributor?.name && (
            <div
              className="flex flex-row gap-1 items-center justify-start overflow-hidden w-fit hover:text-blue-400 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                if (onUpdateFilter)
                  onUpdateFilter('distributor._id', {
                    id: system?.distributor?._id ?? '',
                    label: system?.distributor?.name ?? '',
                  });
              }}
            >
              <TruckIcon className="min-w-[1.25rem] w-5 h-5 text-blue-500" />

              <Text className="whitespace-nowrap overflow-hidden" overrideColor wrap overflow="ellipsis">
                {system?.distributor?.name}
              </Text>
            </div>
          )}
        </div>
      )}

      {system?.productFamily === 'PowerGen' && (
        <>
          <div className="flex-1"></div>
          {(system as GenSystem)?.stats?.systemHours ? (
            <SystemItemMetric
              upToDate={upToDate}
              title="Runtime"
              value={(system as GenSystem)?.stats?.systemHours}
              units="hrs"
              className="flex-1 flex"
            />
          ) : (
            <div className="flex-1"></div>
          )}
          {typeof userLoadP === 'number' ? (
            <SystemItemMetric
              upToDate={upToDate}
              className="flex-1"
              title="Load"
              value={formatValue(userLoadP / 1000)}
              units="kW"
            />
          ) : (
            <div className="flex-1"></div>
          )}
        </>
      )}

      {system?.productFamily === 'CAP' && (
        <>
          {typeof totalAirCfm === 'number' ? (
            <SystemItemMetric
              upToDate={upToDate}
              title="Total Air"
              value={totalAirCfm}
              units="scf"
              className="flex-1 min-w-[80px] flex"
            />
          ) : (
            <div className="flex-1"></div>
          )}
          {typeof lastHrFlow === 'number' ? (
            <SystemItemMetric
              upToDate={upToDate}
              title="Flow"
              value={lastHrFlow}
              units="scfm"
              className="flex-1 min-w-[80px]"
            />
          ) : (
            <div className="flex-1"></div>
          )}
          {typeof latestPressure === 'number' ? (
            <SystemItemMetric
              upToDate={upToDate}
              title="Pressure"
              value={formatValue(latestPressure, 1)}
              units="psi"
              className="flex-1 min-w-[80px]"
            />
          ) : (
            <div className="flex-1"></div>
          )}
        </>
      )}

      <SystemItemMetric title="Last Seen" value={lastSeen} className="flex-1 min-w-[110px]" />

      <div className="mx-2 flex items-center gap-2 justify-end">
        <div>
          <SubscriptionExpirationIndicator
            status={system?.subscriptionStatus}
            expirationDate={system?.subscriptionExpirationDate as string}
          />
        </div>
      </div>
    </Link>
  );
};
