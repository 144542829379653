import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Text, TextType } from '../../../ComponentLibrary/src';
import { VersionProps } from '../types';

const Version_1_0_0: React.FunctionComponent<VersionProps> = ({ markAsRead }) => {
  const navigate = useNavigate();
  // Link to release note
  const handleMoreInfo = useCallback(() => {
    markAsRead();
    navigate('/releasenotes/2023-11-16T10:00:00.000Z');
  }, [navigate, markAsRead]);
  // Link to notifications
  const handleNotificationSettings = useCallback(() => {
    markAsRead().then(() => {
      navigate('/notifications');
    });
  }, [navigate, markAsRead]);

  return (
    <div className="flex flex-col">
      <Text type={TextType.h2} center>
        Notifications Redesign
      </Text>
      <Text type={TextType.body} overflow="" wrap>
        New features and improvements have been made to email notifications, with the goal to improve the quality and
        experience. Due to some of the changes, in an effort to not lose important notifications, it&apos;s possible you
        will receive notifications which you weren&apos;t receiving before, so
        <b>
          <a className="text-emerald-500" onClick={handleNotificationSettings}>
            &nbsp;please visit the notifications page&nbsp;
          </a>
        </b>
        to adjust your settings. For more information on how new notification settings works visit the
        <b>
          <a className="text-emerald-500" onClick={handleMoreInfo}>
            &nbsp;release notes.
          </a>
        </b>
      </Text>
      <div className="mt-4">
        <Text className="mb-2" type={TextType.h3}>
          New Features
        </Text>
        <ul className="text-blue-800" style={{ listStyle: 'initial', margin: '1rem' }}>
          <li>Whitelist/Blacklist mode</li>
          <li>Batching notifications to a single email</li>
          <li>Subscribe to systems by organizations and sites</li>
          <li>New email designs</li>
          <li>Daily reports</li>
        </ul>
      </div>
    </div>
  );
};

export default Version_1_0_0;
