export enum GridTableSortDirection {
  up = 'up',
  down = 'down',
}

export interface GridTableColumn {
  id: string | number;
  label: string | number;
  sortDirection?: GridTableSortDirection;
  isContentColumn?: boolean;
  wrap?: boolean;
}

export interface GridTableProps {
  className?: string | string[];
  columns: GridTableColumn[];
  rows: Record<string, unknown>[];
  pageNumber: number;
  countPerPage: number;
  totalCount: number;
  loading?: boolean;
  showHeader?: boolean;
  showRowLines?: boolean;
  highlightRows?: boolean;
  onClickRow?: (rowId: string | number) => void;
  onChangeCountPerPage: (count: number) => void;
  onChangePageNumber: (page: number) => void;
  onChangeColumns: (columns: GridTableColumn[]) => void;
}
