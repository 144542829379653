import { Divider } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { fetchReleaseNote } from '../../adapters/api/releaseNotes';
import { Card, Text, TextType } from '../../ComponentLibrary/src';
import { usePageContext } from '../../components/Page';
import { useMobile, useSetDocumentTitle } from '../../hooks';
import { NoteChange, ReleaseNote } from '../../types';
import Loading from './components/Loading';
import ReleaseNoteChange from './components/ReleaseNoteChange';

export default function ReleaseNotePage(): JSX.Element {
  useSetDocumentTitle('Release Note');
  const { t } = useTranslation('release_notes');
  const isMobile = useMobile();
  const { releaseNoteDate } = useParams();
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [note, setNote] = useState<ReleaseNote | null>(null);

  useEffect(() => {
    setTitle('');
    if (releaseNoteDate) {
      setBreadcrumbs([
        { text: 'Release Notes', href: '/releasenotes' },
        { text: DateTime.fromISO(releaseNoteDate).toFormat('DD') ?? '' },
      ]);
    }
    setScrollable(true);
  }, [releaseNoteDate, setTitle, setBreadcrumbs, setScrollable]);

  useEffect(() => {
    fetchReleaseNote(releaseNoteDate)
      .then((note: ReleaseNote) => {
        setNote(note);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [releaseNoteDate]);

  return (
    <div className="flex justify-center">
      <Card className="note-container w-full xl:w-2/3" noPadding>
        {loading ? (
          <Loading changes={5} />
        ) : (
          note && (
            <>
              <Text textClassName={isMobile ? 'my-4' : 'my-8'} type={TextType.h3} center>
                {t('overview')}
              </Text>
              <Text overflow="" type={TextType.body} wrap>
                {note.overview}
              </Text>
              <Text textClassName={isMobile ? 'my-4' : 'my-8'} type={TextType.h3} center>
                {t('changes')}
              </Text>
              <div className="note-change-container">
                {note.changes.map((change: NoteChange, idx: number) => (
                  <React.Fragment key={change.title}>
                    <ReleaseNoteChange change={change} />
                    {idx + 1 !== note.changes.length && <Divider />}
                  </React.Fragment>
                ))}
              </div>
            </>
          )
        )}
      </Card>
    </div>
  );
}
