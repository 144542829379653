export enum RadioButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface RadioButtonProps {
  id: string;
  label: string;
  value: string;
  name?: string;
  size?: RadioButtonSize;
  disabled?: boolean;
  className?: string;
}
