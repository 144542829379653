import { DateTime } from 'luxon';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, DatePicker, FormContextData, Text, TextType } from '@/ComponentLibrary/src';
import FormContext from '@/ComponentLibrary/src/Form/FormContext';

import { AuthContext } from '../../../context/Auth';
import { PERMISSIONS } from '../../../util/constants';
import { SystemDatesProps } from './types';

export const SystemDates: React.FC<SystemDatesProps> = ({
  isMobile,
  isLoading,
  hasSubscriptionExpirationDate,
  hasWarrantyExpirationDate,
  hasShipDate,
  hasCommissionDate,
}) => {
  const formContextData = useContext(FormContext);
  const { hasPermissions } = useContext(AuthContext);
  const { t } = useTranslation(['translation', 'system']);
  const { update, updateShipDate, updateProductAccess } = PERMISSIONS.dashboard.systems;
  const canUpdateShipDate =
    hasWarrantyExpirationDate || hasSubscriptionExpirationDate
      ? hasPermissions([updateShipDate, updateProductAccess])
      : hasPermissions(updateShipDate);

  const canUpdateCommissionDate =
    hasWarrantyExpirationDate || hasSubscriptionExpirationDate
      ? hasPermissions([update, updateProductAccess]) && !!formContextData.getFromFormState<Date>('shipDate')?.value
      : hasPermissions(update) && !!formContextData.getFromFormState<Date>('shipDate')?.value;

  const shipDateValidator = useCallback(
    async (formContext?: FormContextData, value?: Date) => {
      const commissionDate = formContext?.getFromFormState<Date>('commissionDate');

      if (
        value &&
        commissionDate?.value &&
        DateTime.fromJSDate(value).startOf('day') > DateTime.fromJSDate(commissionDate.value).startOf('day')
      ) {
        return t('system:ship_commission_date_invalid');
      }

      if (commissionDate?.isInvalid && formContext) {
        formContext['getFromFormState'] = function <P>() {
          return value as P;
        };
        await commissionDate.validate?.(commissionDate.value, formContext);
      }

      return undefined;
    },
    [t],
  );

  const commissionDateValidator = useCallback(
    async (formContext?: FormContextData, value?: Date) => {
      const shipDate = formContext?.getFromFormState<Date>('shipDate');

      if (
        value &&
        shipDate?.value &&
        DateTime.fromJSDate(value).startOf('day') < DateTime.fromJSDate(shipDate.value).startOf('day')
      ) {
        return t('system:commission_ship_date_invalid');
      }

      if (shipDate?.isInvalid && formContext) {
        formContext['getFromFormState'] = function <P>() {
          return value as P;
        };
        await shipDate.validate?.(shipDate.value, formContext);
      }

      return undefined;
    },
    [t],
  );

  return (
    <Card>
      <div className="flex flex-col w-full gap-2">
        <Text type={TextType.h3}>{t('Dates')}</Text>
        <DatePicker
          id="shipDate"
          className={`${isMobile ? 'w-full' : 'flex-1'}`}
          label={t('system:ship_date')}
          tooltip={t('system:ship_date_help_text')}
          loading={isLoading}
          disabled={!canUpdateShipDate}
          data-pwid="ship-date-picker"
          data-testid="ship-date-picker"
          validator={shipDateValidator}
          showOptional={false}
          required={!!formContextData.getFromFormState<Date>('commissionDate')?.value || hasShipDate}
          maxDate={new Date(Date.now() + 60 * 60 * 24 * 1000)}
        />
        <DatePicker
          id="commissionDate"
          className={`${isMobile ? 'w-full' : 'flex-1'}`}
          label={t('system:commission_date')}
          tooltip={t('system:commission_date_help_text')}
          loading={isLoading}
          disabled={!canUpdateCommissionDate}
          data-pwid="commission-date-picker"
          data-testid="commission-date-picker"
          helpText={canUpdateCommissionDate ? undefined : t('system:ship_date_must_be_selected')}
          validator={commissionDateValidator}
          required={hasCommissionDate}
          showOptional={false}
          maxDate={new Date(Date.now() + 60 * 60 * 24 * 1000)}
        />
        <DatePicker
          id="lastAnnualInspectionDate"
          className={`${isMobile ? 'w-full' : 'flex-1'}`}
          label={t('system:last_inspection_date')}
          tooltip={t('system:last_inspection_date_help_text')}
          loading={isLoading}
          disabled={!hasPermissions(PERMISSIONS.dashboard.systems.update)}
          data-pwid="last-annual-inspection-date-picker"
          data-testid="last-annual-inspection-date-picker"
          showOptional={false}
        />
      </div>
    </Card>
  );
};
