import { Column } from '.';

/**
 * Converts the width of a column to a css width property value. If the width is a number, it will be converted to a percentage string.
 *
 * @param columns Column[]
 * @param col Column
 * @returns css style object
 */
export function getColumnStyle(columns: Column[], col: Column): Record<string, unknown> {
  let width = '';
  if (typeof col.width === 'number') {
    const pct = col.width / columns.reduce((a, c) => a + (typeof c.width === 'number' ? c.width : 0), 0);
    width = `${(pct * 100).toFixed(2)}%`;
  } else if (typeof col.width === 'string') {
    width = col.width;
  }
  return {
    width,
  };
}
