import React, { useContext, useMemo } from 'react';

import FormGroupContext from '../../ComponentLibrary/src/FormGroup/FormGroupContext';
import { appendClassProps } from '../../ComponentLibrary/src/util';
import { RadioButtonProps, RadioButtonSize } from './index.types';

export const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  label,
  disabled,
  value,
  className,
  name,
  children,
  size = RadioButtonSize.MEDIUM,
}): JSX.Element => {
  const { selected, onChange, onBlur, disabled: formDisabled, group } = useContext(FormGroupContext);
  const getColor = useMemo(() => {
    let colors = 'secondary-bg border hover:border-gray-300';

    if (selected === value) {
      colors = 'text-white bg-blue-800 focus-within:border-blue-400 focus-within:border-2';
    }

    if (disabled) colors = `${colors} opacity-50 cursor-default`;

    return colors;
  }, [disabled, selected, value]);

  const sizeClasses = useMemo(() => {
    switch (size) {
      case RadioButtonSize.SMALL:
        return 'min-w-[1.75rem] h-7 text-sm pr-1';
      case RadioButtonSize.LARGE:
        return 'min-w-[2.25rem] h-14 text-2xl pl-3 pr-4';
      default:
        return 'min-w-[2.25rem] h-12 sm:h-9 text-lg sm:text-base pl-3 pr-4';
    }
  }, [size]);

  const classes = `${getColor} whitespace-nowrap rounded-sm flex flex-row items-center gap-2 justify-center cursor-pointer ${sizeClasses} ${appendClassProps(
    className,
  )}`;

  return (
    <label data-testid={`${id}-radio-button`} data-pwid={`${id}-radio-button`} className={classes}>
      <input
        type="radio"
        className="w-[1px] h-[1px] outline-none ml-[-5px]"
        checked={selected === value}
        value={value}
        id={id}
        name={name ?? group}
        onChange={() => onChange(id, value)}
        onBlur={onBlur}
        disabled={disabled ?? formDisabled ?? false}
        data-testid={`${id}-radio-button-input`}
      />
      {label}
      {children}
    </label>
  );
};
