import { ChartDataset, Color, ScriptableContext, TooltipItem } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import colors from '../style/colors';
import { MetricChartProps } from './index.types';
import { getChartOptions, placeholderData, placeholderLabels } from './util';

export const BarChart: React.FC<MetricChartProps> = ({ chartData, lineColor, showPlaceholder }) => {
  const firstDataset: ChartDataset<'bar'> = {
    data: [],
  };
  const data = {
    labels: [''],
    datasets: [firstDataset],
  };
  if (!chartData) {
    if (showPlaceholder) {
      data.labels = placeholderLabels;
      firstDataset.data = placeholderData;
      firstDataset.backgroundColor = (dataset: ScriptableContext<'bar'>) => {
        const ctx = dataset.chart.ctx;

        const bg = ctx.createLinearGradient(0, 0, 0, 100);
        bg.addColorStop(0, 'rgba(209, 213, 226, 0.05)');
        bg.addColorStop(1, 'rgba(209, 213, 226, 0.35)');

        return bg as Color;
      };
    }
  } else {
    const lastIndex = chartData.y.length - 1;
    data.labels = chartData.x;
    firstDataset.data = chartData.y;
    firstDataset.backgroundColor = chartData.y.map((_, index) => {
      return index === lastIndex ? colors.blue['600'] : lineColor;
    });
  }
  // Update options with props
  const options = getChartOptions(showPlaceholder);
  if (options.scales?.x) {
    // Change zone to UTC and add UTC to label
    Object.assign(options.scales.x, {
      time: {
        tooltipFormat: 'DDDD',
      },
      adapters: {
        date: {
          zone: 'UTC',
        },
      },
    });
  }

  if (options.plugins?.tooltip) {
    Object.assign(options.plugins.tooltip, {
      callbacks: {
        title: function (tooltipItem: TooltipItem<'bar'>[]) {
          return `${tooltipItem[0].label} (UTC)`;
        },
      },
    });
  }

  return <Bar data={data} options={options} />;
};
