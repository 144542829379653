import QueryString from 'qs';

import { FilterKey, Sort } from '../../../ComponentLibrary/src';
import { ActionLog, SystemTimelineProps } from '../../../context/Systems';
import { JsonApiQuery, queryToUrl } from '../../../JsonApi/src';
import { Image, OnFailRequest } from '../../../types';
import { Method } from '../../../util/types';
import Api from '..';
import { getApiUrl } from '../auth';

interface ActionLogList {
  actionlogs: Array<ActionLog>;
  count: number;
  pageNumber?: number;
}

export async function fetchSystemActionLogs(
  sysId?: string,
  options?: Record<string, unknown>,
  onFail?: OnFailRequest,
): Promise<ActionLogList> {
  const query: JsonApiQuery = {
    filterKeys: options?.filter ? (options?.filter as FilterKey[]) : ([] as FilterKey[]),
  };
  if (options?.countPerPage && options?.pageNumber) {
    query.page = {
      number: options?.pageNumber as number,
      size: options?.countPerPage as number,
    };
  }

  if (options?.sort) query.sort = options?.sort as Sort;

  const params = queryToUrl(query);

  if (options?.focusedItem) {
    params.focusedItem = options?.focusedItem;
  }

  if (sysId) {
    return Api.request({
      method: Method.get,
      path: `systems/actionlogs/${sysId}`,
      params,
      onFail,
    }).then((results) => {
      return results;
    }) as Promise<ActionLogList>;
  }

  return {
    actionlogs: [],
    count: 0,
  };
}

export async function fetchSystemActionLog(actionLogId: string): Promise<ActionLog | void> {
  return Api.request({
    method: Method.get,
    path: `systems/actionlogs/${actionLogId}`,
  }) as Promise<ActionLog>;
}

export async function postSystemActionLog(
  sysId: string,
  actionDate: string,
  internal?: boolean,
  actionLog?: string,
  images?: Image[],
): Promise<unknown | void> {
  const data: { internal?: boolean; content?: string; images?: Image[]; actionDate: string } = {
    actionDate,
  };

  if (actionLog) data.content = actionLog;
  if (images?.length) data.images = images;
  if (internal !== undefined) data.internal = internal;

  return Api.request({
    method: Method.post,
    path: `systems/actionlogs/${sysId}`,
    data,
  }) as Promise<unknown | void>;
}

export async function patchActionLog(
  actionLogId: string,
  internal?: boolean,
  actionLog?: string,
  images?: Image[],
  actionDate?: string,
): Promise<unknown | void> {
  return Api.request({
    method: Method.patch,
    path: `systems/actionlogs/${actionLogId}`,
    data: {
      content: actionLog,
      images,
      internal,
      actionDate,
    },
  }) as Promise<unknown | void>;
}

export async function deleteSystemActionLog(actionLogId: string): Promise<void> {
  return Api.request({
    method: Method.delete,
    path: `systems/actionlogs/${actionLogId}`,
  }) as Promise<void>;
}

export async function downloadActionLogs({ sysId, filter, sort }: SystemTimelineProps): Promise<void> {
  const query: JsonApiQuery = {
    filterKeys: filter ? filter : [],
  };

  if (sort) query.sort = sort;

  const params = queryToUrl(query);

  const apiUrl = getApiUrl();
  const downloadUrl = `${apiUrl}/systems/actionlogs/system/csv/${sysId}?${QueryString.stringify(params, {
    arrayFormat: 'repeat',
  })}`;
  window.open(downloadUrl, '_blank');
}
