import { DivIcon, divIcon } from 'leaflet';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { DualStatusIndicator, IndicatorTier, SystemDisplayState } from '../../ComponentLibrary/src';
import { getSystemDisplayState } from '../../context/util';
import { System } from '../../types';
import { systemHasWarnings } from './util';

export default function SystemIcon(system: System, demoMode?: boolean): DivIcon {
  const systemDisplayState = getSystemDisplayState(system);
  const online = systemDisplayState !== SystemDisplayState.unknown;
  const hasWarnings = online ? systemHasWarnings(system) : false;

  return divIcon({
    className: `${getSystemDisplayState(system)}${hasWarnings ? '-warning' : ''}`,
    html: ReactDOMServer.renderToString(
      demoMode ? (
        <div className="single-map-marker" data-pwid={system.sysId}></div>
      ) : (
        <div>
          <DualStatusIndicator
            primaryIndicatorState={systemDisplayState}
            secondaryIndicatorState={
              hasWarnings && systemDisplayState !== SystemDisplayState.faulted ? SystemDisplayState.warning : undefined
            }
            tier={IndicatorTier.one}
            online={online}
            data-pwid={system.sysId}
          />
        </div>
      ),
    ),
  });
}
