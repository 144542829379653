import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import get from 'lodash.get';
import React, { ReactNode, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { MenuItem } from '../Filter';
import { Link } from '../Link';
import { Pagination } from '../Pagination';
import { Text } from '../Text';
import { appendClassProps } from '../util';
import { Cell, Column, SortDirection, TableCellProps, TableProps, TableRowProps } from './index.types';
import { getColumnStyle } from './util';

const TableCell: React.FC<TableCellProps> = ({
  children,
  showRowLines = true,
  wrap,
  className,
  href,
}: TableCellProps): JSX.Element => {
  // TODO: Conditionally apply title
  // https://codesandbox.io/s/material-demo-p2omr?file=/demo.js
  let content = children;
  if (href) {
    content = (
      <Link href={href} className="block p-2">
        {children}
      </Link>
    );
  } else {
    content = <div className="block p-2">{children}</div>;
  }

  return (
    <td
      className={`td${showRowLines ? ' border-t border-gray-300' : ''}${href ? ' cursor-pointer' : ''}${
        wrap ? ' wrap' : ''
      }${appendClassProps(className)}`}
      data-tip={typeof children === 'string' && !wrap ? children : undefined}
    >
      {content}
    </td>
  );
};

const TableRow: React.FC<TableRowProps> = ({ showRowLines, highlightRows, row, rowIdx, children }): JSX.Element => {
  return (
    <tr
      className={`${showRowLines ? 'border-b ' : ''} border-gray-300${row.href ? ' cursor-pointer' : ''}${
        highlightRows ? ' highlight-color' : ''
      }  relative`}
      key={row.id ?? rowIdx}
    >
      {children}
    </tr>
  );
};

/**
 - Table component for rendering a page of data
 - Can provide any array of data objects and the table will only render the columns provided in the column prop
 */
export const Table: React.FC<TableProps> = ({
  showHeader = true,
  showRowLines = true,
  highlightRows = true,
  showPagination = true,
  className,
  columns,
  rows,
  pageNumber,
  countPerPage,
  totalCount,
  loading,
  onChangeCountPerPage,
  onChangePageNumber,
  onChangeColumns,
  updateFilter,
}: TableProps) => {
  const handleChangeCountPerPage = (count: string | number) => {
    onChangeCountPerPage(parseInt(count as string));
  };

  const handleChangePageNumber = (page: string) => {
    onChangePageNumber(parseInt(page));
  };

  const handleClickColumn = (id: string | number) => {
    const newColumns = [...columns];
    for (let i = 0; i < newColumns.length; i++) {
      if (newColumns[i].id === id) {
        if (newColumns[i].disableSort) return;
        if (!newColumns[i].sortDirection) {
          newColumns[i].sortDirection = newColumns[i].sortDirOnFirstClick ?? SortDirection.up;
        } else {
          newColumns[i].sortDirection =
            newColumns[i].sortDirection === SortDirection.down ? SortDirection.up : SortDirection.down;
        }
      } else {
        delete newColumns[i].sortDirection;
      }
    }
    onChangeColumns(newColumns);
  };

  const tableBody = useMemo(
    () =>
      rows.map((row, rowIdx) => (
        <TableRow key={row.id} row={row} rowIdx={rowIdx} highlightRows={highlightRows} showRowLines={showRowLines}>
          {columns.map((column: Column, colIdx: number) => {
            const value: Cell = get(row, column.id.toString()) as Cell;
            let content;
            if (typeof value === 'string' || typeof value === 'number') {
              content = (
                <Text key={column.id.toString()} wrap={column.wrap}>
                  {value}
                </Text>
              );
            } else if (React.isValidElement(value)) {
              content = <React.Fragment key={column.id.toString()}>{value}</React.Fragment>;
            } else if ((value as Record<string, unknown>)?.filter) {
              content = (
                <Text
                  key={column.id.toString()}
                  wrap={column.wrap}
                  data-tip={(value as Record<string, unknown>).tooltip as string}
                  className="hover:text-blue-400 cursor-pointer"
                  onClick={(e) => {
                    if (updateFilter)
                      updateFilter((value as Record<string, unknown>).filter as Record<string, MenuItem>);
                    e.preventDefault();
                  }}
                >
                  {(value as Record<string, unknown>).value as ReactNode}
                </Text>
              );
            } else if ((value as Record<string, unknown>)?.tooltip) {
              content = (
                <Text
                  key={column.id.toString()}
                  wrap={column.wrap}
                  overflow={!column.wrap ? 'ellipsis' : undefined}
                  data-tip={(value as Record<string, unknown>).tooltip as string}
                >
                  {(value as Record<string, unknown>).value as ReactNode}
                </Text>
              );
            } else if (
              typeof value === 'object' &&
              Object.keys(value as Record<string, unknown>).includes('value') &&
              !(value as Record<string, unknown>).value
            ) {
              content = (
                <Text key={column.id.toString()} wrap={column.wrap}>
                  {''}
                </Text>
              );
            } else {
              content = value ? (
                <Text key={column.id.toString()} wrap={column.wrap}>
                  {JSON.stringify(value)}
                </Text>
              ) : null;
            }
            return (
              <TableCell
                className={`overflow-hidden ${colIdx === 0 ? 'pl-2' : ''} ${
                  colIdx === columns.length - 1 ? 'pr-2' : ''
                } relative${loading ? ' invisible' : ''}`}
                key={column.id.toString()}
                showRowLines={showRowLines && rowIdx !== 0}
                wrap={column.wrap}
                href={row.href}
              >
                <div className="h-full relative w-full">{content}</div>
              </TableCell>
            );
          })}
          {loading && (
            <td className="absolute top-0 left-0 right-0 bottom-0 z-0 px-4 py-2" colSpan={columns.length}>
              <Skeleton className="h-full !leading-10" style={{ zIndex: 0 }} />
            </td>
          )}
        </TableRow>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, columns, showRowLines, loading, highlightRows],
  );

  const loadingTableBody: JSX.Element[] = [];
  if (loading) {
    for (let i = 0; i < Math.min(countPerPage, 50); i++) {
      loadingTableBody.push(
        <tr className={`border-b border-gray-300`} key={i}>
          <td
            className="py-2 px-4 max-w-xs overflow-hidden whitespace-nowrap overflow-ellipsis"
            colSpan={columns.length}
          >
            <Text textClassName="w-full">
              <Skeleton className="!leading-5" style={{ zIndex: 0 }} />
            </Text>
          </td>
        </tr>,
      );
    }
  }

  return (
    <table
      className={`relative w-full bg-gray-50 table-fixed${appendClassProps(className)}`}
      data-testid="table"
      cellPadding="0"
      cellSpacing="0"
    >
      <colgroup>
        {columns.map((col: Column) => (
          <col key={col.id.toString()} style={getColumnStyle(columns, col)} />
        ))}
      </colgroup>
      {showHeader && (
        <thead className="sticky top-0 z-10">
          <tr className="text-white bg-blue-100">
            {columns.map((column) => (
              <th
                className={`sticky top-0 p-2 text-blue-800 font-normal bg-blue-100 ${
                  column.disableSort ? '' : 'cursor-pointer'
                }`}
                key={column.id.toString()}
                onClick={() => handleClickColumn(column.id.toString())}
              >
                <div className="flex flex-row gap-2">
                  {column.label && (
                    <Text overrideColor wrap>
                      {column.label}
                    </Text>
                  )}
                  {column.sortDirection === SortDirection.up && <ChevronUpIcon className="h-5" />}
                  {column.sortDirection === SortDirection.down && <ChevronDownIcon className="h-5" />}
                  {!column.sortDirection && <ChevronDownIcon className="h-5 opacity-0" />}
                </div>
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>{loading && (!rows || rows.length < 1) ? loadingTableBody : tableBody}</tbody>
      {showPagination && (
        <tfoot>
          <tr className="w-full">
            <td colSpan={columns.length} className="sticky bottom-0 bg-gray-50">
              <Pagination
                className="h-14"
                pageNumber={pageNumber}
                countPerPage={countPerPage}
                totalCount={totalCount}
                onChangeCountPerPage={handleChangeCountPerPage}
                onChangePageNumber={(page: number) => handleChangePageNumber(page.toString())}
                loading={loading}
              />
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export * from './index.types';
