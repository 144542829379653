/* eslint-disable @typescript-eslint/no-explicit-any */
// NOTE: we are restricted to url-friendly characters: ALPHA  DIGIT  "-" / "." / "_" / "~" per [RFC 3986](http://www.ietf.org/rfc/rfc3986.txt)
// NOTE: This conforms to the section on filtering: [JSON:API Filtering](https://jsonapi.org/format/#fetching-filtering)

import cloneDeep from 'lodash.clonedeep'
import { FilterKey, MultiKey } from '../types'

// REFACTORME: export this in some global type file like @types/, maybe @qnergy/types/

interface Page {
  size: number
}

export interface OffsetBasedPagination extends Page {
  number: number
}

export interface CursorBasedPagination extends Page {
  before?: string
  after?: string
}

export interface Sort {
  [key: string]: number
}

export interface JsonApiQuery {
  filterKeys?: FilterKey[]
  page?: OffsetBasedPagination | CursorBasedPagination
  sort?: Sort
  project?: string | string[]
  textSearch?: string
}

export interface JsonApi {
  filter?: Record<string, unknown>
  page?: OffsetBasedPagination | CursorBasedPagination
  sort?: Sort
  project?: string | string[]
  textSearch?: string
}

export enum SortDirection {
  up = 'up',
  down = 'down'
}

export interface Column {
  id: string | number | boolean
  label: string | number
  sortDirection?: SortDirection
}

/* INPUT FORMAT:
===================================

{
  textSearch: 'test',
  filter: {
    sysId: 'PG123',
    lastSeen: {
      $gt: '2021-06-28T18:46:22.147Z'
    },
    fpcFwVer: {
      $in: ['1.9.0', '1.9.1']
    },
    description: {
      $nin: ['test', 'test2'],
      regex: 'test'
    }
  },
  page: {
    number: 2,
    size: 25
  },
  sort: {
    sysId: 1,
    lastSeen: -1
  },
  project: ['sysId', 'lastSeen']
}

OUTPUT FORMAT:
===================================

{
  textSearch: 'test',
  filter[sysId]: 'PG123',
  filter[fpcFwVer_in]: ['1.9.0','1.9.1'],
  filter[lastSeen_gt]: '2021-06-28T18:46:22.147Z',
  filter[sysId_in]: ['PG123','PG124'],
  filter[description_nin]: ['test', 'test2'],
  filter[description_regex]: 'test',
  page[number]: 2,
  page[size]: 25,
  sort: ['sysId, -lastSeen'],
  project: ['sysId', 'lastSeen']
}
*/
export const jsonToUrl = (json: JsonApi): Record<string, unknown> => {
  let final: Record<string, unknown> = {}
  const filters: [string, string | unknown][] | undefined = json.filter
    ? Object.entries(json.filter)
    : undefined
  const pageNumber = (json.page as OffsetBasedPagination)?.number
  const pageSize = json.page?.size
  const pageBefore = (json.page as CursorBasedPagination)?.before
  const pageAfter = (json.page as CursorBasedPagination)?.after
  const sort: [string, number][] | undefined = json.sort
    ? Object.entries(json.sort)
    : undefined
  const projectJson = json.project
  const textSearch = json.textSearch

  if (filters) {
    const filtersParsed = filters.reduce<Record<string, string | unknown>>(
      (filtersParsed, [key, value]) => {
        if (value instanceof Date) {
          filtersParsed[`filter[${key}]`] = value.toISOString()
        } else if (value === null) {
          filtersParsed[`filter[${key}]`] = ''
        } else if (
          typeof value === 'string' ||
          typeof value === 'number' ||
          typeof value === 'boolean'
        ) {
          filtersParsed[`filter[${key}]`] = value
        } else {
          const entries = Object.entries(value as Record<string, unknown>)
          for (const entry of entries) {
            const [op, filter]: [string, string | unknown] = entry
            let filterParsed = filter
            if (filter instanceof Date) {
              filterParsed = filter.toISOString()
            }
            filtersParsed[`filter[${key}_${op.replace('$', '')}]`] =
              filterParsed
          }
        }
        return filtersParsed
      },
      {}
    )
    final = {
      ...final,
      ...filtersParsed
    }
  }

  if (Array.isArray(sort) && sort.length > 0) {
    final.sort = []
    for (const [prop, dir] of sort) {
      // eslint-disable-next-line prettier/prettier
      (final.sort as string[]).push(dir < 0 ? '-' + prop : prop)
    }
  }

  if (projectJson) {
    final.project = projectJson
  }

  if (pageNumber) {
    final[`page[number]`] = pageNumber
  }

  if (pageSize) {
    final[`page[size]`] = pageSize
  }

  if (textSearch) {
    final.textSearch = textSearch
  }

  if (pageBefore) {
    final[`page[before]`] = pageBefore
  }

  if (pageAfter) {
    final[`page[after]`] = pageAfter
  }

  return final
}

/**
 * Converts a list of FilterKey to a JsonApi object which can be converted to URL params
 */
// REFACTORME: update with new filter type definition (selectedValues are always only selected)

export const filterKeysToJson = (filterKeys: FilterKey[]): JsonApi => {
  const filter = cloneDeep(filterKeys)
  const json: any = {}

  const selectedProperties: FilterKey[] = filter.filter((item) => item.selected)

  for (const selectedProperty of selectedProperties) {
    const id = selectedProperty.id?.toString() ?? ''
    if (
      Array.isArray(selectedProperty.selectedValues) ||
      selectedProperty.textSearch
    ) {
      if (selectedProperty.textSearch) {
        if (!json.filter) json.filter = {}
        json.filter[selectedProperty.textSearchId ?? id] = {
          regex: selectedProperty.textSearch
        }
      }

      selectedProperty.selectedValues = selectedProperty.selectedValues?.sort(
        (a, b) => {
          if (b.id !== null && (a.id === null || a.id < b.id)) return -1
          if (a.id !== null && (b.id === null || a.id > b.id)) return 1
          return 0
        }
      )

      // must explicitly set selected to false to be "unselected"
      let selectedValues =
        selectedProperty.selectedValues?.filter(
          (val) => val.selected !== false
        ) ?? []
      let unselectedValues =
        selectedProperty.selectedValues?.filter(
          (val) => val.selected === false
        ) ?? []

      const selectedCount = selectedValues.length
      const totalCount = (selectedProperty as MultiKey).count || 0

      // skip if no selected values
      if (selectedCount < 1 || totalCount === selectedCount) {
        continue
      }

      // remove duplicates
      selectedValues = selectedValues?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      )
      unselectedValues = unselectedValues?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      )

      if (!json.filter) json.filter = {}
      if (!json.filter[id]) json.filter[id] = {}

      if (selectedCount === 1) {
        const matchId = selectedValues[0].id
        if (selectedProperty.exclusionMode) {
          json.filter[id].$ne = [matchId]
        } else {
          json.filter[id] = matchId
        }
      } else if (totalCount && selectedCount > totalCount - selectedCount) {
        // negated majority mode
        const key =
          unselectedValues?.length === 1
            ? selectedProperty.exclusionMode
              ? '$eq'
              : '$ne'
            : selectedProperty.exclusionMode
            ? '$in'
            : '$nin'
        json.filter[id][key] = unselectedValues?.map((value) => value.id)
      } else {
        const key = selectedProperty.exclusionMode ? '$nin' : '$in'
        json.filter[id][key] = selectedValues.map((value) => value.id)
      }
    } else if (selectedProperty.range) {
      if (!json.filter) json.filter = {}
      json.filter[id] = {
        $gte: selectedProperty.range.from,
        $lte: selectedProperty.range.to
      }
    }
  }
  return json
}

export const queryToUrl = ({
  filterKeys,
  textSearch,
  page,
  sort,
  project
}: JsonApiQuery): any => {
  const json = filterKeys ? filterKeysToJson(filterKeys) : {}
  json.page = page
  json.sort = sort
  json.project = project
  json.textSearch = textSearch
  const url = jsonToUrl(json)
  return url
}

export const columnsToSortJson = (columns: Column[]): Sort => {
  const initialJson: Sort = {}
  return columns.reduce((json: Sort, col: Column) => {
    if (col.sortDirection) {
      json[col.id.toString()] = col.sortDirection === SortDirection.up ? 1 : -1
    }
    return json
  }, initialJson)
}
