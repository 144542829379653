import { ClipboardCopyIcon } from '@heroicons/react/solid';
import L from 'leaflet';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, ZoomControl } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Skeleton from 'react-loading-skeleton';

import { gmApiKey } from '../../../adapters/googleMaps';
import { Button, HeroIcons, KeyValue, Variant } from '../../../ComponentLibrary/src';
import colors from '../../../ComponentLibrary/src/style/colors';
import { useMounted } from '../../../hooks';
import { GenSystem, System } from '../../../types';
import { copyTextToClipboard, formatValue, metersToFeet } from '../../../util';
import { addLeafletFilterStyle } from '../../Overview/SystemsInformation';

interface Props {
  system?: System;
  isLoading: boolean;
}

export default function Map({ system, isLoading }: Props): JSX.Element {
  const [showSatellite, setShowSatellite] = useState(false);
  const mounted = useMounted();
  const { t } = useTranslation('system');

  const latLngFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 6,
  });

  const handleToggleControls = useCallback(() => {
    if (showSatellite) {
      addLeafletFilterStyle('');
    }
    setShowSatellite(!showSatellite);
  }, [showSatellite]);

  const [systemDetails, setSystemDetails] = useState<System | undefined>(undefined);

  useEffect(() => {
    if (!isLoading) {
      setSystemDetails(system);
    }
  }, [isLoading, system]);

  const emptyMapMessage = useMemo(() => {
    if (systemDetails) {
      if (!systemDetails.site) return t('no_site');
      if (systemDetails.site?.coords?.latitude === undefined || systemDetails.site?.coords?.latitude === undefined) {
        return `${t('no_site')} ${t('coordinates')}`;
      }
    }
  }, [systemDetails, t]);

  if (isLoading) {
    return (
      <div className="shadow relative z-10" data-pwid="system-map-card" data-testid="system-map-card">
        <Skeleton height={216} />
      </div>
    );
  }

  return (
    <div className="shadow relative z-10" data-pwid="system-map-card" data-testid="system-map-card">
      {!isLoading && emptyMapMessage && (
        <>
          <div className="absolute left-0 right-0 top-0 bottom-0 z-[1200] flex flex-row items-center justify-center text-red-400">
            <p className="bg-white bg-opacity-50 p-4 backdrop-blur-sm rounded-sm text-lg shadow">{emptyMapMessage}</p>
          </div>
          <div className="absolute left-0 right-0 top-0 bottom-0 bg-black bg-opacity-30 z-[1100]" />
        </>
      )}

      <MapContainer
        data-pwid="system-map"
        data-testid="system-map"
        key={`${systemDetails?.site?.coords?.latitude} + ${systemDetails?.site?.coords?.longitude}`}
        style={{ height: 216 }}
        center={new L.LatLng(systemDetails?.site?.coords?.latitude ?? 0, systemDetails?.site?.coords?.longitude ?? 0)}
        zoom={3}
        zoomControl={false}
        minZoom={2}
        maxZoom={20}
        maxBoundsViscosity={1}
        zoomSnap={1}
        attributionControl={false}
      >
        {mounted && showSatellite && (
          <ReactLeafletGoogleLayer
            apiKey={gmApiKey}
            type="hybrid"
            eventHandlers={{
              add: () => {
                addLeafletFilterStyle('saturate(60%) brightness(130%) contrast(60%)');
              },
            }}
            googleMapsLoaderConf={{
              apiKey: gmApiKey,
              libraries: ['places'],
            }}
          />
        )}
        {!showSatellite && (
          <ReactLeafletGoogleLayer
            apiKey={gmApiKey}
            type="roadmap"
            styles={[
              // https://developers.google.com/maps/documentation/javascript/style-reference
              { featureType: 'all', stylers: [{ saturation: -100 }] },
              {
                featureType: 'water',
                stylers: [{ color: colors.blue['800'] }, { saturation: -60 }, { lightness: 70 }],
              },
              { elementType: 'labels', stylers: [{ visibility: 'off' }] },
              { featureType: 'poi', stylers: [{ visibility: 'off' }] },
              { featureType: 'administrative', elementType: 'geometry', stylers: [{ visibility: 'off' }] },
              {
                featureType: 'administrative.country',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'administrative.province',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'administrative.locality',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'administrative.neighborhood',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'administrative.land_parcel',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'administrative.locality',
                elementType: 'labels',
                stylers: [{ visibility: 'on' }],
              },
              { featureType: 'administrative.country', elementType: 'labels', stylers: [{ visibility: 'on' }] },
            ]}
            googleMapsLoaderConf={{
              apiKey: gmApiKey,
              libraries: ['places'],
            }}
          />
        )}
        {systemDetails?.site?.coords?.latitude !== undefined && systemDetails?.site?.coords?.latitude !== undefined && (
          <Marker
            position={
              new L.LatLng(systemDetails?.site?.coords?.latitude ?? 0, systemDetails?.site?.coords?.longitude ?? 0)
            }
          ></Marker>
        )}
        {<ZoomControl position="bottomright" />}
      </MapContainer>
      <div className="absolute top-0 left-0 right-0 z-400 p-2 flex gap-4 justify-between">
        <div
          className="bg-white bg-opacity-50 p-1 backdrop-blur-sm rounded-sm text-lg shadow cursor-pointer"
          onClick={handleToggleControls}
        >
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="32px"
            height="32px"
            viewBox="0 0 48.698 48.698"
            // style="enable-background:new 0 0 48.698 48.698;"
            xmlSpace="preserve"
          >
            <g color="#002255">
              <polygon points="47.784,13.309 24.349,0 0.914,13.309 24.349,26.698" fill="currentColor" />
              <polygon
                points="24.349,29.002 8.548,19.974 0.914,24.309 24.349,37.698 47.784,24.309 40.151,19.974"
                fill="currentColor"
              />
              <polygon
                points="24.349,40.002 8.548,30.974 0.914,35.309 24.349,48.698 47.784,35.309 40.151,30.974"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>

        <Button
          icon={HeroIcons.TruckIcon}
          variant={Variant.secondaryFilled}
          onClick={() => {
            window
              .open(
                `https://google.com/maps/dir/?api=1&destination=${systemDetails?.site?.coords?.latitude},${systemDetails?.site?.coords?.longitude}`,
                '_blank',
              )
              ?.focus();
          }}
        ></Button>
      </div>
      <div className="absolute bottom-0 left-0 z-[1000002] p-2 flex flex-row gap-2 items-center bg-white backdrop-blur-sm bg-opacity-50">
        <div className="flex flex-col">
          <KeyValue
            value={
              systemDetails?.site?.coords?.latitude !== undefined && systemDetails?.site?.coords?.latitude !== null
                ? latLngFormatter.format(systemDetails?.site?.coords?.latitude)
                : '-'
            }
            label="Lat"
          />
          <KeyValue
            value={
              systemDetails?.site?.coords?.longitude !== undefined && systemDetails?.site?.coords?.longitude !== null
                ? latLngFormatter.format(systemDetails?.site?.coords?.longitude)
                : '-'
            }
            label="Lng"
          />
          {(system as GenSystem)?.altitude && (
            <KeyValue
              valueTooltip={`${formatValue((system as GenSystem)?.altitude)} ft`}
              value={
                (system as GenSystem)?.altitude !== undefined && (system as GenSystem)?.altitude !== null
                  ? `${formatValue(metersToFeet((system as GenSystem)?.altitude ?? 0))} m`
                  : '?'
              }
              label="Alt"
            />
          )}
        </div>

        <ClipboardCopyIcon
          className="h-7 w-7 cursor-pointer text-blue-800"
          onClick={() =>
            copyTextToClipboard(`${systemDetails?.site?.coords?.latitude} ${systemDetails?.site?.coords?.longitude}`)
          }
        />
      </div>
    </div>
  );
}
