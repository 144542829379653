import { MouseEventHandler } from 'react';

import { HeroIcons } from '../style/heroicons';

export enum PillVariant {
  primary = 'primary',
  secondary = 'secondary',
  warning = 'warning',
  danger = 'danger',
  highlighted = 'highlighted',
}

export interface PillProps {
  children?: string;
  // removable?: boolean
  className?: string | string[];
  color?: string;
  // onRemove?: () => void
  leftIcon?: HeroIcons;
  rightIcon?: HeroIcons;
  variant?: PillVariant;
  propagateLeftIconClickEvent?: boolean;
  propagateRightIconClickEvent?: boolean;
  tooltip?: string;
  testId?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClickLeftIcon?: () => void;
  onClickRightIcon?: () => void;
  'data-pwid'?: string;
}
