import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  Form,
  FormContextData,
  FormGroup,
  FormSave,
  Input,
  InputType,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
  Radio,
  Select,
  Text,
  TextType,
} from '@/ComponentLibrary/src';

import { RadioButton } from '../../../components/RadioButton';
import { RadioButtonSize } from '../../../components/RadioButton/index.types';
import { Interval } from '../../../context/Systems';
import { DataDialogProps, ExportValues } from './types';
import {
  calculateDateRange,
  DATA_INTERVALS,
  luxonUnits,
  QUICK_RANGE_MINUTES_OPTIONS,
  QUICK_RANGE_OPTIONS,
} from './util';

const dateTimeFormat = "yyyy-MM-dd'T'HH:mm";
const dateFormat = 'yyyy-LL-dd';

export default function DataDialog({
  dataSources,
  selected,
  interval = Interval.hour,
  quickRange = { value: 7, units: 'days' },
  dateRange,
  onClose,
  onExport,
}: DataDialogProps): JSX.Element {
  const [portalTo, setPortalTo] = useState<HTMLElement>();
  const [search, setSearch] = useState<string>('');
  const [all, setAll] = useState<boolean>(false);
  const [selectedInterval, setSelectedInterval] = useState<Interval>(interval);
  const { t } = useTranslation(['data', 'translation']);
  const isHourMinute = selectedInterval === Interval.minute || selectedInterval === Interval.hour;

  const handleExport = async ({ interval, to, from, dataSourcesSelected }: ExportValues): Promise<void> => {
    const fromForm = isHourMinute ? DateTime.fromFormat(from, dateTimeFormat) : DateTime.fromFormat(from, dateFormat);
    const toForm = isHourMinute ? DateTime.fromFormat(to, dateTimeFormat) : DateTime.fromFormat(to, dateFormat);

    onExport(fromForm.toISO(), toForm.toISO(), interval, dataSourcesSelected);
    onClose();
  };

  const formatDateRange = (int: Interval, date: DateTime): string => {
    if (int === Interval.hour || int === Interval.minute) {
      return int === Interval.hour ? date.startOf('hour').toFormat(dateTimeFormat) : date.toFormat(dateTimeFormat);
    }

    return int === Interval.day ? date.startOf('day').toFormat(dateFormat) : date.toFormat(dateFormat);
  };

  const handleChangeDates = (_?: string | number, formContext?: FormContextData) => {
    formContext?.updateFormState('quickRange', '');
  };

  const handleQuickRange = (newValue?: string | string[], formContext?: FormContextData) => {
    if (!newValue || Array.isArray(newValue)) return;
    const units = luxonUnits[newValue.match(/[a-z]/i)?.[0] as keyof typeof luxonUnits];
    const value = parseInt(newValue.match(/[0-9]*/)?.[0] ?? '24');
    const { to, from } = calculateDateRange({ units, value });
    let int: Interval;

    if (units === 'hours') {
      int = Interval.hour;
    } else if (units === 'minutes') {
      int = Interval.minute;
    } else {
      int = Interval.day;
    }

    setSelectedInterval(int);
    formContext?.updateFormState('interval', int);
    formContext?.updateFormState('from', formatDateRange(int, DateTime.fromJSDate(from as Date)));
    formContext?.updateFormState('to', formatDateRange(int, DateTime.fromJSDate(to as Date)));
  };

  const handelInterval = (int?: Interval | Interval[], formContext?: FormContextData) => {
    if (int === selectedInterval) return;
    const stateFrom = formContext?.getFromFormState<string>('from')?.value;
    const stateTo = formContext?.getFromFormState<string>('to')?.value;
    const quickRange = formContext?.getFromFormState<string>('quickRange')?.value;

    if (stateFrom && stateTo) {
      let from;
      let to;
      if (isHourMinute) {
        from = DateTime.fromFormat(stateFrom, dateTimeFormat);
        to = DateTime.fromFormat(stateTo, dateTimeFormat);

        if (int === Interval.hour) {
          // Set to time to start of previous hour
          from = from.minus({ hours: 1 });
          // Confusing if quick range 30 minutes is selected but interval is hour
          if (quickRange === '30m') {
            formContext?.updateFormState('quickRange', '1h');
          }
        }
        if (int === Interval.day) {
          // Confusing if quick range 1 hour or 12 hours is selected but interval is day
          if (quickRange === '1h' || quickRange === '12h') {
            formContext?.updateFormState('quickRange', '24h');
          }
        }
      } else {
        from = DateTime.fromFormat(stateFrom, dateFormat);
        to = DateTime.fromFormat(stateTo, dateFormat);
      }

      formContext?.updateFormState('from', formatDateRange(int as Interval, from));
      formContext?.updateFormState('to', formatDateRange(int as Interval, to));
    }

    setSelectedInterval(int as Interval);
  };

  useEffect(() => {
    if (!portalTo) setPortalTo(document.getElementById('modal-root') ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStep = useMemo(() => {
    if (selectedInterval === Interval.minute) return 60;

    if (selectedInterval === Interval.hour) return 60 * 60;

    return 'any';
  }, [selectedInterval]);

  const initVal = useMemo(() => {
    const { to, from } = calculateDateRange(quickRange, dateRange);

    return {
      quickRange: `${quickRange.value}${quickRange.units.charAt(0)}`,
      from: formatDateRange(interval, DateTime.fromJSDate(from as Date)),
      to: formatDateRange(interval, DateTime.fromJSDate(to as Date)),
      interval: interval,
      dataSourcesSelected: selected,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal modalClassName="min-w-[98%] lg:min-w-[75%] 2xl:min-w-[60%] z-901 drop-shadow-xl" onClose={onClose}>
      <Form<ExportValues>
        initialValues={initVal}
        onSubmit={handleExport}
        onCancel={onClose}
        className="h-full flex flex-col overflow-hidden"
      >
        <ModalTitle>
          <Text type={TextType.h4} data-testid="data-modal-title" data-pwid="data-modal-title">
            {t('modal_title')}
          </Text>
        </ModalTitle>
        <ModalContent>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-4">
              <FormGroup<string> id="quickRange" label="" onChange={handleQuickRange}>
                <div className="flex flex-row flex-wrap gap-3">
                  {[...QUICK_RANGE_MINUTES_OPTIONS, ...QUICK_RANGE_OPTIONS].map((opt, idx) => {
                    return <RadioButton id={opt} label={opt} value={opt} key={idx} size={RadioButtonSize.SMALL} />;
                  })}
                </div>
              </FormGroup>
            </div>
            <Select<Interval>
              id="interval"
              label={t('interval')}
              className="w-64"
              options={[...DATA_INTERVALS]}
              onChangeValue={handelInterval}
              data-pwid="data-modal-interval-select"
              portalTo={portalTo}
              clearable={false}
              required
            />

            <div className="flex flex-row gap-2 items-center">
              <Input
                type={isHourMinute ? InputType.datetimeLocal : InputType.date}
                label={t('From')}
                id="from"
                data-testid="from"
                onChange={handleChangeDates}
                step={getStep}
                required
              />
              <Input
                type={isHourMinute ? InputType.datetimeLocal : InputType.date}
                label={t('To')}
                id="to"
                data-testid="to"
                onChange={handleChangeDates}
                step={getStep}
                required
              />
              <Text>({Intl.DateTimeFormat().resolvedOptions().timeZone})</Text>
            </div>
            <FormGroup<boolean>
              id="allDataSources"
              label={t('data_fields')}
              selected={all}
              onChange={(value) => setAll(value as boolean)}
              skipRegister
              required
            >
              <Radio<boolean> id="all" value={true} label={t('All')} />
              <Radio<boolean> id="custom" value={false} label={t('Custom')} />
            </FormGroup>
            {!all && (
              <>
                <Input
                  id="filter"
                  label={t('data_field')}
                  className="w-64"
                  placeholder="Filter"
                  value={search}
                  onChangeValue={(text) => setSearch(text as string)}
                  clearable
                />
                <FormGroup id="dataSourcesSelected" label="" min={1} required>
                  {dataSources
                    .filter(({ label }) => {
                      return (label?.toString() ?? '').toLowerCase().includes(search.toLowerCase());
                    })
                    .map(({ label, id }) => {
                      return <Checkbox id={`${id}`} key={`${id}`} label={`${label}`} />;
                    })}
                </FormGroup>
              </>
            )}
          </div>
        </ModalContent>
        <ModalActions>
          <FormSave saveLabel={t('Export')} submitBtnDisable={false} />
        </ModalActions>
      </Form>
    </Modal>
  );
}
