export interface StatusIndicatorProps {
  state: SystemDisplayState;
  tier?: IndicatorTier;
  isSecondary?: boolean;
  online?: boolean;
  style?: React.CSSProperties;
  sizeClassOverride?: string;
  className?: string | string[];
  title?: string;
  clusterCount?: number;
  tooltip?: string;
}

export enum SystemDisplayState {
  faulted = 'Faulted',
  warning = 'Warning',
  unknown = 'Unknown',
  disabled = 'Disabled',
  standby = 'Standby',
  enabled = 'Enabled',
}

export enum IndicatorTier {
  one = 1,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
}

export interface IndicatorClasses {
  sizeClass: string;
}
