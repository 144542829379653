import { ReactNode } from 'react';

import { MenuItem } from '../Filter';

export enum SortDirection {
  up = 'up',
  down = 'down',
}

export interface Column {
  id: string | number | boolean;
  label: string | number;
  sortDirection?: SortDirection;
  isContentColumn?: boolean;
  wrap?: boolean;
  width?: number | string;
  sortDirOnFirstClick?: SortDirection;
  disableSort?: boolean;
}

export type Cell = string | number | Record<string, Record<string, string> | string | number | undefined> | ReactNode;

export interface TableCellProps {
  children: ReactNode;
  showRowLines?: boolean;
  wrap?: boolean;
  className?: string | string[];
  href?: string;
}

export type Row = {
  id: string | number;
  href?: string;
  item?: unknown;
} & Record<string, Cell>;

export interface TableRowProps {
  children: ReactNode;
  row: Row;
  rowIdx: number;
  showRowLines?: boolean;
  highlightRows?: boolean;
}

export interface TableProps {
  className?: string | string[];
  columns: Column[];
  rows: Row[];
  pageNumber: number;
  countPerPage: number;
  totalCount: number;
  loading?: boolean;
  showHeader?: boolean;
  showRowLines?: boolean;
  highlightRows?: boolean;
  onClickRow?: (rowId: string | number) => void;
  onChangeCountPerPage: (count: number) => void;
  onChangePageNumber: (page: number) => void;
  onChangeColumns: (columns: Column[]) => void;
  updateFilter?: (filter: Record<string, MenuItem>) => void;
  showPagination?: boolean;
}
