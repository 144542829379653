import React from 'react';

import { Border, Card, KeyValue, Text, TextType } from '../../ComponentLibrary/src';
import { CurrentSystemData } from '../../context/Systems';
import { hasCap, isCap } from '../../pages/System/util';
import { GenSystem } from '../../types';
import { formatValue } from '../../util';

interface Props {
  isLoading: boolean;
  system?: GenSystem;
  currentSystemData?: CurrentSystemData;
}

export default function CapData({ isLoading, system, currentSystemData }: Props): JSX.Element {
  if (!hasCap(system) || !system?.capSN || isCap(system)) return <></>;

  return (
    <Card className="flex flex-row gap-2">
      <div className="flex flex-col w-full gap-2">
        <Text type={TextType.h3}>Air Data</Text>
        <div className="flex flex-row flex-wrap gap-y-2 gap-x-8">
          <div className="flex flex-1 flex-col gap-2 w-full">
            {system?.capSN !== undefined && (
              <KeyValue
                label="Skid Serial Number"
                value={system?.capSN ? `C${system?.capSN}` : ''}
                loading={isLoading}
                border={Border.none}
              />
            )}
            {currentSystemData?.tCap1 !== undefined && (
              <KeyValue
                label="Comp A Temperature (℃)"
                value={formatValue(currentSystemData?.tCap1)}
                loading={isLoading}
                border={Border.none}
              />
            )}
            {system?.stats?.capHoursA !== undefined && (
              <KeyValue
                label="Comp A Hours"
                value={formatValue(system?.stats?.capHoursA ?? currentSystemData?.runtimeA)}
                loading={isLoading}
                border={Border.none}
              />
            )}
          </div>
          <div className="flex flex-1 flex-col gap-2 w-full">
            {currentSystemData?.tCap3 !== undefined && (
              <KeyValue
                label="Building Temperature (℃)"
                value={formatValue(currentSystemData?.tCap3)}
                loading={isLoading}
                border={Border.none}
              />
            )}
            {currentSystemData?.tCap2 !== undefined && (
              <KeyValue
                label="Comp B Temperature (℃)"
                value={formatValue(currentSystemData?.tCap2)}
                loading={isLoading}
                border={Border.none}
              />
            )}
            {system?.stats?.capHoursB !== undefined && (
              <KeyValue
                label="Comp B Hours"
                value={formatValue(system?.stats?.capHoursB ?? currentSystemData?.runtimeB)}
                loading={isLoading}
                border={Border.none}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
