import { FilterKey, MenuItem, SystemDisplayState } from '../ComponentLibrary/src';
import { State, System } from '../types';

//REFACTORME: This is a bit of a hack to get the system display state to work with the filter key. This should be stored this way in the db.

interface StateParams {
  'filter[stats.state]'?: string;
  'filter[stats.state_ne]'?: string;
  'filter[stats.state_in]'?: string[];
  'filter[stats.state_nin]'?: string[];
  'filter[stats.state_gte]'?: string;
  'filter[stats.state_lt]'?: string;
  activeWarnings?: boolean;
}

type SingleStateFilterKeys =
  | 'filter[stats.state]'
  | 'filter[stats.state_ne]'
  | 'filter[stats.state_gte]'
  | 'filter[stats.state_lt]';
type MultiStateFilterKeys = 'filter[stats.state_in]' | 'filter[stats.state_nin]';

export function getSystemDisplayState(system?: System): SystemDisplayState {
  const systemState = system?.stats?.state;
  if (systemState === State.faulted) return SystemDisplayState.faulted;
  if (
    systemState === State.disabled ||
    systemState === State.fillSeqAborted ||
    systemState === State.fillSeqInProcess ||
    systemState === State.fillSeqDone ||
    systemState === State.deactivating ||
    systemState === State.unknownSetup
  )
    return SystemDisplayState.disabled;
  if (systemState === State.standby || systemState === State.goToStandby) return SystemDisplayState.standby;
  if (systemState === State.enabled || systemState === State.activating) return SystemDisplayState.enabled;
  return SystemDisplayState.unknown;
}

/**
 * Converts a filter based on system state to a JSON:API url parameter
 * Because some displayed states are mapped to multiple FPC state values
 * @param filter
 * @returns
 */
export function extractFilterStateParam(filter: FilterKey[]): StateParams | undefined {
  let params: StateParams | undefined = undefined;
  const stateFilterIndex = filter.findIndex((item) => item.id === 'stats.state' && item.selected === true);

  if (filter[stateFilterIndex]?.selectedValues) {
    const statsStateItemsSelected =
      filter[stateFilterIndex]?.selectedValues?.filter((val: MenuItem) => val.selected)?.length ?? 0;
    for (const item of filter[stateFilterIndex].selectedValues ?? []) {
      if (item.selected) {
        if (!params) params = {};

        let op: '_in' | '_nin' | '_ne' | '_gte' | '_lt' | '' = '_in';

        if (params) {
          switch (item.id) {
            case SystemDisplayState.disabled:
              op = filter[stateFilterIndex].exclusionMode ? '_nin' : '_in';
              params[`filter[stats.state${op}]` as MultiStateFilterKeys] = (
                params[`filter[stats.state${op}]` as MultiStateFilterKeys] ?? []
              )
                .concat([
                  State.disabled,
                  State.fillSeqAborted,
                  State.fillSeqDone,
                  State.fillSeqInProcess,
                  State.deactivating,
                  State.unknownSetup,
                ])
                .sort();
              break;
            case SystemDisplayState.enabled:
              op = filter[stateFilterIndex].exclusionMode ? '_nin' : '_in';
              params[`filter[stats.state${op}]` as MultiStateFilterKeys] = (
                params[`filter[stats.state${op}]` as MultiStateFilterKeys] ?? []
              )
                .concat([State.enabled, State.activating])
                .sort();
              break;
            case SystemDisplayState.faulted:
              if (statsStateItemsSelected > 1) {
                op = filter[stateFilterIndex].exclusionMode ? '_nin' : '_in';
                params[`filter[stats.state${op}]` as MultiStateFilterKeys] = (
                  params[`filter[stats.state${op}]` as MultiStateFilterKeys] ?? []
                )
                  .concat([State.faulted])
                  .sort();
              } else {
                op = filter[stateFilterIndex].exclusionMode ? '_ne' : '';
                params[`filter[stats.state${op}]` as SingleStateFilterKeys] = State.faulted;
              }
              break;
            case SystemDisplayState.standby:
              op = filter[stateFilterIndex].exclusionMode ? '_nin' : '_in';
              params[`filter[stats.state${op}]` as MultiStateFilterKeys] = (
                params[`filter[stats.state${op}]` as MultiStateFilterKeys] ?? []
              )
                .concat([State.standby, State.goToStandby])
                .sort();
              break;
            case SystemDisplayState.unknown:
              if (statsStateItemsSelected > 1) {
                op = filter[stateFilterIndex].exclusionMode ? '_nin' : '_in';
                params[`filter[stats.state${op}]` as MultiStateFilterKeys] = (
                  params[`filter[stats.state${op}]` as MultiStateFilterKeys] ?? []
                )
                  .concat([State.unknown])
                  .sort();
              } else {
                op = filter[stateFilterIndex].exclusionMode ? '_ne' : '';
                params[`filter[stats.state${op}]` as SingleStateFilterKeys] = State.unknown;
              }
              break;
          }
        }
      }
    }
  }

  return params;
}
