import { JsonApiQuery, queryToUrl, Sort } from '../../JsonApi/src';
import { OnFailRequest, Site } from '../../types';
import { Method } from '../../util/types';
import Api from '.';

export async function fetchSite(siteId: string): Promise<Site | void> {
  return Api.request({ method: Method.get, path: `sites/${siteId}` }) as Promise<Site>;
}

export async function fetchSites(): Promise<Site[] | void> {
  return Api.request({ method: Method.get, path: 'sites' }) as Promise<Site[]>;
}

export async function postSite(site: Omit<Site, '_id'>): Promise<void> {
  return Api.request({
    method: Method.post,
    path: 'sites',
    data: {
      ...site,
      gasComposition: {
        ch4: site.gasComposition.ch4 / 100,
        co2: site.gasComposition.co2 / 100,
        ger: site.gasComposition.ger,
      },
    },
  }) as Promise<void>;
}

export async function patchSite(siteId: string, site: Partial<Site>, onFail?: OnFailRequest): Promise<unknown | void> {
  const data = { ...site };

  if (site.gasComposition) {
    data.gasComposition = {
      ch4: site.gasComposition.ch4 / 100,
      co2: site.gasComposition.co2 / 100,
      ger: site.gasComposition.ger,
      ts: new Date().toISOString(),
    };
  }
  // Convert back to decimal
  return Api.request({
    method: Method.patch,
    path: `sites/${siteId}`,
    data,
    onFail,
  });
}

export async function postDistToSite(siteId: string, distId: string): Promise<void> {
  return Api.request({
    method: Method.post,
    path: `sites/addDist/${siteId}/${distId}`,
  }) as Promise<void>;
}

export async function deleteSiteDist(siteId: string): Promise<void> {
  return Api.request({
    method: Method.delete,
    path: `sites/removeDist/${siteId}`,
  }) as Promise<void>;
}

export async function deleteSite(siteId: string): Promise<void> {
  return Api.request({
    method: Method.delete,
    path: `sites/${siteId}`,
  }) as Promise<void>;
}

interface SearchSitesResponse {
  sites: Site[];
  count: number;
}

export async function fetchSearchSites(options?: {
  sort?: Sort;
  countPerPage?: number;
  pageNumber?: number;
  count?: boolean;
  project?: string[];
  searchTerm?: string;
  orgId?: string;
  distributorId?: string;
  // TODO: Record<string, any> needs to be more specific, whatever an asset type is
}): Promise<SearchSitesResponse | void> {
  const query: JsonApiQuery = options
    ? {
        project: options.project,
        sort: options.sort,
      }
    : {};

  if (options?.countPerPage && options?.pageNumber) {
    query.page = {
      number: options?.pageNumber,
      size: options?.countPerPage,
    };
  }

  const params = query ? queryToUrl(query) : {};

  if (options?.searchTerm) {
    params.textSearch = options?.searchTerm;
  }
  if (options?.orgId) {
    params['filter[orgId]'] = options?.orgId;
  }
  if (options?.distributorId) {
    params['filter[distributorId]'] = options?.distributorId;
  }

  if (options?.count) {
    params.count = options?.count;
  }

  return Api.request({ method: Method.get, path: 'sites', params }).then((response) => {
    return response as SearchSitesResponse;
  });
}
