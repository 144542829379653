import { UserProfile } from '../../types';

export enum NotificationType {
  dailyReport = 'dailyReport',
  weeklyReport = 'weeklyReport',
  connectivity = 'connectivity',
  events = 'events',
  releaseNotes = 'releaseNotes',
}

export interface UnsubscribeTokenOption {
  type: 'event' | 'type' | 'all';
  label?: string;
  value?: string;
}

export interface UnsubscribeOption extends Omit<UnsubscribeTokenOption, 'type'> {
  type: 'org' | 'site' | 'system' | 'type' | 'all';
  label?: string;
  value?: string;
}

export interface UnsubscribeTokenPayload {
  email: string;
  sysId?: string;
  org?: {
    id: string;
    name: string;
  };
  site?: {
    id: string;
    name: string;
  };
  exp: number;
  profile?: Omit<UserProfile, 'lastName'>;
  options: UnsubscribeTokenOption[];
  scope: 'PATCH /users/notifications/unsubscribe';
}
