import React from 'react';

import { Text, TextType } from '../../../ComponentLibrary/src';
import { NoteChange } from '../../../types';
import { IMAGE_FALLBACK } from '../../../util/constants';

interface NoteChangeProps {
  change: NoteChange;
}

const ReleaseNoteChange: React.FunctionComponent<NoteChangeProps> = ({ change }): JSX.Element => {
  return (
    <div className="note-change">
      <Text type={TextType.h4} overflow="" wrap>
        {change.title}
      </Text>
      <Text type={TextType.body} overflow="" wrap>
        {change.notes}
      </Text>
      <div className="image-group">
        {change.images?.map((image) => (
          <img
            key={image}
            alt={`Release Note Image for ${change.title}`}
            src={image}
            onError={({ currentTarget }) => {
              currentTarget.src = IMAGE_FALLBACK;
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ReleaseNoteChange;
