import { DateTime, Duration } from 'luxon';
import { useCallback, useEffect, useRef } from 'react';

const defaultInterval = parseInt(process.env.REACT_APP_POLLING_INTERVAL_MS || '10000');
/**
 * Takes an iso date string and returns a string that either represents the date or a time duration string relative to a server time
 * based on max duration for time duration strings.
 * @param isoTime ISO 8601 time string
 * @param serverTime Unix time in milliseconds
 * @param maxDurationForRelativeTime Luxon Duration object representing the max duration that would result in a relative time string
 * @returns string representing the time or a relative time string
 */
export function getDisplayTime(isoTime: string, serverTime?: DateTime, maxDurationForRelativeTime?: Duration): string {
  const time = DateTime.fromISO(isoTime);
  if (!time.isValid) return 'Invalid date';
  const formattedTime = time.toFormat('M/d/yy h:mm:ss a');
  if (!serverTime) return formattedTime;
  if (!serverTime.isValid) return formattedTime;
  const diff = serverTime.diff(time);

  if (maxDurationForRelativeTime && +diff > +maxDurationForRelativeTime) {
    return formattedTime;
  } else if (diff.as('minutes') <= 5 && diff.as('minutes') >= 0) {
    return 'Just now';
  } else if (diff.as('minutes') >= -5 && diff.as('minutes') < 0) {
    return 'Right now';
  } else {
    return time.toRelative({ base: serverTime }) ?? formattedTime;
  }
}

/**
 * a react hook that allows you to run a function at a specified interval
 * @param callback function to call (if a function returns a promise, it will be awaited)
 * @param effectDeps dependencies that will trigger an update of the callback
 * @param delayMs polling interval in milliseconds
 */
export function usePolling(
  callback: () => void | Promise<void>,
  effectDeps: unknown[] = [],
  delayMs: number = defaultInterval,
): void {
  const poll = useRef<ReturnType<typeof setTimeout> | null>(null);
  const callbackInstance = useRef<() => void | Promise<void>>();

  useEffect(() => {
    startPolling();
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, effectDeps);

  const cancelPoll = useCallback(() => {
    if (poll.current) {
      clearTimeout(poll.current);
      poll.current = null;
    }
  }, []);

  const stopPolling = useCallback(() => {
    cancelPoll();
    callbackInstance.current = undefined;
  }, [cancelPoll]);

  const startPolling = useCallback(async () => {
    const results = callback();
    callbackInstance.current = callback;
    // cancel existing poll if it exists
    cancelPoll();
    // Check if results is promise and wait
    if (results instanceof Promise) await results;
    // don't queue another poll if a different one was created while awaiting the api request
    if (callbackInstance.current && callbackInstance.current === callback)
      poll.current = setTimeout(startPolling, delayMs);
  }, [callback, cancelPoll, delayMs]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      startPolling();
    } else {
      stopPolling();
    }
  }, [startPolling, stopPolling]);
}

/**
 * Function to escape regex-related characters in a string
 * @param str string with regex characters
 * @returns string
 */
export function escapeRegex(str: string): string {
  return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export const displayNumber = ({
  num,
  numberFormatOptions = {
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short',
  },
  units,
}: {
  num?: string | number;
  numberFormatOptions?: Intl.NumberFormatOptions;
  units?: string;
}): { value: string; suffix: string } => {
  if (num === undefined) return { value: '', suffix: '' };
  const display = {
    value: num ? num.toLocaleString() : '?',
    suffix: '',
  };
  if (typeof num !== 'number') num = parseFloat(num);

  const formatter = new Intl.NumberFormat('en-US', numberFormatOptions);
  const parts = formatter.formatToParts(num);

  display.value = parts.reduce((value, part) => {
    if (part.type !== 'compact') {
      return value + part.value;
    }
    return value;
  }, '');

  let suffix = parts.find((part) => part.type === 'compact')?.value ?? '';
  if (units === 'h') {
    display.value = `${display.value}${suffix}`;
  } else {
    // use scientific units
    if (suffix === 'B') suffix = 'G';
    if (suffix === 'K') suffix = 'k';
    display.suffix = suffix;
  }
  return display;
};
