import React, { useEffect } from 'react';

import { Text, TextType } from '../ComponentLibrary/src/Text';
import { usePageContext } from '../components/Page';
import { useSetDocumentTitle } from '../hooks';

export default function Privacy(): JSX.Element {
  const { setTitle, setBreadcrumbs } = usePageContext();
  useSetDocumentTitle('Not Found');

  useEffect(() => {
    setTitle('');
    setBreadcrumbs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full flex items-center justify-center">
      <div className="flex flex-col gap-4 items-center justify-center">
        <Text type={TextType.custom} className="text-9xl">
          404
        </Text>
        <Text type={TextType.h3}>This page could not be found.</Text>
      </div>
    </div>
  );
}
