import React from 'react';

import { Button, Variant } from '../Button';
import { Link } from '../Link';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { useMobile } from '../util/hooks';
import { ButtonTabsProps, Option } from './index.types';

/**
 - Use button as a generic clickable component
 */
const ButtonTabs = ({ className, options }: ButtonTabsProps): JSX.Element => {
  const isMobile = useMobile();

  return isMobile ? (
    <div
      className={`flex justify-around bg-blue-800 text-white p-2 absolute bottom-0 left-0 right-0 z-900${appendClassProps(
        className,
      )}`}
    >
      {options.map((option: Option) => (
        <Link
          key={option.title}
          href={option.href}
          tooltip={option.tooltip}
          disabled={option.disabled}
          color="white"
          data-pwid={option['data-pwid'] || ''}
        >
          <div
            key={option.title}
            className={`flex flex-col items-center cursor-pointer${!option.selected ? ' opacity-40' : ''}`}
            data-tip={option.tooltip}
          >
            {option.mobileIcon}
            <Text type={TextType.custom} className="text-sm" overrideColor>
              {option.title}
            </Text>
          </div>
        </Link>
      ))}
    </div>
  ) : (
    <div className={`flex justify-center items-center${appendClassProps(className)}`}>
      {options.map((option) =>
        option.selected ? (
          <Button
            key={option.title}
            variant={Variant.secondaryFilled}
            tooltip={option.tooltip}
            data-pwid={option['data-pwid'] || ''}
          >
            {option.title}
          </Button>
        ) : (
          <Link
            key={option.title}
            className="px-4"
            href={option.href}
            tooltip={option.tooltip}
            disabled={option.disabled}
            data-pwid={option['data-pwid'] || ''}
          >
            {option.title}
          </Link>
        ),
      )}
    </div>
  );
};

export * from './index.types';
export { ButtonTabs };
