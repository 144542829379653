import React, {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { appendClassProps } from '../util';
import { useMobile, useMounted, useOutsideAlerter } from '../util/hooks';
import { DropdownProps, Position } from './index.types';

// Component for conditionally forwarding ref
const UseOverrideRef = ({
  forwardedRef,
  dropdownRef,
}: {
  forwardedRef: ForwardedRef<HTMLDivElement>;
  dropdownRef: MutableRefObject<HTMLDivElement>;
}) => {
  useImperativeHandle(forwardedRef, () => {
    return {
      ...dropdownRef?.current,
      focus: () => {
        dropdownRef?.current?.focus();
      },
    };
  });
  return null;
};

/**
 - Use text for any text on the screen to conform to typography styles
 */
const Dropdown = (
  {
    fullWidth = false,
    disabled = false,
    show,
    content,
    children,
    className,
    childRef,
    position,
    yOffset,
    testId,
    optionsTestId,
    wrapperClassName,
    onClose,
    onOpen,
    'data-pwid': dataPwId = 'dropdown',
  }: DropdownProps,
  ref: ForwardedRef<HTMLDivElement | HTMLInputElement>,
): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState<boolean | undefined>(show ?? undefined);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const mounted = useMounted();
  const isMobile = useMobile();

  useEffect(() => {
    // only open the first time the menu is rendered (when it mounts)
    if (mounted && show !== undefined) {
      setShowDropdown(show);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (showDropdown === false) {
      if (mounted && onClose) {
        onClose();
      }
    } else if (onOpen && showDropdown === true) {
      onOpen();
    }
    if (isMobile) {
      // adjust the position of the dropdown
      if (dropdownRef?.current) {
        dropdownRef.current.style['left'] = `0px`;
        dropdownRef.current.style['right'] = `0px`;
      }
      return;
    }
    if (showDropdown && childRef?.current && dropdownRef?.current) {
      // determine the size of the dropdown
      const { top: Ym, left: Xm, bottom, right } = dropdownRef.current.getBoundingClientRect();
      // calculate the position in the window, to adjust the direction
      const { top: Yc, left: Xc, bottom: childBottom, right: childRight } = childRef.current.getBoundingClientRect();

      const parentRect = {
        bottom: window.innerHeight,
        right: window.innerWidth,
      };
      const { bottom: parentBottom, right: parentRight } = parentRect ? parentRect : { bottom: null, right: null };

      const Hc = childBottom - Yc;
      const Wc = childRight - Xc;
      const Hm = bottom - Ym;
      const Wm = right - Xm;

      let xPosition = 0;
      let yPosition = 0 + Hc + (yOffset || 0);

      const yOverflow = parentBottom ? Yc + Hm > parentBottom : false;
      const xOverflow = parentRight ? Xc + Wm > parentRight : false;

      // overrides
      if (position === Position.bottomLeft) {
        xPosition = -(Wm - Wc);
      } else if (position === Position.topLeft) {
        xPosition = -(Wm - Wc);
        yPosition = -Hm;
      } else if (position === Position.topRight) {
        yPosition = -Hm;
      } else if (position !== Position.bottomRight) {
        if (xOverflow) xPosition = -(Wm - Wc);
        if (yOverflow) yPosition = -Hm;
      }

      // adjust the position of the dropdown
      if (dropdownRef?.current) {
        dropdownRef.current.style['top'] = `${yPosition}px`;
        dropdownRef.current.style['left'] = `${xPosition}px`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef, showDropdown, position, yOffset, isMobile]);

  const handleClickWrapper = () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  };

  const handleClickDropdownButton = () => {
    if (!disabled) {
      setShowDropdown(!showDropdown);
    }
  };

  useOutsideAlerter(wrapperRef, handleClickWrapper);

  return (
    <div data-testid={testId} data-pwid={testId} className={wrapperClassName}>
      {showDropdown && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 z-[1000002]"
          data-pwid="wrapper"
          onClick={handleClickWrapper}
        ></div>
      )}
      <div data-testid="menu" data-pwid={dataPwId} className={`${appendClassProps(className)}`} ref={wrapperRef}>
        {/* Conditionally override ref */}
        {ref && Object.keys(ref).length > 0 ? (
          <UseOverrideRef forwardedRef={ref} dropdownRef={dropdownRef as MutableRefObject<HTMLDivElement>} />
        ) : null}

        {children && React.cloneElement(children, { onClick: handleClickDropdownButton })}
        <div
          ref={dropdownRef}
          data-testid={showDropdown ? optionsTestId : undefined}
          data-pwid={showDropdown ? optionsTestId : undefined}
          className={`shadow-md bg-gray-50 text-blue-800 flex flex-col gap-2 absolute ${
            fullWidth ? 'w-full' : 'min-w-[14rem]'
          } ${!!showDropdown ? '' : ' hidden'} z-[1000003]`}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

const WrappedDropdown = forwardRef<HTMLDivElement, DropdownProps>(Dropdown);
export * from './index.types';
export { Dropdown as BareDropdown, WrappedDropdown as Dropdown }; // for storybook (fails to properly parse component if wrapped with forwardRef)
