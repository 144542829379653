import semver from 'semver';

import { VersionProps } from '../types';
import Version_1_0_0 from './1.0.0';

const releases = ['1.0.0'];

function getComponent(version: string) {
  switch (version) {
    case '1.0.0':
      return Version_1_0_0;
    default:
      // Return latest
      return Version_1_0_0;
  }
}
export const getUserLatestComponent = (userVersion: string): React.FunctionComponent<VersionProps>[] => {
  // Loop and get all versions from user latest
  return releases.filter((release) => semver.gt(release, userVersion)).map((version) => getComponent(version));
};

export const latest = releases[releases.length - 1];
