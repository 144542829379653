import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  MinusCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid';
import React from 'react';

import i18n from '../../../i18n';
import { ExpirationStatus } from './types';

interface Props {
  status?: string;
  tooltips: {
    active: string;
    expired: string;
    willExpireSoon?: string;
  };
  'data-pwid'?: string;
  isLoading?: boolean;
}

export default function ExpirationIndicator({
  status,
  tooltips,
  'data-pwid': dataPwId = 'expiration-indicator',
  isLoading,
}: Props): JSX.Element {
  const sizeClass = 'h-5 w-5';
  let icon = <></>;
  if (isLoading) return <></>;
  switch (status) {
    case ExpirationStatus.active:
      icon = (
        <CheckCircleIcon
          className={`text-emerald-500 ${sizeClass}`}
          data-tip={tooltips.active}
          data-pwid={`${dataPwId}-active-indicator`}
        />
      );
      break;
    case ExpirationStatus.willExpireSoon:
      icon = (
        <ExclamationCircleIcon
          className={`text-amber-800 ${sizeClass}`}
          data-tip={tooltips.willExpireSoon}
          data-pwid={`${dataPwId}-expire-soon-indicator`}
        />
      );
      break;
    case ExpirationStatus.expired:
      icon = (
        <MinusCircleIcon
          className={`text-red-600 ${sizeClass}`}
          data-tip={tooltips.expired}
          data-pwid={`${dataPwId}-expired-indicator`}
        />
      );
      break;
    default:
      icon = (
        <QuestionMarkCircleIcon
          className={`text-gray-500 ${sizeClass}`}
          data-tip={i18n.t('unknown')}
          data-pwid={`${dataPwId}-unknown-indicator`}
        />
      );
      break;
  }

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      data-pwid={dataPwId}
    >
      {icon}
    </div>
  );
}
