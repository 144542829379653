import React, { useCallback, useEffect, useState } from 'react';

import { Accordion } from '../../../ComponentLibrary/src';
import { Distributor, Site, System } from '../../../types';
import SiteListItem from './SiteListItem';
import StaticSystem from './StaticSystem';

interface StaticSiteProps {
  site: Site;
  hideActions?: boolean;
  bulkMove?: boolean;
  siteClasses?: string;
  parentDistributors?: Distributor[];
  checkedSites?: Site[];
  checkedSystems?: System[];
  error?: string;
  onChecked?: (checked: boolean, system?: System, site?: Site) => void;
  handleUndo?: (site: Site) => void;
  refreshData: () => void;
}

export default function StaticSite({
  site,
  hideActions = false,
  bulkMove,
  siteClasses,
  checkedSites,
  checkedSystems,
  onChecked,
  parentDistributors,
  handleUndo,
  refreshData,
  error,
}: StaticSiteProps): JSX.Element {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const handleOnChangeValue = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
      if (onChecked) onChecked(checked, undefined, site);
    },
    [site, onChecked],
  );

  useEffect(() => {
    setIsChecked(!!checkedSites?.some(({ _id }: Site) => _id === site._id));
  }, [checkedSites, site, setIsChecked]);

  return (
    <Accordion
      headerClassName={` border-b border-gray-300 bg-gray-50 min-h-[2.75rem] highlight-color ${siteClasses}`}
      key={site._id}
      header={
        <SiteListItem
          site={site}
          bulkMove={bulkMove}
          checked={isChecked}
          handleUndoSite={handleUndo}
          onChecked={onChecked ? handleOnChangeValue : undefined}
          textColor="text-blue-800"
          hideActions={hideActions}
          parentDistributors={parentDistributors}
          checkedSystems={checkedSystems}
          refreshData={refreshData}
          error={error}
          data-pwid={site.name}
        />
      }
    >
      <React.Fragment>
        {site.systems?.map((system: System) => {
          system.siteId = site._id;
          return (
            <div
              key={JSON.stringify(system)}
              className="relative flex flex-row gap-2 px-2 ml-10 items-center h-11 hover:bg-gray-100"
            >
              <StaticSystem
                key={JSON.stringify(system)}
                disabled={isChecked}
                showRemove={!hideActions}
                checkedSystems={checkedSystems}
                system={system}
                systemClasses=""
                onChecked={onChecked}
                bulkMove={bulkMove}
                parentDistributors={site.distributor ? [site.distributor] : []}
                refreshData={refreshData}
              />
            </div>
          );
        })}
      </React.Fragment>
    </Accordion>
  );
}
