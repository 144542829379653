import {
  ExternalLinkIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  OfficeBuildingIcon,
  TruckIcon,
} from '@heroicons/react/solid';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { SystemDisplayState, Text, TextType } from '../../ComponentLibrary/src';
import { getSystemDisplayState } from '../../context/util';
import { Fault, System } from '../../types';
import { sortActiveFaults } from '../../util';
import ActiveAlert from '../ActiveAlert';
import { mapStateToColorClass } from './util';

export default function SystemPopup({ system }: { system?: System }): JSX.Element {
  const systemDisplayState = system ? getSystemDisplayState(system) : SystemDisplayState.unknown;

  const numActiveFaultsToShow = 5;

  return (
    <div
      className="flex flex-col gap-4 bg-blue-800"
      style={{
        minWidth: '14rem',
      }}
    >
      <div className="flex flex-row justify-between gap-4 items-center">
        <div className="flex flex-col">
          <Text type={TextType.custom} overrideColor className="text-white font-medium text-base w-full" wrap>
            {system?.sysId ? system.sysId : <Skeleton className="w-12" />}
          </Text>

          <Text type={TextType.custom} overrideColor className="text-white text-sm w-full" wrap>
            {system?.model ? system.model : <Skeleton className="w-24" />}
          </Text>
        </div>

        {systemDisplayState && (
          <div
            className={`${mapStateToColorClass(systemDisplayState).markerBgColorClass} rounded-sm p-0.5 min-h`}
            style={{ height: 'min-content' }}
          >
            <Text type={TextType.custom} overrideColor className="text-white text-md w-full" wrap>
              {systemDisplayState ? systemDisplayState.toUpperCase() : <Skeleton className="w-6" />}
            </Text>
          </div>
        )}
      </div>

      {system?.stats?.activeFaults && (
        <>
          <div className="active-alerts">
            {(system?.stats?.activeFaults as Fault[])
              .sort(sortActiveFaults)
              .slice(0, numActiveFaultsToShow)
              .map((fault: Fault) => (
                <ActiveAlert key={fault._id} fault={fault} filled small />
              ))}
          </div>
          {system?.stats?.activeFaults?.length > numActiveFaultsToShow && (
            <Text type={TextType.custom} overrideColor className="text-gray-400 text-sm">
              And {system?.stats?.activeFaults.length - numActiveFaultsToShow} more...
            </Text>
          )}
        </>
      )}

      <div className="flex flex-col gap-1">
        {system?.systemDescription && (
          <div className="flex flex-row gap-2 items-center">
            <InformationCircleIcon className="text-white h-4 w-4" />
            <Text
              type={TextType.custom}
              overrideColor
              className={`text-white text-base w-full ${
                system?.systemDescription ? '' : 'italic opacity-75 font-thin'
              }`}
              wrap
            >
              {system?.systemDescription}
            </Text>
          </div>
        )}
        {!system ? (
          <span className="text-base">
            <Skeleton className="w-40" />
          </span>
        ) : (
          system?.distributor?.name && (
            <div className="flex flex-row gap-2 items-center">
              <TruckIcon className="text-white h-4 w-4" />
              <Text
                type={TextType.custom}
                overrideColor
                className={`text-white text-base w-full ${
                  system?.distributor?.name ? '' : 'italic opacity-75 font-thin'
                }`}
                wrap
              >
                {system?.distributor?.name}
              </Text>
            </div>
          )
        )}

        {!system ? (
          <span className="text-base">
            <Skeleton className="w-40" />
          </span>
        ) : (
          system?.org?.name && (
            <div className="flex flex-row gap-2 items-center">
              <OfficeBuildingIcon className="text-white h-4 w-4" />
              <Text
                type={TextType.custom}
                overrideColor
                className={`text-white text-base w-full ${system?.org?.name ? '' : 'italic opacity-75 font-thin'}`}
                wrap
              >
                {system?.org?.name}
              </Text>
            </div>
          )
        )}

        {!system ? (
          <span className="text-base">
            <Skeleton className="w-40" />
          </span>
        ) : (
          system?.site?.name && (
            <div className="flex flex-row gap-2 items-center">
              <LocationMarkerIcon className="text-white h-4 w-4" />
              <Text
                type={TextType.custom}
                overrideColor
                className={`text-white text-base w-full ${system?.site?.name ? '' : 'italic opacity-75 font-thin'}`}
                wrap
              >
                {system?.site?.name}
              </Text>
            </div>
          )
        )}
      </div>

      <div className="flex flex-col gap-1">
        {system?.sysId ? (
          <a
            href={`/systems/${system?.sysId}`}
            className="flex flex-row gap-2 items-center cursor-pointer"
            onClick={() =>
              window.history.pushState(
                {},
                `${process.env.REACT_APP_NAME} | ${system?.sysId}`,
                `/systems/${system?.sysId}`,
              )
            }
          >
            <ExternalLinkIcon className="text-white h-4 w-4" />
            <Text type={TextType.custom} overrideColor className="text-white text-bold">
              See System
            </Text>
          </a>
        ) : (
          <Skeleton className="w-12" />
        )}
      </div>
    </div>
  );
}
