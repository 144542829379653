import React, { useMemo } from 'react';

import { useMobile } from '../util/hooks';
import { SideNavProps } from './index.types';
import { SideNavDesktop } from './SideNav.Desktop';
import { SideNavMobile } from './SideNav.Mobile';

export const SideNav: React.FC<SideNavProps> = (props: SideNavProps) => {
  const isMobile = useMobile();

  const { showUsers, showAssets, showDistributors, showFaultSettings } = props;

  const hasAdminPriveleges = useMemo(
    () => !!showUsers || !!showAssets || !!showDistributors || !!showFaultSettings,
    [showAssets, showDistributors, showFaultSettings, showUsers],
  );

  return isMobile ? (
    <SideNavMobile {...props} hasAdminPriveleges={hasAdminPriveleges} />
  ) : (
    <SideNavDesktop {...props} hasAdminPriveleges={hasAdminPriveleges} />
  );
};

export const PlaywrightTestIds = {
  menuIcon: 'side-nav-menu-icon',
  imageLogo: 'side-nav-logo-img',
  chevronLeftIcon: 'side-nav-chevron-left-icon',
  mapIcon: 'side-nav-map-icon',
  systemsTableIcon: 'side-nav-systems-table-icon',
  usersIcon: 'side-nav-users-icon',
  assetsIcon: 'side-nav-assets-icon',
  distributorsIcon: 'side-nav-distributors-icon',
  faultsIcon: 'side-nav-fault-icon',
  phoneIcon: 'side-nav-phone-icon',
  termsIcon: 'side-nav-terms-icon',
  privacyIcon: 'side-nav-privacy-icon',
  releaseNotesIcon: 'side-nav-release-notes-icon',
  avatar: 'side-nav-avatar',
  accountsLink: 'side-nav-accounts-link',
  notificationsLink: 'side-nav-notifications-link',
  logoutLink: 'side-nav-logout-link',
};

export * from './index.types';
