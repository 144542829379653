import React from 'react';

import { Link } from '../Link';
import { Text } from '../Text';
import { TextType } from '../Text/index.types';
import { appendClassProps } from '../util';
import { SectionProps, SubsectionProps } from './index.types';

export const Section: React.FC<SectionProps> = ({ className, children, collapsed, mobile }: SectionProps) => {
  return (
    <div
      className={`text-white flex-row ${mobile ? 'p-1 gap-1 h-8' : 'p-2 gap-2 h-10'} ${
        collapsed ? 'hidden sm:flex' : 'flex'
      }${className ? `${appendClassProps(className)}` : ''}`}
    >
      {children}
    </div>
  );
};

export const Subsection: React.FC<SubsectionProps> = ({
  'data-pwid': dataPwId = 'subsection',
  className,
  selected,
  children,
  mobile,
  href,
  external,
  onClick,
}: SubsectionProps) => {
  let colorClass = 'text-white';
  if (selected) {
    colorClass = 'text-blue-800 bg-white';
  } else {
    colorClass = 'text-white';
  }
  return (
    <Link
      href={href}
      className={`flex flex-row gap-3.5 ${
        mobile ? 'p-1 h-11' : 'p-2 h-10'
      } whitespace-nowrap ${colorClass} w-full cursor-pointer items-center${
        className ? `${appendClassProps(className)}` : ''
      }`}
      onClick={onClick}
      target={external ? '_blank' : undefined}
      rel="noreferrer"
      color={selected ? 'dark-blue' : 'white'}
      data-pwid={dataPwId}
    >
      {children}
    </Link>
  );
};

export const SubsectionTitle = ({
  className,
  children,
}: {
  className?: string | string[];
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Text type={TextType.custom} className={`flex-1 font-light${appendClassProps(className)}`} overrideColor>
      {children}
    </Text>
  );
};

export const SectionTitle = ({
  className,
  children,
}: {
  className?: string | string[];
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Text type={TextType.custom} className={`font-normal${appendClassProps(className)}`} overrideColor={true}>
      {children}
    </Text>
  );
};
