import React from 'react';
import { useTranslation } from 'react-i18next';

import { Border, Card, KeyValue, Text, TextType } from '../../ComponentLibrary/src';
import { CurrentSystemData } from '../../context/Systems';
import { useMobile } from '../../hooks';

interface CustomMonitorProps {
  currentSystemData?: CurrentSystemData;
  isLoading: boolean;
}

interface Monitor {
  label: string;
  value: number | string;
}

const CustomMonitor: React.FunctionComponent<CustomMonitorProps> = ({
  currentSystemData,
  isLoading,
}: CustomMonitorProps) => {
  const { t } = useTranslation('system');
  const isMobile = useMobile();

  if (currentSystemData) {
    const reg = new RegExp(/^mon\d+/);
    const monitors: Monitor[] = Object.keys(currentSystemData)
      .filter((key) => reg.test(key))
      .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
      .map((monitorKey: string): Monitor => {
        return {
          label: t(monitorKey),
          value: currentSystemData[monitorKey as keyof CurrentSystemData] || 0,
        };
      });

    if (isLoading || monitors.length === 0) return <></>;

    return (
      <Card className="flex flex-col gap-2">
        <Text type={TextType.h3}>{t('custom_monitors')}</Text>
        <div className={`grid gap-x-8 gap-y-2 w-full ${isMobile ? 'grid-cols-1' : 'grid-cols-2'}`}>
          {monitors.map(({ label, value }) => (
            <KeyValue key={label} label={label} value={`${value}`} border={Border.none} />
          ))}
        </div>
      </Card>
    );
  }
  return <></>;
};

export default CustomMonitor;
