import '../../style/action-logs.scss';

import { Image, Skeleton, Space, Timeline, TimelineItemProps } from 'antd';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Api from '../../adapters/api';
import { Avatar, Card, Link, Pill, PillVariant, Text, TextType } from '../../ComponentLibrary/src';
import { ActionLog } from '../../context/Systems';
import { useMobile } from '../../hooks';
import { IMAGE_FALLBACK, S3_URL } from '../../util/constants';
import { getDisplayTime } from '../../WebUtils';

interface TimelineProps {
  actionLogs: ActionLog[];
  loading: boolean;
  focusActionLog?: string;
  itemActions: (actionlog: ActionLog) => JSX.Element;
}

const ActionLogTimeLine: React.FunctionComponent<TimelineProps> = ({
  actionLogs,
  itemActions,
  loading,
  focusActionLog,
}: TimelineProps) => {
  const { t } = useTranslation(['translations', 'action_logs']);
  const st = Api.getServerTime();
  const isDown = Api.isApiDown();
  const isMobile = useMobile();

  const getUrl = useCallback((email: string): string => {
    return `${process.env.REACT_APP_SMARTVIEW_ACCOUNTS_URL}/users/${email}`;
  }, []);

  const getInitials = useCallback(({ firstName, lastName, email }: ActionLog): string => {
    const firstChar = firstName?.charAt(0) ?? email?.charAt(0) ?? 'N';
    const secondChar = lastName?.charAt(0) ?? email?.charAt(1) ?? 'A';

    return `${firstChar.toUpperCase()}${secondChar.toUpperCase()}`;
  }, []);

  useEffect(() => {
    if (!loading && focusActionLog) {
      const element = document.querySelector(`div[data-id="${focusActionLog}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [loading, focusActionLog]);

  const getPostAtTime = useCallback(
    (log: ActionLog) => {
      const date = DateTime.fromISO(log.ts);

      if (!date.isValid) return '';

      const dateMed = date.toLocaleString(DateTime.DATETIME_MED);

      if (isDown) return dateMed;

      return `${dateMed} (${getDisplayTime(log.ts, st)})`;
    },
    [st, isDown],
  );

  const getActionDate = useCallback(
    (log: ActionLog) => {
      const date = DateTime.fromISO(log.actionDate, { zone: 'utc' });

      if (!date.isValid) return '';
      if (isDown) return date.toLocaleString(DateTime.DATE_MED);

      const dateMed = date.toLocaleString(DateTime.DATE_MED);
      // Added absolute because diff as days can be negative
      const diff = Math.round(st.setZone('utc', { keepLocalTime: true }).endOf('day').diff(date).as('days'));

      if (diff === 0) {
        return `${dateMed} (${t('Today')})`;
      } else if (diff === 1) {
        return `${dateMed} (Yesterday)`;
      } else {
        return `${dateMed} (${date.toRelative({
          base: st,
          unit: ['years', 'months', 'weeks', 'days'],
        })})`;
      }
    },
    [st, isDown, t],
  );

  const skeletonLoading = useMemo(() => {
    return {
      color: 'gray',
      children: (
        <Card>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Space style={{ marginTop: '1rem' }}>
              <Skeleton.Avatar active={true} size="large" />
              <Skeleton.Button size="large" />
            </Space>
            <Skeleton paragraph={{ rows: 2 }} />
            <Space>
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
            </Space>
          </Space>
        </Card>
      ),
    };
  }, []);

  let items: TimelineItemProps[] = [];

  if (loading) {
    let i = 0;
    while (i < 5) {
      items.push(skeletonLoading);
      i++;
    }
  } else {
    items = actionLogs.map((log: ActionLog) => {
      const content = log.content || '';
      return {
        color: 'green',
        children: (
          <Card
            className={`overflow-hidden ${focusActionLog === log._id ? 'focused' : ''}`}
            data-pwid={`action-log-${log._id}`}
          >
            <div className="flex items-stretch mb-2" data-id={log._id}>
              <div className="flex items-center">
                <Avatar
                  onClick={log.email ? () => window.open(getUrl(log.email), '_blank')?.focus() : undefined}
                  uniqueId={log.email}
                  className="w-10 h-10 mr-2 text-sm"
                  tooltip={`${log.email ? '' : t('action_logs:deactivated_user')}`}
                  initials={getInitials(log)}
                />
              </div>
              <div className="overflow-hidden">
                <Link className="font-semibold" href={getUrl(log.email)} newTab>
                  <Text type={TextType.h6}>
                    {`${log.firstName ?? log.email ?? t('action_logs:inactive_user')} ${log.lastName ?? ''}`}
                  </Text>
                </Link>
                <Text wrap type={TextType.h6} textClassName="text-gray-400">
                  <strong>{t('action_logs:posted_at')}:</strong>&nbsp;
                  {getPostAtTime(log)}
                </Text>
              </div>
              <div className={`flex flex-col flex-1 justify-end ${isMobile ? 'gap-1' : 'gap-2'} items-end`}>
                {log.internal && (
                  <div className="ml-4 flex display-none" data-pwid={`${log._id}-internal-pill`}>
                    <Pill variant={PillVariant.warning}>{t('action_logs:internal')}</Pill>
                  </div>
                )}
                {itemActions(log)}
              </div>
            </div>
            {log.actionDate && (
              <span className="text-blue-800">
                <strong>{t('action_logs:action_date')}:</strong>&nbsp;
                {getActionDate(log)}
              </span>
            )}
            {log.content && (
              <div className="mt-2">
                <Text wrap>{content}</Text>
              </div>
            )}
            {log.images && (
              <div className="mt-6">
                <Image.PreviewGroup>
                  <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {log.images?.map((image, index) => (
                      <Image
                        key={image.fullSize}
                        alt="Uploaded Image"
                        height={100}
                        width={100}
                        fallback={IMAGE_FALLBACK}
                        preview={{ src: `${S3_URL}/${image.fullSize}` }}
                        src={`${S3_URL}/${image.thumbnail}`}
                        data-pwid={`action-log-image-${index}`}
                      />
                    ))}
                  </Space>
                </Image.PreviewGroup>
              </div>
            )}
          </Card>
        ),
      };
    });
  }
  return <Timeline rootClassName="flex-1 !overflow-visible" mode="left" items={items} />;
};

export default ActionLogTimeLine;
