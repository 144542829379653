import React from 'react';

import Api from '../../../adapters/api';
import i18n from '../../../i18n';
import { getDisplayTime } from '../../../WebUtils';
import ExpirationIndicator from './ExpirationIndicator';
import { ExpirationStatus } from './types';

interface Props {
  expirationDate: string;
  isLoading?: boolean;
  status?: ExpirationStatus;
  'data-pwid'?: string;
}

export default function SubscriptionExpirationIndicator({
  expirationDate,
  isLoading,
  status,
  'data-pwid': dataPwId = 'expiration-indicator',
}: Props): JSX.Element {
  const serverTime = Api.getServerTime();
  const relativeTime = getDisplayTime(expirationDate, serverTime);

  return (
    <ExpirationIndicator
      data-pwid={`${dataPwId}-subscription`}
      status={status}
      tooltips={{
        expired: i18n.t('subscription:expired'),
        willExpireSoon: i18n.t('subscription:willExpireSoon', {
          relativeTime,
        }),
        active: i18n.t('subscription:active'),
      }}
      isLoading={isLoading}
    />
  );
}
