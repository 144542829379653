import { SMARTVIEW_API_URL } from '../../util/constants';
import { Method } from '../../util/types';
import Api from './index';

export let token: string | undefined;

export function getApiUrl(): string {
  return process.env.REACT_APP_SMARTVIEW_API_URL || SMARTVIEW_API_URL;
}

export const getAuthorizationCode = (): void => {
  const redirectUrl = window.location.href;
  const apiUrl = getApiUrl();
  window.location.href = `${apiUrl}/login${redirectUrl ? '?redirectUrl=' + redirectUrl : ''}`;
};

export async function logout(): Promise<void> {
  localStorage.clear();
  window.location.href = `${getApiUrl()}/logout`;
}

export async function getToken(): Promise<void> {
  return Api.request({
    method: Method.get,
    path: `token`,
    auth: false,
  }) as Promise<void>;
}
