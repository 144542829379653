module.exports = {
  black: '#000',
  white: '#fff',
  pink: {
    50: '#FEE3EE',
    100: '#FDB8D5',
    200: '#fbcfe8',
    300: '#FE539C',
    400: '#FE1284',
    500: '#FF006B',
    600: '#ED0069',
    700: '#D60064',
    800: '#C10061',
    900: '#9A005B',
  },
  violet: {
    50: '#FAF5FF',
    100: '#F3E8FF',
    200: '#E9D5FF',
    300: '#D8B4FE',
    400: '#BC05FF',
    500: '#AE00FD',
    600: '#9900F8',
    700: '#7A00F3',
    800: '#5A00F0',
    900: '#0000E5',
  },
  indigo: {
    50: '#E9EAF6',
    100: '#C7CAE9',
    200: '#A3A8D9',
    300: '#7F86CA',
    400: '#646BBE',
    500: '#4B50B3',
    600: '#4548A9',
    700: '#3C3E9D',
    800: '#353491',
    900: '#28227C',
  },
  blue: {
    50: '#E5E8EE',
    100: '#CCD3DD',
    200: '#99A7BB',
    300: '#7F90AA',
    400: '#667A99',
    500: '#4D6588',
    600: '#334E77',
    700: '#1A3966',
    800: '#002255',
    // 900: '#0E384E',
  },
  emerald: {
    50: '#F4F9E6',
    // 100: '#E4F1C0',
    // 200: '#D1E797',
    300: '#A3E066',
    400: '#85D633',
    500: '#66CC00',
    // 600: '#8CBE00',
    // 700: '#75AA00',
    // 800: '#5E9700',
    // 900: '#367500',
  },
  amber: {
    50: '#FFFCE5',
    100: '#FFF6BF',
    200: '#FFF193',
    300: '#FFEB67',
    400: '#FEE641',
    500: '#FFE312',
    600: '#FED00D',
    700: '#FCB804',
    800: '#FB9F00',
    900: '#F97300',
  },
  orange: {
    // 50: '#FBF2E1',
    100: '#FFE6CC',
    200: '#FFCD99',
    300: '#FFB566',
    400: '#FF9C33',
    500: '#FF8300',
    // 600: '#DF8C1A',
    // 700: '#D97E15',
    // 800: '#D26F11',
    // 900: '#C8590D',
  },
  red: {
    50: '#FDEBED',
    100: '#FBCDCF',
    200: '#E79B96',
    300: '#DA766E',
    400: '#E25A4A',
    500: '#E44F30',
    600: '#D6452F',
    700: '#C43C2A',
    800: '#B73623',
    900: '#A82E19',
  },
  gray: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    150: '#EEEFF0',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    433: '#8C939F',
    450: '#848B98',
    466: '#7B8290',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
};
