import { createContext, useCallback, useState } from 'react';

import { fetchOrgs, fetchOrgsForDistributor } from '../adapters/api/organizations';
import { JsonApiQuery, queryToUrl, Sort } from '../JsonApi/src';
import { Organization } from '../types';

export interface OrganizationsContextData {
  allOrganizations: Organization[];
  organizations: Organization[];
  allOrganizationsCount: number;
  getAllOrganizations: (params?: {
    sort?: Sort;
    countPerPage?: number;
    pageNumber?: number;
    count?: boolean;
    project?: string[];
    addEmbeds?: boolean;
    searchTerm?: string;
  }) => Promise<void>;
  getOrganizationsForDistributor: (distributorId: string) => Promise<void>;
}

const organizationsContextDefaultValue: OrganizationsContextData = {
  allOrganizations: [],
  organizations: [],
  allOrganizationsCount: 0,
  getAllOrganizations: async () => undefined,
  getOrganizationsForDistributor: async () => undefined,
};

export const useOrganizationsContextValue = (): OrganizationsContextData => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
  const [allOrganizationsCount, setAllOrganizationsCount] = useState(0);

  const getAllOrganizations = useCallback(
    async (args?: {
      sort?: Sort;
      countPerPage?: number;
      pageNumber?: number;
      count?: boolean;
      project?: string[];
      addEmbeds?: boolean;
      searchTerm?: string;
    }) => {
      const { sort, countPerPage, pageNumber, count, project, addEmbeds } = args || {};
      const query: JsonApiQuery = {};
      if (countPerPage && pageNumber) {
        query.page = {
          number: pageNumber,
          size: countPerPage,
        };
      }

      if (sort) query.sort = sort;
      if (project) query.project = project;

      let params = queryToUrl(query);

      if (count) params.count = 1;
      if (addEmbeds) params.addEmbeds = true;

      params = {
        ...params,
        sort: 'name',
      };

      if (args?.searchTerm) {
        params.textSearch = args?.searchTerm;
      }

      return fetchOrgs(params).then((response) => {
        if (!response) return;
        if (count) {
          const { orgs, count } = response as {
            orgs: Organization[];
            count: number;
          };
          setAllOrganizations(orgs);
          setAllOrganizationsCount(count);
        } else {
          setAllOrganizations(response as Organization[]);
        }
      });
    },
    [],
  );

  const getOrganizationsForDistributor = useCallback((distributorId) => {
    return fetchOrgsForDistributor(distributorId).then((response) => {
      if (response) setOrganizations(response as Organization[]);
    });
  }, []);

  return {
    allOrganizations,
    organizations,
    allOrganizationsCount,
    getAllOrganizations,
    getOrganizationsForDistributor,
  };
};

export const OrganizationsContext = createContext<OrganizationsContextData>(organizationsContextDefaultValue);
