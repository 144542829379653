import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, TextType } from '../../../ComponentLibrary/src';
import { appendClassProps } from '../../../util';

export interface SystemItemMetricProps {
  title: string;
  upToDate?: boolean;
  value?: number | string;
  units?: string;
  className?: string | string[];
  'data-testid'?: string;
}

export function SystemItemMetric({
  title,
  value,
  units,
  className,
  upToDate = true,
  'data-testid': dataTestId = 'system-item-metric',
}: SystemItemMetricProps): JSX.Element {
  const { t } = useTranslation('systems');
  const tooltip = t('stale_data_message', { time: process.env.REACT_APP_DATA_STALE_MIN ?? '20' });
  const textColor = upToDate ? 'text-blue-500' : 'text-gray-400';

  return (
    <div
      className={`flex flex-col items-center${appendClassProps(className)}`}
      data-testid={dataTestId}
      data-pwid={dataTestId}
      data-tip={upToDate ? undefined : tooltip}
    >
      <Text
        type={TextType.custom}
        className="text-base"
        textClassName={textColor}
        data-testid={`${dataTestId}-title`}
        data-pwid={`${dataTestId}-title`}
        overrideColor
      >
        {title}
      </Text>
      <div className="flex flex-row gap-2 items-center">
        <Text
          type={TextType.custom}
          className="text-base font-medium"
          textClassName={textColor}
          data-testid={`${dataTestId}-value`}
          data-pwid={`${dataTestId}-value`}
          overrideColor
        >
          {value}
        </Text>
        {units && (
          <Text
            type={TextType.custom}
            className="text-base font-light"
            textClassName={textColor}
            data-testid={`${dataTestId}-units`}
            data-pwid={`${dataTestId}-units`}
            overrideColor
          >
            {units}
          </Text>
        )}
      </div>
    </div>
  );
}
