import React, { useEffect, useState } from 'react';

import { Link } from '../Link';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { useMobile } from '../util/hooks';
import { PageHeaderProps } from './index.types';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  breadcrumbs,
  leftComponent,
  rightComponent,
  bottomBorder,
  children,
  className,
}: PageHeaderProps) => {
  const isMobile = useMobile();
  const [hideBreadcrumbs, setHideBreadcrumbs] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (isMobile) {
        const scrollDistance = document.getElementById('content')?.scrollTop;
        if (scrollDistance && scrollDistance > 20) {
          setHideBreadcrumbs(true);
        } else if (scrollDistance && scrollDistance < 21) {
          setHideBreadcrumbs(false);
        }
      }
    };
    window.addEventListener('scrollPage', handleScroll);

    return () => window.removeEventListener('scrollPage', handleScroll);
  }, [isMobile]);

  // TODO: make the font sizes responsive
  if (!title && !breadcrumbs) {
    return null;
  }

  return (
    <div
      data-testid="header"
      className={`flex flex-col${bottomBorder ? ' border-blue-100 border-b' : ''}${appendClassProps(className)}`}
    >
      {breadcrumbs && (
        <div className="flex items-center justify-between gap-2">
          {!hideBreadcrumbs && (
            <div className={`flex-1 flex items-center gap-2`}>
              {breadcrumbs?.map((breadcrumb, i) => (
                <React.Fragment key={breadcrumb.href || i}>
                  {breadcrumb.href ? (
                    <Link url={breadcrumb.href}>{breadcrumb.text}</Link>
                  ) : (
                    <Text type={TextType.custom} className="text-base text-gray-400" inline>
                      {breadcrumb.text}
                    </Text>
                  )}
                  {i < breadcrumbs.length - 1 && (
                    <Text type={TextType.custom} className="text-base" inline>
                      /
                    </Text>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
          {children && <div className="flex-2">{children}</div>}
          {!isMobile && <div className="flex-1"></div>}
        </div>
      )}
      <div className="flex flex-col">
        <div className="flex flex-row justify-between w-full">
          {/* Left */}
          <span className="flex flex-row gap-2 lg:gap-4 items-center min-w-[25%]">
            {leftComponent}
            {title && (
              <Text
                className={`flex flex-row items-center flex-wrap-reverse font-medium${isMobile ? '' : ' py-1'}`}
                type={TextType.h3}
                wrap
              >
                {title}
              </Text>
            )}
            <Text type={isMobile ? TextType.h5 : TextType.h4} className="font-light" wrap>
              {/* <LocationMarkerIcon className="w-6 h-6" /> */}
              {subtitle}
            </Text>
          </span>
          {/* Right */}
          <div className="flex flex-row gap-4 justify-end items-center min-w-[25%]">{rightComponent}</div>
        </div>
      </div>
    </div>
  );
};

export * from './index.types';
