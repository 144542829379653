import { CubeIcon, LocationMarkerIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import { ExclamationIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { deleteOrgFromDistributor, patchOrgOnDistributor } from '../../../adapters/api/distributors';
import { deleteOrg } from '../../../adapters/api/organizations';
import { Link, Text } from '../../../ComponentLibrary/src';
import { AuthContext } from '../../../context/Auth';
import { Distributor, DragItem, Organization, Site, System } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';
import { debouncedFunction } from '../util';
import Distributors from './Distributors';

interface OrgListItemProps {
  org: Organization;
  updateAllOrganizations: () => Promise<void>;
  onDrop: (item: DragItem, destination: string) => void;
}
export default function OrganizationListItem({ org, onDrop, updateAllOrganizations }: OrgListItemProps): JSX.Element {
  const { t } = useTranslation(['assets', 'translation']);
  const navigate = useNavigate();
  const { hasPermissions } = useContext(AuthContext);
  const [{ isOver }, drop] = useDrop(() => {
    return {
      accept: ['System'],
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
        };
      },
      drop: (item: DragItem) => {
        debouncedOnDragOverOrg.cancel();
        item.org = org;
        onDrop(item, 'org');
      },
    };
  });

  const handleOverOrg = useCallback(() => {
    navigate(`/assets/${org._id}${document.location.search}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateToEdit = useCallback(
    (e: React.MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      e.preventDefault();
      navigate(`/assets/editOrg/${org._id}`);
    },
    [org, navigate],
  );

  const debouncedOnDragOverOrg = useMemo(() => {
    return debouncedFunction<void>(handleOverOrg);
  }, [handleOverOrg]);

  useEffect(() => {
    if (isOver) {
      debouncedOnDragOverOrg();
    } else {
      debouncedOnDragOverOrg.cancel();
    }
  }, [isOver, debouncedOnDragOverOrg]);

  const handleDeleteOrg = useCallback(
    (e: React.MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (confirm(t('confirm_delete_asset', { asset: org.name }))) {
        deleteOrg(org._id).then(updateAllOrganizations);
      }
    },
    [updateAllOrganizations, t, org],
  );

  const handleAddDistributor = useCallback(
    async (distributorId: string | null) => {
      if (!distributorId) return;
      return patchOrgOnDistributor(distributorId, org._id).then(updateAllOrganizations);
    },
    [updateAllOrganizations, org],
  );

  const handleRemoveDistributor = useCallback(
    async ({ _id, name }: Distributor) => {
      if (confirm(t('confirm_remove_distributor', { distributor: name, place: org.name }))) {
        org.distributors = org.distributors?.filter((dist) => dist._id !== _id);
        if (org._id && _id) {
          await deleteOrgFromDistributor(org._id, _id);
          await updateAllOrganizations();
        }
      }
    },
    [updateAllOrganizations, t, org],
  );

  const distributorsSynced = useMemo((): boolean => {
    // detect if any distributors in any of the sites are missing on the org
    let siteDistributors = [];
    let synced = true;
    if (org.sites) {
      siteDistributors = org.sites.reduce((acc: string[], site: Site) => {
        if (site.distributor) {
          acc.push(site.distributor._id);
        }
        return acc;
      }, []);
      synced =
        synced &&
        (siteDistributors.length < 1 ||
          siteDistributors.every((dist: string) => org.distributors?.map((d: Distributor) => d._id).includes(dist)));
    }
    // detect if any distributors in unallocated systems are missing on the org
    let systemDistributors = [];
    if (org.systems) {
      systemDistributors = org.systems.reduce((acc: string[], system: System) => {
        if (system.distributor) {
          acc.push(system.distributor._id);
        }
        return acc;
      }, []);
      synced =
        synced &&
        (systemDistributors.length < 1 ||
          systemDistributors.every((dist: string) => org.distributors?.map((d: Distributor) => d._id).includes(dist)));
    }
    return synced;
  }, [org]);

  return (
    <div ref={drop} className={`border-b border-gray-300 highlight-color ${isOver ? 'bg-gray-200' : 'bg-gray-50'}`}>
      <Link
        href={`/assets/${org._id}${document.location.search}`}
        className="flex flex-row py-3 sm:px-2"
        data-pwid={`${org.name}-link`}
      >
        <div className="flex flex-2 items-center flex-row flex-wrap gap-2">
          <OfficeBuildingIcon className="text-blue-800 w-5 h-5 inline" />
          <Text inline data-pwid={`${org.name}`}>
            {org.name}
          </Text>
          {!distributorsSynced && (
            <div data-tip={t('usynced_org_site_system_distributors')}>
              <ExclamationIcon className="text-amber-700 w-5 h-5" />
            </div>
          )}
          <Distributors
            selectedDistributors={org.distributors}
            parentName={org.name}
            onAdd={handleAddDistributor}
            onRemove={handleRemoveDistributor}
            showAdd={true}
            canEdit={hasPermissions(PERMISSIONS.dashboard.distributors.update)}
            data-pwid={`${org.name}-distributors`}
          />
        </div>
        <div className="max-md:hidden px-4">
          <LocationMarkerIcon className="w-5 h-5 inline" />
          <Text data-tip={t('number_of_sites')} inline>
            {org.sites?.length}
          </Text>
        </div>
        <div className="max-md:hidden px-4">
          <CubeIcon className="w-5 h-5 inline" />
          <Text data-tip={t('number_of_systems')} inline>
            {org.sites?.reduce((acc: number, site: Site) => {
              return acc + (site?.systems?.length ?? 0);
            }, org.systems?.length || 0)}
          </Text>
        </div>
        <div className="flex flex-row items-center gap-2 md:pl-4">
          {hasPermissions(PERMISSIONS.dashboard.orgs.update) && (
            <PencilIcon
              className="w-5 h-5 text-blue-800 cursor-pointer"
              data-tip={t('edit_asset', { asset: org.name })}
              onClick={handleNavigateToEdit}
              data-pwid="edit-org"
            />
          )}
          {hasPermissions(PERMISSIONS.dashboard.orgs.delete) && (
            <TrashIcon
              className="w-5 h-5 text-blue-800 cursor-pointer"
              onClick={handleDeleteOrg}
              data-tip={t('delete_asset', { asset: org.name })}
              data-pwid="delete-org"
            />
          )}
        </div>
      </Link>
    </div>
  );
}
