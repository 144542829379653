import React, { PropsWithChildren } from 'react';

import { AssistiveText } from '../AssistiveText';
import { Label } from '../Label';
import { useFormGroup } from '../util/hooks';
import FormGroupContext from './FormGroupContext';
import { FormGroupProps } from './index.types';

export const FormGroup = function FormGroup<T>({
  validator,
  helpText,
  children,
  label,
  id,
  max,
  min,
  disabled,
  showOptional,
  selected,
  onChange,
  onBlur,
  required = false,
  row = false,
  skipRegister = false,
}: PropsWithChildren<FormGroupProps<T>>): JSX.Element {
  const { formDisabled, error, groupValue, handleOnChange, handleOnBlur, register, unregister } = useFormGroup<T>({
    id,
    max,
    min,
    required,
    onChange,
    onBlur,
    validator,
    selected,
    skipRegister,
  });

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <Label
          htmlFor={id}
          showOptional={showOptional ?? (!required && !disabled)}
          data-pwid={`${id}-form-group-label`}
          data-testid={`${id}-form-group-label`}
        >
          {label}
        </Label>
      )}
      <div
        id={id}
        data-testid={`${id}-form-group-container`}
        data-pwid={`${id}-form-group-container`}
        role="group"
        className={`flex ${row ? 'flex-row flex-wrap' : 'flex-col flex-nowrap'}`}
      >
        <FormGroupContext.Provider
          value={{
            group: id,
            selected: groupValue,
            disabled: disabled ?? formDisabled,
            onChange: (id, value, checked) => handleOnChange(id, value as T, checked),
            onBlur: (e) => handleOnBlur(e, groupValue as T),
            register: (id, value) => register(id, value as T),
            unregister,
          }}
        >
          {children}
        </FormGroupContext.Provider>
      </div>
      <AssistiveText
        id={id}
        helperText={helpText}
        error={error}
        showCharCount={required}
        max={max}
        valueLength={(groupValue as string)?.length}
      />
    </div>
  );
};

export * from './index.types';
