import {
  CheckCircleIcon,
  ExclamationIcon,
  FlagIcon,
  QuestionMarkCircleIcon,
  RefreshIcon,
  SwitchHorizontalIcon,
  TruckIcon,
} from '@heroicons/react/outline';
import React from 'react';

import { ITimelineItem } from '../../context/Systems';
import { Fault, FaultSeverity } from '../../types';
import { TimelineItemType } from './util';

export default function TypeIndicator({ item }: { item: ITimelineItem }): JSX.Element {
  switch (item.evtType as TimelineItemType) {
    case TimelineItemType.fault:
      let content = <ExclamationIcon className="h-6 w-6 text-red-600" />;
      switch ((item.data.fault as Fault)?.severity) {
        case FaultSeverity.info:
        case FaultSeverity.external:
          content = <ExclamationIcon className="h-6 w-6 text-blue-800" />;
          break;
        case FaultSeverity.autoSystemRestart:
          content = <RefreshIcon className="h-6 w-6 text-amber-800" />;
          break;
        case FaultSeverity.warning:
          content = <ExclamationIcon className="h-6 w-6 text-amber-800" />;
          break;
        case FaultSeverity.internalFault:
        case FaultSeverity.serviceRequired:
        case FaultSeverity.userClearableLock:
          break;
      }
      return <div className="flex flex-col items-center justify-center w-6 h-6">{content}</div>;
    case TimelineItemType.faultCleared:
      return (
        <div>
          <CheckCircleIcon className="w-6 h-6 text-emerald-500" />
        </div>
      );
    case TimelineItemType.commissionDate:
      return <FlagIcon className="w-6 h-6 text-blue-800" />;
    case TimelineItemType.shipDate:
      return <TruckIcon className="w-6 h-6 text-blue-800" />;
    case TimelineItemType.stateChange:
      return <SwitchHorizontalIcon className="w-6 h-6 text-blue-800" />;
    default:
      return <QuestionMarkCircleIcon className="w-6 h-6 text-gray-400" />;
  }
}
