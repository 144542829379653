import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';

import colors from '../style/colors';
import { appendClassProps } from '../util';
import { InternalTabProps } from './index.types';
import { tabStyles } from './styles';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const TabDesktop: React.FC<InternalTabProps> = ({
  className,
  tabs,
  value,
  tabContent,
  onChange,
  'data-pwid': dataPwId,
}: InternalTabProps) => {
  return (
    <div className={`bg-white h-max${appendClassProps(className)}`} data-testid="tabs" data-pwid={dataPwId}>
      <Tabs
        value={value}
        onChange={onChange}
        centered
        TabIndicatorProps={{ style: { background: colors.blue['800'] } }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            sx={{
              ...tabStyles,
              color: `${tab.label === value ? colors.blue['800'] : colors.gray['400']} !important`,
            }}
            className="font-base"
            icon={tab.icon}
            iconPosition="start"
            label={tab.label}
            value={tab.label}
            data-pwid={tab.label}
          />
        ))}
      </Tabs>
      {tabContent}
    </div>
  );
};
