import L, { Marker } from 'leaflet';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  AsyncSelect,
  Button,
  Card,
  Form,
  FormContextData,
  FormSave,
  HeroIcons,
  Input,
  ReactSelectOption,
  Text,
  TextType,
  Type,
  Variant,
} from '@/ComponentLibrary/src';

import { fetchSearchDistributors } from '../../adapters/api/distributors';
import { fetchOrg, fetchSearchOrgs } from '../../adapters/api/organizations';
import { patchSite, postSite } from '../../adapters/api/sites';
import { autoCompleteLocation, getPlaceDetails } from '../../adapters/googleMaps';
import { usePageContext } from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import { DistributorsContext, useDistributorsContextValue } from '../../context/Distributors';
import { OrganizationsContext, useOrganizationsContextValue } from '../../context/Organizations';
import { SitesContext, useSitesContextValue } from '../../context/Sites';
import { useMobile } from '../../hooks';
import { Organization, Site as ISite } from '../../types';
import { PERMISSIONS } from '../../util/constants';
import GasComposition from './Components/GasComposition';
import { ClearLatLng, GetGps, Map } from './Components/Map';
import { validateSiteName } from './formValidation';
import { updateAndValidateLatLng } from './util';

interface SiteProps {
  edit?: boolean;
}

interface SiteForm extends Record<string, unknown> {
  siteName: string;
  selectedOrgId: string;
  distributorId?: string;
  ch4: number;
  co2: number;
  ger: number;
  latitude?: string;
  longitude?: string;
}

function Site({ edit }: SiteProps): JSX.Element {
  const { t } = useTranslation(['assets', 'sites', 'translation']);
  const { siteId, orgId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermissions } = useContext(AuthContext);
  const { getSite, site } = useContext(SitesContext);
  const isMobile = useMobile();
  const markerRef = useRef<Marker>(null);
  const [loading, setLoading] = useState(edit);
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const [selectedOrg, setSelectedOrg] = useState<string>(orgId ?? site.org?._id ?? '');
  const [clickToSet, setClickToSet] = useState(false);

  useEffect(() => {
    const title = t(edit ? 'sites:edit_site' : 'sites:create_site');
    setTitle('');
    setBreadcrumbs([{ text: 'Assets', href: '/assets' }, { text: title }]);
    setScrollable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (edit && siteId) {
      getSite(siteId).then(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, siteId]);

  const didUpdateGasComp = (gasComposition: ISite['gasComposition']) => {
    const { ch4, co2, ger } = site.gasComposition;
    return ch4 !== gasComposition.ch4 || co2 !== gasComposition.co2 || ger !== gasComposition.ger;
  };

  const handleSaveSite = async ({
    siteName,
    selectedOrgId,
    distributorId,
    ch4,
    co2,
    ger,
    latitude,
    longitude,
  }: SiteForm) => {
    const newSite: Omit<ISite, '_id'> = {
      name: siteName,
      orgId: selectedOrgId,
      gasComposition: {
        ch4: ch4,
        co2: co2,
        ger: ger,
      },
    };

    if (distributorId !== site.distributor?._id) {
      if (edit) {
        newSite.distributorId = distributorId ? distributorId : null;
      } else if (distributorId) {
        newSite.distributorId = distributorId;
      }
    }

    if (latitude === undefined || longitude === undefined) {
      if (edit) newSite.coords = null;
    } else {
      newSite.coords = { latitude: Number(latitude), longitude: Number(longitude) };
    }

    if (edit) {
      const response = await patchSite(siteId as string, {
        ...newSite,
        gasComposition: didUpdateGasComp(newSite.gasComposition) ? newSite.gasComposition : undefined,
      });
      if (!response) return;
      toast(t('sites:success_message', { method: 'updated' }), { type: 'success' });

      await getSite(siteId as string);

      location.key === 'default' ? navigate('/') : window.history.back();
    } else {
      const response: unknown = await postSite(newSite);
      if (!response) return;

      toast(t('sites:success_message', { method: 'created' }), { type: 'success' });
      location.key === 'default' ? navigate('/') : window.history.back();
    }
  };

  const updateSearchDistributors = async (searchTerm: string) => {
    if (!hasPermissions(PERMISSIONS.dashboard.distributors.read)) return [];

    const distributors = await fetchSearchDistributors({
      project: ['name'],
      pageNumber: 1,
      countPerPage: 10,
      sort: {
        name: 1,
      },
      orgId: selectedOrg || (orgId ?? site.org?._id),
      searchTerm,
    });

    return distributors;
  };

  const handleSelectSite = (newVal?: string | string[], formContext?: FormContextData) => {
    getPlaceDetails(newVal as string).then((details) => {
      const lat = details?.geometry?.location?.lat();
      const lng = details?.geometry?.location?.lng();

      if (lat !== undefined && lng !== undefined) {
        updateAndValidateLatLng(lat.toString(), lng.toString(), formContext, false);
        markerRef.current?.setLatLng(new L.LatLng(lat, lng));
      }
    });
  };

  const handleFetchOrgs = async (searchTerm: string): Promise<ReactSelectOption<string>[]> => {
    const res: unknown = await fetchSearchOrgs({
      count: true,
      project: ['name'],
      pageNumber: 1,
      countPerPage: 10,
      searchTerm,
      sort: { name: 1 },
    });
    const { orgs, count } = res as { orgs: Organization[]; count: number };
    // fetch org name if orgId was passed in the url and not in the list
    const orgVal = selectedOrg || (orgId ?? site.org?._id);

    if (orgVal) {
      if (orgs.length && !orgs.find(({ _id }) => orgVal === _id)) {
        const foundOrg = await fetchOrg(orgVal);

        if (foundOrg) {
          orgs.push(foundOrg);
        }
      }
    }

    return [
      ...orgs.map((org) => ({
        value: org._id,
        label: org.label ?? org.name,
      })),
      ...(count > orgs.length
        ? [
            {
              value: '',
              label: `And ${count - orgs.length} more...`,
              isDisabled: true,
            },
          ]
        : []),
    ];
  };

  const orgValidator = useCallback(
    async (formContext?: FormContextData, value?: string | string[]) => {
      if (!value) return t('org_not_selected');

      const dist = formContext?.getFromFormState<string>('distributorId')?.value;

      if (!dist) return undefined;
    },
    [t],
  );

  const distValidator = useCallback(async (formContext?: FormContextData, value?: string | string[]) => {
    if (formContext) {
      const org = formContext.getFromFormState<string>('selectedOrgId');
      formContext['getFromFormState'] = function <P>() {
        return value as P;
      };
      await org?.validate?.(org.value, formContext);
    }
    return undefined;
  }, []);

  const initVals = useMemo(() => {
    return {
      siteName: site.name,
      selectedOrgId: site.org?._id ?? (site.orgId || orgId),
      distributorId: site.distributor?._id ?? site.distributorId ?? undefined,
      ch4: site.gasComposition?.ch4,
      co2: site.gasComposition?.co2,
      other: site.gasComposition?.other,
      ger: site.gasComposition?.ger,
      latitude: site.coords?.latitude?.toString(),
      longitude: site.coords?.longitude?.toString(),
      total: 100,
    };
  }, [site, orgId]);

  const handleSelectOrg = (newOrg?: string | string[], formContext?: FormContextData) => {
    setSelectedOrg(newOrg as string);
    formContext?.updateFormState('distributorId', undefined);
  };

  const latLngValidator = useCallback(
    (min: number, max: number) => {
      return async (_?: FormContextData, newValue?: string | number) => {
        if (newValue === undefined || newValue === '') return undefined;

        const numberLng = Number(newValue);

        if (isNaN(numberLng) || numberLng < min || numberLng > max) {
          return t('sites:must_be_between', { min, max });
        }
      };
    },
    [t],
  );

  return (
    <Form<SiteForm>
      className={`h-full flex flex-col gap-2 ${isMobile ? 'p-2' : 'px-4'}`}
      onSubmit={handleSaveSite}
      onCancel={() => navigate('/assets')}
      initialValues={initVals}
    >
      <FormSave className="self-end" />
      <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
        <div className="flex max-md:flex-col gap-2">
          <Card className="flex flex-col flex-1">
            <Text className="mb-2" type={TextType.h4}>
              {t('General')}
            </Text>
            <Input
              id="siteName"
              label={t('sites:site_name')}
              className="w-full"
              tooltip={t('sites:site_name_tooltip')}
              validator={async (_, value) => {
                if (!validateSiteName(value as string)) {
                  return t('assets:name_length_error', {
                    length: 3,
                  });
                }
              }}
              loading={loading}
              data-pwid="site-name-input"
              required
            />
            <AsyncSelect<string>
              id="selectedOrgId"
              className="w-full"
              tooltip={t('sites:organization_tooltip')}
              onLoadOptions={handleFetchOrgs}
              onChange={handleSelectOrg}
              validator={orgValidator}
              label={t('Organization')}
              menuPlacement="bottom"
              data-pwid="org-select"
              required
            />
            <AsyncSelect<string>
              id="distributorId"
              callLoadOptionsOn={selectedOrg}
              className="w-full"
              label={t('Distributor')}
              tooltip={t('sites:distributor_tooltip')}
              onLoadOptions={updateSearchDistributors}
              validator={distValidator}
              menuPlacement="bottom"
              loading={loading}
              disabled={!hasPermissions(PERMISSIONS.dashboard.distributors.read)}
              data-pwid="distributor-select"
              clearable
              showOptional={false}
            />
          </Card>
          <GasComposition />
        </div>
        <Card className="flex flex-col">
          <Text className="mb-2" type={TextType.h4}>
            {t('sites:location')}
          </Text>
          <div className="flex flex-row gap-2 flex-wrap w-full">
            <Input
              id="latitude"
              label={t('sites:latitude')}
              className="w-full flex-1"
              tooltip={t('sites:lat_tooltip')}
              rightIcon={HeroIcons.MapIcon}
              step="any"
              data-pwid="latitude-input"
              showOptional={false}
              validator={latLngValidator(-90, 90)}
              validateOnChange
            />
            <Input
              id="longitude"
              label={t('sites:longitude')}
              className="w-full"
              tooltip={t('sites:long_tooltip')}
              rightIcon={HeroIcons.MapIcon}
              step="any"
              data-pwid="longitude-input"
              showOptional={false}
              validator={latLngValidator(-180, 180)}
              validateOnChange
            />
          </div>
          <div className="flex flex-row gap-2 flex-wrap w-full mb-2">
            <div className="flex flex-1 gap-2 items-end">
              <AsyncSelect<string>
                id="siteLocation"
                label={t('sites:site_location')}
                className="w-full"
                tooltip={t('sites:site_location_tooltip')}
                menuPlacement="bottom"
                onLoadOptions={autoCompleteLocation}
                onChange={handleSelectSite}
                loading={loading}
                clearable
                data-pwid="site-location-input"
                showOptional={false}
              />
              <ClearLatLng label={t('Unset')} />
              <GetGps markerRef={markerRef} />
              <Button
                type={Type.button}
                onClick={() => setClickToSet(!clickToSet)}
                icon={HeroIcons.LocationMarkerIcon}
                variant={clickToSet ? Variant.secondary : Variant.secondaryFilled}
              ></Button>
            </div>
          </div>
          <Map
            isMobile={isMobile}
            clickToSet={clickToSet}
            markerRef={markerRef}
            setClickToSet={() => setClickToSet(!clickToSet)}
          />
        </Card>
      </div>
    </Form>
  );
}

export default function SiteContainer(props: SiteProps): JSX.Element {
  const sitesContextValue = useSitesContextValue();
  const distributorsContextValue = useDistributorsContextValue();
  const organizationsContextValue = useOrganizationsContextValue();

  return (
    <SitesContext.Provider value={sitesContextValue}>
      <DistributorsContext.Provider value={distributorsContextValue}>
        <OrganizationsContext.Provider value={organizationsContextValue}>
          <Site {...props} />
        </OrganizationsContext.Provider>
      </DistributorsContext.Provider>
    </SitesContext.Provider>
  );
}
