import { createContext } from 'react';

import Api from '../adapters/api';

export interface AuthContextData {
  hasPermissions: (permissions: string | string[], operator?: 'and' | 'or') => boolean;
  isDistributor: () => boolean;
}

const authContextDefaultValue: AuthContextData = {
  hasPermissions: () => false,
  isDistributor: () => false,
};

export const useAuthContextValue = (): AuthContextData => {
  const hasPermissions = (permissions: string | string[], operator?: 'and' | 'or'): boolean => {
    const user = Api.getUser();
    if (!user) {
      return false;
    }

    const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];

    return operator === 'or'
      ? permissionsArray.some((permission) => user?.permissions?.includes(permission))
      : permissionsArray.every((permission) => user?.permissions?.includes(permission));
  };

  const isDistributor = (): boolean => {
    const user = Api.getUser();
    return user?.isDistributor ?? false;
  };

  return {
    hasPermissions,
    isDistributor,
  };
};

export const AuthContext = createContext<AuthContextData>(authContextDefaultValue);
