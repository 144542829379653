import React from 'react';

import { Border, Card, KeyValue, Text, TextType } from '../../ComponentLibrary/src';
import { CurrentSystemData } from '../../context/Systems';
import { formatValue } from '../../util';

interface Props {
  currentSystemData?: CurrentSystemData;
  isLoading: boolean;
  isCap: boolean;
}

export default function Battery({ currentSystemData, isLoading, isCap }: Props): JSX.Element {
  if (isCap || (currentSystemData?.battV === undefined && currentSystemData?.battI === undefined)) return <></>;

  return (
    <Card className="flex flex-col gap-2 w-full" data-pwid="system-internal-battery-card">
      <Text type={TextType.h3}>Internal Battery</Text>
      <KeyValue
        tooltip="Present voltage of battery output"
        label="Voltage (V)"
        value={`${formatValue(currentSystemData?.battV)} V`}
        loading={isLoading}
        border={Border.none}
      />
      <KeyValue
        tooltip="Present current of battery output"
        label="Current (A)"
        value={`${formatValue(currentSystemData?.battI)} A`}
        loading={isLoading}
        border={Border.none}
      />
    </Card>
  );
}
