import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Api from '../../../adapters/api';
import { deleteSystemActionLog, downloadActionLogs } from '../../../adapters/api/systems/actionLogs';
import {
  Button,
  Filter,
  FilterKey,
  HeroIcons,
  IndicatorTier,
  Link,
  MenuItem,
  Mode,
  PageHeader,
  Pagination,
  Signal,
  Text,
  Variant,
} from '../../../ComponentLibrary/src';
import { usePageContext } from '../../../components/Page';
import ActionLogTimeline from '../../../components/System/ActionLogTimeline';
import TabNavigation from '../../../components/System/TabNavigation';
import StatusIndicator from '../../../components/Systems/StatusIndicator';
import { AuthContext } from '../../../context/Auth';
import { ActionLog, SystemsContext } from '../../../context/Systems';
import { useMobile, usePaginationQuery, useSetDocumentTitle } from '../../../hooks';
import { copyTextToClipboard } from '../../../util';
import { PERMISSIONS } from '../../../util/constants';
import { parseSignalStrength } from '../util';

const defaultCountPerPage = 20;

export default function Timeline(): JSX.Element {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { hasPermissions } = useContext(AuthContext);
  const { t } = useTranslation('action_logs');
  const navigate = useNavigate();
  const { page, setPage, pageSize, setPageSize, focusedItem, setFocusedItem, filter, setFilter } = usePaginationQuery({
    page: 1,
    pageSize: defaultCountPerPage,
    focusedItem: undefined,
    filter: [
      {
        id: 'ts',
        label: 'Log Date',
        selected: false,
        range: { from: DateTime.now().toJSDate(), to: DateTime.now().toJSDate() },
        mode: Mode.dateRange,
      },
      {
        id: 'actionDate',
        label: 'Action Date',
        selected: false,
        range: { from: DateTime.now().toJSDate(), to: DateTime.now().toJSDate() },
        mode: Mode.dateRange,
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const { sysId } = useParams();
  useSetDocumentTitle(`${sysId} | Action Logs`);
  const {
    system,
    summitInfo,
    getSystem,
    getSystemActionLogs,
    setSystemActionLogsPageNumber,
    systemActionLogs,
    systemActionLogsCount,
    systemActionLogsPageNumber,
  } = useContext(SystemsContext);
  const isMobile = useMobile();
  const decodedToken = Api.getUser();

  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();

  useEffect(() => {
    setTitle('');
    setBreadcrumbs();
    setScrollable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sysId]);

  // Re-wire tooltips
  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const updateTimeline = useCallback(
    async ({
      sysId,
      filter,
      pageSize,
      page,
      focusedItem,
    }: {
      sysId?: string;
      filter: FilterKey[];
      pageSize: number;
      page: number;
      focusedItem?: string;
    }) => {
      if (!sysId) return;
      setLoading(true);
      return getSystemActionLogs({
        sysId,
        filter,
        countPerPage: pageSize,
        pageNumber: page,
        focusedItem,
        sort: { actionDate: -1 },
        onFail: ({ response }: AxiosError) => {
          if (response?.status === 402 || response?.status === 403) {
            navigate(`/systems/${sysId}`);
          }
        },
      }).finally(() => setLoading(false));
    },
    [getSystemActionLogs, navigate],
  );

  const clearFocusedItem = useCallback(() => {
    setSystemActionLogsPageNumber(0);
    setFocusedItem(undefined);
  }, [setFocusedItem, setSystemActionLogsPageNumber]);

  useEffect(() => {
    if (sysId)
      getSystem({
        sysId,
        fetchSummit: true,
        project: ['stats.state', 'stats.activeFaults', 'site.name', 'subscriptionStatus'],
      });
    if (!focusedItem) {
      updateTimeline({
        sysId,
        filter,
        pageSize,
        page,
        focusedItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sysId, page, pageSize, filter]);

  // need this separate useEffect so that the one above it handles all cases when focusedItem is not set
  // and this one only trigges a fetch when focusedItem is set.
  // this prevents a double fetch when the page number updates after fetching with focusedItem
  useEffect(() => {
    if (focusedItem) {
      updateTimeline({
        sysId,
        filter,
        pageSize,
        page,
        focusedItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedItem]);

  // show loading and scroll to top of table when filter/pagination changes
  useEffect(() => {
    if (!focusedItem) {
      tableContainerRef.current?.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [page, pageSize, filter, focusedItem]);

  // Update page number if page number comes back from action logs results
  useEffect(() => {
    if (systemActionLogsPageNumber && focusedItem) {
      setPage(systemActionLogsPageNumber);
    }
  }, [systemActionLogsPageNumber, focusedItem, setPage]);

  const handleChangeFilter = useCallback(
    (newFilters: FilterKey[]) => {
      clearFocusedItem();
      setFilter(newFilters);
    },
    [clearFocusedItem, setFilter],
  );

  const handleExport = useCallback(() => {
    downloadActionLogs({
      sysId: sysId as string,
      filter,
      sort: {
        actionDate: 1,
      },
    });
  }, [filter, sysId]);

  const handleClickDelete = useCallback(
    (actionLogId?: string) => {
      if (actionLogId && confirm('Are you sure you want to delete this action log?')) {
        deleteSystemActionLog(actionLogId).then(() => {
          clearFocusedItem();
          updateTimeline({
            sysId,
            filter,
            pageSize,
            page,
          });
        });
      }
    },
    [clearFocusedItem, filter, page, pageSize, sysId, updateTimeline],
  );

  const handleCopyLink = useCallback((actionLogId: string) => {
    copyTextToClipboard(`${window.location.origin}${window.location.pathname}?focusedItem=${actionLogId}`);
  }, []);

  const actions = (
    <div className="flex flex-row gap-4 items-center justify-between">
      <Button icon={HeroIcons.DownloadIcon} className="w-max" variant={Variant.secondaryFilled} onClick={handleExport}>
        {isMobile ? '' : 'Export'}
      </Button>
      {hasPermissions(PERMISSIONS.dashboard.actionLogs.add) && (
        <Link href={`/systems/${sysId}/actionlogs/new`}>
          <Button icon={HeroIcons.DocumentAddIcon} className="w-max" variant={Variant.primary}>
            {isMobile ? '' : 'Action Log'}
          </Button>
        </Link>
      )}
    </div>
  );

  const itemActions = (actionlog: ActionLog) => {
    const isSelf = actionlog.email === decodedToken?.email;
    const canUpdate = isSelf
      ? hasPermissions(PERMISSIONS.dashboard.ownActionLogs.update)
      : hasPermissions(PERMISSIONS.dashboard.othersActionLogs.update);
    const canDelete = isSelf
      ? hasPermissions(PERMISSIONS.dashboard.ownActionLogs.delete)
      : hasPermissions(PERMISSIONS.dashboard.othersActionLogs.delete);

    return (
      <div className="flex flex-row justify-end items-end gap-2">
        <>
          <Button
            icon={HeroIcons.LinkIcon}
            variant={Variant.secondaryFilled}
            size="small"
            data-pwid="link-action-log"
            tooltip={t('copy_link_tooltip')}
            onClick={() => handleCopyLink(actionlog._id ?? '')}
          />
          {canUpdate && (
            <Link href={`/systems/${sysId}/actionlogs/${actionlog._id}`}>
              <Button
                icon={HeroIcons.PencilIcon}
                variant={Variant.secondaryFilled}
                size="small"
                data-pwid="edit-action-log"
              />
            </Link>
          )}
          {canDelete && (
            <Button
              data-pwid="delete-action-log"
              icon={HeroIcons.TrashIcon}
              variant={Variant.secondaryFilled}
              onClick={() => handleClickDelete(actionlog._id)}
              size="small"
            />
          )}
        </>
      </div>
    );
  };

  const handleLoadOptions = useCallback(async (_: string, searchTerm?: string) => {
    const unfilteredOptions: MenuItem[] = [];
    const data = searchTerm
      ? unfilteredOptions.filter((option) => {
          return (option.label?.toString() ?? '').toLowerCase().includes(searchTerm.toLowerCase());
        })
      : unfilteredOptions;
    return {
      data,
      count: data.length,
    };
  }, []);

  const handleChangePageNumber = useCallback(
    (page: number) => {
      clearFocusedItem();
      setPage(page);
    },
    [clearFocusedItem, setPage],
  );

  const handlePageSize = useCallback(
    (pageSize: number) => {
      clearFocusedItem();
      setPageSize(pageSize);
    },
    [clearFocusedItem, setPageSize],
  );

  const signalStrength = useMemo(() => parseSignalStrength(summitInfo), [summitInfo]);

  return (
    <div
      className={`${
        isMobile ? 'pt-2 px-2 mb-28' : 'm-4 relative'
      } flex flex-col flex-1 gap-3 bg-blue-50 overflow-hidden`}
      id="content"
      onScroll={() => window.dispatchEvent(new Event('scrollPage'))}
    >
      <PageHeader
        title={sysId}
        subtitle={loading ? '' : system?.site?.name}
        breadcrumbs={[{ text: 'Systems', href: '/systems' }, { text: sysId ?? '' }]}
        leftComponent={
          <>
            <StatusIndicator system={system} tier={IndicatorTier.two} isLoading={loading} />
          </>
        }
        rightComponent={
          <>
            <Signal signalStrength={signalStrength} isLoading={loading} className="h-6 w-6" />
          </>
        }
        bottomBorder
      >
        <TabNavigation
          sysId={sysId}
          pageSelected="actionlogs"
          subscriptionStatus={loading ? '' : system?.subscriptionStatus || ''}
        />
      </PageHeader>
      <div className="flex">
        <Filter filter={filter} onChangeFilter={handleChangeFilter} loadOptions={handleLoadOptions} />
        {actions}
      </div>
      <div ref={tableContainerRef} className={`flex flex-1 flex-col overflow-y-auto ${!isMobile && 'mb-14'}`}>
        {loading || systemActionLogs?.length ? (
          <React.Fragment>
            <ActionLogTimeline
              actionLogs={systemActionLogs || []}
              itemActions={itemActions}
              loading={loading}
              focusActionLog={focusedItem}
            />
            <Pagination
              className={`${isMobile ? 'left-0 right-0 z-900 bottom-16 h-12' : 'h-14 bottom-0'} absolute`}
              pageNumber={page}
              countPerPage={pageSize}
              totalCount={systemActionLogsCount || 0}
              onChangePageNumber={handleChangePageNumber}
              onChangeCountPerPage={handlePageSize}
              data-pwid="action-logs-pagination"
              mobile={isMobile}
            />
          </React.Fragment>
        ) : (
          <div className="flex flex-col items-center">
            <Text>{t('no_data')}</Text>
          </div>
        )}
      </div>
    </div>
  );
}
