import get from 'lodash.get';
import React from 'react';

import { Card, Text, TextType } from '../../ComponentLibrary/src';
import { Border, KeyValue } from '../../ComponentLibrary/src';
import { CurrentSystemData } from '../../context/Systems';
import { useMobile } from '../../hooks';
import { isCap } from '../../pages/System/util';
import { System } from '../../types';
import { getLabel, getSystemField } from './constants';
import { labelSort } from './util';
export interface GeneralProps {
  system?: System;
  isLoading: boolean;
  currentSystemData?: CurrentSystemData;
}

export default function General(props: GeneralProps): JSX.Element {
  let fieldsToDisplay = powerGenFields;
  if (isCap(props.system)) {
    fieldsToDisplay = capFields;
  }
  const isMobile = useMobile();
  const classes = 'grid gap-x-8 gap-y-2 w-full ' + (isMobile ? 'grid-cols-1' : 'grid-cols-2');
  return (
    <Card className="flex flex-row gap-2" data-pwid="system-general-card">
      <div className="flex flex-col w-full gap-2">
        <Text type={TextType.h3}>General</Text>
        <div className="flex flex-row flex-wrap gap-y-2 gap-x-8">
          <div className={classes}>
            {fieldsToDisplay.sort(labelSort).map((field) => {
              if (['string', 'number'].includes(typeof get(props.system, field)))
                return (
                  <KeyValue
                    key={field}
                    label={getLabel(field, true)}
                    value={get(props.system, field)?.toString() ?? ''}
                    tooltip={getSystemField(field)?.tooltip}
                    loading={props.isLoading}
                    border={Border.none}
                  />
                );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}

const powerGenFields = [
  'fpcFwVer',
  'qecFwVer',
  'engId',
  'qecHwVer',
  'qecId',
  'stats.hotRunHours',
  'stats.coldRunHours',
  'stats.coldStarts',
  'stats.tCyclesFast',
  'stats.tCyclesSlow',
  'stats.ignitionCycles',
  'stats.ignitionFailures',
];

const capFields = [
  'plcVer',
  'qdlSwVer',
  'config.pHighSp',
  'stats.runtimeDryer',
  'config.pLowSp',
  'config.flowHighLim',
  'config.pAlSp',
  'config.pAlSpBk',
  'config.pHighSpBk',
  'config.pLowSpBk',
  'stats.overTempCtr',
];
