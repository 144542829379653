import { RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import FormContext from '../Form/FormContext';
import { FormContextData } from '../Form/index.types';
import { InputType } from '../Input/index.types';
import { screenSizeMediaQuery } from './constants';
import { debounce, getErrors } from './index';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(refs: RefObject<unknown> | RefObject<unknown>[] | null, callback: () => void): void {
  if (!Array.isArray(refs) && refs) refs = [refs];
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    if (!refs) return;
    const handleClickAnywhere = (event: MouseEvent) => {
      let clickIsInsideARef = false;
      for (const ref of refs as RefObject<unknown>[]) {
        if (!ref.current) continue;
        if (event.composedPath().some((ancestor) => ancestor === ref.current)) {
          clickIsInsideARef = true;
        }
      }
      return clickIsInsideARef ? undefined : callback();
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickAnywhere);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickAnywhere);
    };
  }, [refs, callback]);
}

export function useMounted(): boolean {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted.current;
}

export function useMobile(): boolean {
  return useMediaQuery(screenSizeMediaQuery.mobile);
}

export interface IUseFormControlValidation<P> {
  formStateValue?: P;
  formDisabled: boolean;
  validating: boolean;
  error: string | undefined;
  handleOnChange: (newValue?: P) => void;
  handleOnBlur: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>, value?: P) => void;
  disableFormBtn: (disable: boolean) => void;
  updateIsDirty: () => void;
}

export interface IUseFormControlValidationProps<P> {
  id: string;
  min?: number;
  max?: number;
  type?: InputType | 'select';
  required?: boolean;
  skipRegister?: boolean;
  validateOnChange?: boolean;
  onChange?: (newData?: P, formContext?: FormContextData) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>, newData?: P) => void;
  validator?: (formContext?: FormContextData, value?: P) => Promise<string | undefined>;
}

export const useFormControlValidation = <P>({
  id,
  min,
  max,
  type,
  required,
  skipRegister = false,
  validateOnChange = true,
  onChange,
  onBlur,
  validator,
}: IUseFormControlValidationProps<P>): IUseFormControlValidation<P> => {
  const formContext = useContext(FormContext);
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState<string>();
  const timer = useRef<NodeJS.Timeout>(null);

  const validate = useCallback(
    async (value?: unknown, formCtx?: FormContextData) => {
      setValidating(true);
      let errMessage = getErrors({
        value,
        min,
        max,
        type,
        required,
      });

      if (validator && !errMessage) {
        errMessage = await validator(formCtx, value as P);
      }

      setValidating(false);
      setError(errMessage);

      if (!formCtx?._isDefault) formCtx?.updateIsInvalid(id, !!errMessage);

      return !errMessage;
    },
    [min, max, type, required, id, validator],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialDebounceFn = useMemo(() => debounce(validate as () => Promise<boolean>, 1500, timer), []);

  const handleOnChange = (newValue?: P) => {
    if (onChange) {
      formContext._isDefault ? onChange(newValue) : onChange(newValue, formContext);
    }

    if (!formContext._isDefault && !skipRegister) {
      formContext.updateFormState(id, newValue);
    }

    if (validateOnChange || error) {
      // Only debounce for input text or textarea
      if (type === InputType.text || type === InputType.textArea) {
        if (!error && !initialDebounceFn(newValue, formContext)) return;
      }
      // Disable save button
      formContext?.validating(true);
      validate(newValue, formContext).then(() => formContext?.validating(false));
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>, value?: P) => {
    if (onBlur) onBlur(e, value);

    if (timer.current) clearTimeout(timer.current);

    formContext?.validating(true);
    validate(value, formContext).then(() => formContext?.validating(false));
  };

  // This registers with context so that form can call validate
  useEffect(() => {
    if (!formContext._isDefault && !skipRegister) {
      // Run new validate function if validate updates,
      // this will not run on first render
      if (error) {
        validate(formContext.getFromFormState<P>(id)?.value, formContext);
      }

      formContext.register(id, validate, () => setError(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate]);
  // This useEffect is to un-register with context when the component unmounts
  useEffect(() => {
    return () => {
      if (!formContext._isDefault && !skipRegister) formContext.unregister(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formDisabled: formContext.formDisabled ?? false,
    formStateValue: formContext.getFromFormState<P>(id).value,
    validating,
    error,
    handleOnChange,
    handleOnBlur,
    disableFormBtn: (disable: boolean) => formContext.validating(disable),
    updateIsDirty: formContext.updateIsDirty,
  };
};

export interface UseFormGroupProps<T> extends IUseFormControlValidationProps<T> {
  selected?: T;
}

export interface UseFormGroup<T>
  extends Omit<IUseFormControlValidation<T>, 'formStateValue' | 'validating' | 'handleOnChange'> {
  groupValue: T | string[];
  register: (id: string, value?: T) => void;
  unregister: (id: string) => void;
  handleOnChange: (id: string, value?: T, checked?: boolean) => void;
}

interface GroupState<T> {
  [id: string]: {
    isRegistered: boolean;
    selected: boolean;
    value?: T;
  };
}

export const useFormGroup = <T>({
  id,
  min,
  max,
  required,
  skipRegister = false,
  onChange,
  onBlur,
  validator,
  selected,
}: UseFormGroupProps<T | string[]>): UseFormGroup<T> => {
  // This state is the source of truth to know which children were added to the dom at least once.
  // The currently displayed children have isRegistered to true and holds selected value
  const [groupState, setGroupState] = useState<GroupState<T>>({});
  const { formDisabled, error, formStateValue, handleOnChange, handleOnBlur, disableFormBtn, updateIsDirty } =
    useFormControlValidation<T | string[]>({
      id,
      max,
      min,
      required,
      type: InputType.radio,
      onBlur,
      onChange,
      validator,
      skipRegister,
      validateOnChange: true,
    });
  /**
   * Updates groupState, adds a default empty array for checkboxes if one did get provided in
   * initial values, and updates selected value with registered selected items.
   */
  const handleLocalChange = (id: string, value?: T, checked?: boolean) => {
    if (groupState[id]?.isRegistered) {
      if (value === undefined) {
        setGroupState({
          ...groupState,
          [id]: {
            ...groupState[id],
            selected: !!checked,
          },
        });
      } else {
        const newState = Object.entries(groupState)
          .filter(([, value]) => value.isRegistered && value.selected)
          .map(([key]) => key)
          .reduce((acc, key) => {
            acc[key] = { ...acc[key], selected: false };
            return acc;
          }, groupState);

        setGroupState(newState);
      }
    }
    // Value is used for radio
    if (value !== undefined) {
      handleOnChange(value);
    } else {
      const stateVal = (formStateValue as string[]) ?? [];
      const newVal = checked
        ? [...stateVal, id]
        : stateVal.filter((selected) => {
            return selected !== id;
          });

      handleOnChange(newVal);
    }
  };
  /**
   * Helper function to update group state, get's called for register and unregister
   *
   * @param id child id, could be checkbox or radio buttons
   * @param isRegistered boolean
   * @param value value of the radio buttons
   */
  const updateGroupState = (id: string, isRegistered: boolean, value?: T) => {
    const stateVal = selected ?? formStateValue;
    let isSelected = stateVal === value;
    if (Array.isArray(stateVal)) {
      isSelected = (stateVal as string[]).includes(id);
    }

    setGroupState((prev) => {
      return {
        ...prev,
        [id]: {
          ...(prev[id] ?? {}),
          selected: isSelected,
          isRegistered,
          value,
        },
      };
    });
  };
  /**
   * Adds child to group state
   */
  const register = useCallback((id: string, value?: T) => {
    updateGroupState(id, true, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Update group state, does not delete or change value
   */
  const unregister = useCallback((id) => {
    updateGroupState(id, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Clean form state and selected after register/unregister
  useEffect(() => {
    const groupStateSelected = Object.entries(groupState).filter(([, value]) => value.isRegistered && value.selected);
    if (groupStateSelected.length) {
      const val = selected ?? formStateValue;
      if (Array.isArray(val)) {
        const expectedState = groupStateSelected.map(([key]) => key);
        if (expectedState.length !== val.length || expectedState.some((expected) => !val.includes(expected))) {
          handleOnChange(expectedState);
        }
      } else {
        const groupState = groupStateSelected[0][1]?.value;
        if (groupState !== val) {
          handleOnChange(groupState ?? ('' as T));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState]);

  return {
    formDisabled: formDisabled ?? false,
    groupValue: selected ?? (formStateValue as T | string[]) ?? ('' as T),
    error,
    handleOnChange: handleLocalChange,
    handleOnBlur,
    register,
    unregister,
    disableFormBtn,
    updateIsDirty,
  };
};
