import { ChartOptions } from 'chart.js';

export const displayNumber = ({
  num,
  numberFormatOptions = {
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short',
  },
  units,
}: {
  num?: string | number;
  numberFormatOptions?: Intl.NumberFormatOptions;
  units?: string;
}): { value: string; suffix: string } => {
  if (num === undefined) return { value: '', suffix: '' };
  const display = {
    value: num ? num.toLocaleString() : '?',
    suffix: '',
  };
  if (typeof num !== 'number') num = parseFloat(num);

  const formatter = new Intl.NumberFormat('en-US', numberFormatOptions);
  const parts = formatter.formatToParts(num);

  display.value = parts.reduce((value, part) => {
    if (part.type !== 'compact') {
      return value + part.value;
    }
    return value;
  }, '');

  let suffix = parts.find((part) => part.type === 'compact')?.value ?? '';
  if (units === 'h') {
    display.value = `${display.value}${suffix}`;
  } else {
    // use scientific units
    if (suffix === 'B') suffix = 'G';
    if (suffix === 'K') suffix = 'k';
    display.suffix = suffix;
  }
  return display;
};

export function getChartOptions(showPlaceholder: boolean): ChartOptions<'bar' | 'line'> {
  return {
    interaction: {
      axis: 'x',
      intersect: false,
    },
    responsive: true,
    layout: {
      padding: 8,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: !showPlaceholder,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        type: showPlaceholder ? undefined : 'time',
        display: false,
      },
    },
    maintainAspectRatio: false,
  };
}

export const placeholderLabels = ['1', '2', '3', '4', '5', '6', '7'];
export const placeholderData = [1, 2, 3, 3, 4, 3, 3];
