import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '../ComponentLibrary/src';
import { Variant } from '../ComponentLibrary/src/Button/index.types';
import { Text, TextType } from '../ComponentLibrary/src/Text';
import { useSetDocumentTitle } from '../hooks';
import logo from '../image/QD.png';

export default function ServerError(): JSX.Element {
  useSetDocumentTitle('Service Unavailable');
  const { t } = useTranslation(['translation']);
  return (
    <div className="flex flex-col items-center w-screen h-screen bg-white">
      <img src={logo} className="w-[10rem] pt-4" />
      <div className="flex flex-col flex-1 items-center justify-center">
        <div className="flex flex-col justify-center p-4 max-w-lg">
          <Text type={TextType.custom} className="text-5xl md:text-6xl lg:text-7xl font-bold">
            Oops!
          </Text>
          <Text type={TextType.h3}>This is unexpected...</Text>
          <Text type={TextType.body} wrap className="mt-4">
            <Trans t={t} i18nKey="Service Unavailable" values={{ support: process.env.REACT_APP_SUPPORT_TEL }}>
              <a href={`tel:${process.env.REACT_APP_SUPPORT_TEL}`} className="text-emerald-500"></a>
            </Trans>
          </Text>
        </div>
        <Button
          variant={Variant.primary}
          onClick={() => {
            window.location.reload();
          }}
          className={`mt-2`}
        >
          Refresh
        </Button>
      </div>
    </div>
  );
}
