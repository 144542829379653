import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

import { Button, DateRange, DateRangePicker, HeroIcons, Select, Side, Variant } from '../../ComponentLibrary/src';
import { useMobile } from '../../ComponentLibrary/src/util/hooks';
import { Interval, SystemsContext, useSystemsContextValue } from '../../context/Systems';
import { QuickRange } from '../../pages/System/Data/types';
import { DATA_INTERVALS, luxonUnits, QUICK_RANGE_OPTIONS } from '../../pages/System/Data/util';
import { copyTextToClipboard } from '../../util';

interface Props {
  dateRange: DateRange;
  loading?: boolean;
  dataInterval?: Interval;
  minInterval?: Interval;
  quickRange?: QuickRange;
  setDateRange: (range: DateRange) => void;
  onChangeExport?: () => void;
  setDataInterval?: (selectedInterval: Interval) => void;
  setQuickRange?: (quickRange: QuickRange) => void;
  onRefresh?: () => void;
  onDownloadImage?: () => void;
  'data-pwid'?: string;
}

function DataFilter({
  dateRange,
  loading,
  dataInterval,
  minInterval,
  quickRange,
  setDateRange,
  onChangeExport,
  setDataInterval,
  setQuickRange,
  onRefresh,
  onDownloadImage,
  'data-pwid': dataPwId = 'data-filter',
}: Props): JSX.Element {
  const isMobile = useMobile();

  const copyUrl = () => {
    copyTextToClipboard(window.location.href);
  };

  const intervals = useMemo(() => {
    return DATA_INTERVALS.map((interval, idx) => {
      return {
        ...interval,
        isDisabled:
          typeof minInterval === 'string' && idx < DATA_INTERVALS.findIndex((int) => int.value === minInterval),
      };
    });
  }, [minInterval]);

  return (
    <div className={`flex flex-row gap-2 justify-between flex-wrap`}>
      <div className={`flex flex-row gap-2 flex-wrap items-center`}>
        <Button
          icon={HeroIcons.RefreshIcon}
          onClick={onRefresh}
          disabled={loading}
          tooltip="Refresh chart with the latest data"
          data-pwid="refresh-button"
        ></Button>
        <div data-tip="Select an interval. Note: some options are disabled for larger date ranges.">
          <Select
            id="data-page-interval"
            className="min-w-[12rem]"
            options={intervals}
            value={dataInterval}
            onChangeValue={(value) => {
              ReactTooltip.hide();
              if (setDataInterval) setDataInterval(value as Interval);
            }}
            placeholder={`${minInterval ? minInterval.charAt(0).toUpperCase() + minInterval.slice(1) + 's' : ''}`}
            data-pwid={`${dataPwId}-data-interval-select`}
            clearable={false}
          />
        </div>

        <div data-tip={!isMobile ? 'Select a date range' : undefined} data-pwid={`${dataPwId}-date-range-picker`}>
          <DateRangePicker
            id="dateRange"
            className="flex flex-row w-full sm:w-auto justify-between"
            dateRange={dateRange}
            onChange={(range?: DateRange) => {
              if (range) {
                setDateRange(range);
              }
            }}
            side={Side.left}
          />
        </div>
        {!isMobile && setQuickRange && (
          <>
            {QUICK_RANGE_OPTIONS.map((opt) => (
              <Button
                key={opt}
                className="w-max"
                variant={
                  opt === `${quickRange?.value}${quickRange?.units.charAt(0)}`
                    ? Variant.secondaryFilled
                    : Variant.secondary
                }
                onClick={() =>
                  setQuickRange({
                    units: luxonUnits[opt.match(/[a-z]/i)?.[0] as keyof typeof luxonUnits],
                    value: parseInt(opt.match(/[0-9]*/)?.[0] ?? '24'),
                  })
                }
                size="small"
              >
                {opt}
              </Button>
            ))}
          </>
        )}
      </div>
      {!isMobile && (
        <div className={`flex flex-row gap-2 flex-wrap items-center`}>
          <Button
            tooltip="Copy url"
            icon={HeroIcons.LinkIcon}
            className="w-max"
            variant={Variant.secondaryFilled}
            onClick={copyUrl}
          ></Button>
          {onDownloadImage && (
            <Button
              tooltip="Download chart as image"
              icon={HeroIcons.PhotographIcon}
              className="w-max"
              variant={Variant.secondaryFilled}
              onClick={onDownloadImage}
            ></Button>
          )}
          {onChangeExport && (
            <Button
              icon={HeroIcons.DownloadIcon}
              className="w-max"
              variant={Variant.secondaryFilled}
              tooltip="Export data to a CSV file"
              data-pwid={`${dataPwId}-export-button`}
              onClick={onChangeExport}
            >
              Export
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default function DataFilterContainer(props: Props): JSX.Element {
  const systemsContextValue = useSystemsContextValue();

  return (
    <SystemsContext.Provider value={systemsContextValue}>
      <DataFilter {...props} />
    </SystemsContext.Provider>
  );
}
