import React from 'react';

import { IndicatorTier, StatusIndicator } from '../StatusIndicator';
import { appendClassProps } from '../util';
import { DualStatusIndicatorProps } from './index.types';

/**
 - Use signal to display an icon indicating signal strength
 */
export const DualStatusIndicator = ({
  tier = IndicatorTier.one,
  primaryIndicatorState,
  secondaryIndicatorState,
  online,
  className,
  clusterCount,
  'data-pwid': dataPwId = 'dual-status-indicator',
}: DualStatusIndicatorProps): JSX.Element => (
  <div
    className={`relative w-fit${appendClassProps(className)}`}
    data-testid="dualStatusIndicator"
    onClick={(e) => e.stopPropagation()}
    data-pwid={dataPwId}
  >
    <StatusIndicator state={primaryIndicatorState} tier={tier} online={online} clusterCount={clusterCount} />
    {secondaryIndicatorState && (
      <StatusIndicator
        isSecondary
        state={secondaryIndicatorState}
        tier={tier}
        className="absolute"
        style={tier === IndicatorTier.one ? { right: '-20%', top: '-20%' } : { right: '-8%', top: '-8%' }}
      />
    )}
  </div>
);

export * from './index.types';
