import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { AuthContext } from '../../../context/Auth';
import { Distributor, DragItem, Site, System } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';
import DraggableSystem from './DraggableSystem';
import DropPlaceholder from './DropPlaceholder';

interface SitePageProps {
  site: Site;
  isDraggingSystem: boolean;
  parentDistributors: Distributor[];
  savingSystems: Set<string>;
  onDrop?: (item: DragItem, destination: string) => void;
  draggingFromAllocated?: (allocated: boolean) => void;
  handleDragging: (dragging: boolean) => void;
  refreshData: () => void;
  setCurrentDragging: (system?: System) => void;
  currentDragging?: System;
  orgId?: string;
  orgName?: string;
}

export default function SitePage({
  site,
  isDraggingSystem,
  parentDistributors,
  savingSystems,
  onDrop,
  handleDragging,
  draggingFromAllocated,
  refreshData,
  setCurrentDragging,
  currentDragging,
  orgId,
}: SitePageProps): JSX.Element {
  const { hasPermissions } = useContext(AuthContext);
  const { t } = useTranslation(['assets', 'translation']);

  useEffect(() => {
    if (orgId) site.orgId = orgId;
  }, [orgId, site]);

  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
  });

  return (
    <React.Fragment>
      <div>
        <DropPlaceholder
          text={t('drop_help', { destination: site.name })}
          show={
            isDraggingSystem &&
            currentDragging?.siteId !== site._id &&
            hasPermissions(PERMISSIONS.dashboard.systems.updateSite)
          }
          onDrop={(item: DragItem) => {
            site.orgId = orgId || '';
            item.site = site;
            if (onDrop) onDrop(item, 'site');
          }}
        />
        {site.systems?.map((system: System) => {
          system.orgId = orgId;
          system.siteId = site._id;
          return (
            <div
              key={JSON.stringify(system)}
              className="relative flex flex-row gap-2 border border-dashed rounded px-2 mx-1 mb-0.5 items-center h-11 hover:bg-gray-100"
            >
              <DraggableSystem
                key={JSON.stringify(system)}
                list="allocated"
                systemClasses=""
                setCurrentDragging={setCurrentDragging}
                savingSystems={savingSystems}
                isAlreadyDragging={isDraggingSystem}
                system={system}
                handleDragging={handleDragging}
                draggingFromAllocated={draggingFromAllocated}
                parentDistributors={parentDistributors}
                refreshData={refreshData}
                showRemove
              />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}
