import React from 'react';

import { Card, Link } from '../ComponentLibrary/src';
import logo from '../image/QD.png';

export default function Terms(): JSX.Element {
  return (
    <div className="flex justify-center">
      <Card className="flex flex-col sm:m-4 p-4 sm:p-8 md:p-16 terms w-full xl:w-2/3">
        <div className="flex justify-center">
          <img src={logo} className="h-12" />
        </div>
        <h1>Privacy Policy</h1>
        <p>
          Qnergy Inc. (referred to as the “<strong>Qnergy</strong>”, “<strong>we</strong>”, “<strong>us</strong>” or “
          <strong>our</strong>” in this Privacy Policy) respects your privacy and is committed to protecting your
          Personal Data. This Privacy Policy (“<strong>Privacy Policy</strong>” or “Policy”) is designed to inform you
          as to how we handle your Personal Data in connection with your use of the Qnergy Dashboard Platform (the “
          <strong>Platform</strong>”), and notify you about your privacy rights and how the law protects such rights. “
          <strong>Personal Data</strong>” means any information about an individual from which that person can be
          identified.
        </p>
        <p>
          Capitalized terms used but not otherwise defined in this Privacy Policy shall have the meanings ascribed to
          such terms in the{' '}
          <Link href="/terms" inline color="green">
            Terms of Use
          </Link>
          .
        </p>
        <p>
          To the extent that the European General Data Protection Regulation (“<strong>GDPR</strong>”) or the United
          Kingdom General Data Protection Regulation (“<strong>UK GDPR</strong>”) apply, Qnergy is considered a “data
          processor” with respect to Personal Data that it processes for the provision of services to the customer or
          reseller on behalf of which you are using the Platform (collectively, “<strong>Customer</strong>”). To the
          extent that the California Consumer Privacy Act (“<strong>CCPA</strong>”), or the California Privacy Rights
          Act (“<strong>CPRA</strong>”) (upon its entry into force) apply, we act as a “service provider” with respect
          to such processing. The Customer&apos;s privacy policy shall apply to such processing.
        </p>
        <p>
          We may also act as a “data controller” (to the extent that the GDPR applies) or as a “business” (to the extent
          that the CCPA or the CPRA apply) with respect to the processing described in Section 3 below under “Other
          Purposes”.
        </p>
        <p>
          We encourage you to read this Privacy Policy carefully and to regularly check this page to review any changes
          we might make to the terms of this Privacy Policy. This Privacy Policy supplements other policies we may
          provide as part of our interactions with you or policies you may have received from other controllers and is
          not intended to override such policies.
        </p>
        <p>
          This Privacy Policy is provided in a layered format so you can click through to the specific areas set out
          below.
        </p>
        {/*<p>
        [Note to Qnergy: (a) we recommend building the Privacy Policy in a “layered format” (e.g., the text of
        each section would be displayed if the user clicked on the header of the section) to allow users to easily
        navigate the Privacy Policy; (b) users should also be able to easily print the Privacy Policy as one document
        (so perhaps offer it in both PDF and HTML formats); (c) generally recognized industry standards for ensuring
        users with disabilities can access the policy should be followed (e.g., Web Content Accessibility Guidelines
        published by the World Wide Web Consortium for online Policies)]
      </p>*/}
        <ol className="toc">
          <li>
            <Link href="#data-we-collect" inline color="green">
              <strong>The Data We Collect About You</strong>
            </Link>
          </li>
          <li>
            <Link href="#how-we-collect" inline color="green">
              <strong>How We Collect Your Personal Data</strong>
            </Link>
          </li>
          <li>
            <Link href="#how-we-use" inline color="green">
              <strong>How We Use Your Personal Data</strong>
            </Link>
          </li>
          <li>
            <Link href="#sharing" inline color="green">
              <strong>Sharing Your Personal Data with Third Parties</strong>
            </Link>
          </li>
          <li>
            <Link href="#minors" inline color="green">
              <strong>Minors</strong>
            </Link>
          </li>
          <li>
            <Link href="#transfers" inline color="green">
              <strong>International Transfers</strong>
            </Link>
          </li>
          <li>
            <Link href="#security" inline color="green">
              <strong>Data Security</strong>
            </Link>
          </li>
          <li>
            <Link href="#retention" inline color="green">
              <strong>Data Retention</strong>
            </Link>
          </li>
          <li>
            <Link href="#rights" inline color="green">
              <strong>Your Rights</strong>
            </Link>
          </li>
          <li>
            <Link href="#accessibility" inline color="green">
              <strong>Accessibility</strong>
            </Link>
          </li>
          <li>
            <Link href="#ccpa" inline color="green">
              <strong>California Consumer Protection Act and California Privacy Rights Act</strong>
            </Link>
          </li>
          <li>
            <Link href="#contact-us" inline color="green">
              <strong>Contact Details</strong>
            </Link>
          </li>
          <li>
            <Link href="#changes" inline color="green">
              <strong>Changes to this Privacy Policy</strong>
            </Link>
          </li>
        </ol>
        <ol className="top-list">
          <li>
            <h2 id="data-we-collect">The Data We Collect About You</h2>
            <p>
              When you use our Platform or contact us through it, we may collect the following types of Personal Data on
              you:
            </p>
            <ul>
              <li>
                <strong>Identity Data</strong> includes your first and last name.
              </li>
              <li>
                <strong>Contact Data</strong> includes your physical address, email address and telephone number.
              </li>
              <li>
                <strong>Business Data</strong> includes your company name and position.
              </li>
              <li>
                <strong>Usage Data</strong> includes information about how you use or interact with our Platform (such
                as, when you entered the Platform, how long you stayed on the Platform and/or a specific page on the
                Platform).
              </li>
              <li>
                <strong>Technical Data</strong> includes information on your internet connection (such as your IP
                address and internet service provider name), on the device and software that you are using to access the
                Platform (e.g., your web browser type and computer operating system).
              </li>
              <li>
                <strong>Additional Inquiry Data </strong>includes additional information that you may provide when you
                contact us through the Platform or when you sign up for any of our services.
              </li>
            </ul>
            <p>
              We also collect, use and share aggregated data such as statistical data for any purpose. Such information
              does not reveal your identity and therefore is not considered Personal Data. For example, we may aggregate
              your Usage Data to calculate the percentage of users accessing a specific feature available through the
              Platform.
            </p>
            <h3>Protecting the Privacy Rights of Third Parties</h3>
            <p>
              If you provide us any Personal Data relating to others, you must make sure that you have permission to do
              so.
            </p>
          </li>
          <li>
            <h2 id="how-we-collect">How We Collect Your Personal Data</h2>
            <p>We use different methods to collect data from and about you including through:</p>
            <ul>
              <li>
                <strong>Direct interactions</strong>. When you register to use the Platform or otherwise provide your
                Personal Data directly to us, you will be asked to provide us with your Identity Data, Contact Data, and
                Business Data so that we can provide our services to you, respond to your questions or requests or
                otherwise contact you. You may also provide Additional Inquiry Data in such instances.
              </li>
              <li>
                <strong>Automated technologies or interactions</strong>. As you interact with our Platform, we
                automatically collect the applicable Technical Data and Usage Data. We collect this data by using
                cookies, server logs, and other similar technologies.
              </li>
              <li>
                <strong>Third parties or publicly available sources</strong>. We may receive Technical Data and Usage
                Data about you from third parties, such as Usage Data and Technical Data that we may receive from
                analytics providers, and from advertising and marketing partners.
              </li>
            </ul>
            <p>
              You are not legally obligated to provide any Personal Data to Qnergy. Any Personal Data that you choose to
              provide to Qnergy, is provided at your own free will.
            </p>
          </li>
          <li>
            <h2 id="how-we-use">How We Use Your Personal Data</h2>
            <h3>Purposes for Which We Use Your Personal Data</h3>
            <h4>Provision of Services</h4>
            <p>
              We will process your Personal Data in connection with the provision of Services to the Customer, which
              would generally include the following:
            </p>
            <ul>
              <li>to provide the Services to you or to the Customer;</li>
              <li>to provide technical support with respect to the Services;</li>
              <li>to store information relating to the Services for the Customer;</li>
              <li>to manage our relationship with you as part of the provision of Services;</li>
              <li>to facilitate connections between you and other Users of the Services; and</li>
              <li>to comply with the Customer&apos;s instructions.</li>
            </ul>
            <p>
              The Customer is responsible for securing a legal basis for any processing of your Personal Data that we
              perform on behalf of the Customer.
            </p>
            <h4>Other Purposes</h4>
            <p>
              In addition, we may process your Personal Data for the following purposes. To the extent that the GDPR and
              UK GDPR apply to such processing, we have mentioned the legal bases we rely on to process your Personal
              Data, and identified what our legitimate interests are where appropriate:
            </p>
            <ul>
              <li>
                We use your Identity Data, Contact Data and Business Data to enter into a contract or to perform a
                contract with you.
              </li>
              <li>
                We use your Contact Data and Additional Inquiry Data to respond to your requests or questions and to
                manage our relationship with you, including by notifying you about changes to our terms of use or
                Privacy Policy. We do so for the performance of a contract with you, if it is necessary to comply with a
                legal obligation or if it is necessary for our legitimate interests (to provide information to you).
              </li>
              {/*<li>
              We use your Usage Data, Technical Data and Contact Data for marketing purposes, to deliver
              relevant and personalized content to you, send service messages (for example, for security purposes) and
              to measure or understand the effectiveness of our marketing efforts. We do so where it is necessary for
              our legitimate interests (to grow our business, to inform our marketing strategy and to maintain and
              secure the services); or if we have obtained your prior consent (where laws require consent for such
              communications). [Note to Qnergy: is this relevant? Would you be sending marketing communications to the
              Users?]
              </li>*/}
              <li>
                We use your Usage Data and Technical Data to improve our Platform, services, marketing, customer
                relationships and experiences, including by personalizing and customizing our content. We do so where it
                is necessary for our legitimate interests (to keep our Platform updated and relevant, to develop our
                business and to inform our marketing strategy).
              </li>
              <li>
                In the event of a legal dispute between you (and/or a party on your behalf) to us (and/or a party on our
                behalf), we may use your Personal Data to the extent necessary in connection with handling such dispute.
                We do so where it is necessary for our legitimate interests (to defend and enforce our legal rights).
              </li>
              <li>
                We may also use your Personal Data as: (a) required by subpoena, law, or other legal process; (b)
                necessary to assist law enforcement officials or government enforcement agencies; (c) necessary to
                investigate violations of or otherwise enforce our terms of use, to maintain security of the Platform
                and prevent fraudulent or illegal activity; (d) necessary to protect us from legal action or claims from
                third parties, including you and/or other users; or (e) necessary to protect the legal rights,
                personal/real property, or personal safety of our company, users, employees, and affiliates. We do so
                based on our legitimate interest (to defend and enforce our legal rights and to protect and ensure the
                continuous operation of our Platform).
              </li>
            </ul>
            <p>
              Note that we may process your Personal Data for more than one lawful ground depending on the specific
              purpose for which we are using your data.
            </p>
            {/*<p>
            <strong>
              <em>Promotional Offers </em>
            </strong>
            [Note to Qnergy: please see our comment above regarding marketing. Is this relevant?]
          </p>
          <p>
            You may receive marketing communications from us if you have requested information from us and you have not
            opted out of receiving marketing communications.
          </p>
          <p>
            You can change your contact preferences at any time through your account or by sending us an email with your
            request to: <Link href="mailto:info@qnergy.com">info@qnergy.com</Link>. If you wish to stop receiving
            marketing communications from us, you may ask us to stop sending you marketing communications at any time by
            following the opt-out link on any marketing message sent to you or by{' '}
            <Link href="#bookmark=id.3fwokq0">contacting us</Link>
              .
          </p>*/}
            <p>
              <h3>Cookies and Other Tracking Technologies</h3>
            </p>
            <p>
              Our Platform uses cookies. A cookie is a small piece of data or a text file that is downloaded to your
              computer or mobile device when you access certain websites. Cookies may contain text that can be read by
              the web server that delivered the cookie to you. The text contained in the cookie generally consists of a
              sequence of letters and numbers that uniquely identifies your computer or mobile device; it may contain
              other information as well. By agreeing to accept our use of cookies, you are giving us, and third parties
              we partner with, permission to place, store, and access some or all the cookies described below on your
              computer.
            </p>
            <h4>Types of cookies:</h4>
            <ul>
              <li>
                <strong>Strictly Necessary Cookies</strong>. These cookies are necessary for proper functioning of the
                Platform, such as displaying content, logging in, validating your session, responding to your request
                for services, and other functions.
              </li>
              <li>
                <strong>Performance Cookies</strong>. These cookies collect information about the use of the Platform,
                such as pages visited, traffic sources, users&apos; interests, content management, and other Platform
                measurements.
              </li>
              <li>
                <strong>Functional Cookies</strong>. These cookies enable the Platform to remember a user&apos;s choices
                - such as their language, user name, and other personal choices - while using the Platform. They can
                also be used to deliver services, such as letting a user make a blog post, listen to audio, or watch
                videos on the Platform.
              </li>
              <li>
                <strong>Media Cookies</strong>. These cookies can be used to improve the Platform&apos;s performance and
                provide special features and content. They can be placed by third parties who provide services to us or
                by our company.
              </li>
              {/*<li>
              <strong>&lt;&lt;Advertising or Targeting Cookies</strong>. These cookies are usually placed and used by
              advertising companies to develop a profile of your browsing interests and serve advertisements on other
              Platforms that are related to your interests. You will see less advertising if you disable these
              cookies.&gt;&gt;
            </li>*/}
              <li>
                <strong>Session Cookies</strong>. These cookies allow Platforms to link the actions of a user during a
                browser session. Session cookies permit users to be recognized as they navigate a Platform so that any
                item or page changes they make are remembered from page to page. Session cookies expire after a browser
                session; thus, they are not stored long term.
              </li>
              <li>
                <strong>Persistent Cookies</strong>. These cookies are stored on a user&apos;s device in between browser
                sessions, which allows the user&apos;s preferences or actions across a site (or, in some cases, across
                different sites) to be remembered. Persistent cookies may be used for a variety of purposes, including
                remembering users&apos; choices and preferences when using a Platform or to target advertising to them.
              </li>
            </ul>
            <p>
              Most web browsers can be set to disable the use of cookies. However, if you disable cookies, you may not
              be able to access features on our Platform correctly or at all. Further details on how to disable cookies
              in different web browsers can be found here:
            </p>
            <p>
              Internet Explorer:{' '}
              <Link
                href="http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies"
                inline
                color="green"
              >
                http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies
              </Link>
            </p>
            <p>
              Google Chrome:{' '}
              <Link href="https://support.google.com/chrome/answer/95647?hl=en" inline color="green">
                https://support.google.com/chrome/answer/95647?hl=en
              </Link>
            </p>
            <p>
              Mozilla Firefox:{' '}
              <Link
                href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer#w_cookie-settings"
                inline
                color="green"
              >
                https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer#w_cookie-settings
              </Link>
            </p>
            <p>
              Safari:{' '}
              <Link href="http://help.apple.com/safari/mac/8.0/#/sfri11471" inline color="green">
                http://help.apple.com/safari/mac/8.0/#/sfri11471
              </Link>
            </p>
            <p>
              Microsoft Edge:{' '}
              <Link
                href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                inline
                color="green"
              >
                https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
              </Link>
            </p>
            <p>
              Opera:{' '}
              <Link href="https://help.opera.com/en/latest/web-preferences/" inline color="green">
                https://help.opera.com/en/latest/web-preferences/
              </Link>
            </p>
            {/* <p>
            We may also use a technology called web beacons to collect general information about your use of our
            Platform and your use of special promotions or newsletters. The information we collect by web beacons allows
            us to statistically monitor the number of people who open our emails. Web beacons also help us to understand
            the behavior of our customers, members, and visitors. [Note to Qnergy: do you use such items?]
          </p>
          <p>
            Third parties may use cookies, web beacons, and other storage technologies to collect or receive information
            from our Platform and elsewhere on the internet, and use that information to provide measurement services
            and target ads. [Note to Qnergy: please confirm if this is relevant to the Platform]
          </p> */}
            <p>
              In addition, we use tools that track your interaction with the Platform (e.g. how much time you spend on
              which pages, which links you choose to click, what you do and do not like in the Platform, etc.) so that
              we can better understand our Users&apos; needs and can optimize the Services. These tools use cookies and
              other technologies to collect data on our User&apos;s activity on the Platform and their devices.
            </p>
            <p>
              In addition, our Platform uses Google Analytics to collect information about the use of our Platform.
              Google Analytics collects information from users such as age, gender, interests, demographics, how often
              they visit our Platform, what pages they visit, and what other websites they have used before coming to
              our Platform. We use the information we get from Google Analytics to analyze traffic, remarket our
              products and services to users, improve our marketing, advertising, and to improve our Platform. We have
              enabled Google Analytics advertising features such as remarketing with Google Analytics, Google Display
              Network Impression Reporting, and Google Analytics Demographics and Interest Reporting. For more
              information on how Google collects and processes your data, visit{' '}
              <Link href="https://www.google.com/policies/privacy/partners/" inline color="green">
                https://www.google.com/policies/privacy/partners/
              </Link>
              . You can prevent Google Analytics from using your information by opting out at this link:{' '}
              <Link href="https://tools.google.com/dlpage/gaoptout" inline color="green">
                https://tools.google.com/dlpage/gaoptout
              </Link>
              .
            </p>
            <h3>Third-Party Links</h3>
            <p>
              The Platform may include links to third-party websites, plug-ins and applications. Clicking on those links
              or interacting with such content may allow your data to be shared with the relevant third-party. We do not
              control these third-party websites and are not responsible for their privacy statements; we therefore
              recommend that you read the privacy policy of every website you visit.
            </p>
          </li>
          <li>
            <h2 id="sharing">Sharing Your Personal Data with Third Parties</h2>

            <p>
              We share Personal Data with third party service providers that provide us services in connection with the
              purposes listed above.
            </p>
            <p>
              For example, we may share your applicable Identity Data, Contact Data, Usage Data and Technical Data with
              web analytics providers, email {/* and marketing service providers  */}
              and other vendors that assist us in maintaining the Platform. For additional information, please see the
              description above in the section titled Cookies and Other Tracking Technologies.
            </p>
            <p>
              We may also share your Personal Data with legal and regulatory authorities to the extent required by
              applicable law our professional advisors (e.g. lawyers, accountants) to the extent necessary for the
              provision of their services to us; and third parties to whom we may choose to sell, transfer, or merge our
              business (or parts thereof) or our assets, or parties whose business we wish to acquire.
            </p>
          </li>
          <li>
            <h2 id="minors">Minors</h2>
            <p>
              In order to use the Platform, you must be at least 18 years of age, or older, if otherwise required by the
              laws of the country you reside in. Our Platform is not designed for use by anyone under the age of 18 and
              we do not knowingly collect Personal Data from children under the age of 18.
            </p>
            <p>
              If you are a parent or legal guardian and have concerns about your child&apos;s privacy, or if you believe
              that your child may have provided us with their Personal Data, please contact us using the{' '}
              <Link href="#contact-us" inline color="green">
                Contact Details
              </Link>{' '}
              below. When you approach us as a parent or legal guardian, we may request that you provide certain
              information needed to confirm your identity, in order to prevent malicious removal of account information.
            </p>
          </li>
          <li>
            <h2 id="transfers">International Transfers</h2>
            <p>
              We may transfer your Personal Data to countries outside of the country in which you reside (including
              outside of the European Economic Area (EEA) or the United Kingdom (UK), if applicable), that do not
              necessarily provide the same level of data protection as the country in which you reside and are not
              recognized by the European Commission and/or the applicable UK authority as providing an adequate level of
              data protection.
            </p>
            <p>
              Our transfers of Personal Data outside of the EEA or the UK are done for the performance of a contract or
              implementation of pre-contractual relations with you, based on your consent, or subject to safeguards that
              ensure the protection of your Personal Data, such as standard contractual clauses approved by the European
              Commission and/or the applicable UK authority.
            </p>
          </li>
          <li>
            <h2 id="security">Data Security</h2>
            <p>
              We have put in place reasonable security measures intended to prevent your Personal Data from accidental
              or unlawful loss, use, alteration or disclosure. We also maintain reasonable technical and physical
              safeguards to protect your Personal Data. Unfortunately, we cannot guarantee against the loss or misuse of
              your Personal Data or secure data transmission over the Internet because of its nature.
            </p>
          </li>
          <li>
            <h2 id="retention">Data Retention</h2>
            <p>
              We retain your Personal Data for as long as necessary to fulfill the purposes we collected it for,
              including for legal or reporting requirements.
            </p>
            <p>
              In order to determine the appropriate retention period for Personal Data, we consider the following
              criteria: the volume, nature, and sensitivity of the Personal Data, the potential risk of harm from
              unauthorized use or disclosure of Personal Data, the purposes for which we collect and process Personal
              Data, the timeframe during which we may require such Personal Data in connection with potential
              litigation, and applicable legal requirements.
            </p>
          </li>
          <li>
            <h2 id="rights">Your Rights</h2>
            <p>
              Certain privacy laws, including the GDPR and UK GDPR, provide users with rights related to their Personal
              Data. To the extent that such laws apply to your Personal Data, you may have the following rights:
            </p>
            <ul>
              <li>
                <strong>Request access</strong>. This means that you may receive a copy of the Personal Data we hold
                about you.
              </li>
              <li>
                <strong>Request correction</strong>. This means that you may ask us to correct or complete inaccurate or
                incomplete data that we hold about you.
              </li>
              <li>
                <strong>Request erasure</strong>. This means that you may ask us to delete or remove Personal Data that
                we hold about you. If we are unable to comply with such request, we will notify you of the specific
                legal reasons for our decision.
              </li>
              <li>
                <strong>Object to processing</strong>. This means that you may object to the processing of your Personal
                Data where it is used for direct marketing purposes, or where we are relying on a legitimate interest
                and you feel that such interest is overridden by your rights and freedoms.
              </li>
              <li>
                <strong>Request restriction of processing</strong>. This means that you may ask us to suspend the
                processing of your Personal Data if you believe that such data is inaccurate, if our processing is
                unlawful or if we no longer need to process such data for a particular purpose, but you need us to
                continue to hold the data.
              </li>
              <li>
                <strong>Data Portability. </strong>This means that you may request that we transfer your Personal Data
                to you or to a third party. This right applies where we have obtained and processed the data from you
                based on your consent or where we used the information to perform a contract with you.
              </li>
              <li>
                <strong>Withdraw consent at any time. </strong>This means that you may withdraw your consent where we
                are processing your Personal Data based on your consent.
              </li>
            </ul>
            <p>
              With respect to Personal Data we process as a data processor, please contact the Customer that is the data
              controller with respect to your Personal Data in order to exercise your rights under the GDPR, CCPA, CPRA
              or any other applicable law.
            </p>
            <p>
              If you wish to exercise any of the rights set out above with respect to other processing activities that
              we conduct, please{' '}
              <Link href="#contact-us" inline color="green">
                contact us
              </Link>
              .
            </p>
            <h3>What We May Need From You</h3>
            <p>
              When you approach us with a request to exercise one of your rights, we may request that you provide us
              with information needed to confirm your identity as a security measure to ensure that your Personal Data
              is not disclosed to any person who has no right to receive it.
            </p>
            <h3>Time Limit to Respond</h3>
            <p>
              We try to respond to all legitimate requests within one month or less, if required by law. Where it takes
              us more than one month to respond (for example, where your request is particularly complicated or where
              you have made a number of requests) we will notify you and keep you updated.
            </p>
          </li>
          <li>
            <h2 id="accessibility">Accessibility</h2>
            <p>
              If you have a disability and would like to access this policy in an alternative format, please contact us
              via our{' '}
              <Link href="#contact-us" inline color="green">
                Contact Details
              </Link>{' '}
              listed below.
            </p>
          </li>
          <li>
            <h2 id="ccpa">California Consumer Protection Act and California Privacy Right Act</h2>
            <h3>Scope</h3>
            <p>
              The CCPA and the CPRA (upon its entry into force) provide California residents with specific rights
              regarding their Personal Data. This section applies only to our processing of Personal Data as a
              “business” and to users that are California residents, describes the rights under the CCPA/CPRA and
              explains how to exercise those rights. This section does not apply to our processing of Personal Data as a
              “service provider”, our handling of publicly available information from government records, de-identified
              or aggregated information or other information that is excluded from the CCPA/CPRA&apos;s scope. For the
              purposes of this section, “Personal Data” shall mean “Personal Information” as defined under the
              CCPA/CPRA.
            </p>
            <h3>Personal Information We Collect, Use and Disclose</h3>
            <p>
              We may receive and disclose to third parties for business purposes the following categories of Personal
              Data:
            </p>
            <ul>
              <li>Identifiers, such as name, email address and IP address.</li>
              <li>
                Internet or other electronic network activity information, including, but not limited to, information
                regarding your interaction with the Platform, or advertisement.
              </li>
              <li>
                Personal information categories listed in the California Customer Records statute (Cal. Civ. Code §
                1798.80(e)), such as name, address, telephone number and employment.
              </li>
              <li>
                Professional or employment related information (such as the company you work in and your position).
              </li>
              <li>Inferences drawn from other Personal Data, such as a profile of a person&apos;s preferences.</li>
            </ul>
            <p>
              Information that you provide to us when you contact us at your initiative may contain other categories of
              Personal Data that are not described above.
            </p>
            <p>
              <u>Sources</u>. For information on the sources from which information is collected, please see the section
              in this Policy titled{' '}
              <Link href="#how-we-collect" inline color="green">
                How we collect your Personal Data
              </Link>
              .
            </p>
            <p>
              <u>Disclosures to third parties for business purposes</u>. For information on the categories of third
              parties to whom we disclose for business purposes, please see the section in this Privacy Policy titled{' '}
              <Link href="#sharing" inline color="green">
                Sharing your Personal Data with third parties
              </Link>
              .
            </p>
            <p>
              We do not sell Personal Data to third parties for monetary value. However, the term “sale” is defined
              broadly under the CCPA/CPRA. To the extent that “sale” under the CCPA/CPRA is interpreted to include
              interest based advertising or other data uses described in the{' '}
              <Link href="#how-we-collect" inline color="green">
                How we Collect Your Personal Data
              </Link>{' '}
              section above, we will comply with applicable law as to those activities.
            </p>
            <p>
              <u>Purposes</u>. For information on the business or commercial purposes for collection, use and sharing of
              Personal Data, please see the section in this Policy titled{' '}
              <Link href="#how-we-use" inline color="green">
                How we use your Personal Data
              </Link>
              .
            </p>
            <p>
              <u>Retention</u>. For information on the retention periods of your Personal Data, please see the section
              in this Policy titled{' '}
              <Link href="#retention" inline color="green">
                Data Retention
              </Link>
              .
            </p>
            <p>
              This section describes our current practices and our practices during the 12 months preceding the “Last
              Updated” date of this Policy.
            </p>
            <h3>Your California Privacy Rights under CCPA/CPRA</h3>
            <h4>Right to Know and Data Portability </h4>
            <p>
              You have the right to request certain information regarding our collection and use of Personal Data, and
              disclosure of Personal Data to third parties.
            </p>
            <h4>Right to Deletion and Rectification </h4>
            <p>
              You have the right to request that we delete any of your Personal Data that we collected from you and
              retained or that we correct inaccurate Personal Data we retained. We shall use commercially reasonable
              efforts to correct such inaccurate Personal Data. We may deny your deletion request in certain defined
              cases, for example, if retaining the data is necessary for us or our service providers to perform our
              contract with you, detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
              activity, debug products, exercise free speech, engage in research, enable internal uses or comply with
              applicable laws and legal obligations.
            </p>
            <p>
              Additionally, if you are a California resident under the age of eighteen (18) and a registered user of the
              Services, California Business and Professions Code Section 22581 permits you to remove content or Personal
              Data you have publicly posted. If you wish to remove such content or Personal Data and you specify which
              content or Personal Data you wish to be removed, we will do so in accordance with applicable law. Please
              be aware that after removal you may not be able to restore removed content or Personal Data. In addition,
              such removal does not ensure complete or comprehensive removal of the content or Personal Data you have
              posted, and there may be circumstances in which the law does not require us to enable removal of content.
            </p>
            <h4>Our California Do Not Track Policy</h4>
            <p>
              Some browsers transmit “do-not-track” signals to Platforms. Because of differences in how browsers
              incorporate and activate this feature, it is not always clear whether users intend for these signals to be
              transmitted, or whether they even are aware of them. We currently do not take action in response to these
              signals.
            </p>
            <h4>Right to Opt-out of Sale of Personal Information (“Do Not Sell”/”Do Not Share”).</h4>
            <p>You have the right to opt-out of our sale or sharing of your Personal Data.</p>
            <h4>Non-discrimination</h4>
            <p>
              Limiting the use of your Personal Data may affect features and uses that rely on that data. You have a
              right not to receive discriminatory treatment by us for the exercise of such privacy rights as conferred
              by the CCPA/CPRA. Unless permitted by the CCPA/CPRA, we will not: deny you goods or services, provide you
              a different level or quality of goods or services or suggest that you may receive a different price or
              rate for goods or services or a different level or quality of goods or services.
            </p>
            <h4>Automated decision-making</h4>
            <p>
              You will not be subject to decisions that will have a significant impact on you based solely on automated
              decision-making, unless we have notified you and allowed you to opt-out.
            </p>
            <h4>Submitting Requests regarding Your Rights</h4>
            <p>
              To make a request regarding your rights as detailed above, please submit a request using one of the
              methods in the{' '}
              <Link href="#contact-us" inline color="green">
                Contact Details
              </Link>{' '}
              section below.
            </p>
            <p>
              You may use a legally authorized person on your behalf to submit a request, if you provide a signed
              written permission to such person. Your request to know or delete must provide sufficient information that
              allows us to reasonably verify you are the person about whom we collected Personal Data or an authorized
              representative and should describe your request with sufficient detail that allows us to properly
              understand, evaluate, and respond to it.
            </p>
            <p>
              Please note that we will respond to one request per customer each year, unless otherwise required by law.
            </p>
            <p>
              California&apos;s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Platform that
              are California residents to request certain information regarding our disclosure of Personal Data to third
              parties for their direct marketing purposes. To make such a request, please contact us using one of the
              methods in the{' '}
              <Link href="#contact-us" inline color="green">
                Contact Details
              </Link>{' '}
              section below.
            </p>
          </li>
          <li>
            <h2 id="contact-us">Contact Details</h2>
            <p>If you have any questions or concerns, please contact us at:</p>
            <p>
              Email:{' '}
              <Link href="mailto:info@qnergy.com" inline color="green">
                info@qnergy.com
              </Link>
            </p>
            <p>Postal address: 300 W 12th St, Ogden, Utah 84404, United States</p>
            <p>
              Telephone number:{' '}
              <Link href={`tel:${process.env.REACT_APP_SALES_TEL?.replaceAll('-', '')}`} inline color="green">
                {process.env.REACT_APP_SALES_TEL}
              </Link>
            </p>
            <p>
              You have the right to make a complaint at any time to data protection authorities. We would, however,
              appreciate the chance to address your concerns before you approach a data protection authority, so please
              contact us in the first instance.
            </p>
          </li>
          <li>
            <h2 id="changes">Changes to this Privacy Policy</h2>
            <p>
              We reserve the right to change this Privacy Policy at any time. If we do so, we will post an updated
              version of this Privacy Policy so that our users and customers are always aware of what information we
              collect, use, and disclose. Your continued use of our Platform and services after any change to this
              Privacy Policy will constitute your acceptance of such change.
            </p>
          </li>
        </ol>
        <p>
          <i>{`Last Updated ${new Date(process.env.REACT_APP_PRIVACY_UPDATED ?? '').toLocaleDateString()}`}</i>
        </p>
      </Card>
    </div>
  );
}
