import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Filter,
  FilterKey,
  Form,
  FormContextData,
  FormGroup,
  FormSave,
  Input,
  InputType,
  MenuItem,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
  Text,
  TextType,
} from '@/ComponentLibrary/src';

import { RadioButton } from '../../../components/RadioButton';
import { RadioButtonSize } from '../../../components/RadioButton/index.types';
import { useMobile } from '../../../hooks';
import { calculateDateRange, luxonUnits, QUICK_RANGE_MINUTES_OPTIONS, QUICK_RANGE_OPTIONS } from '../Data/util';
import { dateTimeFormat, getFilterInitVals } from './util';

export interface ExportDialogProps {
  filter: FilterKey[];
  handleLoadOptions: (
    key: string,
    searchTerm?: string,
    onlySelected?: boolean,
  ) => Promise<{ data: MenuItem[]; count: number } | undefined>;
  onClose: () => void;
  onExport: (exportFilters: FilterKey[]) => void;
}

interface ExportValues extends Record<string, unknown> {
  to: string;
  from: string;
}

export default function ExportDialog({ filter, handleLoadOptions, onClose, onExport }: ExportDialogProps): JSX.Element {
  const { t } = useTranslation(['events', 'translation']);
  const isMobile = useMobile();
  const [initVal] = useState<ExportValues>(getFilterInitVals(filter));
  const handleExport = async ({ to, from }: ExportValues): Promise<void> => {
    const fromForm = DateTime.fromFormat(from, dateTimeFormat).toJSDate();
    const toForm = DateTime.fromFormat(to, dateTimeFormat).toJSDate();
    const timeFilter = filter.find(({ id }) => id === 'ts');

    if (timeFilter) {
      timeFilter.selected = true;
      timeFilter.range = { from: fromForm, to: toForm };
    }

    onExport(filter);
    onClose();
  };

  const handleChangeDates = (_?: string | number, formContext?: FormContextData) => {
    formContext?.updateFormState('quickRange', '');
  };

  const handleQuickRange = (newValue?: string | string[], formContext?: FormContextData) => {
    if (!newValue || Array.isArray(newValue)) return;
    const units = luxonUnits[newValue.match(/[a-z]/i)?.[0] as keyof typeof luxonUnits];
    const value = parseInt(newValue.match(/[0-9]*/)?.[0] ?? '24');
    const { to, from } = calculateDateRange({ units, value });

    formContext?.updateFormState('from', DateTime.fromJSDate(from as Date).toFormat(dateTimeFormat));
    formContext?.updateFormState('to', DateTime.fromJSDate(to as Date).toFormat(dateTimeFormat));
  };

  return (
    <Modal modalClassName="min-w-[98%] lg:min-w-[75%] 2xl:min-w-[60%] z-901 drop-shadow-xl" onClose={onClose}>
      <Form<ExportValues>
        initialValues={initVal}
        onSubmit={handleExport}
        onCancel={onClose}
        className="h-full flex flex-col overflow-hidden"
      >
        <ModalTitle>
          <Text type={TextType.h4} data-testid="data-modal-title" data-pwid="data-modal-title">
            {t('export_events')}
          </Text>
        </ModalTitle>
        <ModalContent>
          {filter.filter(({ id }) => id !== 'ts').some((item) => item.selected) && (
            <div className="flex flex-col gap-2 pb-4">
              <Text type={TextType.h5} textClassName="font-normal">
                {t('filter')}
              </Text>
              <Filter
                filter={filter.filter(({ id }) => id !== 'ts')}
                onChangeFilter={() => {
                  // noop
                }}
                loadOptions={handleLoadOptions}
                readonly
              />
            </div>
          )}
          <Text className="mb-4" type={TextType.h5} textClassName="font-normal">
            {t('date_range')}
          </Text>
          <FormGroup<string> id="quickRange" label="" onChange={handleQuickRange} row>
            <div className="flex flex-row flex-wrap gap-3">
              {[...QUICK_RANGE_MINUTES_OPTIONS, ...QUICK_RANGE_OPTIONS].map((opt, idx) => {
                return <RadioButton id={opt} label={opt} value={opt} key={idx} size={RadioButtonSize.SMALL} />;
              })}
            </div>
          </FormGroup>
          <div className={`mt-4 flex ${isMobile ? 'flex-col' : 'flex-row items-center gap-2'}`}>
            <Input
              type={InputType.datetimeLocal}
              label={t('From')}
              id="from"
              data-testid="from"
              onChange={handleChangeDates}
              step={60}
              required
            />
            <Input
              type={InputType.datetimeLocal}
              label={t('To')}
              id="to"
              data-testid="to"
              onChange={handleChangeDates}
              step={60}
              required
            />
            <Text>({Intl.DateTimeFormat().resolvedOptions().timeZone})</Text>
          </div>
        </ModalContent>
        <ModalActions>
          <FormSave saveLabel={t('Export')} submitBtnDisable={false} />
        </ModalActions>
      </Form>
    </Modal>
  );
}
