import React from 'react';

import { Pill, PillVariant, Text, TextType } from '../ComponentLibrary/src';
import { Fault, FaultSeverity } from '../types';
import { getFaultTooltip } from './System/util';

export default function ActiveAlert({
  fault,
  filled,
  small,
  advancedRead,
}: {
  fault: Fault;
  filled?: boolean;
  small?: boolean;
  advancedRead?: boolean;
}): JSX.Element {
  let variant = PillVariant.secondary;
  if (
    [FaultSeverity.serviceRequired, FaultSeverity.userClearableLock, FaultSeverity.autoSystemRestart].includes(
      fault.severity as FaultSeverity,
    )
  ) {
    variant = PillVariant.danger;
  } else if (fault.severity === FaultSeverity.warning) {
    variant = PillVariant.warning;
  }

  return (
    <>
      <div>
        <Pill className="w-fit" variant={variant}>
          {fault.faultCode.toString()}
        </Pill>
      </div>
      <div data-tip={getFaultTooltip(fault, advancedRead)} data-html={true}>
        <Text
          wrap
          overrideColor={filled}
          type={small ? TextType.custom : undefined}
          className={`${filled ? 'text-white' : ''} ${small ? 'text-sm' : ''}`}
        >
          {fault.description}
        </Text>
      </div>
    </>
  );
}
