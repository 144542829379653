import React, { useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';

import { Distributor, DragItem, Site as ISite } from '../../../types';
import { debouncedFunction } from '../util';
import SiteListItem from './SiteListItem';

interface DroppableSiteProps {
  site: ISite;
  parentDistributors: Distributor[];
  onDrop: (item: DragItem, destination: string) => void;
  refreshData: () => void;
  orgId?: string;
  setSelectedSite: (site: ISite) => void;
}

export default function DroppableSite({
  site,
  parentDistributors,
  onDrop,
  refreshData,
  orgId,
  setSelectedSite,
}: DroppableSiteProps): JSX.Element {
  const [{ isOver }, drop] = useDrop(() => {
    return {
      accept: ['System'],
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
        };
      },
      drop: (item: DragItem) => {
        site.orgId = orgId || '';
        item.site = site;
        onDrop(item, 'site');
      },
    };
  });

  const debouncedOnDragOverSite = useMemo(() => {
    return debouncedFunction<ISite>(setSelectedSite);
  }, [setSelectedSite]);

  useEffect(() => {
    if (isOver) {
      debouncedOnDragOverSite(site);
    } else {
      debouncedOnDragOverSite.cancel();
    }
  }, [isOver, debouncedOnDragOverSite, site]);

  return (
    <div
      ref={drop}
      id={site._id}
      className={`flex flex-row border-b border-gray-300 cursor-pointer highlight-color h-11 p-2 ${
        isOver ? 'bg-gray-200' : 'bg-gray-50'
      }`}
      onClick={() => setSelectedSite(site)}
      data-pwid={site.name}
    >
      <SiteListItem
        site={site}
        textColor="text-blue-800"
        hideActions={false}
        parentDistributors={parentDistributors}
        refreshData={refreshData}
      />
    </div>
  );
}
