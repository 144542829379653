import { Dispatch, SetStateAction } from 'react';

export type OptionId = string | number | boolean | null;

export interface Sort {
  [key: string]: number;
}

export interface IdRange {
  from?: OptionId;
  to?: OptionId;
}
export interface Range {
  from?: number;
  to?: number;
}

export interface DateRange {
  from?: Date;
  to?: Date;
}

export interface MenuItem {
  key?: string;
  id: OptionId;
  label: string | number | null;
  selected?: boolean;
  openOnSelect?: boolean;
}

export enum Mode {
  multi = 'multi',
  range = 'range',
  single = 'single',
  dateRange = 'dateRange',
}

export interface MultiKey extends MenuItem {
  mode?: Mode.multi;
  selectedValues?: MenuItem[];
  exclusionMode?: boolean;
  count?: number;
  textSearch?: string;
  textSearchId?: string;
  range?: IdRange;
  searchable?: boolean;
}

export interface SingleKey extends MenuItem {
  mode?: Mode.single;
  selectedValues?: MenuItem[];
  exclusionMode?: boolean;
  textSearch?: string;
  textSearchId?: string;
  searchable?: boolean;

  // disallowed properties
  range?: undefined;
  count?: undefined;
}
export interface RangeKey extends MenuItem {
  mode?: Mode.range | Mode.dateRange;
  range?: Range | DateRange;

  // disallowed properties
  selectedValues?: undefined;
  exclusionMode?: undefined;
  textSearch?: undefined;
  textSearchId?: undefined;
  searchable?: undefined;
}

export type FilterKey = MultiKey | SingleKey | RangeKey;

export type LoadOptions = (
  itemId: string,
  searchTerm?: string,
  onlySelected?: boolean,
) => Promise<{
  data: MenuItem[];
  count: number;
} | void>;

export interface FilterProps {
  className?: string | string[];
  filter: FilterKey[];
  textSearch?: string;
  autofocusSearch?: boolean;
  loadOptionLabels?: (filter: FilterKey[]) => Promise<FilterKey[]>;
  loadOptions: LoadOptions;
  onChangeFilter: (filter: FilterKey[]) => void;
  // This is debounced by the component
  onChangeTextSearch?: (term: string) => void;
  onLoading?: Dispatch<SetStateAction<boolean>>;
  onChangeFilterEditing?: Dispatch<SetStateAction<boolean>>;
  readonly?: boolean;
}
