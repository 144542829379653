export enum Side {
  left = 'left',
  right = 'right',
}

export enum Direction {
  up = 'up',
  down = 'down',
  left = 'left',
  right = 'right',
}

export enum FlexDirection {
  row = 'row',
  column = 'column',
}
