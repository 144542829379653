import { ChartDataset, Color, ScriptableContext } from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';

import { MetricChartProps } from './index.types';
import { getChartOptions, placeholderData, placeholderLabels } from './util';

export const LineChart: React.FC<MetricChartProps> = ({ chartData, lineColor, showPlaceholder }) => {
  const firstDataset: ChartDataset<'line'> = {
    data: [],
    tension: 0.3,
    borderColor: lineColor,
  };
  const data = {
    labels: [''],
    datasets: [firstDataset],
  };

  if (!chartData) {
    if (showPlaceholder) {
      data.labels = placeholderLabels;
      firstDataset.data = placeholderData;
      firstDataset.borderColor = (dataset: ScriptableContext<'line'>) => {
        const ctx = dataset.chart.ctx;

        const bg = ctx.createLinearGradient(100, 0, 0, 0);
        bg.addColorStop(0, 'rgba(209, 213, 226, 0.15)');
        bg.addColorStop(1, 'rgba(209, 213, 226, 0.40)');

        return bg as Color;
      };
    }
  } else {
    data.labels = chartData.x;
    firstDataset.data = chartData.y;
  }
  // Update options with props
  const options = getChartOptions(showPlaceholder);

  return <Line data={data} options={options} />;
};
