import { ChevronDownIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/outline';
import { LocationMarkerIcon, OfficeBuildingIcon, TruckIcon } from '@heroicons/react/solid';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';

import { downloadSystems, fetchSystemFieldKeys } from '../adapters/api/systems';
import {
  Button,
  Dropdown,
  Filter,
  FilterKey,
  HeroIcons,
  List,
  MenuItem,
  Pagination,
  Position,
  Side,
  Text,
  TextType,
  Variant,
} from '../ComponentLibrary/src';
import { usePageContext } from '../components/Page';
import SubscriptionExpirationIndicator from '../components/System/ExpirationIndicator/SubscriptionExpirationIndicator';
import StatusIndicator from '../components/Systems/StatusIndicator';
import { SystemItem } from '../components/Systems/SystemItem';
import { AuthContext } from '../context/Auth';
import { SystemsContext, useSystemsContextValue } from '../context/Systems';
import { useMobile, usePaginationQuery, useSetDocumentTitle } from '../hooks';
import cap3m from '../image/CAP3M-thumbnail-cc.png';
import cap3Nano from '../image/CAP3Nano-thumbnail-cc.png';
import cap3Nano2 from '../image/CAP3Nano2-thumbnail-cc.png';
import cap3V from '../image/CAP3V-thumbnail-cc.png';
import capPico from '../image/CAPPico-thumbnail.png';
import pg from '../image/PG5650-thumbnail-cc.png';
import { CapSystem, GenSystem } from '../types';
import { loadOptionLabels } from '../util';
import { defaultSystemFilter } from '../util/constants';
import { usePolling } from '../WebUtils';

const defaultCountPerPage = 20;

const defaultColumns = [
  {
    id: 'stats.state',
    label: 'State',
  },
  {
    id: 'sysId',
    label: 'Serial No.',
  },
  {
    id: 'site.name',
    label: 'Site',
  },
  {
    id: 'org.name',
    label: 'Organization',
  },
  {
    id: 'distributor.name',
    label: 'Distributor',
  },
  {
    id: 'lastSeen',
    label: 'Last Seen',
  },
  {
    id: 'subscriptionStatus',
    label: 'Subscription',
  },
  {
    id: 'stats.systemHours',
    label: 'Runtime (hrs)',
  },
  {
    id: 'latest.userLoadP',
    label: 'Load (kW)',
  },
  {
    id: 'fpcConfig.systemType',
    label: 'Product Type',
  },
  {
    id: 'latest.accAir',
    label: 'Total Air',
  },
  {
    id: 'stats.flow1Hours',
    label: 'Flow',
  },
  {
    id: 'latest.pressure',
    label: 'Pressure',
  },
];

const getThumbnail = (system: GenSystem | CapSystem) => {
  let thumbnail: string | undefined;
  if (
    (system as GenSystem)?.model === 'PowerGen 1200' ||
    (system as GenSystem)?.model === 'PowerGen 5650' ||
    (system as GenSystem)?.model === 'PowerGen'
  ) {
    thumbnail = pg;
  } else if ((system as CapSystem)?.model === 'CAP M') {
    thumbnail = cap3m;
  } else if ((system as CapSystem)?.model === 'CAP Nano2') {
    thumbnail = cap3Nano2;
  } else if ((system as CapSystem)?.model === 'CAP V3') {
    thumbnail = cap3V;
  } else if ((system as CapSystem)?.model === 'CAP V5') {
    thumbnail = cap3V;
  } else if ((system as CapSystem)?.model === 'CAP V10') {
    thumbnail = cap3V;
  } else if ((system as CapSystem)?.model === 'CAP Nano') {
    thumbnail = cap3Nano;
  } else if ((system as CapSystem)?.model === 'CAP Pico') {
    thumbnail = capPico;
  }
  return thumbnail;
};

function Systems(): JSX.Element {
  useSetDocumentTitle('Systems');
  const sortButtonRef = useRef<HTMLButtonElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [showSort, setShowSort] = useState(false);
  const {
    sort,
    setSort,
    order,
    setOrder,
    page,
    setPage,
    pageSize,
    setPageSize,
    filter,
    setFilter,
    textSearch,
    setTextSearch,
  } = usePaginationQuery({
    sort: 'stats.state',
    order: 1,
    page: 1,
    pageSize: defaultCountPerPage,
    filter: defaultSystemFilter,
  });
  const [loading, setLoading] = useState(true);
  const { systemsSummary, getSystems } = useContext(SystemsContext);
  const { isDistributor } = useContext(AuthContext);
  const [systemFilterLoading, setSystemFilterLoading] = useState(false);
  const [editingFilter, setEditingFilter] = useState(false);
  const [pageChangedManually, setPageChangedManually] = useState(false);
  const isMobile = useMobile();
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const { t } = useTranslation('systems');
  useEffect(() => {
    setTitle('Systems');
    setBreadcrumbs();
    setScrollable(false);

    return () => {
      setColumns(defaultColumns);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDistributor() && columns.find((col) => col.id === 'distributor.name')) {
      setColumns(columns.filter((col) => col.id !== 'distributor.name'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  // Re-wire tooltips
  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
  });

  /* REMOVED for now per https://qnergy.atlassian.net/browse/DASH-600
  useEffect(
    function updateColumnsForSystemTypes() {
      fetchSystemFieldKeys({ filterKeys: filter, key: 'productFamily', searchTerm, forMap: false }).then((res) => {
        const list = res as { data: MenuItem[]; count: number };
        const productFamilyFilter = filter.find((f) => {
          return f.id === 'productFamily';
        });
        let tempColumns = columns;
        if (
          list.data.some((sys) => sys.id === 'PowerGen') &&
          !productFamilyFilter?.selectedValues?.some((filter) => filter.id === 'CAP3') &&
          !columns.some((col) => ['stats.systemHours', 'latest.userLoadP', 'fpcConfig.systemType'].includes(col.id))
        ) {
          tempColumns = [
            ...tempColumns,
            {
              id: 'stats.systemHours',
              label: 'Runtime (hrs)',
            },
            {
              id: 'latest.userLoadP',
              label: 'Load (kW)',
            },
            {
              id: 'fpcConfig.systemType',
              label: 'Product Type',
            },
          ];
          setColumns(tempColumns);
        }
        if (
          list.data.some((sys) => sys.id === 'CAP3') &&
          !productFamilyFilter?.selectedValues?.some((filter) => filter.id === 'PowerGen') &&
          !columns.some((col) => ['latest.accAir', 'stats.flow1Hours', 'latest.pressure'].includes(col.id))
        ) {
          tempColumns = [
            ...tempColumns,
            {
              id: 'latest.accAir',
              label: 'Total Air',
            },
            {
              id: 'stats.flow1Hours',
              label: 'Flow',
            },
            {
              id: 'latest.pressure',
              label: 'Pressure',
            },
          ];
          setColumns(tempColumns);
        }
        if (
          systemsSummary?.systems &&
          systemsSummary?.systems?.length > 0 &&
          !tempColumns.some((col) => {
            return col.id === sort;
          })
        ) {
          setOrder(1);
          setSort('stats.state');
          setShowSort(false);
        }
      });
    },
    [columns, systemsSummary?.systems],
  );*/

  const getSortParams = useCallback(() => {
    return {
      ...(sort ? { [`${sort}`]: order } : {}),
      ...(sort === 'stats.state' ? { lastSeen: -order } : {}),
      sysId: sort === 'sysId' ? order : 1,
    };
  }, [sort, order]);

  const updateSystems = useCallback(async (): Promise<void> => {
    return getSystems({
      pageChangedManually,
      textSearch,
      filterKeys: filter,
      sort: getSortParams(),
      countPerPage: pageSize,
      pageNumber: page,
      count: true,
      siteExists: false,
      project: [
        'sysId',
        'site._id',
        'site.name',
        'org._id',
        'org.name',
        'distributor._id',
        'distributor.name',
        'fpcConfig.systemType',
        'stats.state',
        'stats.systemHours',
        'stats.activeFaults',
        'stats.flow1Hours',
        'latest.pressure',
        'latest.accAir',
        'latest.userLoadP',
        'latest.ts',
        'lastSeen',
        'subscriptionStatus',
        'subscriptionExpirationDate',
        'commissionDate',
        'shipDate',
        'systemDescription',
        'model',
        'productFamily',
      ],
    }).then(() => {
      // setColumns(defaultColumns);
      setLoading(false);
      setPageChangedManually(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, getSortParams, page, pageChangedManually, pageSize, textSearch]);

  // reset the polling function when filter/pagination changes
  usePolling(() => {
    if (!editingFilter) {
      return updateSystems();
    }
  }, [sort, page, pageSize, order, filter, textSearch, editingFilter, updateSystems]);

  // show loading and scroll to top of table when filter/pagination changes
  useEffect(() => {
    setLoading(true);
    tableContainerRef.current?.scrollTo({
      top: 0,
      left: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, page, pageSize, order, filter]);

  useEffect(() => {
    if (systemFilterLoading) setLoading(true);
  }, [systemFilterLoading]);

  const handleChangePageNumber = (newPageNumber: number) => {
    setPage(newPageNumber);
    setPageChangedManually(true);
  };

  const handleExport = () => {
    // TODO: do we want to only export the columns that are visible (based on screen size)?
    downloadSystems(filter, getSortParams(), textSearch);
  };

  const handleUpdateFilter = useCallback(
    (key: string, value: MenuItem) => {
      const newFilter = filter.map((item: FilterKey) => {
        if (item.id === key) {
          if (
            !item.selectedValues?.find((selectedValue: MenuItem) => {
              selectedValue.id === value.id && selectedValue.selected;
            })
          ) {
            if (!item.selectedValues) item.selectedValues = [];
            item.selectedValues.push({
              id: value.id,
              label: value.label,
              selected: true,
            });
            item.selected = true;
            item.exclusionMode = false;
            item.openOnSelect = false;
            item.range = undefined;
          }
        }
        return item;
      });

      setFilter(newFilter);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  );

  const actions = isMobile ? (
    <div className="flex gap-2 items-center">
      <Text data-pwid="systems-records-count">{systemsSummary?.count || 0} total</Text>
      <Button
        icon={HeroIcons.DownloadIcon}
        className="w-max"
        variant={Variant.secondaryFilled}
        onClick={handleExport}
        data-pwid="systems-download-button"
      />
    </div>
  ) : (
    <div className="flex flex-row gap-4 items-center">
      <Dropdown
        className="relative"
        data-pwid="systems-filter-dropdown"
        childRef={sortButtonRef}
        show={showSort}
        onClose={() => setShowSort(false)}
        onOpen={() => setShowSort(true)}
        position={Position.bottomLeft}
        content={
          <div className="flex flex-col text-blue-800">
            <div className={`flex justify-between p-2 cursor-pointer hover:bg-gray-100`}>
              <Text
                overrideColor
                className="text-gray-400 hover:text-gray-300 italic"
                onClick={() => {
                  setOrder(1);
                  setSort('stats.state');
                  setShowSort(false);
                }}
                data-pwid="systems-reset-filter"
              >
                Reset
              </Text>
            </div>
            {columns.map((col) => (
              <div
                key={col.id}
                className={`flex justify-between p-2 cursor-pointer${
                  sort === col.id ? ' bg-gray-200' : ' hover:bg-gray-100'
                }`}
                onClick={() => {
                  setSort(col.id);
                  setShowSort(false);
                }}
              >
                <Text overrideColor className="hover:text-blue-400">
                  {col.label}
                </Text>
                {sort === col.id &&
                  (order === 1 ? (
                    <SortAscendingIcon
                      className="h-6 w-6 hover:text-blue-400"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOrder(-1);
                      }}
                    />
                  ) : (
                    <SortDescendingIcon
                      className="h-6 w-6 hover:text-blue-400"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOrder(1);
                      }}
                    />
                  ))}
              </div>
            ))}
          </div>
        }
      >
        <Button
          className="!min-w-[13rem]"
          ref={sortButtonRef}
          variant={Variant.secondary}
          icon={order === 1 ? HeroIcons.SortAscendingIcon : HeroIcons.SortDescendingIcon}
          iconSide={Side.left}
          alignment="left"
          onClickIcon={() => setOrder(order === 1 ? -1 : 1)} // -order doesn't work because TS complains
        >
          <div className="flex gap-4 flex-1 justify-between">
            {columns.sort((a, b) => a.label.localeCompare(b.label)).find((col) => col.id === sort)?.label ?? '?'}
            <ChevronDownIcon className="h-6 w-6 text-blue-50" />
          </div>
        </Button>
      </Dropdown>
      <Button
        icon={HeroIcons.DownloadIcon}
        className="w-max"
        variant={Variant.secondaryFilled}
        onClick={handleExport}
        data-pwid="systems-download-button"
      >
        Export
      </Button>
    </div>
  );
  const filterComponent = (
    <div className="flex">
      <Filter
        filter={filter}
        onChangeFilter={setFilter}
        loadOptions={(key, textSearch, onlySelected) =>
          fetchSystemFieldKeys({ filterKeys: filter, key, searchTerm: textSearch, forMap: false, onlySelected })
        }
        onChangeTextSearch={setTextSearch}
        onLoading={setSystemFilterLoading}
        loadOptionLabels={loadOptionLabels}
        onChangeFilterEditing={setEditingFilter}
        textSearch={textSearch}
      />
      {actions}
    </div>
  );

  return isMobile ? (
    <div ref={tableContainerRef} className="flex-1 flex flex-col gap-2 p-2 overflow-y-auto overflow-x-hidden mb-12">
      {filterComponent}
      <List
        items={systemsSummary?.systems?.map((system) => {
          const thumbnail = getThumbnail(system);

          return {
            thumbnail: <img src={thumbnail}></img>,
            indicator: <StatusIndicator system={system} />,
            indicator2: (
              <SubscriptionExpirationIndicator
                status={system.subscriptionStatus}
                expirationDate={system.subscriptionExpirationDate as string}
              />
            ),
            title: `${system.sysId}`,
            description: `${system.systemDescription || ''}`,
            subtitle: `${(system as GenSystem).fpcConfig?.systemType || ''}`,
            properties: (
              <span className="flex-1 flex flex-row text-blue-800 gap-1 items-center w-full overflow-hidden flex-wrap">
                <span className="flex flex-row gap-1 items-center justify-start overflow-hidden">
                  <OfficeBuildingIcon className="w-4 h-4" />
                  <Text className="whitespace-nowrap overflow-hidden w-full">{system.org?.name ?? 'N/A'}</Text>
                </span>
                <span className="flex flex-row gap-1 items-center justify-start overflow-hidden">
                  <LocationMarkerIcon className="w-4 h-4" />

                  <Text className="whitespace-nowrap overflow-hidden w-full">{system.site?.name ?? 'N/A'}</Text>
                </span>
                <span className="flex flex-row gap-1 items-center justify-start overflow-hidden">
                  <TruckIcon className="w-4 h-4" />

                  <Text className="whitespace-nowrap overflow-hidden w-full">{system.distributor?.name ?? 'N/A'}</Text>
                </span>
              </span>
            ),
            linkUrl: `/systems/${system.sysId}`,
            state: {
              breadcrumb: {
                text: 'Systems',
                href: '/systems',
              },
            },
            className: 'bg-white',
            'data-pwid': `systems-list-item-${system.sysId}`,
          };
        })}
        loadingCount={loading ? 10 : undefined}
      />
      <Pagination
        className="absolute bottom-0 left-0 right-0 z-900"
        pageNumber={page}
        totalCount={systemsSummary?.count || 0}
        countPerPage={pageSize}
        onChangePageNumber={handleChangePageNumber}
        mobile
        data-pwid="systems-pagination"
      />
    </div>
  ) : (
    <div className="flex-1 m-4 flex flex-col gap-4 overflow-hidden relative">
      {filterComponent}
      <div
        ref={tableContainerRef}
        className={`flex-1 ${!loading ? 'overflow-y-auto' : 'overflow-hidden'} flex flex-col bg-gray-50 mb-14`}
      >
        {loading ? (
          <Skeleton count={Math.min(pageSize, 50)} height={42} containerClassName="flex-1 px-2" className="my-2" />
        ) : (
          systemsSummary?.systems?.map((system) => (
            <SystemItem
              key={system.sysId}
              system={system}
              lastSeenTs={system.lastSeen}
              latestTs={system.latest?.ts}
              userLoadP={(system as GenSystem).latest?.userLoadP}
              totalAirCfm={(system as CapSystem).latest?.accAir}
              lastHrFlow={(system as GenSystem).stats?.flow1Hours}
              latestPressure={(system as CapSystem).latest?.pressure}
              thumbnail={getThumbnail(system)}
              onUpdateFilter={handleUpdateFilter}
              data-pwid={`systems-list-item-${system.sysId}`}
            />
          ))
        )}
        {!loading && !systemsSummary?.systems.length && (
          <div className="flex items-center justify-center h-full">
            <Text type={TextType.h4} overrideColor className="text-red-600">
              {t('no_systems_found')}
            </Text>
          </div>
        )}
        <Pagination
          className="max-w bg-blue-100 h-14 absolute bottom-0"
          pageNumber={page}
          countPerPage={pageSize}
          totalCount={systemsSummary?.count ?? 0}
          onChangeCountPerPage={setPageSize}
          onChangePageNumber={setPage}
          loading={loading}
          data-pwid="systems-pagination"
        />
      </div>
    </div>
  );
}

export default function SystemsContainer(): JSX.Element {
  const systemsContextValue = useSystemsContextValue();

  return (
    <SystemsContext.Provider value={systemsContextValue}>
      <Systems />
    </SystemsContext.Provider>
  );
}
