import React from 'react';

import { Card, Text, TextType } from '../../ComponentLibrary/src';
import { System } from '../../types';
import { getLabel } from './constants';

interface Props {
  system?: System;
  isLoading: boolean;
}

export default function InstallDescription({ system, isLoading }: Props): JSX.Element {
  if (isLoading || !system?.installDescription) return <></>;

  return (
    <Card className="flex flex-col gap-2" data-pwid="system-install-description-card">
      <Text type={TextType.h3}>{getLabel('installDescription')}</Text>
      <Text className="w-full" wrap>
        {system?.installDescription || ''}
      </Text>
    </Card>
  );
}
