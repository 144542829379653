import { JsonApiQuery, queryToUrl, Sort } from '../../JsonApi/src';
import { Organization } from '../../types';
import { Method } from '../../util/types';
import Api from '.';

interface AssetsResponse {
  orgs: Organization[];
  count: number;
}

export async function fetchNestedAssets(options?: {
  sort?: Sort;
  countPerPage?: number;
  pageNumber?: number;
  project?: string[];
  searchTerm?: string;
  distributorId?: string;
  count?: boolean;
}): Promise<AssetsResponse | void> {
  const query: JsonApiQuery = {};
  if (options?.project) query.project = options.project;
  if (options?.sort) query.sort = options.sort;

  if (options?.countPerPage && options?.pageNumber) {
    query.page = {
      number: options?.pageNumber,
      size: options?.countPerPage,
    };
  }

  const params = query ? queryToUrl(query) : {};

  if (options?.searchTerm) {
    params.textSearch = `${options?.searchTerm}`;
  }

  if (options?.count) {
    params.count = options?.count;
  }

  return Api.request({ method: Method.get, path: 'assets/nested', params }).then((response) => {
    return response as AssetsResponse;
  });
}
