import { JsonApiQuery, queryToUrl } from '../../JsonApi/src';
import { UnsubscribeSelection } from '../../pages/Unsubscribe/Options';
import { NewNotifications, OnFailRequest, User } from '../../types';
import { Method } from '../../util/types';
import Api from '.';

interface FetchUserOptions {
  email: string;
  project?: string[];
  token?: string;
  onFail?: OnFailRequest;
}

export async function fetchUser(options: FetchUserOptions): Promise<User | void> {
  const query: JsonApiQuery = options.project
    ? {
        project: options.project,
      }
    : {};

  const params = query ? queryToUrl(query) : {};

  const headers: Record<string, unknown> = {};
  if (options.token) headers['Authorization'] = `Bearer ${options.token}`;

  return Api.request({
    method: Method.get,
    path: `users/${encodeURIComponent(options.email)}`,
    params,
    headers,
    onFail: options.onFail,
  }) as Promise<User>;
}

export const fetchCurrentUser = async (options?: Omit<FetchUserOptions, 'email'>): Promise<User | void> =>
  fetchUser({ ...(options ? options : {}), email: 'current' });

export async function putUserNotifications({
  notifications,
  email,
  onFail,
}: {
  notifications: NewNotifications;
  email?: string;
  onFail?: OnFailRequest;
}): Promise<void> {
  if (!email) email = Api.getUser()?.email;
  if (email) {
    return Api.request({
      method: Method.put,
      path: `users/notifications/${encodeURIComponent(email)}`,
      data: { notifications },
      onFail,
    }) as Promise<void>;
  }
}

export async function fetchUserNotificationsUnsubscribe({
  token,
  onFail,
}: {
  token?: string;
  onFail?: OnFailRequest;
}): Promise<NewNotifications> {
  const headers: Record<string, unknown> = {};
  if (token) headers['Authorization'] = `Bearer ${token}`;

  return Api.request({
    method: Method.get,
    path: `users/notifications/unsubscribe`,
    headers,
    onFail,
  }) as Promise<NewNotifications>;
}

export async function patchUserNotificationsUnsubscribe({
  token,
  selection,
  onFail,
}: {
  token?: string;
  selection: UnsubscribeSelection;
  onFail?: OnFailRequest;
}): Promise<void> {
  const headers: Record<string, unknown> = {};
  if (token) headers['Authorization'] = `Bearer ${token}`;

  return Api.request({
    method: Method.patch,
    path: `users/notifications/unsubscribe`,
    data: selection,
    headers,
    onFail,
  }) as Promise<void>;
}

export async function patchUserTermsAndPrivacy(): Promise<void> {
  const email = Api.getUser()?.email;
  if (email) {
    return Api.request({
      method: Method.patch,
      path: `users/${encodeURIComponent(email)}`,
      data: { termsAgreed: true, privacyAgreed: true },
    }) as Promise<void>;
  }
}

export async function patchUserAnnouncements(announcements: string): Promise<void> {
  const email = Api.getUser()?.email;
  if (email) {
    return Api.request({
      method: Method.patch,
      path: `users/${encodeURIComponent(email)}`,
      data: { announcements },
    }) as Promise<void>;
  }
}
