import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card, Text, TextType } from '../../ComponentLibrary/src';
import { CurrentSystemData } from '../../context/Systems';

interface Props {
  isLoading: boolean;
  currentSystemData?: CurrentSystemData;
}

export default function IO({ isLoading, currentSystemData }: Props): JSX.Element {
  if (!currentSystemData) return <></>;

  const ioClosedClass = 'bg-emerald-500';
  const ioOpenClass = 'bg-gray-400';
  const IOStatus = {
    inputs:
      typeof currentSystemData?.febGPI !== 'undefined'
        ? {
            1: (currentSystemData?.febGPI & 1) !== 0 ? 'closed' : 'open',
            2: (currentSystemData?.febGPI & (1 << 1)) !== 0 ? 'closed' : 'open',
            3: (currentSystemData?.febGPI & (1 << 2)) !== 0 ? 'closed' : 'open',
            4: (currentSystemData?.febGPI & (1 << 3)) !== 0 ? 'closed' : 'open',
            5: (currentSystemData?.febGPI & (1 << 4)) !== 0 ? 'closed' : 'open',
            6: (currentSystemData?.febGPI & (1 << 5)) !== 0 ? 'closed' : 'open',
          }
        : null,
    outputs:
      typeof currentSystemData?.febGPO !== 'undefined'
        ? {
            1: (currentSystemData?.febGPO & 1) !== 0 ? 'closed' : 'open',
            2: (currentSystemData?.febGPO & (1 << 1)) !== 0 ? 'closed' : 'open',
            3: (currentSystemData?.febGPO & (1 << 2)) !== 0 ? 'closed' : 'open',
            4: (currentSystemData?.febGPO & (1 << 3)) !== 0 ? 'closed' : 'open',
            5: (currentSystemData?.febGPO & (1 << 4)) !== 0 ? 'closed' : 'open',
            6: (currentSystemData?.febGPO & (1 << 5)) !== 0 ? 'closed' : 'open',
            7: (currentSystemData?.febGPO & (1 << 6)) !== 0 ? 'closed' : 'open',
            8: (currentSystemData?.febGPO & (1 << 7)) !== 0 ? 'closed' : 'open',
          }
        : null,
  };

  if (currentSystemData?.febGPI === undefined && currentSystemData?.febGPO === undefined) return <></>;

  return (
    <Card className="flex flex-col gap-2" data-pwid="system-io-card">
      <Text type={TextType.h3}>Input & Outputs</Text>
      <div className="flex flex-col gap-8">
        <div className="flex-1" data-pwid="io-input">
          <Text>Inputs</Text>
          <div className="flex flex-row gap-2 flex-wrap">
            {Object.entries(IOStatus?.inputs ?? []).map(([input, status]) =>
              isLoading ? (
                <div className="h-10 w-10 leading-none" key={input}>
                  <Skeleton circle style={{ height: '100%' }} />
                </div>
              ) : (
                <div
                  key={input}
                  className={`${
                    status === 'closed' ? ioClosedClass : ioOpenClass
                  } h-10 w-10 flex flex-col items-center justify-center rounded-full`}
                >
                  <Text overrideColor className="text-white">
                    {input}
                  </Text>
                </div>
              ),
            )}
          </div>
        </div>
        <div className="flex-1" data-pwid="io-output">
          <Text>Outputs</Text>
          <div className="flex flex-row gap-2 flex-wrap">
            {Object.entries(IOStatus?.outputs ?? []).map(([output, status]) =>
              isLoading ? (
                <div className="h-10 w-10 leading-none" key={output}>
                  <Skeleton circle style={{ height: '100%' }} />
                </div>
              ) : (
                <div
                  key={output}
                  className={`${
                    status === 'closed' ? ioClosedClass : ioOpenClass
                  } h-10 w-10 flex flex-col items-center justify-center rounded-full`}
                >
                  <Text overrideColor className="text-white">
                    {output}
                  </Text>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
