import { JsonApiQuery, queryToUrl, Sort } from '../../JsonApi/src';
import { Organization } from '../../types';
import { Method } from '../../util/types';
import Api from '.';

export async function fetchOrg(orgId: string): Promise<Organization | void> {
  const response = (await Api.request({ method: Method.get, path: `orgs/${orgId}` })) as {
    org: Organization;
  };
  return response?.org;
}

export async function fetchOrgs(params: Record<string, unknown>): Promise<
  | {
      orgs: Organization[];
      count: number;
    }
  | Organization[]
  | void
> {
  return Api.request({ method: Method.get, path: 'orgs', params }) as Promise<{
    orgs: Organization[];
    count: number;
  }>;
}

export async function fetchOrgsForDistributor(distributorId: string): Promise<Organization[] | void> {
  return Api.request({ method: Method.get, path: `orgs/distributors/${distributorId}` }) as Promise<Organization[]>;
}

export async function postOrg(data: Omit<Organization, '_id'>): Promise<void> {
  return Api.request({ method: Method.post, path: `orgs`, data }) as Promise<void>;
}

export async function putOrg(orgId: string, data: Omit<Organization, '_id'>): Promise<void> {
  return Api.request({ method: Method.put, path: `orgs/${orgId}`, data }) as Promise<void>;
}

export async function deleteOrg(orgId: string): Promise<void> {
  return Api.request({ method: Method.delete, path: `orgs/${orgId}` }) as Promise<void>;
}

export interface SearchOrgsResponse {
  count: number;
  orgs: Organization[];
}

export async function fetchSearchOrgs(options?: {
  sort?: Sort;
  countPerPage?: number;
  pageNumber?: number;
  count?: boolean;
  project?: string[];
  searchTerm?: string;
  distributorId?: string;
}): Promise<Organization[] | SearchOrgsResponse | void> {
  const query: JsonApiQuery = options
    ? {
        project: options.project,
        sort: options.sort,
      }
    : {};

  if (options?.countPerPage && options?.pageNumber) {
    query.page = {
      number: options?.pageNumber,
      size: options?.countPerPage,
    };
  }

  const params = query ? queryToUrl(query) : {};

  if (options?.searchTerm) {
    params.textSearch = options?.searchTerm;
  }

  if (options?.distributorId) {
    params['distributorId'] = options?.distributorId;
  }

  if (options?.count) {
    params.count = options?.count;
  }

  return Api.request({ method: Method.get, path: 'orgs', params }).then((response) => {
    return response as Organization[] | SearchOrgsResponse;
  });
}
