import { Skeleton } from 'antd';
import React from 'react';

interface LoadingProps {
  changes?: number;
}

export default function Loading({ changes = 0 }: LoadingProps): JSX.Element {
  const loadingChanges = [];
  for (let i = 0; i < changes; i++) {
    loadingChanges.push(
      <div className="flex flex-col gap-2" key={i}>
        <Skeleton paragraph={{ rows: 3 }} active />
        <div className="flex flex-row gap-2">
          {i % 3 === 0 ? (
            <Skeleton.Image active />
          ) : (changes + i) % 2 === 0 ? (
            <>
              <Skeleton.Image active />
              <Skeleton.Image active />
              <Skeleton.Image active />
            </>
          ) : (
            <>
              <Skeleton.Image active />
              <Skeleton.Image active />
            </>
          )}
        </div>
      </div>,
    );
  }
  return (
    <div className="flex flex-col gap-6 my-4">
      <div className="flex justify-center gap-2">
        <Skeleton.Input active />
      </div>
      <Skeleton title={false} active />
      <div className="flex justify-center gap-2">
        <Skeleton.Input active />
      </div>
      {loadingChanges}
    </div>
  );
}
