export enum Method {
  get = 'get',
  post = 'post',
  put = 'put',
  patch = 'patch',
  delete = 'delete',
}

export interface RequestParams {
  sort?: string | number;
  limit?: number;
  skip?: number;
  count?: number;
  order?: number;
  systemsStats?: number;
}
