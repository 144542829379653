export enum HeroIcons {
  AcademicCapIcon = 'AcademicCapIcon',
  AdjustmentsIcon = 'AdjustmentsIcon',
  AnnotationIcon = 'AnnotationIcon',
  ArchiveIcon = 'ArchiveIcon',
  ArrowCircleDownIcon = 'ArrowCircleDownIcon',
  ArrowCircleLeftIcon = 'ArrowCircleLeftIcon',
  ArrowCircleRightIcon = 'ArrowCircleRightIcon',
  ArrowCircleUpIcon = 'ArrowCircleUpIcon',
  ArrowDownIcon = 'ArrowDownIcon',
  ArrowLeftIcon = 'ArrowLeftIcon',
  ArrowNarrowDownIcon = 'ArrowNarrowDownIcon',
  ArrowNarrowLeftIcon = 'ArrowNarrowLeftIcon',
  ArrowNarrowRightIcon = 'ArrowNarrowRightIcon',
  ArrowNarrowUpIcon = 'ArrowNarrowUpIcon',
  ArrowRightIcon = 'ArrowRightIcon',
  ArrowUpIcon = 'ArrowUpIcon',
  ArrowsExpandIcon = 'ArrowsExpandIcon',
  AtSymbolIcon = 'AtSymbolIcon',
  BackspaceIcon = 'BackspaceIcon',
  BadgeCheckIcon = 'BadgeCheckIcon',
  BanIcon = 'BanIcon',
  BeakerIcon = 'BeakerIcon',
  BellIcon = 'BellIcon',
  BookOpenIcon = 'BookOpenIcon',
  BookmarkAltIcon = 'BookmarkAltIcon',
  BookmarkIcon = 'BookmarkIcon',
  BriefcaseIcon = 'BriefcaseIcon',
  CakeIcon = 'CakeIcon',
  CalculatorIcon = 'CalculatorIcon',
  CalendarIcon = 'CalendarIcon',
  CameraIcon = 'CameraIcon',
  CashIcon = 'CashIcon',
  ChartBarIcon = 'ChartBarIcon',
  ChartPieIcon = 'ChartPieIcon',
  ChartSquareBarIcon = 'ChartSquareBarIcon',
  ChatAlt2Icon = 'ChatAlt2Icon',
  ChatAltIcon = 'ChatAltIcon',
  ChatIcon = 'ChatIcon',
  CheckCircleIcon = 'CheckCircleIcon',
  CheckIcon = 'CheckIcon',
  ChevronDoubleDownIcon = 'ChevronDoubleDownIcon',
  ChevronDoubleLeftIcon = 'ChevronDoubleLeftIcon',
  ChevronDoubleRightIcon = 'ChevronDoubleRightIcon',
  ChevronDoubleUpIcon = 'ChevronDoubleUpIcon',
  ChevronDownIcon = 'ChevronDownIcon',
  ChevronLeftIcon = 'ChevronLeftIcon',
  ChevronRightIcon = 'ChevronRightIcon',
  ChevronUpIcon = 'ChevronUpIcon',
  ChipIcon = 'ChipIcon',
  ClipboardCheckIcon = 'ClipboardCheckIcon',
  ClipboardCopyIcon = 'ClipboardCopyIcon',
  ClipboardIcon = 'ClipboardIcon',
  ClipboardListIcon = 'ClipboardListIcon',
  ClockIcon = 'ClockIcon',
  CloudDownloadIcon = 'CloudDownloadIcon',
  CloudIcon = 'CloudIcon',
  CloudUploadIcon = 'CloudUploadIcon',
  CodeIcon = 'CodeIcon',
  CogIcon = 'CogIcon',
  CollectionIcon = 'CollectionIcon',
  ColorSwatchIcon = 'ColorSwatchIcon',
  CreditCardIcon = 'CreditCardIcon',
  CubeIcon = 'CubeIcon',
  CubeTransparentIcon = 'CubeTransparentIcon',
  CurrencyBangladeshiIcon = 'CurrencyBangladeshiIcon',
  CurrencyDollarIcon = 'CurrencyDollarIcon',
  CurrencyEuroIcon = 'CurrencyEuroIcon',
  CurrencyPoundIcon = 'CurrencyPoundIcon',
  CurrencyRupeeIcon = 'CurrencyRupeeIcon',
  CurrencyYenIcon = 'CurrencyYenIcon',
  CursorClickIcon = 'CursorClickIcon',
  DatabaseIcon = 'DatabaseIcon',
  DesktopComputerIcon = 'DesktopComputerIcon',
  DeviceMobileIcon = 'DeviceMobileIcon',
  DeviceTabletIcon = 'DeviceTabletIcon',
  DocumentAddIcon = 'DocumentAddIcon',
  DocumentDownloadIcon = 'DocumentDownloadIcon',
  DocumentDuplicateIcon = 'DocumentDuplicateIcon',
  DocumentIcon = 'DocumentIcon',
  DocumentRemoveIcon = 'DocumentRemoveIcon',
  DocumentReportIcon = 'DocumentReportIcon',
  DocumentSearchIcon = 'DocumentSearchIcon',
  DocumentTextIcon = 'DocumentTextIcon',
  DotsCircleHorizontalIcon = 'DotsCircleHorizontalIcon',
  DotsHorizontalIcon = 'DotsHorizontalIcon',
  DotsVerticalIcon = 'DotsVerticalIcon',
  DownloadIcon = 'DownloadIcon',
  DuplicateIcon = 'DuplicateIcon',
  EmojiHappyIcon = 'EmojiHappyIcon',
  EmojiSadIcon = 'EmojiSadIcon',
  ExclamationCircleIcon = 'ExclamationCircleIcon',
  ExclamationIcon = 'ExclamationIcon',
  ExternalLinkIcon = 'ExternalLinkIcon',
  EyeIcon = 'EyeIcon',
  EyeOffIcon = 'EyeOffIcon',
  FastForwardIcon = 'FastForwardIcon',
  FilmIcon = 'FilmIcon',
  FilterIcon = 'FilterIcon',
  FingerPrintIcon = 'FingerPrintIcon',
  FireIcon = 'FireIcon',
  FlagIcon = 'FlagIcon',
  FolderAddIcon = 'FolderAddIcon',
  FolderDownloadIcon = 'FolderDownloadIcon',
  FolderIcon = 'FolderIcon',
  FolderOpenIcon = 'FolderOpenIcon',
  FolderRemoveIcon = 'FolderRemoveIcon',
  GiftIcon = 'GiftIcon',
  GlobeAltIcon = 'GlobeAltIcon',
  GlobeIcon = 'GlobeIcon',
  HandIcon = 'HandIcon',
  HashtagIcon = 'HashtagIcon',
  HeartIcon = 'HeartIcon',
  HomeIcon = 'HomeIcon',
  IdentificationIcon = 'IdentificationIcon',
  InboxIcon = 'InboxIcon',
  InboxInIcon = 'InboxInIcon',
  InformationCircleIcon = 'InformationCircleIcon',
  KeyIcon = 'KeyIcon',
  LibraryIcon = 'LibraryIcon',
  LightBulbIcon = 'LightBulbIcon',
  LightningBoltIcon = 'LightningBoltIcon',
  LinkIcon = 'LinkIcon',
  LocationMarkerIcon = 'LocationMarkerIcon',
  LockClosedIcon = 'LockClosedIcon',
  LockOpenIcon = 'LockOpenIcon',
  LoginIcon = 'LoginIcon',
  LogoutIcon = 'LogoutIcon',
  MailIcon = 'MailIcon',
  MailOpenIcon = 'MailOpenIcon',
  MapIcon = 'MapIcon',
  MenuAlt1Icon = 'MenuAlt1Icon',
  MenuAlt2Icon = 'MenuAlt2Icon',
  MenuAlt3Icon = 'MenuAlt3Icon',
  MenuAlt4Icon = 'MenuAlt4Icon',
  MenuIcon = 'MenuIcon',
  MicrophoneIcon = 'MicrophoneIcon',
  MinusCircleIcon = 'MinusCircleIcon',
  MinusIcon = 'MinusIcon',
  MinusSmIcon = 'MinusSmIcon',
  MoonIcon = 'MoonIcon',
  MusicNoteIcon = 'MusicNoteIcon',
  NewspaperIcon = 'NewspaperIcon',
  OfficeBuildingIcon = 'OfficeBuildingIcon',
  PaperAirplaneIcon = 'PaperAirplaneIcon',
  PaperClipIcon = 'PaperClipIcon',
  PauseIcon = 'PauseIcon',
  PencilAltIcon = 'PencilAltIcon',
  PencilIcon = 'PencilIcon',
  PhoneIcon = 'PhoneIcon',
  PhoneIncomingIcon = 'PhoneIncomingIcon',
  PhoneMissedCallIcon = 'PhoneMissedCallIcon',
  PhoneOutgoingIcon = 'PhoneOutgoingIcon',
  PhotographIcon = 'PhotographIcon',
  PlayIcon = 'PlayIcon',
  PlusCircleIcon = 'PlusCircleIcon',
  PlusIcon = 'PlusIcon',
  PlusSmIcon = 'PlusSmIcon',
  PresentationChartBarIcon = 'PresentationChartBarIcon',
  PresentationChartLineIcon = 'PresentationChartLineIcon',
  PrinterIcon = 'PrinterIcon',
  PuzzleIcon = 'PuzzleIcon',
  QrcodeIcon = 'QrcodeIcon',
  QuestionMarkCircleIcon = 'QuestionMarkCircleIcon',
  ReceiptRefundIcon = 'ReceiptRefundIcon',
  ReceiptTaxIcon = 'ReceiptTaxIcon',
  RefreshIcon = 'RefreshIcon',
  ReplyIcon = 'ReplyIcon',
  RewindIcon = 'RewindIcon',
  RssIcon = 'RssIcon',
  SaveAsIcon = 'SaveAsIcon',
  SaveIcon = 'SaveIcon',
  ScaleIcon = 'ScaleIcon',
  ScissorsIcon = 'ScissorsIcon',
  SearchCircleIcon = 'SearchCircleIcon',
  SearchIcon = 'SearchIcon',
  SelectorIcon = 'SelectorIcon',
  ServerIcon = 'ServerIcon',
  ShareIcon = 'ShareIcon',
  ShieldCheckIcon = 'ShieldCheckIcon',
  ShieldExclamationIcon = 'ShieldExclamationIcon',
  ShoppingBagIcon = 'ShoppingBagIcon',
  ShoppingCartIcon = 'ShoppingCartIcon',
  SortAscendingIcon = 'SortAscendingIcon',
  SortDescendingIcon = 'SortDescendingIcon',
  SparklesIcon = 'SparklesIcon',
  SpeakerphoneIcon = 'SpeakerphoneIcon',
  StarIcon = 'StarIcon',
  StatusOfflineIcon = 'StatusOfflineIcon',
  StatusOnlineIcon = 'StatusOnlineIcon',
  StopIcon = 'StopIcon',
  SunIcon = 'SunIcon',
  SupportIcon = 'SupportIcon',
  SwitchHorizontalIcon = 'SwitchHorizontalIcon',
  SwitchVerticalIcon = 'SwitchVerticalIcon',
  TableIcon = 'TableIcon',
  TagIcon = 'TagIcon',
  TemplateIcon = 'TemplateIcon',
  TerminalIcon = 'TerminalIcon',
  ThumbDownIcon = 'ThumbDownIcon',
  ThumbUpIcon = 'ThumbUpIcon',
  TicketIcon = 'TicketIcon',
  TranslateIcon = 'TranslateIcon',
  TrashIcon = 'TrashIcon',
  TrendingDownIcon = 'TrendingDownIcon',
  TrendingUpIcon = 'TrendingUpIcon',
  TruckIcon = 'TruckIcon',
  UploadIcon = 'UploadIcon',
  UserAddIcon = 'UserAddIcon',
  UserCircleIcon = 'UserCircleIcon',
  UserGroupIcon = 'UserGroupIcon',
  UserIcon = 'UserIcon',
  UserRemoveIcon = 'UserRemoveIcon',
  UsersIcon = 'UsersIcon',
  VariableIcon = 'VariableIcon',
  VideoCameraIcon = 'VideoCameraIcon',
  ViewBoardsIcon = 'ViewBoardsIcon',
  ViewGridAddIcon = 'ViewGridAddIcon',
  ViewGridIcon = 'ViewGridIcon',
  ViewListIcon = 'ViewListIcon',
  VolumeOffIcon = 'VolumeOffIcon',
  VolumeUpIcon = 'VolumeUpIcon',
  WifiIcon = 'WifiIcon',
  XCircleIcon = 'XCircleIcon',
  XIcon = 'XIcon',
  ZoomInIcon = 'ZoomInIcon',
  ZoomOutIcon = 'ZoomOutIcon',
}
