import { Transition } from '@headlessui/react';
import React, { useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { Pagination } from '../../../ComponentLibrary/src';
import { useMobile } from '../../../hooks';
import { DragItem, Organization, Site, System } from '../../../types';
import Loading from './Loading';
import OrganizationListItem from './OrganizationListItem';
import OrganizationPage from './OrganizationPage';
import OrganizationsHeader from './OrganizationsHeader';
import SitePage from './SitePage';

interface AllocatedProps {
  orgs: Organization[];
  selectedOrgId: string | undefined;
  selectedSiteId: string | undefined;
  assetsCount: number;
  page: number;
  pageSize: number;
  orgsLoading: boolean;
  isDraggingSystem: boolean;
  all: boolean;
  bulkMove: boolean;
  savingSystems: Set<string>;
  checkedSystems: System[];
  checkedSites: Site[];
  orgSearchTerm?: string;
  onChangeCountPerPage: (count: number) => void;
  onChangePageNumber: (page: number) => void;
  updateAllOrganizations: () => Promise<void>;
  setOrgSearchTerm: (term: string) => void;
  refreshData: () => void;
  handleBulkCancel: (bulkMove: boolean) => void;
  onCheck: (checked: boolean, system?: System, site?: Site) => void;
  handleDragging: (dragging: boolean) => void;
  draggingFromAllocated: (allocated: boolean) => void;
  onDrop: (item: DragItem, destination: string) => void;
  setCurrentDragging: (system?: System) => void;
  currentDragging?: System;
}

export default React.memo(function Allocated({
  orgs,
  selectedOrgId,
  selectedSiteId,
  assetsCount,
  page,
  pageSize,
  orgsLoading,
  isDraggingSystem,
  bulkMove,
  savingSystems,
  checkedSystems,
  checkedSites,
  orgSearchTerm,
  all,
  onChangeCountPerPage,
  onChangePageNumber,
  updateAllOrganizations,
  setOrgSearchTerm,
  refreshData,
  handleBulkCancel,
  onCheck,
  handleDragging,
  draggingFromAllocated,
  onDrop,
  setCurrentDragging,
  currentDragging,
}: AllocatedProps): JSX.Element {
  const isMobile = useMobile();
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const [viewAllOrgs, setViewAllOrgs] = useState<boolean>(false);
  const [viewOrg, setViewOrg] = useState<boolean>(false);
  const [viewSite, setViewSite] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const foundOrg = useMemo(() => {
    return orgs.find((org) => org._id === selectedOrgId);
  }, [orgs, selectedOrgId]);

  const foundSite = useMemo(() => {
    return foundOrg?.sites?.find((site) => site._id === selectedSiteId);
  }, [foundOrg, selectedSiteId]);

  useEffect(() => {
    if (orgsLoading) return;

    if (foundOrg) {
      setSelectedOrg(foundOrg);
      if (foundSite) {
        setSelectedSite(foundSite);
        setViewOrg(false);
        if (firstLoad) setViewSite(true);
      } else {
        setViewSite(false);
        if (firstLoad) setViewOrg(true);
      }
      setViewAllOrgs(false);
    } else {
      setViewOrg(false);
      setViewSite(false);
      if (firstLoad) setViewAllOrgs(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundOrg, foundSite, orgs, orgsLoading]);

  // Re-wire tooltips
  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
  });

  return (
    <React.Fragment>
      <OrganizationsHeader
        selectedOrg={selectedOrg}
        selectedSite={selectedSite}
        bulkMove={bulkMove}
        handleBulkCancel={handleBulkCancel}
        setOrgSearchTerm={setOrgSearchTerm}
        orgSearchTerm={orgSearchTerm}
        onDrop={onDrop}
      />
      <div className="flex flex-row h-full overflow-auto">
        <Transition
          className="flex flex-col flex-auto overflow-x-hidden overflow-y-auto mb-12"
          show={viewAllOrgs}
          enter="ease transition-all duration-200"
          enterFrom={firstLoad ? '' : '-translate-x-full'}
          leave="ease transition-all duration-200"
          leaveTo="-translate-x-full"
          afterEnter={() => {
            setFirstLoad(false);
            setSelectedSite(null);
            setSelectedOrg(null);
          }}
          afterLeave={() => {
            setViewOrg(true);
          }}
        >
          {orgsLoading ? (
            <Loading pageSize={assetsCount} colspan={4} showHeader={false} />
          ) : (
            orgs.map((org) => (
              <OrganizationListItem
                key={org._id}
                org={org}
                onDrop={onDrop}
                updateAllOrganizations={updateAllOrganizations}
              />
            ))
          )}
          <Pagination
            className="absolute bottom-0 left-0 right-0 z-900"
            loading={orgsLoading}
            pageNumber={page}
            totalCount={assetsCount}
            countPerPage={pageSize}
            onChangePageNumber={onChangePageNumber}
            onChangeCountPerPage={onChangeCountPerPage}
            mobile={isMobile}
          />
        </Transition>
        <Transition
          className="flex flex-1 flex-col h-full gap-4"
          show={viewOrg}
          enter="ease transition-all duration-200"
          enterFrom={
            firstLoad ? '' : (!selectedSite && foundOrg) || foundSite ? 'translate-x-full' : '-translate-x-full'
          }
          leave="ease transition-all duration-200"
          leaveTo={(!selectedSite && foundOrg) || foundSite ? '-translate-x-full' : 'translate-x-full'}
          afterEnter={() => {
            setFirstLoad(false);
            if (!!foundOrg !== !!foundSite) {
              setSelectedSite(null);
            } else {
              setViewOrg(false);
            }
          }}
          afterLeave={() => {
            if (foundSite) {
              setViewSite(true);
            } else if (!foundOrg) {
              setViewAllOrgs(true);
            }
          }}
        >
          {selectedOrg && (
            <OrganizationPage
              org={selectedOrg}
              all={all}
              isDraggingSystem={isDraggingSystem}
              onDrop={onDrop}
              savingSystems={savingSystems}
              handleDragging={handleDragging}
              draggingFromAllocated={draggingFromAllocated}
              handleOnCheck={onCheck}
              bulkMove={bulkMove}
              refreshData={refreshData}
              selectSite={setSelectedSite}
              checkedSystems={checkedSystems}
              checkedSites={checkedSites}
              setCurrentDragging={setCurrentDragging}
              currentDragging={currentDragging}
            />
          )}
        </Transition>
        <Transition
          className="flex flex-1 flex-col h-full gap-4"
          show={viewSite}
          enter="ease transition-all duration-200"
          enterFrom={firstLoad ? '' : 'translate-x-full'}
          leave="ease transition-all duration-200"
          leaveTo="translate-x-full"
          afterEnter={() => {
            setFirstLoad(false);
          }}
          afterLeave={() => {
            setViewOrg(true);
          }}
        >
          {selectedSite && (
            <SitePage
              site={selectedSite}
              orgId={selectedOrg?._id}
              orgName={selectedOrg?.name}
              savingSystems={savingSystems}
              isDraggingSystem={isDraggingSystem}
              handleDragging={handleDragging}
              draggingFromAllocated={draggingFromAllocated}
              onDrop={onDrop}
              parentDistributors={selectedSite.distributor ? [selectedSite.distributor] : []}
              refreshData={refreshData}
              setCurrentDragging={setCurrentDragging}
              currentDragging={currentDragging}
            />
          )}
        </Transition>
      </div>
    </React.Fragment>
  );
});
