import React from 'react';

import { appendClassProps } from '../util';
import { CardProps } from './index.types';

/**
 - Use card as a generic UI wrapper for visual organization
 */
export const Card: React.FC<CardProps> = ({
  noPadding = false,
  transparent,
  windowMode,
  children,
  className,
  'data-pwid': dataPwId = 'card',
}: CardProps) => {
  return (
    <div
      data-testid="card"
      className={`${noPadding ? '' : 'p-4'}${transparent ? '' : ' bg-white shadow'} rounded-sm${
        windowMode ? ' bg-white backdrop-blur-sm bg-opacity-50' : ''
      }${appendClassProps(className)}`}
      data-pwid={dataPwId}
    >
      {children}
    </div>
  );
};

export * from './index.types';
