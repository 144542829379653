import 'chartjs-adapter-luxon';

import { Chart as ChartJs, ChartConfiguration, ChartData, ChartDataset, LegendItem, TooltipItem } from 'chart.js';
import React, { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';

import { Loading, Text } from '../../../ComponentLibrary/src';
import colors from '../../../ComponentLibrary/src/style/colors';
import { colorChoices } from '../../../ComponentLibrary/src/util';
import { CompressorState, Interval, SystemData, TransferSwitchPosition } from '../../../context/Systems';
import { ChartOptions } from './types';

export const DataChart: React.FC<ChartOptions> = ({
  loading,
  isMobile,
  chartSources,
  dataSources,
  systemData,
  dataInterval,
  chartColors,
  dateRange,
}): JSX.Element => {
  /* Get next chart color in order */
  const getNextChartColor = (dataSource: string): string => {
    // Iterate over each color
    // If there are less than the maxColorChart number of data sources for the color, add this one
    // Otherwise continue
    // If we have traversed all the colors, then start over and add this one to the first option

    for (const [color, colorDataSources] of chartColors.colors) {
      if (
        colorDataSources.length < chartColors.maxColorCharts &&
        !chartColors.colors.get(color)?.includes(dataSource)
      ) {
        chartColors.colors.set(color, [...(chartColors.colors.get(color) ?? []), dataSource]);
        return color;
      }
    }

    const color = chartColors.colors.keys().next().value;
    if (!chartColors.colors.get(color)?.includes(dataSource)) {
      chartColors.maxColorCharts += 1;
      chartColors.colors.set(color, [...(chartColors.colors.get(color) ?? []), dataSource]);
    }
    return color;
  };

  const data: ChartData<'bar' | 'line'> = useMemo(
    () => {
      if (!systemData) return { datasets: [] };

      for (const color of colorChoices) {
        chartColors.colors.set(color, []);
      }

      const showOpacity = Object.keys(chartSources).some((chartSource) => {
        const found = dataSources.find(({ id }) => id === chartSource);
        return found && !found.cumulative;
      });

      return {
        datasets: Object.entries(chartSources)
          .map(([chartSource, axis]) => {
            const chartColor = getNextChartColor(chartSource);
            const source = dataSources.find((source) => source.id === chartSource);
            const type = source?.cumulative ? 'bar' : 'line';
            let data = systemData.data[chartSource as keyof SystemData] as number[];

            if (!data) {
              data = [];
            }

            return {
              type,
              label: `${source?.label?.toString() ?? 'Unknown'} [${axis === 1 ? 'Left' : 'Right'}]`,
              data: data.map((datum, i) => {
                if (systemData.ts[i]) {
                  return {
                    x: new Date(systemData.ts[i]).getTime(),
                    y: datum,
                  };
                }
                return null;
              }),
              fill: false,
              borderColor: chartColor,
              backgroundColor: () => {
                // Only add opacity if also charting non cumulative props
                if (type === 'bar' && showOpacity) {
                  return `${chartColor}80`;
                }
                return chartColor;
              },
              tension: 0.3,
              yAxisID: axis === 1 ? 'yLeft' : 'yRight',
              pointRadius: 1,
            } as ChartDataset<'bar' | 'line'>;
          })
          .sort(({ type }) => (type === 'line' ? -1 : 1)),
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartSources, dataSources, systemData?.data, systemData?.ts],
  );

  const { leftSuggestedMin, leftSuggestedMax, rightSuggestedMin, rightSuggestedMax } = useMemo(() => {
    const getDataExtreme = (extreme: 'min' | 'max', axis: 1 | 2): number => {
      return Math[extreme](
        ...Object.entries(chartSources)
          .filter(([, chart]) => chart === axis)
          .map(
            ([source]) =>
              (systemData?.data?.[source as keyof SystemData] as number[])?.filter(
                (datum: number | TransferSwitchPosition | CompressorState) => datum !== null,
              ) ?? [],
          )
          .flat(),
      );
    };

    let leftSuggestedMin = 0,
      leftSuggestedMax = 0,
      rightSuggestedMin = 0,
      rightSuggestedMax = 0;

    const maxScale = 0.05;

    let max = getDataExtreme('max', 1);
    let min = getDataExtreme('min', 1);
    let avg = (max + min) / 2 || 0.01;
    let scalePercentage = Math.abs((max - min) / avg);
    if (scalePercentage < maxScale) {
      max *= max > 0 ? 1 + maxScale : 1 - maxScale;
      min *= min > 0 ? 1 - maxScale : 1 + maxScale;
    }
    leftSuggestedMin = min;
    leftSuggestedMax = max;

    max = getDataExtreme('max', 2);
    min = getDataExtreme('min', 2);
    avg = (max + min) / 2 || 0.01;
    scalePercentage = Math.abs((max - min) / avg);
    if (scalePercentage < maxScale) {
      max *= max > 0 ? 1 + maxScale : 1 - maxScale;
      min *= min > 0 ? 1 - maxScale : 1 + maxScale;
    }
    rightSuggestedMin = min;
    rightSuggestedMax = max;

    return {
      leftSuggestedMin,
      leftSuggestedMax,
      rightSuggestedMin,
      rightSuggestedMax,
    };
  }, [chartSources, systemData?.data]);

  // closed issue: https://github.com/reactchartjs/react-chartjs-2/issues/1009
  // ChartOptions<'line'> // wasn't working with the crosshair plugin
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: any = useMemo(() => {
    return {
      elements: {
        point: {
          radius: 0,
        },
      },
      // Turn off animations and data parsing for performance
      animation: false,
      parsing: false,

      interaction: {
        // mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      plugins: {
        decimation: {
          enabled: true,
          algorithm: 'lttb',
          samples: 168,
          threshold: 168,
        },
        crosshair: {
          line: {
            color: colors.gray['300'], // crosshair line color
            width: 1, // crosshair line width
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy',
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
            scaleMode: 'xy',
            speed: 0.7,
          },
          limits: {
            x: {
              min: 'original',
              max: 'original',
            },
          },
        },
        htmlLegend: {
          containerID: 'legend',
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: function (tooltipItem: TooltipItem<'bar' | 'line'>[]) {
              return `${tooltipItem[0].label}${
                [Interval.month, Interval.day].includes(dataInterval ?? Interval.hour) ? ' (UTC)' : ''
              }`;
            },
          },
        },
      },

      scales: {
        x: {
          min: dateRange.from,
          max: dateRange.to,
          type: 'time',
          time: {
            // Luxon Format Strings https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens
            // ChartJS Display Formats https://www.chartjs.org/docs/latest/axes/cartesian/time.html#display-formats
            displayFormats: () => {
              const formats: {
                minute?: string;
                hour?: string;
                day?: string;
                week?: string;
                month?: string;
              } = {
                minute: 'h:mm a',
                hour: 'LLL d, ha',
                day: 'LLL d, yyyy',
                week: 'LLL d, yyyy',
                month: 'LLL yyyy',
              };

              switch (dataInterval) {
                case Interval.hour:
                  formats.minute = formats.hour;
                  break;
                case Interval.day:
                  formats.minute = formats.day;
                  formats.hour = formats.day;
                  break;
                case Interval.month:
                  formats.minute = formats.month;
                  formats.hour = formats.month;
                  formats.day = formats.month;
                  formats.week = formats.month;
                  break;
                default:
                  break;
              }

              return formats;
            },
            minUnit: dataInterval,
            tooltipFormat:
              dataInterval === Interval.month
                ? 'LLL yyyy'
                : dataInterval === Interval.day
                ? 'LLL d, yyyy'
                : dataInterval === Interval.hour
                ? 'LLL d, yyyy ha'
                : 'LLL d, yyyy h:mm a',
          },
          adapters: {
            date: {
              zone: dataInterval === Interval.month || dataInterval === Interval.day ? 'UTC' : undefined,
            },
          },
          ticks: {
            source: 'auto',
            autoSkip: true,
            autoSkipPadding: 15,
            minRotation: 45,
          },
        },
        yLeft: {
          type: 'linear',
          display: Object.entries(chartSources).filter(([, chart]) => chart === 1).length,
          position: 'left',

          // grid line settings
          grid: {
            drawOnChartArea: Object.entries(chartSources).filter(([, chart]) => chart === 1).length, // only want the grid lines for one axis to show up
          },
          suggestedMin: leftSuggestedMin,
          suggestedMax: leftSuggestedMax,
          title: {
            display: true,
            text: Object.entries(chartSources).reduce((text, [chartSource, chart]) => {
              const label = dataSources?.find((source) => source.id === chartSource)?.label?.toString();
              const matches = /\((.*)\)/.exec(label ?? '');
              const unit = matches?.[1];
              if (!unit || chart !== 1) return text;
              if (!text && unit) {
                return unit;
              } else if (unit && !text.includes(unit)) {
                return `${text}, ${unit}`;
              }
              return text;
            }, ''),
          },
        },
        yRight: {
          type: 'linear',
          display: Object.entries(chartSources).filter(([, chart]) => chart === 2).length,
          position: 'right',

          // grid line settings
          grid: {
            drawOnChartArea:
              !Object.entries(chartSources).filter(([, chart]) => chart === 1).length &&
              Object.entries(chartSources).filter(([, chart]) => chart === 2).length, // only want the grid lines for one axis to show up
          },
          suggestedMin: rightSuggestedMin,
          suggestedMax: rightSuggestedMax,
          title: {
            display: true,
            text: Object.entries(chartSources).reduce((text, [chartSource, chart]) => {
              const label = dataSources?.find((source) => source.id === chartSource)?.label?.toString();
              const matches = /\((.*)\)/.exec(label ?? '');
              const unit = matches?.[1];
              if (!unit || chart === 1) return text;
              if (!text && unit) {
                return unit;
              } else if (unit && !text.includes(unit)) {
                return `${text}, ${unit}`;
              }
              return text;
            }, ''),
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };
  }, [
    chartSources,
    dataInterval,
    dataSources,
    leftSuggestedMax,
    leftSuggestedMin,
    rightSuggestedMax,
    rightSuggestedMin,
    dateRange.from,
    dateRange.to,
  ]);

  const getOrCreateLegendList = (id: string) => {
    const legendContainer = document.getElementById(id);
    if (legendContainer) {
      legendContainer.style.display = 'flex';
      legendContainer.style.gap = '6rem';
    }

    let listContainers = legendContainer?.querySelectorAll('ul');

    while ((listContainers?.length ?? 0) < 2) {
      const listContainer = document.createElement('ul');
      listContainer.style.flex = '1';
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      listContainer.style.margin = '0';
      listContainer.style.padding = '0';
      listContainer.style.flexWrap = 'wrap';
      if (!listContainers?.length) {
        listContainer.style.justifyContent = 'start';
      } else {
        listContainer.style.justifyContent = 'end';
      }

      legendContainer?.appendChild(listContainer);
      listContainers = legendContainer?.querySelectorAll('ul');
    }

    return listContainers || [];
  };

  // Custom Legend
  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(
      chart: ChartJs,
      _: unknown,
      options: {
        containerID: string;
      },
    ) {
      const [ul1, ul2] = getOrCreateLegendList(options.containerID);

      // Reuse the built-in legendItems generator
      if (!chart.options.plugins?.legend?.labels?.generateLabels) return;
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      // Remove old legend items
      while (ul1.firstChild) {
        ul1.firstChild.remove();
      }
      while (ul2.firstChild) {
        ul2.firstChild.remove();
      }

      items.forEach((item: LegendItem) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          const { type } = chart.config as ChartConfiguration;
          if ((type === 'pie' || type === 'doughnut') && item.index !== undefined) {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else if (item.datasetIndex !== undefined) {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle as string;
        boxSpan.style.borderColor = item.strokeStyle as string;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '20px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '20px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor as string;
        textContainer.style.margin = '0';
        textContainer.style.padding = '0';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
        if (isMobile) textContainer.style.fontSize = '0.9rem';

        const axis = /\[([LeftRigh]*)\]/.exec(item.text)?.[1] ?? 'Left';
        const text = document.createTextNode(item.text.replace(` [${axis}]`, ''));
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        if (axis === 'Left') {
          ul1.appendChild(li);
        } else {
          ul2.appendChild(li);
        }
      });

      // HACK: force redraw chart container (and recalculate chart height)
      if (chart.canvas.parentElement) {
        (chart.canvas.parentElement as HTMLDivElement).style.height = '0px';
      }
    },
  };
  let chart = loading ? (
    <div className="flex-1 flex flex-col items-center justify-center">
      <Loading type="small" />
    </div>
  ) : (
    <div className="relative flex-1">
      <Chart type="line" data={data} options={options} plugins={[htmlLegendPlugin]} />
    </div>
  );

  if (!dataSources.length) {
    chart = (
      <div className="flex-1 flex flex-col items-center justify-center">
        <Text>(No Data)</Text>
      </div>
    );
  }

  return chart;
};
