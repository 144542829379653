import QueryString from 'qs';

import { FilterKey, MenuItem } from '../../../ComponentLibrary/src/Filter/index.types';
import { ITimelineItem, SystemTimelineProps } from '../../../context/Systems';
import { JsonApiQuery, queryToUrl } from '../../../JsonApi/src';
import { OnFailRequest } from '../../../types';
import { Method } from '../../../util/types';
import Api from '..';
import { getApiUrl } from '../auth';

interface EventsReponse {
  events: ITimelineItem[];
}

export async function fetchSystemTimeline(
  sysId: string,
  params: Record<string, unknown>,
  onFail?: OnFailRequest,
): Promise<EventsReponse> {
  return Api.request({
    method: Method.get,
    path: `systems/events/${sysId}`,
    params,
    onFail,
  }).then((res) => {
    return res as EventsReponse;
  });
}

export async function downloadSystemTimeline({ sysId, filter, sort }: SystemTimelineProps): Promise<void> {
  const query: JsonApiQuery = {
    filterKeys: filter ? filter : [],
  };

  if (sort) query.sort = sort;

  const params = queryToUrl(query);

  const apiUrl = getApiUrl();
  const downloadUrl = `${apiUrl}/systems/events/csv/${sysId}?${QueryString.stringify(params, {
    arrayFormat: 'repeat',
  })}`;
  window.open(downloadUrl, '_blank');
}

export async function fetchEventFieldKeys(
  key: string,
  sysId: string,
  filter: FilterKey[],
  onlySelected?: boolean,
): Promise<{
  data: MenuItem[];
  count: number;
}> {
  const query: JsonApiQuery = {
    filterKeys: filter.filter((item) => (onlySelected ? item.id === key : item.id !== key)),
  };
  const params = queryToUrl(query);

  const results = (await Api.request({
    method: Method.get,
    path: `systems/events/${sysId}/filterKeys/${key}`,
    params,
  })) as MenuItem[];

  if (!results) return { data: [], count: 0 };

  return { data: results, count: results.length };
}
