import { Transition } from '@headlessui/react';
import {
  BriefcaseIcon,
  ClipboardListIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  MapIcon,
  PhoneIcon,
  ShieldCheckIcon,
  TableIcon,
  TruckIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react';

import { Avatar } from '../Avatar';
import { Link } from '../Link';
import logo from '../style/Qnergy_HOR_NoTag_RGB.svg';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { useOutsideAlerter } from '../util/hooks';
import { PlaywrightTestIds } from '.';
import { SideNavProps } from './index.types';
import { Section, SectionTitle, Subsection, SubsectionTitle } from './SideNav.components';

export const SideNavMobile: React.FC<SideNavProps> = ({
  className,
  dataTestId,
  show,
  hasAdminPriveleges,
  user,
  selectedPage,
  showOverview = true,
  showSystems = true,
  showUsers,
  showAssets,
  showDistributors,
  showFaultSettings,
  swVer,
  phone,
  usersUrl,
  onClickAccount,
  onLogout,
  onClickToggle,
}: SideNavProps) => {
  const sideMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const userIconRef = useRef(null);
  const [selectedSubsection, setSelectedSubsection] = useState(selectedPage);
  const [userClicked, setUserClicked] = useState(false);

  useEffect(() => {
    setSelectedSubsection(selectedPage);
  }, [selectedPage]);

  const handleClickOutsideUserMenu = () => {
    setUserClicked(false);
  };

  const handleClickOutsideSideMenu = () => {
    if (onClickToggle && show) onClickToggle();
  };

  useOutsideAlerter([userMenuRef, userIconRef], handleClickOutsideUserMenu);
  useOutsideAlerter(sideMenuRef, handleClickOutsideSideMenu);

  const handleClickOverview = () => {
    setSelectedSubsection('overview');
    handleClickOutsideSideMenu();
  };

  const handleClickSystems = () => {
    setSelectedSubsection('systems');
    handleClickOutsideSideMenu();
  };

  const handleClickUsers = () => {
    setSelectedSubsection('users');
    handleClickOutsideSideMenu();
  };

  const handleClickAssets = () => {
    setSelectedSubsection('assets');
    handleClickOutsideSideMenu();
  };

  const handleClickDistributors = () => {
    setSelectedSubsection('distributors');
    handleClickOutsideSideMenu();
  };

  const handleClickFaultSettings = () => {
    setSelectedSubsection('faultSettings');
    handleClickOutsideSideMenu();
  };

  const handleClickReleaseNotes = () => {
    setSelectedSubsection('releasenotes');
    handleClickOutsideSideMenu();
  };

  const handleClickUser = () => {
    setUserClicked(!userClicked);
  };

  const handleClickLogo = () => {
    if (onClickToggle) onClickToggle();
  };

  const version = swVer ? `v${swVer}` : '';

  const iconClass = 'h-4 w-4 lg:h-5 lg:w-5';
  return (
    <>
      <Transition
        className="flex flex-row absolute left-0 right-1/4 max-w-[16rem] responsive-h-screen z-[10000001]"
        show={show}
        enter="transition ease-in-out duration-300"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="flex flex-col h-full bg-white w-full overflow-x-hidden overflow-y-auto" ref={sideMenuRef}>
          <div
            className={`relative flex flex-row w-full text-white bg-blue-800 p-2${appendClassProps(className)}`}
            data-testid={dataTestId}
          >
            <div className="flex-1 flex flex-col items-center justify-center">
              <img
                src={logo}
                className="h-11 py-2 cursor-pointer"
                alt="logo"
                onClick={handleClickLogo}
                data-pwid={PlaywrightTestIds.imageLogo}
              />
            </div>
            <div
              ref={userIconRef}
              className="text-white cursor-pointer h-12"
              onClick={handleClickUser}
              data-pwid={PlaywrightTestIds.avatar}
            >
              <Avatar
                uniqueId={user?.email}
                className="h-full"
                initials={`${user?.profile?.firstName.charAt(0).toUpperCase() ?? ''}${
                  user?.profile?.lastName.charAt(0).toUpperCase() ?? ''
                }`}
              />
            </div>
            <ul
              ref={userMenuRef}
              className={`bg-blue-800 text-white flex flex-col absolute right-0 top-16 rounded-tl-sm rounded-b-sm w-40 z-900${
                userClicked ? '' : ' hidden'
              }`}
            >
              <li
                className="p-2 cursor-pointer"
                onClick={() => {
                  setUserClicked(false);

                  onClickAccount();
                }}
                data-pwid={PlaywrightTestIds.accountsLink}
              >
                Account
              </li>
              <li
                className="p-2 cursor-pointer"
                onClick={() => {
                  setUserClicked(false);

                  handleClickOutsideSideMenu();
                }}
                data-pwid={PlaywrightTestIds.notificationsLink}
              >
                <Link href="/notifications" color="white">
                  Notifications
                </Link>
              </li>
              <li className="p-2 cursor-pointer" onClick={onLogout} data-pwid={PlaywrightTestIds.logoutLink}>
                Logout
              </li>
            </ul>
          </div>

          <div className="flex flex-col bg-blue-700 text-white justify-between h-full">
            <div className="flex flex-col items-start">
              {showOverview && (
                <Subsection
                  collapsed={false}
                  className="pl-3.5"
                  href="/"
                  onClick={handleClickOverview}
                  selected={selectedSubsection === 'overview'}
                  mobile
                  data-pwid={PlaywrightTestIds.mapIcon}
                >
                  <div className={iconClass}>
                    <MapIcon className="w-full" />
                  </div>
                  <SubsectionTitle>Overview</SubsectionTitle>
                </Subsection>
              )}

              {showSystems && (
                <>
                  <Section collapsed={false} mobile>
                    <SectionTitle>SYSTEMS</SectionTitle>
                  </Section>
                  <Subsection
                    className="pl-3.5"
                    onClick={handleClickSystems}
                    selected={selectedSubsection === 'systems'}
                    href="/systems"
                    mobile
                    data-pwid={PlaywrightTestIds.systemsTableIcon}
                  >
                    <div className={iconClass}>
                      <TableIcon className="w-full" />
                    </div>
                    <SubsectionTitle>Systems</SubsectionTitle>
                  </Subsection>
                </>
              )}

              {hasAdminPriveleges && (
                <Section collapsed={false} mobile>
                  <SectionTitle>ADMINISTRATION</SectionTitle>
                </Section>
              )}
              {showUsers && usersUrl && (
                <Subsection
                  className="pl-3.5"
                  onClick={handleClickUsers}
                  selected={selectedSubsection === 'users'}
                  href={usersUrl}
                  external
                  mobile
                  data-pwid={PlaywrightTestIds.usersIcon}
                >
                  <div className={iconClass}>
                    <UsersIcon className="w-full" />
                  </div>
                  <SubsectionTitle>Users</SubsectionTitle>
                </Subsection>
              )}
              {showAssets && (
                <Subsection
                  className="pl-3.5"
                  onClick={handleClickAssets}
                  selected={selectedSubsection === 'assets'}
                  href="/assets"
                  mobile
                  data-pwid={PlaywrightTestIds.assetsIcon}
                >
                  <div className={iconClass}>
                    <BriefcaseIcon className="w-full" />
                  </div>
                  <SubsectionTitle>Assets</SubsectionTitle>
                </Subsection>
              )}
              {showDistributors && (
                <Subsection
                  className="pl-3.5"
                  onClick={handleClickDistributors}
                  selected={selectedSubsection === 'distributors'}
                  href="/distributors"
                  mobile
                  data-pwid={PlaywrightTestIds.distributorsIcon}
                >
                  <div className={iconClass}>
                    <TruckIcon className="w-full" />
                  </div>
                  <SubsectionTitle>Distributors</SubsectionTitle>
                </Subsection>
              )}
              {showFaultSettings && (
                <Subsection
                  className="pl-3.5"
                  onClick={handleClickFaultSettings}
                  selected={selectedSubsection === 'faultSettings'}
                  href="/faults"
                  mobile
                  data-pwid={PlaywrightTestIds.faultsIcon}
                >
                  <div className={iconClass}>
                    <ExclamationCircleIcon className="w-full" />
                  </div>
                  <SubsectionTitle>Fault Settings</SubsectionTitle>
                </Subsection>
              )}
            </div>

            <div className="flex flex-col items-start">
              <Section collapsed={false} mobile>
                <SectionTitle>SUPPORT</SectionTitle>
              </Section>
              {phone && (
                <Subsection
                  className="pl-3.5"
                  href={`tel:${phone?.replaceAll('-', '')}`}
                  mobile
                  data-pwid={PlaywrightTestIds.phoneIcon}
                >
                  <div className={iconClass}>
                    <PhoneIcon className="w-full" />
                  </div>
                  <SubsectionTitle>{phone}</SubsectionTitle>
                </Subsection>
              )}
              <Subsection className="pl-3.5" href="/terms" mobile external data-pwid={PlaywrightTestIds.termsIcon}>
                <div className={iconClass}>
                  <ClipboardListIcon className="w-full" />
                </div>
                <SubsectionTitle>Terms</SubsectionTitle>
              </Subsection>
              <Subsection className="pl-3.5" href="/privacy" mobile external data-pwid={PlaywrightTestIds.privacyIcon}>
                <div className={iconClass}>
                  <ShieldCheckIcon className="w-full" />
                </div>
                <SubsectionTitle>Privacy Policy</SubsectionTitle>
              </Subsection>
              <Subsection
                className="pl-3.5"
                onClick={handleClickReleaseNotes}
                selected={selectedSubsection === 'releasenotes'}
                href="/releasenotes"
                mobile
                data-pwid={PlaywrightTestIds.releaseNotesIcon}
              >
                <div className={iconClass}>
                  <DocumentTextIcon className="w-full" />
                </div>
                <SubsectionTitle>Release Notes</SubsectionTitle>
              </Subsection>
              <div className={`flex flex-row w-full my-2 items-center justify-center gap-1`}>
                <Text type={TextType.custom} overrideColor className="text-sm font-extralight text-blue-100">
                  {version}
                </Text>
                <div className="whitespace-nowrap flex flex-row gap-1 items-center">
                  <SubsectionTitle>© {new Date().getFullYear()} Qnergy</SubsectionTitle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition
        className="w-full flex flex-row absolute left-0 right-0 h-screen z-[10000000]"
        show={show}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full h-full bg-black bg-opacity-60"></div>
      </Transition>
    </>
  );
};

export * from './index.types';
