import DOMPurify from 'dompurify';

import { Fault } from '../../types';
import { getLabel } from './constants';

export enum TimelineItemType {
  faultCleared = 'faultClearEvt',
  shipDate = 'shipped',
  commissionDate = 'commissioned',
  fault = 'faultEvt',
  stateChange = 'stateEvt',
}

export enum Display {
  normal = 'normal',
  small = 'small',
}

export const getFaultTooltip = (fault?: Fault, advancedReader?: boolean): string => {
  let content = '';
  if (fault?.simple?.possibleCauses) content += `<div><b>Possible Causes: </b>${fault?.simple?.possibleCauses}</div>`;
  if (fault?.simple?.whatToDo) {
    if (content) content += '\n';
    content += `<div><b>What To Do: </b>${fault?.simple?.whatToDo}</div>`;
  }
  if (advancedReader) {
    if (fault?.advanced?.possibleCauses) {
      if (content) content += '\n';
      content += `<div><b>Advanced Causes: </b>${fault?.advanced?.possibleCauses}</div>`;
    }
    if (fault?.advanced?.whatToDo) {
      if (content) content += '\n';
      content += `<div><b>Advanced Solutions: </b>${fault?.advanced?.whatToDo}</div>`;
    }
  }
  return DOMPurify.sanitize(content, { ALLOWED_TAGS: ['b', 'div'] });
};

export const labelSort = (a: string, b: string): number => {
  const labelA = getLabel(a, true);
  const labelB = getLabel(b, true);

  return labelA.localeCompare(labelB, 'en', { numeric: true });
};
