import { Divider, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import semver from 'semver';

import { Button, Variant } from '../../ComponentLibrary/src';
import { useMobile } from '../../hooks';
import { getUserLatestComponent, latest } from './Versions';

export interface AnnouncementsProps {
  userLatestRelease?: string;
  markAsRead: (latestRelease: string) => Promise<void>;
}

const Announcements: React.FunctionComponent<AnnouncementsProps> = ({ userLatestRelease, markAsRead }) => {
  const isMobile = useMobile();
  const { t } = useTranslation('translate');
  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false);
  const userLatest = useMemo(() => userLatestRelease ?? '0.0.0', [userLatestRelease]);
  const Announcements = useMemo(() => getUserLatestComponent(userLatest), [userLatest]);

  const handleDismiss = useCallback(() => {
    return markAsRead(latest).finally(() => {
      setShowAnnouncement(false);
    });
  }, [markAsRead]);

  useEffect(() => {
    if (userLatestRelease) {
      if (semver.gt(latest, userLatest)) {
        setShowAnnouncement(true);
      }
    } else {
      // First log in user don't show any announcements
      handleDismiss();
    }
  }, [userLatest, userLatestRelease, handleDismiss]);

  return showAnnouncement ? (
    <Modal
      centered
      open
      closable={false}
      maskClosable={false}
      width={isMobile ? '100%' : undefined}
      footer={
        <Button key="back" containerClassName="flex justify-end" variant={Variant.secondary} onClick={handleDismiss}>
          {t('Dismiss')}
        </Button>
      }
    >
      {Announcements.map((AnnouncementVersion, idx) => {
        return (
          <React.Fragment key={idx}>
            <AnnouncementVersion markAsRead={handleDismiss} />
            {idx + 1 !== Announcements.length && <Divider />}
          </React.Fragment>
        );
      })}
    </Modal>
  ) : (
    <></>
  );
};

export default Announcements;
