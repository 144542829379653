import React, { useEffect, useState } from 'react';

import { getAuthorizationCode } from '../adapters/api/auth';
import { Card, Text, TextType } from '../ComponentLibrary/src';
import AuthWallBg from '../components/Bg';
import { useQuery, useSetDocumentTitle } from '../hooks';

/**
 * Used for redirecting from Auth server
 */
function Login(): JSX.Element {
  useSetDocumentTitle('Login');
  const [title, setTitle] = useState('Redirecting...');
  const [message, setMessage] = useState('');
  const params = useQuery();
  const code = params.get('code');
  const error = params.get('error');
  const errorDescription = params.get('error_description');

  useEffect(() => {
    if (error) {
      setTitle(`${error}`);
      setMessage(`${errorDescription}`);
    } else {
      getAuthorizationCode();
    }
  }, [code, error, errorDescription]);

  return (
    <AuthWallBg>
      <Card className="inline-flex flex-col md:p-16 p-8 rounded-lg gap-y-4 max-w-sm max-h-full items-center">
        <Text type={TextType.h1}>Authentication</Text>
        <Text type={TextType.h3}>{errorDescription || title}</Text>
        <Text type={TextType.body}>{message}</Text>
      </Card>
    </AuthWallBg>
  );
}

export default Login;
