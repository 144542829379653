import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, TextType } from '../../../ComponentLibrary/src';

export interface EmptyListProps {
  mode: string;
}

const EmptyList: React.FunctionComponent<EmptyListProps> = ({ mode }) => {
  const { t } = useTranslation('notifications');

  return (
    <Text type={TextType.h3} className="h-64 items-center justify-center" textClassName="text-gray-300" overrideColor>
      {t(`empty_${mode}`)}
    </Text>
  );
};

export default EmptyList;
