import { BaseNotifications, NotificationMode, NotificationPriority, Notifications } from '../types';

export interface UserContextData {
  userNotifications: Notifications;
}

export const defaultBaseNotifications: BaseNotifications = {
  mode: NotificationMode.Whitelist,
  fault: false,
  warning: false,
  info: false,
  connectivity: false,
  expirations: false,
  dailyReport: false,
  releaseNotes: true,
  batchWindows: {
    fault: NotificationPriority.Fast,
    warning: NotificationPriority.Slow,
    info: NotificationPriority.Slow,
    connectivity: NotificationPriority.Slow,
  },
};

export const userContextDefaultValue: UserContextData = {
  userNotifications: {
    orgs: [],
    systems: [],
    ...defaultBaseNotifications,
  },
};
