export interface AssetsProps {
  addDist: string;
  setAddDist: (dist: string) => void;
}

export interface InternalAssetsProps extends AssetsProps {
  pageNumber: number;
  countPerPage: number;
  addDistInOrgs: string;
  orgSearchTerm: string;
  loading?: boolean;
  selectedOrgId?: string;
  selectedSiteId?: string;
  updateAssets: () => Promise<void>;
  updateUnallocatedSystems: () => Promise<void>;
  setLoading: (loading: boolean) => void;
  setOrgSearchTerm: (term: string) => void;
  setAddDistInOrgs: (dist: string) => void;
  onChangeCountPerPage: (count: number) => void;
  onChangePageNumber: (page: number) => void;
}

export enum GasCompositionProps {
  ch4 = 'ch4',
  co2 = 'co2',
  other = 'other',
  ger = 'ger',
}

export interface AssetError {
  id: string;
  error: string;
}
