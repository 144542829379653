import React, { useEffect, useMemo } from 'react';

import { useMobile } from '../util/hooks';
import { TabDesktop } from './Desktop';
import { TabProps } from './index.types';
import { TabMobile } from './Mobile';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const Tab: React.FC<TabProps> = (props: TabProps) => {
  const [value, setValue] = React.useState(props.tabs[0].label);

  useEffect(() => {
    const selectedHasbeenRemoved = props.tabs.every(({ label }) => label !== value);

    if (selectedHasbeenRemoved) {
      setValue(props.tabs[0].label);
    }
  }, [props.tabs, value]);

  const handleChange = (_: unknown, newValue: string) => {
    setValue(newValue);
  };
  const isMobile = useMobile();

  const tabContent = useMemo(() => {
    if (!isMobile) {
      return Array.isArray(props.tabContents)
        ? props.tabContents.filter((child) => {
            return child?.key === value;
          })
        : props.tabContents;
    } else {
      return props.tabContents;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabContents, value]);

  const internalProps = {
    ...props,
    value,
    tabContent,
    onChange: handleChange,
  };

  return isMobile ? <TabMobile {...internalProps} /> : <TabDesktop {...internalProps} />;
};

export * from './index.types';
