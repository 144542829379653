import { isEqual } from 'lodash';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FilterKey } from '../ComponentLibrary/src/Filter';
import {
  filterToSearchParams,
  searchParamsToFilter,
  searchTermFromUrl,
  searchTermToUrl,
} from '../ComponentLibrary/src/Filter/util';
import { screenSizeMediaQuery } from '../ComponentLibrary/src/util/constants';

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export function usePrevious(value: unknown): unknown {
  const ref = useRef<unknown | null>(null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useMounted(): boolean {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted.current;
}

export function useMobile(): boolean {
  return useMediaQuery(screenSizeMediaQuery.mobile);
}

export function useSetDocumentTitle(title: string): void {
  useEffect(() => {
    document.title = `${title} | ${process.env.REACT_APP_NAME}`;
  }, [title]);
}

// REFACTORME: should move this to a context or something so handlers can be added throughout the app
export function useKeyPress(callback: (keys: { key: string; metaKey?: boolean }) => void, deps?: unknown[]): void {
  useEffect(() => {
    const isMac = () => {
      return navigator.platform.match(/Mac/);
    };

    const isPlatformMetaKey = (event: Event) => {
      if (!(event instanceof KeyboardEvent)) {
        return false;
      }

      return event[platformMetaKey];
    };

    const platformMetaKey = isMac() ? 'metaKey' : 'ctrlKey';

    const handler = (e: KeyboardEvent) => {
      callback({
        key: e.key,
        metaKey: isPlatformMetaKey(e),
      });
    };

    document.onkeydown = handler;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || []);
}

/**
 * Creates and stores pagination parameters in state and syncs with query parameters in the url
 * @param params initial values
 * @returns pagination paratemeters and their setters
 */
export function usePaginationQuery({
  page = 1,
  pageSize = 20,
  sort = '',
  order = 1,
  filter = [],
  focusedItem = undefined,
  textSearch = undefined,
}: {
  page?: number;
  pageSize?: number;
  sort?: string;
  order?: 1 | -1;
  filter?: FilterKey[];
  focusedItem?: string;
  textSearch?: string;
}): {
  page: number;
  setPage: Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: Dispatch<React.SetStateAction<number>>;
  sort: string;
  setSort: Dispatch<React.SetStateAction<string>>;
  order: 1 | -1;
  setOrder: Dispatch<React.SetStateAction<1 | -1>>;
  filter: FilterKey[];
  setFilter: Dispatch<React.SetStateAction<FilterKey[]>>;
  focusedItem: string | undefined;
  setFocusedItem: Dispatch<React.SetStateAction<string | undefined>>;
  textSearch: string | undefined;
  setTextSearch: Dispatch<React.SetStateAction<string | undefined>>;
} {
  const isMounted = useMounted();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page') ? parseInt(searchParams.get('page') as string) : page;
  const pageSizeParam = searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize') as string) : pageSize;
  const sortParam = searchParams.get('sort') ? (searchParams.get('sort') as string) : sort;
  // TODO: figure this out, what is order
  const orderParam = searchParams.get('order') ? parseInt(searchParams.get('order') as string) : order;
  const focusedItemParam = searchParams.get('focusedItem') ? searchParams.get('focusedItem') ?? undefined : focusedItem;
  const textSearchParam = searchParams.get('q') ? (searchParams.get('q') as string) : textSearch;

  const [_filter, setFilter] = useState(searchParamsToFilter(searchParams, filter));
  const [_page, setPage] = useState(pageParam);
  const [_pageSize, setPageSize] = useState(pageSizeParam);
  const [_sort, setSort] = useState(sortParam);
  const [_order, setOrder] = useState<1 | -1>(orderParam as 1 | -1);
  const [_focusedItem, setFocusedItem] = useState(focusedItemParam);
  const [_textSearch, setTextSearch] = useState(textSearchParam);

  // update url params
  useEffect(() => {
    let newSearch = new URLSearchParams({
      ...Object.fromEntries(searchParams),
    });

    // if param is not default or it's key already exists in the query, set it
    if (_page !== page || searchParams.get('page')) {
      newSearch.set('page', _page.toString());
    }
    if (_pageSize !== pageSize || searchParams.get('pageSize')) {
      newSearch.set('pageSize', _pageSize.toString());
    }
    if (_sort !== sort || searchParams.get('sort')) {
      newSearch.set('sort', _sort);
    }
    if (_order !== order || searchParams.get('order')) {
      newSearch.set('order', _order.toString());
    }

    _focusedItem ? newSearch.set('focusedItem', _focusedItem ?? '') : newSearch.delete('focusedItem');
    _textSearch ? newSearch.set('q', _textSearch ?? '') : newSearch.delete('q');
    newSearch = filterToSearchParams(_filter, newSearch);

    // if one of these things changes (and page is defined), go to default page, another approach would be to store all of these params on one object in state
    if (
      isMounted &&
      (!isEqual(_filter, searchParamsToFilter(searchParams, _filter)) ||
        _textSearch !== textSearchParam ||
        _pageSize !== pageSizeParam ||
        _focusedItem ||
        _sort !== sortParam)
    ) {
      newSearch.delete('page');
    }

    // sort the two search params and check for equality
    searchParams.sort();
    newSearch.sort();
    if (searchParams.toString() !== newSearch.toString()) {
      setSearchParams(newSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_page, _pageSize, _sort, _order, _filter, _textSearch]);

  useEffect(() => {
    const newFilter = searchParamsToFilter(searchParams, _filter);
    const pageParam = searchParams.get('page') ? parseInt(searchParams.get('page') as string) : page;
    const pageSizeParam = searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize') as string) : pageSize;
    const sortParam = searchParams.get('sort') ? (searchParams.get('sort') as string) : sort;
    const orderParam = searchParams.get('order') ? parseInt(searchParams.get('order') as string) : order;
    const focusedItemParam = searchParams.get('focusedItem')
      ? searchParams.get('focusedItem') ?? undefined
      : focusedItem;
    const textSearchParam = searchParams.get('q') ? (searchParams.get('q') as string) : textSearch;

    if (isMounted) {
      if (!isEqual(newFilter, _filter)) {
        setFilter(newFilter);
      }

      if (!focusedItemParam) setPage(pageParam);
      setPageSize(pageSizeParam);
      setSort(sortParam);
      setOrder(orderParam as 1 | -1);
      setFocusedItem(focusedItemParam);
      setTextSearch(textSearchParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    page: _page,
    setPage,
    pageSize: _pageSize,
    setPageSize,
    sort: _sort,
    setSort,
    order: _order,
    setOrder,
    filter: _filter,
    setFilter,
    focusedItem: _focusedItem,
    setFocusedItem,
    textSearch: _textSearch,
    setTextSearch,
  };
}

export function useSearchQuery(): {
  searchTerm: string;
  setSearchTerm: Dispatch<React.SetStateAction<string>>;
} {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearchTerm] = useState<string>(searchTermFromUrl(searchParams));

  // update url params
  useEffect(() => {
    setSearchParams(searchTermToUrl(search, searchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    searchTerm: search,
    setSearchTerm,
  };
}
