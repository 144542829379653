import QueryString from 'qs';

import { MenuItem } from '../../../ComponentLibrary/src';
import { CurrentSystemData, DataResponse, Interval } from '../../../context/Systems';
import { OnFailRequest } from '../../../types';
import { Method } from '../../../util/types';
import Api from '..';
import { getApiUrl } from '../auth';

export async function fetchSystemData(
  sysId: string,
  params: { interval?: Interval; types?: string[]; startDate?: string; endDate?: string },
  onFail?: OnFailRequest,
): Promise<DataResponse | void> {
  return Api.request({
    method: Method.get,
    path: `systems/data/${sysId}`,
    params,
    onFail,
  }) as Promise<DataResponse>;
}

export async function fetchCurrentSystemData(sysId: string, onFail?: OnFailRequest): Promise<CurrentSystemData | void> {
  return Api.request({
    method: Method.get,
    path: `systems/data/${sysId}/latest`,
    onFail,
  }) as Promise<CurrentSystemData>;
}

export function downloadSystemData({
  sysId,
  types,
  startDate,
  endDate,
  interval,
}: {
  sysId: string;
  types?: string[];
  startDate?: string;
  endDate?: string;
  interval?: Interval;
}): void {
  const params = {
    types,
    startDate,
    endDate,
    interval,
  };

  const apiUrl = getApiUrl();

  const downloadUrl = `${apiUrl}/systems/data/csv/${sysId}?${QueryString.stringify(params, {
    arrayFormat: 'repeat',
  })}`;
  window.open(downloadUrl, '_blank');
}

export async function fetchSystemDataKeys(sysId: string, onFail?: OnFailRequest): Promise<MenuItem[] | void> {
  return Api.request({
    method: Method.get,
    path: `systems/data/keys/${sysId}`,
    onFail,
  }) as Promise<MenuItem[]>;
}
