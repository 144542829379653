import React from 'react';

import { Card, Link } from '../ComponentLibrary/src';
import logo from '../image/QD.png';

export default function Terms(): JSX.Element {
  return (
    <div className="flex justify-center">
      <Card className="flex flex-col sm:m-4 p-4 sm:p-8 md:p-16 terms w-full xl:w-2/3">
        <div className="flex justify-center">
          <img src={logo} className="h-12" />
        </div>
        <h1>Terms of Use</h1>
        <ol className="top-list">
          <li>
            <h2>General</h2>
            <ol>
              <li>
                These Terms of Use (the <strong>“Terms”</strong>) are a legally binding agreement between the User (
                <strong>“you”</strong>) and Qnergy Inc., (<strong>“Qnergy”</strong>, <strong>“us”</strong> or{' '}
                <strong>“we”</strong>). Please carefully read the following Terms before using the Platform to be fully
                aware of the legal rights and obligations of the User with respect thereto.
              </li>
              <li>
                These Terms govern your access to and use of the Platform, the Content, the Services (as such terms are
                defined below) and the features contained therein.
              </li>
              <li>
                Except where these Terms explicitly refer to the Customer, Customer User, Reseller User or Qnergy User,
                the provisions of these Terms should be regarded as applicable to all of the above.
              </li>
              <li>The term “including” is not intended to be exclusive and means “including without limitation”.</li>
            </ol>
          </li>
          <li>
            <h2>Definitions</h2>
            <p>For the purpose of these Terms:</p>
            <p>
              <strong>“Account”</strong> means a Qnergy User Account, a Customer User Account or a Reseller User
              Account, where each Account has its own user login credentials.
            </p>
            <p>
              <strong>“Admin User”</strong> means an administrator user account designated by the Customer to manage on
              behalf of the Customer the internal authorization matrix of Customer Users that are entitled to enter and
              use the Platform, all based on the administrator features available on the Platform. The Admin User may be
              either a Qnergy User or a Customer User.
            </p>
            <p>
              <strong>“Content”</strong> means any input or data uploaded, downloaded, displayed or otherwise available
              on the Platform, whether published and/or uploaded and/or posted by Qnergy or by a User, including, but
              not limited to text, photographs, graphs, maps or any other form of information.
            </p>
            <p>
              <strong>“Customer”</strong> means a Customer that has entered into a binding contract with Qnergy with
              respect to the use of the Platform and to whom Qnergy offers the Services provided through the Platform.
            </p>
            <p>
              <strong>“Customer User”</strong> means any employee, agent, representative or independent contractor of
              the Customer and any other person or entity who are authorized by the Customer to use the Platform or the
              Services on behalf of the Customer.
            </p>
            <p>
              <strong>“Customer User Account”</strong> means an account created either by Qnergy, a Customer or a
              Reseller on the Platform for a Customer User.
            </p>
            <p>
              <strong>“Documentation”</strong> means the documentation, which may be provided by Qnergy as part of the
              Services, which may include, without limitation, manuals, user guides, and training materials, but
              excluding source code.
            </p>
            <p>
              <strong>“External Sites”</strong> means third-party websites, plug-ins, applications or a Customer&apos;s
              intranet.
            </p>
            <p>
              <strong>“Intellectual Property Rights”</strong> means all intellectual, moral, industrial and/or
              proprietary property rights, now or hereafter, recognized under any applicable law anywhere in the world,
              whether issued or pending, registered or unregistered, including, but not limited to (i) all forms of
              patents; (ii) inventions, discoveries, (whether patentable or not); (iii) rights associated with works of
              authorship, including but not limited to copyrights and mask works; (iv) trademarks and service marks,
              trade names, domain name registration; (v) designs (whether or not capable of registration), design
              rights; (vi) database rights; (vii) trade secrets and know-how; (viii) all rights to confidential or
              proprietary information; any rights analogous to those mentioned herein; all derivative works thereof; and
              any current or future applications, renewals, extensions, provisionals, continuations,
              continuations-in-part, divisions, re-exams and reissues thereof, and all of the tangible embodiments
              thereof;
            </p>
            <p>
              <strong>“Platform”</strong> means Qnergy&apos;s proprietary software titled “Qnergy Dashboard” (or any
              subsequent title), in its latest version, and all Documentation, Content, and Services which will be made
              available by Qnergy to the Customer, its Customer Users and the Resellers as a web-based platform service
              via the internet, developed and managed by Qnergy.
            </p>
            <p>
              <strong>“Qnergy User”</strong> means any employee, agent, representative or independent contractor of
              Qnergy and any other person or entity who are authorized by Qnergy to use and/or manage the Platform or
              the Services on behalf of Qnergy.
            </p>
            <p>
              <strong>“Qnergy User Account”</strong> means an account created on the Platform for each Qnergy User.
            </p>
            <p>
              <strong>“Reseller”</strong> means a reseller, distributor or agent that has entered into a binding
              contract with Qnergy with respect to the distribution and/or resale of the Services and who is authorized
              to offer the Services provided through the Platform to third parties on behalf of Qnergy, as well as to
              set-up Customer Accounts.
            </p>
            <p>
              <strong>“Reseller User”</strong> means any person or entity who is authorized by the Reseller to use the
              Platform or the Services, and to set-up Customer Users and subsequent Customer Accounts, subject to the
              authorization granted to the Reseller by Qnergy.
            </p>
            <p>
              <strong>“Reseller User Account”</strong> means an account created on the Platform for each Reseller User.
            </p>
            <p>
              <strong>“Services”</strong> means the services provided through the Platform to the Customer and its
              Customer Users and the Reseller and its Reseller Users, including services provided and/or maintained by
              Qnergy Users, which may include the ability to view, upload and/or download Content to/from the Platform,
              and any other action, service or item supported by or available through the Platform.
            </p>
            <p>
              <strong>“User”</strong> means a Customer User, a Reseller User or a Qnergy User.
            </p>
          </li>
          <li>
            <h2>Accepting the Terms of Use</h2>
            <ol>
              <li>
                These Terms shall become effective between you and Qnergy as of the date on which you first commenced
                use of, or otherwise accessed, the Platform or any part thereof (<strong>“Commencement Date”</strong>).
                Upon accessing the Platform, you hereby accept the terms and conditions contained herein and agree to be
                bound by these Terms with respect to the access and use of the Platform and the Services.
              </li>
              <li>
                The Customer or Reseller, as applicable, represents and warrants that: (a) the execution of these Terms
                by a Customer User or Reseller User, shall constitute execution of these Terms by the Customer or by the
                Reseller, as applicable, on behalf of whom the Customer User or the Reseller User accesses or uses the
                Platform; (b) these Terms constitute a legal, valid and binding obligation of the Customer or the
                Reseller, as applicable, and are enforceable against the Customer and the Customer User or against the
                Reseller and the Reseller User; and (c) any breach of these Terms by a Customer User or a Reseller User,
                as applicable, or any other employee, service provider or contractor of the Customer or the Reseller,
                anyone acting on the Customer&apos;s or Reseller&apos;s behalf (even if not employed or engaged by the
                Customer or the Reseller, or otherwise a party to a business relationship with the Customer or the
                Reseller at the time the breach occurred), or anyone that received access to the Platform or any part
                thereof, directly or indirectly, from the Customer or any Customer User or the Reseller or any Customer
                Reseller, shall be deemed a breach of these Terms by the Customer or Reseller, as applicable.
              </li>
              <li>
                Qnergy&apos;s
                <Link href="/privacy" inline color="green">
                  {' '}
                  Privacy Policy{' '}
                </Link>
                applies to the use of this Platform. By using and/or accessing the Platform and/or Services you
                represent and warrant that you have read and understood the Privacy Policy and agree to its terms.
              </li>
            </ol>
          </li>
          <li>
            <h2>The Platform</h2>
            <ol>
              <li>
                You expressly acknowledge and agree that any access to, or use of, the Platform and/or any Content, and
                any consequences thereof, are at your sole risk, responsibility and liability and shall be in accordance
                with these Terms.
              </li>
              <li>The form and/or features of the Platform may change from time to time without prior notice.</li>
              <li>
                Qnergy may stop (permanently or temporarily) operating the Platform and/or the Services and/or
                displaying any of the Content (or any parts thereof) and/or restrict or terminate the use of, or access
                to, the Platform, the Services and/or any Account if Qnergy believes that the Customer, the Reseller
                and/or the User is in violation of any provision of these Terms and/or if required to do so in order to
                comply with any applicable laws, statutes, directives, regulations or any other legislation, code of
                practice or regulatory rules in force in any applicable legal jurisdiction, without liability to Qnergy.
              </li>
              <li>
                The Platform is designed and shall be used only for the Services provided by Qnergy. You are solely
                responsible for the safe-keeping, storage and back-up of any Content uploaded, downloaded or otherwise
                made available to you or by you in connection with the Platform and/or the Services.
              </li>
            </ol>
          </li>
          <li>
            <h2>Limited License</h2>
            <ol>
              <li>
                Qnergy hereby grants the User a limited, revocable, non-exclusive, non-transferable, non-sublicensable
                license to access and use the Platform, the Services and the Content, all under the condition of
                complying with all obligations under these Terms, and the payment of all applicable Fees (to the extent
                applicable) (the <strong>“License”</strong>).
              </li>
              <li>The License is personal and may be used only by a single identified User for his/her Account.</li>
              <li>Except as explicitly mentioned herein, no other rights, implied or otherwise, are granted.</li>
            </ol>
          </li>
          <li>
            <h2>The Content</h2>
            <ol>
              <li>
                Qnergy does not warrant against deletion of any Content or the failure to recreate or re-upload any
                Content to or on the Platform whether it was published and/or uploaded by Qnergy or by any other person
                or entity.
              </li>
              <li>
                Qnergy does not guarantee and/or warrant that the Content is true, accurate, up-to-date, appropriate for
                use and/or compatible with any law, regulation or other legal requirement.
              </li>
              <li>
                Any use of the Content and/or the Services is at your own risk. You are solely responsible and liable
                for any act or omission (including any decision and/or transaction) based on the Content. Qnergy bears
                no responsibility whatsoever with respect to any Content and/or any such act or omission.
              </li>
              <li>
                You are solely liable for the accuracy and/or reliability of any Content that you present, upload and/or
                disseminate through the Platform.
              </li>
              <li>
                Qnergy shall have the right to collect and analyze data relating to the provision, use, and performance
                of various aspects of the Platform and Services (including, without limitation, information concerning
                Content and data derived therefrom), and Qnergy will be free to (i) use and otherwise exploit such
                information and data to monitor, improve and enhance the Platform and Services and for other
                development, diagnostic and corrective purposes in connection with the Platform and Services, and (ii)
                use, reproduce and disclose Platform information, data and material (including Content) that is
                anonymized, de-identified, aggregate or otherwise rendered not reasonably associated or linked to a User
                (<strong>“De-Identified Data”</strong>) for product improvement and other lawful purposes, and such
                De-Identified Data will be owned by Qnergy, and Qnergy shall have no obligation to pay royalties or any
                other payment whatsoever with respect thereto.
              </li>
              <li>
                The Customer hereby acknowledges that any access by the Customer, the Reseller, and their respective
                Customer Users and Reseller Users, to Content through the Platform will be revoked following the
                expiration or termination of the Services or these Terms.
              </li>
            </ol>
          </li>
          <li>
            <h2>Accounts</h2>
            <ol>
              <li>
                You represent and warrant that any information that you provide to Qnergy, including upon the creation
                or activation of an Account shall be current, complete, and accurate.
              </li>
              <li>Each Account is designated for use by one individual User.</li>
              <li>
                You may not use or share anyone else&apos;s Account or permit anyone else to use or share his/her
                Account. You are solely responsible for maintaining the confidentiality of your Account password. Each
                of the Customer, the Reseller and the User agree to notify (and the Customer and the Reseller agrees to
                make sure that the respective Customer User or Reseller User notifies) Qnergy immediately of any
                unauthorized access or use of an Account or any other breach of security. Qnergy will not be liable for
                any loss or damage resulting from the use of an Account or an Account password by any person other than
                the User, and the Customer or the Reseller, as applicable, will be held liable for losses incurred by
                Qnergy and/or any third party as a result of such use.
              </li>
              <li>
                Certain areas of the Platform and certain Content may be accessed and/or managed solely using an Admin
                Account (<strong>“Restricted Areas”</strong>). Any use of an Admin Account by a User which was not
                granted such an account in order to access and/or otherwise use such Restricted Areas or any information
                or functions therein is strictly forbidden.
              </li>
            </ol>
          </li>
          <li>
            <h2>Fees</h2>
            <ol>
              <li>
                <u>Payments</u>. In consideration for the right to use the Platform and related Services as set forth
                herein, Customer, shall pay Qnergy the annual subscription fees, set forth in the applicable agreement
                and/or purchase order (the <strong>“Fees”</strong>). Unless specifically agreed otherwise in writing or
                otherwise set forth in the applicable agreement and/or purchase order, all payment obligations are
                non-cancelable, and Fees paid are nonrefundable.
              </li>
              <li>
                <u>Payment Terms</u>. The Fees shall be paid to Qnergy on terms set forth in the applicable agreement
                and/or purchase order against proper invoice. The Fees shall be paid in the currency stated in the
                applicable agreement and/or purchase order by the means specified therein.
              </li>
            </ol>
          </li>
          <li>
            <h2>External Sites</h2>
            <ol>
              <li>The Services may enable access to External Sites.</li>
              <li>Use of an External Site is subject to the terms of use of such External Site.</li>
              <li>
                The Customer acknowledges and agrees that Qnergy is not responsible for any External Site, and shall
                have no liability or responsibility whatsoever to the Customer or any third party regarding any access
                to and/or use of any External Site or any content therein.
              </li>
            </ol>
          </li>
          <li>
            <h2>Restrictions on Content and Use of the Platform</h2>
            <ol>
              <li>
                The User may not do any of the following while accessing the Platform or using the Services and the
                Customer and the Reseller shall not permit or encourage any Customer User, Reseller User or third party,
                to directly or indirectly:
                <ol>
                  <li>
                    use the Platform and/or the Services other than in compliance with these Terms and all applicable
                    local, state, federal, and national, laws, statutes, ordinances, rules and regulations;
                  </li>
                  <li>facilitate or encourage any violation of these Terms;</li>
                  <li>
                    except as otherwise provided in these Terms, copy, reproduce, print, download or save a copy,
                    republish, display, perform, advertise, distribute, transmit, broadcast, decompile, reverse
                    engineer, disassemble, attempt to derive the source code of, adapt, modify, create derivative works
                    from, sell, rent, lease, license, loan or otherwise make available or exploit in any form or by any
                    means all or any portion of the Platform or any Content, for any purpose;
                  </li>
                  <li>
                    remove or alter any patent numbers, copyright notices, trademark notices or other proprietary
                    notices or identifying marks, symbols or legends included in the Platform;
                  </li>
                  <li>use the Platform for any unlawful purpose or for promotion of illegal activities;</li>
                  <li>
                    use the Platform in a way that is harmful, fraudulent, deceptive, threatening, harassing,
                    defamatory, obscene, or otherwise objectionable;
                  </li>
                  <li>
                    use the Platform for interfering with, or disrupting (or attempting to do so), the access of any
                    person, host or network, including, without limitation, by uploading and/or sending software viruses
                    or any other computer code, files or programs designed to interrupt, destroy or limit the
                    functionality of any computer software or hardware; overloading, flooding, spamming, mail-bombing;
                    or by scripting the creation of Content in such a manner as to interfere with or create an undue
                    burden on the Platform;
                  </li>
                  <li>do anything that could disable, overburden, or impair the proper activity of the Platform;</li>
                  <li>
                    provide any false information upon registration to the Platform or when updating or creating any
                    Content;
                  </li>
                  <li>attempt to obtain an Account (or login credentials) of another person;</li>
                  <li>
                    use the Platform for the benefit of a person other than Qnergy or the applicable Customer or
                    Reseller;
                  </li>
                  <li>
                    use the Platform in a manner that violates or infringes any rights of any third party, including but
                    not limited to, privacy rights, publicity rights or Intellectual Property Rights; circumvent,
                    disable or otherwise interfere with security-related features of the Platform or features that
                    enforce use limitations;
                  </li>
                  <li>
                    use any robot, spider, or other automated device, process or means to access, retrieve, scrape or
                    index any portion of the Platform and/or Services;
                  </li>
                  <li>deep-link to the Platform and/or the Services without our consent;</li>
                  <li>
                    engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the
                    Services;
                  </li>
                  <li>
                    disclose to any third party any Content without Qnergy&apos;s advance written consent to disclose;
                  </li>
                  <li>
                    access or use any part of the Platform or Services for competitive analysis or to build a product or
                    service which competes with the Platform or Services; or
                  </li>
                  <li>
                    use the Platform or Services in a manner that could otherwise cause damage to Qnergy or any third
                    party.
                  </li>
                </ol>
              </li>
              <li>
                The Customer and the Reseller are solely responsible and liable for, and Qnergy has no responsibility to
                Customer, Reseller, or any third party for any breach by Customer or its Customer Users, or the Reseller
                or its Reseller Users, of their obligations under these Terms and for any consequences thereof
                (including any loss or damage which Qnergy may suffer).
              </li>
            </ol>
          </li>
          <li>
            <h2>No Warranty</h2>
            <ol>
              <li>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE PLATFORM, THE SERVICES AND THE CONTENT ARE
                PROVIDED “AS IS” AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND QNERGY HEREBY
                DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE PLATFORM, THE SERVICES AND THE CONTENT,
                EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF COMPLETENESS,
                ACCURACY, AVAILABILITY, TIMELINESS, USEFULNESS, SECURITY, RELIABILITY OR NON-INFRINGEMENT OF THIRD PARTY
                RIGHTS, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OR OF FITNESS FOR A
                PARTICULAR PURPOSE.
              </li>
              <li>
                Some jurisdictions do not allow the exclusion of implied warranties or limitations on applicable
                statutory rights of a consumer, so the above exclusion and limitations may not apply to you in such
                jurisdictions.
              </li>
            </ol>
          </li>
          <li>
            <h2>Limitation of Liability</h2>
            <ol>
              <li>
                TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT SHALL QNERGY, ITS DIRECTORS, OFFICERS,
                EMPLOYEES AND/OR AGENTS, BE LIABLE FOR (A) ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE,
                EXEMPLARY, OR CONSEQUENTIAL DAMAGES WHATSOEVER, (B) LOSS OF REVENUES, LOSS OF PROFITS, LOSS OF DATA,
                LOSS OF USE, LOSS OF GOODWILL, BUSINESS INTERRUPTION OR ANY OTHER LOSS, INJURY, CLAIM, LIABILITY, OR
                DAMAGE OF ANY KIND, RESULTING IN ANY WAY FROM (I) CUSTOMER, RESELLER AND THEIR RESPECTIVE CUSTOMER USERS
                AND RESELLER USERS, OR ANY OTHER USER, ACCESS TO, OR USE, INABILITY TO USE, OR RELIANCE ON THE PLATFORM,
                THE SERVICES, ANY CONTENT AND/OR EXTERNAL SITE, (II) ANY ERRORS, OMISSIONS OR OTHER INACCURACIES IN ANY
                CONTENT INCLUDED IN THE PLATFORM AND/OR IN ANY EXTERNAL SITE, (III) ANY OTHER MATTER RELATING TO THE
                PLATFORM, THE SERVICES, THE CONTENT AND/OR ANY EXTERNAL SITE, REGARDLESS OF THE THEORY OF LIABILITY
                (WHETHER BASED ON CONTRACT, TORT OR OTHERWISE), EVEN IF QNERGY HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES AND EVEN IF A REMEDY SET FORTH HEREIN FAILS OF ITS ESSENTIAL PURPOSE, (C) ANY CLAIMS OF
                CUSTOMER USERS AND/OR RESELLERS USERS IN CONNECTION WITH THE PLATFORM, OR FOR (D) ANY THIRD PARTY CLAIMS
                AGAINST THE CUSTOMER, THE RESELLER, A CUSTOMER USER, A RESELLER USER OR ANY OTHER USER. NOTWITHSTANDING
                THE AFORESAID, NOTHING HEREIN EXCLUDES OR LIMITS LIABILITY FOR ANY MATTER FOR WHICH IT IS NOT PERMITTED
                BY LAW TO EXCLUDE OR LIMIT, OR TO ATTEMPT TO EXCLUDE OR LIMIT LIABILITY.
              </li>
              <li>
                IN NO EVENT SHALL THE TOTAL LIABILITY OF QNERGY, ITS DIRECTORS, OFFICERS, EMPLOYEES AND/OR AGENTS, TO
                CUSTOMER, RESELLER ANY CUSTOMER USER, ANY RESELLER USER, ANY OTHER USER, AND/OR ANY THIRD PARTY, FOR ALL
                DAMAGES EXCEED THE GREATER OF (A) ONE THOUSAND U.S DOLLARS (USD 1,000), OR (B) THE AMOUNT OF FEES PAID
                BY THE CUSTOMER TO QNERGY FOR THE SERVICES PROVIDED IN THE TWELVE (12) MONTHS PRIOR TO THE ACT THAT GAVE
                RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT QNERGY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES.
              </li>
              <li>
                Any cause of action by the Customer with respect to the Platform and/or the Services, must be instituted
                within one (1) year after the cause of action arose.
              </li>
              <li>
                Some jurisdictions do not allow the exclusion or limitation of liability for personal injury, or of
                incidental or consequential damages, so the limitations above may not apply to you in such
                jurisdictions.
              </li>
            </ol>
          </li>
          <li>
            <h2>Indemnity</h2>
            <p>
              The Customer and the Reseller, as applicable, shall indemnify and hold Qnergy and its directors, officers,
              employees, agents, partners, and licensors harmless from and against all damages, losses, and expenses of
              any kind (including reasonable legal fees and costs), related to any demand or claim brought against
              Qnergy by a Customer User or a Reseller User or any third party, as applicable, due to or in connection
              with any Content that was published, posted and/or uploaded on the Platform, use of the Platform or the
              Services, violation of these Terms and/or any applicable law, and/or the violation of any rights of
              another party, by the Customer, the Reseller, or any of their Customer Users or Resellers users, as
              applicable.
            </p>
          </li>
          <li>
            <h2>Ownership</h2>
            <ol>
              <li>
                You hereby acknowledge that all Intellectual Property Rights, ownership, title and interest in the
                Platform and the Content (excluding External Sites) and the related patent rights, copyrights, trade
                secrets, trademarks and all other related Intellectual Property Rights, are and shall remain the sole
                and exclusive property of Qnergy. Without derogating from the above, Qnergy reserves all proprietary
                rights in and to all designs, engineering details and other data pertaining to the Platform and the
                Content.
              </li>
              <li>
                The Platform contains proprietary information, material and trade secrets that are owned by Qnergy, and
                are protected by applicable intellectual property and other laws, including but not limited to
                copyright.
              </li>
              <li>
                All copyrights in and to the Platform and the Content, are owned solely and exclusively by Qnergy,
                (and/or by its licensors), who reserve all their rights in law and equity. You are not granted with any
                right and/or license, or ownership including any copyright, trademark or other Intellectual Property
                Rights to the Platform or to any Content, other than as explicitly set forth in these Terms.
              </li>
              <li>
                THE USE OF THE PLATFORM, THE SERVICES, THE CONTENT OR ANY PART THEREOF, OTHER THAN AS PERMITTED IN THESE
                TERMS, IS STRICTLY PROHIBITED AND INFRINGES ON THE INTELLECTUAL PROPERTY RIGHTS OF QNERGY AND/OR OF
                OTHERS AND MAY BE SUBJECT TO CIVIL AND CRIMINAL PENALTIES, INCLUDING POSSIBLE MONETARY DAMAGES FOR
                COPYRIGHT INFRINGEMENT.
              </li>
              <li>Qnergy reserves all rights not expressly granted under these Terms.</li>
              <li>
                You agree that the “Qnergy” trademark, trade name, service mark, graphics, logo and other brand features
                used in connection with the Platform and the Services, are trademarks or registered trademarks of Qnergy
                (collectively, the <strong>“Qnergy Marks”</strong>). Nothing in these Terms gives a right to use or
                display Qnergy Marks in any manner.
              </li>
              <li>
                You may, at your sole discretion, provide your input regarding the Services and/or the Platform,
                including, without limitation, comments or suggestions regarding the possible creation, modification,
                correction, improvement or enhancement of the Services, products and/or services (collectively{' '}
                <strong>“Feedback”</strong>). We shall be entitled to use Feedback for any purpose without notice,
                restriction or remuneration of any kind to you and/or your representatives. The use of the Feedback, if
                any, shall be at Qnergy&apos;s sole discretion, and Qnergy, in no way, shall be obliged to make use of
                any kind of the Feedback or part thereof. Qnergy shall be the sole owner of any rights in any
                inventions, developments, improvements, know-how, concepts, techniques, or other intellectual property
                rights in the Feedback and/or developed or conceived by Qnergy as a result of any Feedback and you
                hereby permanently assign to Qnergy, any ownership rights and any other right in the Feedback and waive
                any moral rights you may have thereto. You acknowledge and agree that you will not improperly use or
                disclose to us any confidential information or trade secrets of any third parties and will not breach
                any obligation of confidentiality that you may have to any third party.
              </li>
            </ol>
          </li>
          <li>
            <h2>Termination</h2>
            <ol>
              <li>
                These Terms shall be binding upon you as of the Commencement Date, and for as long as you or anyone
                acting on your behalf continues to use the Platform, and shall remain in effect until the expiration or
                replacement of these Terms with new terms (consensually or pursuant to the provisions of these Terms).
              </li>
              <li>
                Without derogating from Qnergy&apos;s right to terminate these Terms, Qnergy, at its sole discretion,
                without notice to you, may terminate these Terms upon failure of you or anyone acting on your behalf to
                comply (and in the case of a Customer or a Reseller, also in the case of failure to make sure that their
                Customer Users and Resellers Users comply) with any of the provisions of these Terms, or failure by the
                Customer to pay, for any reason, applicable Fees, without prejudice to the right of Qnergy to be
                indemnified for its damages and for any other right and remedy. It is hereby clarified, that although
                Qnergy has no obligation to monitor your use of the Services, Qnergy may do so at its own discretion and
                may terminate these Terms, as detailed above, if Qnergy believes that any use of the Services may be (or
                is alleged to be) in violation of these Terms or applicable laws, regulations, guidelines, professional
                standards, codes of conduct, etc.
              </li>
              <li>
                In all such cases, these Terms shall terminate, but the provisions which by their nature would continue
                beyond termination (including, without limitation, the provisions of Sections 11, 12, 13, 14, 15, 17 and
                18), shall survive such termination for any reason and shall continue to apply.
              </li>
              <li>
                Upon termination of these Terms: (a) the License shall immediately expire; (b) the User shall cease any
                use of the Platform and/or the Services; (c) the User will lose any access to the Content; and (d)
                Qnergy reserves the right (at its sole discretion) to permanently delete any Content at any time
                following the effective date of termination of these Terms, and you hereby waive any rights and/or
                remedies with respect to such deletion of Content.
              </li>
            </ol>
          </li>
          <li>
            <h2>Relationship between the parties</h2>
            <ul>
              <li>
                Nothing contained in these terms shall be construed as creating a partnership, joint venture, agency or
                other similar relationship between you and Qnergy, nor as granting you the right, power, or authority
                (express or implied) to bind or otherwise create any duty or obligation for Qnergy.
              </li>
            </ul>
          </li>
          <li>
            <h2>Governing Law and Jurisdiction</h2>
            <p>
              These Terms shall be governed and construed in accordance with the substantive laws of the state of Utah,
              United States, without reference to its conflicts of laws principles. The United Nations Convention on
              Contracts for the International Sale of Goods will not apply to these Terms. You hereby irrevocably submit
              to the exclusive jurisdiction of the courts of Utah, United States, to resolve any dispute arising out of
              or pursuant to these Terms, and you hereby consent to the exclusive jurisdiction of and venue in such
              courts and waive any objection as to inconvenient forum. Notwithstanding the above, you agree that Qnergy
              shall still be allowed to apply for injunctive remedies in any jurisdiction.
            </p>
          </li>
          <li>
            <h2>Miscellaneous</h2>
            <ol>
              <li>
                <u>Entire Agreement</u>. These Terms contain the complete agreement between you and Qnergy regarding
                your use of the Platform and/or Services and supersede and replace all prior or agreements or
                understandings, written or oral, with respect thereto, excluding the agreement entered into between
                Qnergy and the Customer or the Reseller, as applicable.
              </li>
              <li>
                <u>Severability</u>. In the event that any provision of these Terms is held to be invalid or
                unenforceable, that provision shall be construed, limited, modified or deleted, to the extent necessary
                to eliminate any invalidity or unenforceability, and the remaining provisions of these Terms remain in
                full force and effect.
              </li>
              <li>
                <u>Waiver</u>. No waiver on the part of Qnergy of any right under these Terms shall be effective unless
                in writing and signed by Qnergy&apos;s duly authorized representative. No waiver on the part of Qnergy
                of any past or present right arising from any breach or failure to perform shall be deemed as a waiver
                of any future right arising under these Terms.
              </li>
              <li>
                <u>Assignment</u>. You may not assign, subcontract or otherwise transfer any of your rights and/or
                obligations under these Terms. Qnergy may assign and/or subcontract some or all of these Terms to any
                third party in connection with a merger, acquisition, sale of assets, by operation of law, or otherwise.
                These Terms shall be binding upon, and shall inure to the benefit of, the parties hereto and their
                respective successors and permitted assigns.
              </li>
              <li>
                <u>No Third-Party Beneficiaries.</u> These Terms do not create any obligation of Qnergy to any third
                parties, nor shall they be deemed to create any rights or causes of action on behalf of any third
                parties.
              </li>
              <li>
                <u>Changes to these Terms</u>. Qnergy may change these Terms from time to time, at its own reasonable
                discretion and encourages you to review them periodically. Qnergy will provide the Customer with written
                notice of substantial changes to these Terms on the Platform. Such substantial changes will take effect
                fifteen (15) days after such notice was provided on the Platform, or as required by law. All other
                changes to these Terms are effective immediately upon publication of the updated Terms on the Platform.
                Your continued use of the Platform and/or Services after the publication of the updated Terms will be
                deemed acceptance of any and all such changes.
              </li>
            </ol>
          </li>
          <li>
            <h2>Contact Information</h2>
            <p>
              In case of any questions or comments regarding these Terms, please contact Qnergy at:{' '}
              <Link href="mailto:info@qnergy.com" inline color="green">
                info@qnergy.com
              </Link>
              .
            </p>
          </li>
        </ol>
        <p>
          <i>{`Last Updated ${new Date(process.env.REACT_APP_TERMS_UPDATED ?? '').toLocaleDateString()}`}</i>
        </p>
      </Card>
    </div>
  );
}
