import 'react-loading-skeleton/dist/skeleton.css';
import './ComponentLibrary/src/style/index.scss';
// REMOVEME: when we have removed Tailwind
import './ComponentLibrary/src/style/index.css';
import './ComponentLibrary/src/style/index.scss';
import './i18n';
import './ComponentLibrary/src/style/fonts/poppins.css';
import './style/index.scss';
import 'wicg-inert';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
