import React, { cloneElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Link, Loading, Text } from '../../ComponentLibrary/src';
import TestWrapper from '../TestWrapper';
import { LoadingDirection, LoadItemsParams, Pagination, useInfiniteScroll } from './utils';

export interface InfiniteScrollProps {
  noItemsFound?: React.ReactNode;
  maxBufferSize: number;
  loadItems: LoadItemsParams['loadItems'];
  triggerThresholdItems?: number;
  focusedItem: string;
  searchParams: URLSearchParams;
  triggerLoadItems?: unknown;
  dataPwId?: string;
}

/**
 * TODO: detect full buffer of items are visible, then increase buffer size
 * @returns
 */
export default function InfiniteScroll({
  noItemsFound,
  maxBufferSize,
  loadItems,
  triggerThresholdItems,
  focusedItem,
  searchParams,
  triggerLoadItems,
  dataPwId = 'infinite-scroll',
}: InfiniteScrollProps): JSX.Element {
  const {
    loading,
    scrollableContainerRef,
    items,
    errorLoadingItems,
    preLastItem,
    lastItem,
    preFirstItem,
    firstItem,
    getItems,
  } = useInfiniteScroll({
    loadItems,
    maxBufferSize,
    searchParams,
    triggerLoadItems,
  });

  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  const prevErrorLoadingItems = useRef<boolean>(false);
  const renderListCounter = useRef<number>(0);

  // increment the list counter when an error gets resolved
  if (prevErrorLoadingItems.current && !errorLoadingItems) {
    renderListCounter.current++;
  }
  prevErrorLoadingItems.current = errorLoadingItems;

  const errorLoadingItemsComponent = useCallback(
    (loadingDirection: LoadingDirection) => (
      <div className="w-full flex justify-center pt-2 pb-2">
        Error loading more items...&nbsp;
        <Link
          data-testid="infinite-scroll-try-again-link"
          onClick={() => {
            const pagination: Pagination = {};
            if (loadingDirection === LoadingDirection.UP && items[0]?.key) {
              pagination.before = items[0].key.toString();
            } else if (loadingDirection === LoadingDirection.DOWN && items.length && items[items.length - 1]?.key) {
              pagination.after = items[items.length - 1].key?.toString();
            }
            getItems(pagination as Pagination);
          }}
        >
          Try Again
        </Link>
      </div>
    ),
    [getItems, items],
  );

  const loadingComponent = useMemo(
    () => (
      <div className="w-full flex justify-center pt-2 pb-2">
        <Loading type="small" />
      </div>
    ),
    [],
  );

  const triggerThresholdItemCount = useMemo(() => {
    return Math.floor(Math.min(triggerThresholdItems ?? items.length / 2, items.length / 2));
  }, [items, triggerThresholdItems]);

  useEffect(() => {
    if (focusedItem && !hasScrolled) {
      const element = document.querySelector(`div[data-id="${focusedItem}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setHasScrolled(true);
      }
    } else if (!focusedItem) {
      setHasScrolled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <div
      data-testid="infinite-scroll"
      ref={scrollableContainerRef}
      className="w-full h-full overflow-y-auto"
      data-pwid={`${dataPwId}-container`}
    >
      {loading === LoadingDirection.DOWN && !items.length && (
        <TestWrapper data-testid="infinite-scroll-loading">
          <Skeleton containerTestId="events" count={triggerThresholdItems} height={42} />
        </TestWrapper>
      )}
      {loading === LoadingDirection.UP && (
        <TestWrapper data-testid="infinite-scroll-loading-top">{loadingComponent}</TestWrapper>
      )}
      {!loading && errorLoadingItems && !!items.length && (
        <TestWrapper data-testid="infinite-scroll-error-top">
          {errorLoadingItemsComponent(LoadingDirection.UP)}
        </TestWrapper>
      )}

      {items.length > 0 ? (
        /* Re-render the whole list on error state change so we reset the refs */
        <React.Fragment key={renderListCounter.current}>
          {items.map((item, i) => {
            let ref = null;
            if (i === 0) {
              ref = firstItem;
            } else if (i === items.length - 1) {
              ref = lastItem;
            } else if (i === triggerThresholdItemCount - 1) {
              ref = preFirstItem;
            } else if (i === items.length - triggerThresholdItemCount - 1) {
              ref = preLastItem;
            }

            if (ref !== null) {
              return cloneElement(item, { ref });
            }
            return item;
          })}
        </React.Fragment>
      ) : (
        !errorLoadingItems &&
        (noItemsFound ?? (
          <div className="flex flex-col items-center">
            <Text>No Items Found</Text>
          </div>
        ))
      )}

      {loading === LoadingDirection.DOWN && (
        <TestWrapper data-testid="infinite-scroll-loading-bottom">{loadingComponent}</TestWrapper>
      )}
      {!loading && errorLoadingItems && (
        <TestWrapper data-testid="infinite-scroll-error-bottom">
          {errorLoadingItemsComponent(LoadingDirection.DOWN)}
        </TestWrapper>
      )}
    </div>
  );
}
