import { ReleaseNote } from '../../types';
import { Method } from '../../util/types';
import Api from '.';

export async function fetchReleaseNote(releaseDate = ''): Promise<ReleaseNote> {
  return Api.request({
    method: Method.get,
    path: `releasenotes/${encodeURIComponent(releaseDate)}`,
  }) as Promise<ReleaseNote>;
}

export async function fetchReleaseNotes(): Promise<ReleaseNote[]> {
  return (await Api.request({ method: Method.get, path: 'releasenotes' })) as ReleaseNote[];
}
