import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.fullscreen/Control.FullScreen.js';
import 'leaflet.gridlayer.googlemutant';

import React, { useEffect, useState } from 'react';

import OverviewFilter from '../components/Overview/Filter';
import SystemsInformation from '../components/Overview/SystemsInformation';
import { usePageContext } from '../components/Page';
import { useMobile, usePaginationQuery, useSetDocumentTitle } from '../hooks';
import { defaultSystemFilter } from '../util/constants';

export default function Overview(): JSX.Element {
  const [showControls, setShowControls] = useState(true);
  const isMobile = useMobile();
  const { setTitle, setBreadcrumbs, setScrollable, onMount } = usePageContext();
  const [hideStates, setHideStates] = useState(false);
  const { filter, setFilter } = usePaginationQuery({
    filter: defaultSystemFilter,
  });
  const [systemFilterLoading, setSystemFilterLoading] = useState(false);
  const [editingFilter, setEditingFilter] = useState(false);

  useSetDocumentTitle('Overview');
  useEffect(() => {
    setTitle('');
    setBreadcrumbs();
    setScrollable(false);
    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeShow = (show: boolean) => setShowControls(show);

  return (
    <>
      <div className={`absolute left-0 right-0 top-0 z-500 pl-4 pr-4${showControls ? '' : ' h-0'}`}>
        <OverviewFilter
          filter={filter}
          show={showControls}
          onChangeShow={handleChangeShow}
          onChangeFilter={setFilter}
          onLoading={setSystemFilterLoading}
          setEditingFilter={setEditingFilter}
        />
      </div>
      <SystemsInformation
        filter={filter}
        showMetrics={showControls}
        isMobile={isMobile}
        setShowControls={setShowControls}
        hideStates={hideStates}
        setHideStates={setHideStates}
        systemFilterLoading={systemFilterLoading}
        editingFilter={editingFilter}
      />
    </>
  );
}
