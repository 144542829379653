import { Transition } from '@headlessui/react';
import {
  BriefcaseIcon,
  ChevronLeftIcon,
  ClipboardListIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  ExternalLinkIcon,
  MapIcon,
  MenuIcon,
  PhoneIcon,
  ShieldCheckIcon,
  TableIcon,
  TruckIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import logo from '../style/Qnergy_HOR_NoTag_RGB.svg';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { PlaywrightTestIds } from '.';
import { SideNavProps } from './index.types';
import { Section, SectionTitle, Subsection, SubsectionTitle } from './SideNav.components';

export const SideNavDesktop: React.FC<SideNavProps> = ({
  className,
  dataTestId,
  show,
  hasAdminPriveleges,
  selectedPage,
  showOverview = true,
  showSystems = true,
  showUsers,
  showAssets,
  showDistributors,
  showFaultSettings,
  swVer,
  phone,
  usersUrl,
  onClickToggle,
}: SideNavProps) => {
  const sideMenuRef = useRef(null);
  const [collapsed, setCollapsed] = useState(!show);
  const [selectedSubsection, setSelectedSubsection] = useState(selectedPage);
  const [showPreview, setShowPreview] = useState('');

  useEffect(() => {
    if (typeof show !== 'undefined') {
      setCollapsed(!show);
    }
  }, [show]);

  useEffect(() => {
    setSelectedSubsection(selectedPage);
  }, [selectedPage]);

  const handleClickOverview = () => {
    setSelectedSubsection('overview');
  };

  const handleClickSystems = () => {
    setSelectedSubsection('systems');
  };

  const handleClickAssets = () => {
    setSelectedSubsection('assets');
  };

  const handleClickDistributors = () => {
    setSelectedSubsection('distributors');
  };

  const handleClickFaultSettings = () => {
    setSelectedSubsection('faults');
  };

  const handleClickReleaseNotes = () => {
    setSelectedSubsection('releasenotes');
  };

  const handleShowPreview = (itemName: string) => {
    setShowPreview(itemName);
  };

  const handleClickMenu = () => {
    if (onClickToggle) {
      onClickToggle();
    } else {
      setCollapsed(!collapsed);
    }
  };

  const Preview = ({ children }: { children: ReactNode }): JSX.Element | null => {
    return (showPreview ?? '') === children ? (
      <div className="absolute bg-blue-800 text-gray-50 px-3 py-2 rounded left-[3.25rem] z-[901] shadow-lg">
        {children as string}
      </div>
    ) : null;
  };

  const version = swVer ? `v${swVer}` : '';

  const iconClass = 'h-5 w-5 flex items-center justify-center';
  const externalIconClass = 'h-4 w-4';

  // Desktop
  return (
    <div
      ref={sideMenuRef}
      id="side-menu"
      data-testid={dataTestId}
      className={`flex flex-col h-full bg-blue-700 transition-width duration-500 ease-in-out-slow overflow-x-hidden overflow-y-hidden justify-between ${
        collapsed ? 'w-12' : 'w-56'
      }${appendClassProps(className)}`}
    >
      <div>
        <div
          className={`w-56 justify-center bg-blue-800 h-12 py-2 cursor-pointer flex relative`}
          onClick={handleClickMenu}
          data-pwid={PlaywrightTestIds.menuIcon}
        >
          <MenuIcon
            className={`absolute left-2 h-8 w-8 text-white transition-opacity duration-300 ${
              collapsed ? 'opacity-100' : 'opacity-0'
            }`}
          />
          <>
            <img src={logo} className="h-8 py-1" alt="logo" data-pwid={PlaywrightTestIds.imageLogo} />
            <div
              className="absolute top-0 bottom-0 right-2 flex items-center"
              data-pwid={PlaywrightTestIds.chevronLeftIcon}
            >
              <ChevronLeftIcon className="h-6 w-6 text-white" />
            </div>
          </>
        </div>

        <div className="flex flex-col items-start w-56">
          {showOverview && (
            <Subsection
              collapsed={collapsed}
              className="pl-3.5"
              href="/"
              selected={selectedSubsection === 'overview'}
              onClick={handleClickOverview}
              data-pwid={PlaywrightTestIds.mapIcon}
            >
              <MapIcon
                className={iconClass}
                onMouseOver={() => collapsed && handleShowPreview('Overview')}
                onMouseLeave={() => collapsed && handleShowPreview('')}
              />
              <SubsectionTitle>Overview</SubsectionTitle>
              <Preview>Overview</Preview>
            </Subsection>
          )}

          {showSystems && (
            <>
              <Section collapsed={collapsed}>
                <Transition
                  show={!collapsed}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <SectionTitle>SYSTEMS</SectionTitle>
                </Transition>
              </Section>
              <Subsection
                className="pl-3.5"
                onClick={handleClickSystems}
                selected={selectedSubsection === 'systems'}
                href="/systems"
                data-pwid={PlaywrightTestIds.systemsTableIcon}
              >
                <TableIcon
                  className={iconClass}
                  onMouseOver={() => collapsed && handleShowPreview('Systems')}
                  onMouseLeave={() => collapsed && handleShowPreview('')}
                />
                <SubsectionTitle>Systems</SubsectionTitle>
                <Preview>Systems</Preview>
              </Subsection>
            </>
          )}

          {hasAdminPriveleges && (
            <Section collapsed={collapsed}>
              <Transition
                show={!collapsed}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <SectionTitle>ADMINISTRATION</SectionTitle>
              </Transition>
            </Section>
          )}
          {showUsers && usersUrl && (
            <Subsection className="pl-3.5" href={usersUrl} external data-pwid={PlaywrightTestIds.usersIcon}>
              <UsersIcon
                className={iconClass}
                onMouseOver={() => collapsed && handleShowPreview('Users')}
                onMouseLeave={() => collapsed && handleShowPreview('')}
              />
              <SubsectionTitle>Users</SubsectionTitle>
              <div className={iconClass}>
                <ExternalLinkIcon className={`${externalIconClass}`} />
              </div>
              <Preview>Users</Preview>
            </Subsection>
          )}
          {showAssets && (
            <Subsection
              className="pl-3.5"
              onClick={handleClickAssets}
              selected={selectedSubsection === 'assets'}
              href="/assets"
              data-pwid={PlaywrightTestIds.assetsIcon}
            >
              <BriefcaseIcon
                className={iconClass}
                onMouseOver={() => collapsed && handleShowPreview('Assets')}
                onMouseLeave={() => collapsed && handleShowPreview('')}
              />
              <SubsectionTitle>Assets</SubsectionTitle>
              <Preview>Assets</Preview>
            </Subsection>
          )}
          {showDistributors && (
            <Subsection
              className="pl-3.5"
              onClick={handleClickDistributors}
              selected={selectedSubsection === 'distributors'}
              href="/distributors"
              data-pwid={PlaywrightTestIds.distributorsIcon}
            >
              <TruckIcon
                className={iconClass}
                onMouseOver={() => collapsed && handleShowPreview('Distributors')}
                onMouseLeave={() => collapsed && handleShowPreview('')}
              />
              <SubsectionTitle>Distributors</SubsectionTitle>
              <Preview>Distributors</Preview>
            </Subsection>
          )}
          {showFaultSettings && (
            <Subsection
              className="pl-3.5"
              onClick={handleClickFaultSettings}
              selected={selectedSubsection === 'faults'}
              href="/faults"
              data-pwid={PlaywrightTestIds.faultsIcon}
            >
              <ExclamationCircleIcon
                className={iconClass}
                onMouseOver={() => collapsed && handleShowPreview('Faults')}
                onMouseLeave={() => collapsed && handleShowPreview('')}
              />
              <SubsectionTitle>Fault Settings</SubsectionTitle>
              <Preview>Faults</Preview>
            </Subsection>
          )}
        </div>
      </div>

      <div>
        <div className="flex flex-col items-start w-56 ">
          <Section collapsed={collapsed}>
            <Transition
              show={!collapsed}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <SectionTitle>SUPPORT</SectionTitle>
            </Transition>
          </Section>
          {phone && (
            <Subsection
              className="pl-3.5"
              href={`tel:${phone?.replaceAll('-', '')}`}
              external
              data-pwid={PlaywrightTestIds.phoneIcon}
            >
              <PhoneIcon
                className={iconClass}
                onMouseOver={() => collapsed && handleShowPreview('Contact Us')}
                onMouseLeave={() => collapsed && handleShowPreview('')}
              />
              <SubsectionTitle>{phone}</SubsectionTitle>
              <Preview>{phone}</Preview>
            </Subsection>
          )}
          <Subsection className="pl-3.5" href="/terms" data-pwid={PlaywrightTestIds.termsIcon}>
            <ClipboardListIcon
              className={iconClass}
              onMouseOver={() => collapsed && handleShowPreview('Terms')}
              onMouseLeave={() => collapsed && handleShowPreview('')}
            />
            <SubsectionTitle>Terms</SubsectionTitle>
            <div className={iconClass}>
              <ExternalLinkIcon className={`${externalIconClass}`} />
            </div>
            <Preview>Terms</Preview>
          </Subsection>
          <Subsection className="pl-3.5" href="/privacy" data-pwid={PlaywrightTestIds.privacyIcon}>
            <ShieldCheckIcon
              className={iconClass}
              onMouseOver={() => collapsed && handleShowPreview('Privacy Policy')}
              onMouseLeave={() => collapsed && handleShowPreview('')}
            />
            <SubsectionTitle>Privacy Policy</SubsectionTitle>
            <div className={iconClass}>
              <ExternalLinkIcon className={`${externalIconClass}`} />
            </div>
            <Preview>Privacy Policy</Preview>
          </Subsection>
          <Subsection
            className="pl-3.5"
            onClick={handleClickReleaseNotes}
            selected={selectedSubsection === 'releasenotes'}
            href="/releasenotes"
            data-pwid={PlaywrightTestIds.releaseNotesIcon}
          >
            <DocumentTextIcon
              className={iconClass}
              onMouseOver={() => collapsed && handleShowPreview('Release Notes')}
              onMouseLeave={() => collapsed && handleShowPreview('')}
            />
            <SubsectionTitle>Release Notes</SubsectionTitle>
            <Preview>Release Notes</Preview>
          </Subsection>
        </div>
        <div className={`flex flex-row w-56 my-2 text-white self-end items-center justify-center h-6`}>
          <Transition
            show={!collapsed}
            unmount={false}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="whitespace-nowrap flex flex-row gap-1 items-center w-full justify-center px-2]">
              <Text type={TextType.custom} overrideColor className="text-sm font-extralight text-blue-100">
                {version}
              </Text>
              <img
                className="h-3 w-3"
                src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none' stroke='white'%3e%3cclipPath id='a'%3e%3cpath d='M45 45v110h110v-43.5h-30v-23h30V45'/%3e%3c/clipPath%3e%3ccircle cx='100' cy='100' r='88.5' stroke-width='23'/%3e%3ccircle cx='100' cy='100' r='42.5' stroke-width='23' clip-path='url(%23a)'/%3e%3c/svg%3e"
                alt="copyright"
              />
              <Text type={TextType.custom} overrideColor className="text-sm font-extralight text-white" overflow="none">
                {new Date().getFullYear()} Qnergy
              </Text>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export * from './index.types';
