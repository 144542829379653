export const screenSizeMediaQuery = {
  mobile: {
    query: '(max-width: 768px)',
  },
  tablet: {
    query: '(max-width: 768px)',
  },
  metricCarousel: {
    query: '(max-width: 880px)',
  },
  small: {
    query: '(max-width: 1023px)',
  },
  hd: {
    query: '(max-width: 1280px)',
  },
};
