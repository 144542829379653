import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { SearchableList, Text, TextType } from '../../../ComponentLibrary/src';
import { OptionId } from '../../../JsonApi/src/types';
import { DragItem, System } from '../../../types';
import DraggableSystem from './DraggableSystem';
import DropPlaceholder from './DropPlaceholder';
import Loading from './Loading';

interface UnallocatedProps {
  unallocated: System[];
  onDrop: (item: DragItem, destination: string) => void;
  handleDragging: (dragging: boolean) => void;
  draggingFromAllocated: (allocated: boolean) => void;
  setCurrentDragging: (system?: System) => void;
  isDraggingSystem: boolean;
  refreshData: () => void;
  savingSystems: Set<string>;
  bulkMove: boolean;
  isDraggingAllocated: boolean;
  loading: boolean;
}

export default React.memo(function Unallocated({
  unallocated,
  handleDragging,
  draggingFromAllocated,
  setCurrentDragging,
  onDrop,
  isDraggingSystem,
  isDraggingAllocated,
  refreshData,
  bulkMove,
  loading,
  savingSystems,
}: UnallocatedProps): JSX.Element {
  const { t } = useTranslation(['assets', 'translation']);
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelect = useCallback((newValue: OptionId) => {
    setSelectedOption(newValue as string);
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
  });

  return (
    <div className={`card unallocated ${bulkMove ? 'bulk-edit' : ''}`}>
      {loading ? (
        <Loading pageSize={50} colspan={4} />
      ) : (
        <React.Fragment>
          {isDraggingSystem && isDraggingAllocated && (
            <DropPlaceholder
              text={t('unallocated_drop_help')}
              classnames="h-full"
              show={true}
              onDrop={(item: DragItem) => {
                onDrop(item, 'unallocated');
              }}
            />
          )}
          <div
            className={`flex flex-1 flex-col gap-3 h-full ${isDraggingSystem && isDraggingAllocated ? 'hidden' : ''}`}
          >
            <Text className="h-9 items-center" type={TextType.h5} center>
              {t('unallocated_title')} ({unallocated.length})
            </Text>
            <SearchableList
              className="flex-1 gap-2"
              data-pwid="unallocated-list"
              options={unallocated.map((system: System) => ({
                id: system.sysId,
                label: system.sysId,
                component: (
                  <div className="relative flex flex-row gap-2 border border-dashed rounded px-2 mx-1 mb-0.5 items-center h-11 hover:bg-gray-100">
                    <DraggableSystem
                      key={JSON.stringify(system)}
                      list="unallocated"
                      systemClasses=""
                      savingSystems={savingSystems}
                      showRemove={false}
                      system={system}
                      handleDragging={handleDragging}
                      draggingFromAllocated={draggingFromAllocated}
                      refreshData={refreshData}
                      setCurrentDragging={setCurrentDragging}
                    />
                  </div>
                ),
              }))}
              value={selectedOption}
              onSelect={handleSelect}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
});
