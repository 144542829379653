import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import get from 'lodash.get';
import React, { ReactNode, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Pagination } from '../Pagination';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { GridTableColumn, GridTableProps, GridTableSortDirection } from './index.types';

export interface GridTableCellProps {
  children: ReactNode;
  showRowLines?: boolean;
  wrap?: boolean;
  className?: string | string[];
  onClick?: () => void;
}

const GridTableCell: React.FC<GridTableCellProps> = ({
  children,
  showRowLines = true,
  wrap,
  className,
  onClick,
}: GridTableCellProps): JSX.Element => {
  // TODO: Conditionally apply title
  // https://codesandbox.io/s/material-demo-p2omr?file=/demo.js
  return (
    <div
      className={`td${showRowLines ? ' border-t border-gray-300' : ''} ${onClick ? ' cursor-pointer' : ''}${
        wrap ? ' wrap' : ''
      }${appendClassProps(className)}`}
      onClick={onClick}
      data-tip={typeof children === 'string' && !wrap ? children : undefined}
    >
      {children}
    </div>
  );
};

/**
 - GridTable component for rendering a page of data
 - Can provide any array of data objects and the table will only render the columns provided in the column prop
 */
export const GridTable: React.FC<GridTableProps> = ({
  showHeader = true,
  showRowLines = true,
  highlightRows = true,
  className,
  columns,
  rows,
  pageNumber,
  countPerPage,
  totalCount,
  loading,
  onClickRow,
  onChangeCountPerPage,
  onChangePageNumber,
  onChangeColumns,
}: GridTableProps) => {
  const handleChangeCountPerPage = (count: string | number) => {
    onChangeCountPerPage(parseInt(count as string));
  };

  const handleChangePageNumber = (page: string) => {
    onChangePageNumber(parseInt(page));
  };

  const handleClickColumn = (id: string | number) => {
    const newColumns = [...columns];
    for (let i = 0; i < newColumns.length; i++) {
      if (newColumns[i].id === id) {
        newColumns[i].sortDirection =
          newColumns[i].sortDirection === GridTableSortDirection.down || !newColumns[i].sortDirection
            ? GridTableSortDirection.up
            : GridTableSortDirection.down;
      } else {
        delete newColumns[i].sortDirection;
      }
    }
    onChangeColumns(newColumns);
  };

  const tableBody = useMemo(
    () =>
      rows.map((row, rowIdx) => (
        <div className={`row${highlightRows ? ' highlight-row' : ''}`} key={row.id as string}>
          {columns.map((column: GridTableColumn, colIdx: number) => {
            const value = get(row, column.id);
            let content;
            if (typeof value === 'string' || typeof value === 'number') {
              content = (
                <Text type={TextType.custom} key={column.id} wrap={column.wrap}>
                  {value}
                </Text>
              );
            } else if (React.isValidElement(value)) {
              content = <React.Fragment key={column.id}>{value}</React.Fragment>;
            } else {
              content = value ? (
                <Text type={TextType.custom} key={column.id} wrap={column.wrap}>
                  {JSON.stringify(value)}
                </Text>
              ) : null;
            }
            return (
              <GridTableCell
                className={`py-2 ${colIdx === 0 ? 'pl-4' : 'pl-2'} ${
                  colIdx === columns.length - 1 ? 'pr-4' : 'pr-2'
                } relative`}
                key={column.id}
                showRowLines={showRowLines && rowIdx !== 0}
                onClick={
                  onClickRow
                    ? () => {
                        const highlightedText = window?.getSelection()?.toString();
                        if (onClickRow && !highlightedText) onClickRow(row.id as string);
                      }
                    : undefined
                }
                wrap={column.wrap}
              >
                <div className="h-full relative">
                  {loading && (
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-0">
                      <Skeleton containerClassName="h-full" className="h-full !leading-10" />
                    </div>
                  )}
                  <span className={loading ? 'invisible' : ''}>{content}</span>
                </div>
              </GridTableCell>
            );
          })}
        </div>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, columns, showRowLines, loading, highlightRows],
  );

  const loadingGridTableBody = [];
  if (loading) {
    for (let i = 0; i < countPerPage; i++) {
      loadingGridTableBody.push(
        <div
          className="loading-row"
          style={{
            gridColumn: `1 / ${columns.length + 1}`,
          }}
          key={i}
        >
          <Skeleton height={36.5} style={{ zIndex: 0 }} />
        </div>,
      );
    }
  }

  return (
    <div
      className={`grid-table relative w-full bg-gray-50${appendClassProps(className)}`}
      data-testid="table"
      style={{
        gridTemplateColumns: `${columns
          // .map((col) => (col.isContentColumn ? '1fr' : 'auto'))
          .map(() => '1fr')
          .join(' ')}`,
      }}
    >
      {showHeader &&
        columns.map((column, i) => (
          <div
            className={`thead z-10 text-blue-800 bg-gray-300 cursor-pointer py-2 ${i === 0 ? 'pl-4' : 'pl-2'} ${
              i === columns.length - 1 ? 'pr-4' : 'pr-2'
            }`}
            key={column.id}
            onClick={() => handleClickColumn(column.id)}
          >
            {column.label && (
              <Text type={TextType.custom} wrap>
                {column.label}
              </Text>
            )}
            {column.sortDirection === GridTableSortDirection.up && (
              <ChevronUpIcon className="h-5 w-5 overflow-visible" />
            )}
            {column.sortDirection === GridTableSortDirection.down && (
              <ChevronDownIcon className="h-5 w-5 overflow-visible" />
            )}
            {!column.sortDirection && <ChevronDownIcon className="h-5 w-5 overflow-visible opacity-0" />}
          </div>
        ))}
      {loading && (!rows || rows.length < 1) && loadingGridTableBody}
      {tableBody}
      <div
        className="tfoot bg-gray-50"
        style={{
          gridColumn: `1 / ${columns.length + 1}`,
        }}
      >
        <Pagination
          pageNumber={pageNumber}
          countPerPage={countPerPage}
          totalCount={totalCount}
          onChangeCountPerPage={handleChangeCountPerPage}
          onChangePageNumber={(page: number) => handleChangePageNumber(page.toString())}
          loading={loading}
        />
      </div>
    </div>
  );
};

export * from './index.types';
