import React from 'react';

import { appendClassProps } from '../util';

export const AlarmIcon = ({
  markerColorClass,
  className,
}: {
  markerColorClass: string;
  className?: string | string[];
}): JSX.Element => (
  <svg
    className={`${markerColorClass}${appendClassProps(className)}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      d="M7.7,2.2c1-1.8,3.6-1.8,4.6,0l7.4,13.1c1,1.8-0.3,3.9-2.3,3.9H2.6
      c-2,0-3.3-2.2-2.3-3.9L7.7,2.2z"
    />
    <path
      fill="#FFFFFF"
      d="M10,4.7C9.3,4.7,8.7,5.3,8.7,6v4c0,0.7,0.6,1.3,1.3,1.3
      c0.7,0,1.3-0.6,1.3-1.3V6C11.3,5.3,10.7,4.7,10,4.7z"
    />
    <path
      fill="#FFFFFF"
      d="M11.3,15.2c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3
      s0.6-1.3,1.3-1.3C10.7,13.9,11.3,14.5,11.3,15.2z"
    />
  </svg>
);
export const UnknownIcon = ({
  markerColorClass,
  className,
}: {
  markerColorClass: string;
  className?: string | string[];
}): JSX.Element => (
  <svg
    className={`${markerColorClass}${appendClassProps(className)}`}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle fill="#D1D5DB" cx="10" cy="10" r="10" />
    <circle cx="10" cy="10" r="8" />
    <path
      fill="#FFFFFF"
      d="M10,6.2c-0.5,0-0.9,0.2-1.1,0.6C8.6,7.5,7.8,7.7,7.2,7.3
      C6.6,7,6.4,6.2,6.8,5.6C7.4,4.5,8.6,3.8,10,3.8c2.1,0,3.8,1.7,3.8,3.8c0,1.6-1,3-2.5,3.5v0.2c0,0.7-0.6,1.2-1.2,1.2
      c-0.7,0-1.2-0.6-1.2-1.2V10c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2-0.6,1.2-1.2S10.7,6.2,10,6.2z"
    />
    <circle fill="#FFFFFF" cx="10" cy="15" r="1.2" />
  </svg>
);
export const DisabledIcon = ({
  markerColorClass,
  className,
}: {
  markerColorClass: string;
  className?: string | string[];
}): JSX.Element => (
  <svg
    className={`${markerColorClass}${appendClassProps(className)}`}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10,20c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10C4.5,0,0,4.5,0,10
      C0,15.5,4.5,20,10,20z"
    />
    <path
      fill="#FFFFFF"
      d="M7.5,6.2c-0.7,0-1.2,0.6-1.2,1.2v5c0,0.7,0.6,1.2,1.2,1.2h5
      c0.7,0,1.2-0.6,1.2-1.2v-5c0-0.7-0.6-1.2-1.2-1.2H7.5z"
    />
  </svg>
);
export const StandbyIcon = ({
  markerColorClass,
  className,
}: {
  markerColorClass: string;
  className?: string | string[];
}): JSX.Element => (
  <svg
    className={`${markerColorClass}${appendClassProps(className)}`}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20,10c0,5.5-4.5,10-10,10C4.5,20,0,15.5,0,10C0,4.5,4.5,0,10,0
      C15.5,0,20,4.5,20,10z"
    />
    <path
      fill="#FFFFFF"
      d="M6.2,7.5c0-0.7,0.6-1.2,1.2-1.2s1.2,0.6,1.2,1.2v5
      c0,0.7-0.6,1.2-1.2,1.2s-1.2-0.6-1.2-1.2V7.5z"
    />
    <path
      fill="#FFFFFF"
      d="M12.5,6.2c-0.7,0-1.2,0.6-1.2,1.2v5c0,0.7,0.6,1.2,1.2,1.2
      s1.2-0.6,1.2-1.2v-5C13.8,6.8,13.2,6.2,12.5,6.2z"
    />
  </svg>
);
