import React, { useContext } from 'react';

import Api from '../adapters/api';
import { logout } from '../adapters/api/auth';
import { SideNav } from '../ComponentLibrary/src';
import { AuthContext } from '../context/Auth';
import { PERMISSIONS } from '../util/constants';

interface Props {
  show: boolean;
  user?: {
    email: string;
    profile: {
      firstName: string;
      lastName: string;
    };
  };
  onClickToggle: () => void;
}

const pageMap = {
  '': 'overview',
  data: 'data',
  systems: 'systems',
  assets: 'assets',
  distributors: 'distributors',
  faults: 'faults',
  terms: 'terms',
  privacy: 'privacy',
  releasenotes: 'releasenotes',
};

export default function SideNavMenu({ show, user, onClickToggle }: Props): JSX.Element {
  const { hasPermissions } = useContext(AuthContext);
  const currentPath = window.location.pathname;
  const currentPage = pageMap[currentPath.split('/')[1] as keyof typeof pageMap] || 'overview';

  Api.useRenderOnUserChange();

  return (
    <SideNav
      showAssets={
        hasPermissions(PERMISSIONS.dashboard.orgs.add) ||
        hasPermissions(PERMISSIONS.dashboard.orgs.update) ||
        hasPermissions(PERMISSIONS.dashboard.orgs.delete) ||
        hasPermissions(PERMISSIONS.dashboard.sites.add) ||
        hasPermissions(PERMISSIONS.dashboard.sites.update) ||
        hasPermissions(PERMISSIONS.dashboard.sites.delete)
      }
      showDistributors={hasPermissions([PERMISSIONS.dashboard.distributors.update])}
      showFaultSettings={hasPermissions([PERMISSIONS.dashboard.faults.update])}
      showUsers={hasPermissions([PERMISSIONS.qnergy.users.read])}
      onClickAccount={() =>
        window.open(
          `${process.env.REACT_APP_SMARTVIEW_ACCOUNTS_URL ?? 'https://accounts.qnergy.com'}/users/current`,
          '_blank',
        )
      }
      onLogout={logout}
      onClickToggle={onClickToggle}
      show={show}
      selectedPage={currentPage}
      user={user}
      swVer={process.env.REACT_APP_RELEASE_VERSION}
      phone={process.env.REACT_APP_SUPPORT_TEL}
      usersUrl={`${process.env.REACT_APP_SMARTVIEW_ACCOUNTS_URL ?? 'https://accounts.qnergy.com'}/users`}
    />
  );
}
