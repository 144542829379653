import { ClipboardListIcon, DocumentReportIcon, PresentationChartLineIcon, ViewListIcon } from '@heroicons/react/solid';
import React, { useContext } from 'react';

import { ButtonTabs } from '../../ComponentLibrary/src';
import { AuthContext } from '../../context/Auth';
import i18n from '../../i18n/index';
import { PERMISSIONS } from '../../util/constants';

interface Props {
  sysId?: string;
  pageSelected: 'details' | 'data' | 'actionlogs' | 'events';
  subscriptionStatus: string;
}
export default function TabNavigation({ sysId, pageSelected, subscriptionStatus }: Props): JSX.Element {
  const inactiveSubToolTip = i18n.t('system:subscription_error');
  const { hasPermissions } = useContext(AuthContext);
  const subException = hasPermissions(PERMISSIONS.dashboard.systems.isSubscriptionExempt);
  const showTabs = subException || (subscriptionStatus && subscriptionStatus !== 'expired');
  if (!sysId) return <></>;

  return (
    <ButtonTabs
      className="h-16"
      options={[
        {
          title: 'Details',
          selected: pageSelected === 'details',
          mobileIcon: <DocumentReportIcon className="h-6 w-6" />,
          href: `/systems/${sysId}`,
          'data-pwid': 'system-details-tab',
        },
        {
          title: 'Data',
          selected: pageSelected === 'data',
          mobileIcon: <PresentationChartLineIcon className="h-6 w-6" />,
          href: `/systems/${sysId}/data`,
          disabled: !showTabs,
          tooltip: !showTabs ? inactiveSubToolTip : undefined,
          'data-pwid': 'system-data-tab',
        },
        ...(hasPermissions(PERMISSIONS.dashboard.actionLogs.read)
          ? [
              {
                title: 'Action Logs',
                selected: pageSelected === 'actionlogs',
                mobileIcon: <ClipboardListIcon className="h-6 w-6" />,
                href: `/systems/${sysId}/actionlogs`,
                disabled: !showTabs,
                tooltip: !showTabs ? inactiveSubToolTip : undefined,
                'data-pwid': 'system-action-logs-tab',
              },
            ]
          : []),
        {
          title: 'Events',
          selected: pageSelected === 'events',
          mobileIcon: <ViewListIcon className="h-6 w-6" />,
          href: `/systems/${sysId}/events`,
          disabled: !showTabs,
          tooltip: !showTabs ? inactiveSubToolTip : undefined,
          'data-pwid': 'system-events-tab',
        },
      ]}
    />
  );
}
