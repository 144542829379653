import { createContext, useCallback, useState } from 'react';

import { fetchDistributors } from '../adapters/api/distributors';
import { Distributor } from '../types';

export interface DistributorsContextData {
  allDistributors: Distributor[];
  getAllDistributors: () => Promise<void>;
}

const distributorsContextDefaultValue: DistributorsContextData = {
  allDistributors: [],
  getAllDistributors: async () => undefined,
};

export const useDistributorsContextValue = (): DistributorsContextData => {
  const [allDistributors, setAllDistributors] = useState<Distributor[]>([]);

  const getAllDistributors = useCallback(async () => {
    return fetchDistributors().then((response) => {
      if (response) setAllDistributors(response as Distributor[]);
    });
  }, []);

  return {
    allDistributors,
    getAllDistributors,
  };
};

export const DistributorsContext = createContext<DistributorsContextData>(distributorsContextDefaultValue);
