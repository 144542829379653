import { UserCircleIcon } from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react';

import { appendClassProps, getUniquePalletteColor } from '../util';
import { AvatarProps } from './index.types';

/**
 - Use avatar to generate a unique avatar for a user who has no image
 */
export const Avatar = ({ className, initials, uniqueId, tooltip, onClick }: AvatarProps): JSX.Element => {
  const [textSizeClass, setTextSizeClass] = useState('text-base');
  const avatarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const avatarSize = avatarRef?.current?.offsetWidth;
    if (avatarSize) {
      if (avatarSize <= 32) {
        setTextSizeClass('text-sm');
      } else if (avatarSize <= 64) {
        setTextSizeClass('text-2xl');
      } else if (avatarSize <= 128) {
        setTextSizeClass('text-6xl');
      } else if (avatarSize <= 256) {
        setTextSizeClass('text-8xl');
      } else {
        setTextSizeClass('text-9xl');
      }
    }
  }, [initials, uniqueId]);

  const backgroundColor = getUniquePalletteColor(uniqueId ?? '');
  if (!initials) {
    return <UserCircleIcon className={`text-blue-800${appendClassProps(className)}`} />;
  }
  return (
    <div
      onClick={onClick}
      data-tip={tooltip}
      ref={avatarRef}
      className={`rounded-full flex flex-col items-center justify-center text-white aspect-square${
        onClick ? ' cursor-pointer' : ''
      } ${textSizeClass}${appendClassProps(className)}`}
      data-testid="avatar"
      style={{
        backgroundColor,
      }}
    >
      {initials}
    </div>
  );
};

export * from './index.types';
