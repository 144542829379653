import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Text } from '../../../ComponentLibrary/src';

interface LoadingProps {
  colspan: number;
  pageSize: number;
  showHeader?: boolean;
}

export default function Loading({ colspan, pageSize, showHeader = true }: LoadingProps): JSX.Element {
  // Copy from component lib
  const loadingTableBody = [];
  for (let i = 0; i < pageSize; i++) {
    loadingTableBody.push(
      <tr className={`border-b border-gray-300`} key={i}>
        <td className="py-2 px-4 max-w-xs overflow-hidden whitespace-nowrap overflow-ellipsis" colSpan={colspan}>
          <Text textClassName="w-full">
            <Skeleton className="!leading-5" style={{ zIndex: 0 }} />
          </Text>
        </td>
      </tr>,
    );
  }
  return (
    <div className="flex flex-col gap-4">
      {showHeader && (
        <>
          <div className="flex flex-row justify-between">
            <Skeleton width={100} height={25} />
            <Skeleton width={100} height={25} />
          </div>
          <Skeleton height={25} />
        </>
      )}
      <table className="relative w-full bg-gray-50 table-fixed">
        <tbody>{loadingTableBody}</tbody>
      </table>
    </div>
  );
}
