import React from 'react';

import { Metric } from '../../ComponentLibrary/src/Metric';
import { TransferSwitchPosition } from '../../context/Systems';
interface Props {
  transferSw?: TransferSwitchPosition;
  isLoading: boolean;
}

export default function TransferSwitch({ transferSw, isLoading }: Props): JSX.Element {
  if (transferSw === undefined) return <></>;

  return (
    <Metric
      value={transferSw}
      description="Transfer Switch Position"
      loading={isLoading}
      data-pwid="system-transfer-switch-card"
    />
  );
}
