import { Transition } from '@headlessui/react';
import { InboxInIcon } from '@heroicons/react/outline';
import React from 'react';
import { useDrop } from 'react-dnd';

import { Text, TextType } from '../../../ComponentLibrary/src';
import { DragItem } from '../../../types';

interface DropPlace {
  text: string;
  show: boolean;
  classnames?: string;
  onDrop: (item: DragItem) => void;
}

export default function DropPlaceholder({ text, onDrop, show, classnames }: DropPlace): JSX.Element {
  const [{ isOver }, drop] = useDrop(() => {
    return {
      accept: ['System'],
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
        };
      },
      drop: onDrop,
    };
  });

  return (
    <div ref={drop} className={`${show ? `${classnames} drop-help` : ''} ${isOver ? 'bg-gray-200' : 'bg-gray-50'}`}>
      <Transition
        className="flex flex-1 h-full flex-col items-center justify-center"
        show={show}
        appear={true}
        enter="ease-out transition-transform origin-top duration-100"
        enterFrom="scale-y-0"
        enterTo="scale-y-100"
        leaveFrom="scale-y-100"
        leave="ease-out transition-transform origin-top duration-200"
        leaveTo="scale-y-0"
        unmount
      >
        <InboxInIcon className="w-16 h-16" />
        <Text type={TextType.h3} textClassName="text-blue-400" align="center" overrideColor wrap>
          {text}
        </Text>
      </Transition>
    </div>
  );
}
