import React, { ForwardedRef, forwardRef } from 'react';

interface Props {
  children: React.ReactNode;
  'data-testid': string;
}

function TestWrapper({ children, 'data-testid': dataTestId }: Props, ref: ForwardedRef<HTMLDivElement>): JSX.Element {
  return (
    <div ref={ref} style={{ display: 'contents' }} data-testid={dataTestId}>
      {children}
    </div>
  );
}

export default forwardRef<HTMLDivElement, Props>(TestWrapper);
