import { DateTime } from 'luxon';
import React from 'react';

import Api from '../../../adapters/api';
import i18n from '../../../i18n';
import ExpirationIndicator from './ExpirationIndicator';
import { ExpirationStatus } from './types';

interface Props {
  expirationDate: string;
  isLoading?: boolean;
  'data-pwid'?: string;
}

export default function WarrantyExpirationIndicator({
  expirationDate,
  isLoading,
  'data-pwid': dataPwId = 'expiration-indicator',
}: Props): JSX.Element {
  const serverTime = Api.getServerTime();
  const status = serverTime > DateTime.fromISO(expirationDate) ? ExpirationStatus.expired : ExpirationStatus.active;

  return (
    <ExpirationIndicator
      data-pwid={`${dataPwId}-warranty`}
      status={status}
      tooltips={{
        expired: i18n.t('warranty:expired'),
        active: i18n.t('warranty:active'),
      }}
      isLoading={isLoading}
    />
  );
}
