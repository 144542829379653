import React, { Component, ReactNode } from 'react';

import Error from '../pages/Error';

// eslint-disable-next-line @typescript-eslint/ban-types
export default class ErrorHandler extends Component<{}, { hasError: boolean; error?: unknown; errorInfo?: unknown }> {
  constructor(props: unknown) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    super(props as {});
    this.state = { hasError: false };
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    event.preventDefault();
    this.setState({
      hasError: true,
      error: event.reason,
    });
  };

  static getDerivedStateFromError(): {
    hasError: boolean;
  } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({
      error,
      errorInfo,
    });
  }

  componentDidMount(): void {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentWillUnmount(): void {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error error={this.state.error} errorInfo={this.state.errorInfo} />;
    }

    return this.props.children;
  }
}
