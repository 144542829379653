import { PencilIcon } from '@heroicons/react/solid';
import { DateTime, Duration } from 'luxon';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Api from '../../../adapters/api';
import { Border, Button, Card, KeyValue, Link, Text, TextType, Variant } from '../../../ComponentLibrary/src';
import { AuthContext } from '../../../context/Auth';
import { getThumbnail, getVariants } from '../../../pages/System/util';
import { CapSystem, GenSystem, System } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';
import { getDisplayTime } from '../../../WebUtils';
import { getLabel } from '../constants';
import SubscriptionExpirationIndicator from '../ExpirationIndicator/SubscriptionExpirationIndicator';
import WarrantyExpirationIndicator from '../ExpirationIndicator/WarrantyExpirationIndicator';

interface Props {
  isMobile: boolean;
  system?: System | GenSystem | CapSystem;
  isLoading: boolean;
}

export default function Info({ isMobile, system, isLoading }: Props): JSX.Element {
  const { hasPermissions, isDistributor } = useContext(AuthContext);
  const { t } = useTranslation(['translation', 'system']);

  const lastSeenColor = useMemo(() => {
    if (!system?.lastSeen) return undefined;
    const lastSeen = DateTime.fromISO(system?.lastSeen);
    const diff = Api.getServerTime().diff(lastSeen).valueOf();
    if (diff > 15 * 60 * 1000) return 'text-red-500';
    if (diff > 5 * 60 * 1000) return 'text-amber-900';
    return 'text-emerald-500';
  }, [system?.lastSeen]);

  const showInstalledFeatures = useMemo(() => {
    const genSystem = system as GenSystem;
    if (genSystem?.fpcConfig?.variantCode !== undefined) {
      return genSystem.fpcConfig.variantCode > 0;
    }
    return false;
  }, [system]);

  const showSolar = useMemo(() => {
    return (system as CapSystem).config?.nSolExp !== undefined;
  }, [system]);

  const showHardware = useMemo(() => {
    const capSystem = system as CapSystem;
    if (capSystem?.config?.hardware !== undefined) {
      return capSystem?.config?.hardware?.length > 0;
    }
    return false;
  }, [system]);

  const solarExpansions = useMemo(() => {
    return (system as CapSystem)?.config?.nSolExp ?? 0;
  }, [system]);

  const thumbnail = getThumbnail(system);

  return (
    <Card
      className="flex flex-col md:flex-row gap-4 items-center relative overflow-hidden"
      data-pwid="system-info-card"
    >
      {isMobile && hasPermissions(PERMISSIONS.dashboard.systems.update) && (
        <Link href={`/systems/${system?.sysId}/edit`} data-pwid="system-info-edit" data-testid="system-info-edit">
          <Button variant={Variant.secondaryFilled} className="absolute right-2 top-2">
            {t('Edit')}
          </Button>
        </Link>
      )}
      {!isLoading && thumbnail && <img src={thumbnail} className="h-72 p-8" />}
      <div className="flex flex-col gap-2 overflow-hidden w-full">
        <div className="flex flex-row justify-between items-center">
          <Text data-testid="title" type={TextType.h3}>
            Info
          </Text>
        </div>
        <div className="flex-1 flex flex-row flex-wrap gap-y-2 gap-x-8">
          <div className="w-full 2xl:w-auto 2xl:flex-1 flex flex-col gap-2 overflow-hidden">
            {system?.systemDescription && (
              <KeyValue
                tooltip="System description"
                label={getLabel('systemDescription')}
                value={system?.systemDescription}
                loading={isLoading}
                border={Border.none}
                align="right"
                data-pwid="description"
                data-testid="description"
                wrap
              />
            )}
            <KeyValue
              tooltip="System model (e.g. PG5650)"
              label={getLabel('model')}
              value={(system as GenSystem | CapSystem)?.model || ''}
              loading={isLoading}
              border={Border.none}
              wrap
              valueLink={`/systems?filter[model]=${(system as GenSystem | CapSystem)?.model}`}
              data-pwid="model"
              data-testid="model"
            />
            {showInstalledFeatures && (
              <KeyValue label="Installed Features" loading={isLoading} border={Border.none} wrap>
                <ul style={{ listStyle: 'initial' }} data-pwid="installed-features" data-testid="installed-features">
                  {getVariants((system as GenSystem)?.fpcConfig?.variantCode ?? 0).map((variant) => (
                    <li key={variant}>{variant}</li>
                  ))}
                </ul>
              </KeyValue>
            )}
            {showSolar && (
              <KeyValue label="Installed Features" loading={isLoading} border={Border.none} wrap>
                <span
                  className={solarExpansions ? undefined : 'text-gray-400 italic'}
                  data-testid="solar-expansions"
                  data-pwid="solar-expansions"
                >
                  {solarExpansions ? `${t('system:solar_expansions')} ${solarExpansions}` : t('None')}
                </span>
              </KeyValue>
            )}
            {showHardware && (
              <KeyValue label="Hardware Configuration" loading={isLoading} border={Border.none} wrap>
                <ul style={{ listStyle: 'initial' }} data-testid="hardware-conf" data-pwid="hardware-conf">
                  {(system as CapSystem)?.config?.hardware?.map((hardware, idx) => (
                    <li key={idx}>{hardware}</li>
                  ))}
                </ul>
              </KeyValue>
            )}
            {system?.distributor?.name && !isDistributor() && (
              <KeyValue
                tooltip="Distributor that sold the unit"
                label={getLabel('distributor')}
                value={system?.distributor?.name || ''}
                loading={isLoading}
                border={Border.none}
                valueLink={`/systems?filter[distributor._id]=${system?.distributor?._id}`}
                data-pwid="distributor"
                data-testid="distributor"
                wrap
              />
            )}
            <KeyValue
              tooltip="The organization or customer entity name"
              label={getLabel('org')}
              loading={isLoading}
              border={Border.none}
              wrap
            >
              {system?.org ? (
                <Link href={`/systems?filter[org._id]=${system?.org?._id}`} data-testid="org-link" data-pwid="org-link">
                  <span>{system.org.name}</span>
                </Link>
              ) : (
                <span className="text-gray-400 italic" data-testid="org" data-pwid="org">
                  {t('system:unallocated')}
                </span>
              )}
            </KeyValue>
            <div className="flex gap-2 items-center">
              <KeyValue
                className="w-full"
                tooltip="The name of the site"
                label={getLabel('site')}
                loading={isLoading}
                border={Border.none}
                wrap
              >
                {system?.site ? (
                  <Link
                    href={`/systems?filter[site._id]=${system.site._id}`}
                    data-testid="site-link"
                    data-pwid="site-link"
                  >
                    <span>{system.site.name}</span>
                  </Link>
                ) : (
                  <span className="text-gray-400 italic" data-testid="site" data-pwid="site">
                    {t('system:unallocated')}
                  </span>
                )}
              </KeyValue>
              {!isLoading && system?.site && hasPermissions(PERMISSIONS.dashboard.sites.update) && (
                <Link
                  href={`/assets/editSite/${system?.site?._id}`}
                  data-testid="edit-site-link"
                  data-pwid="edit-site-link"
                >
                  <PencilIcon className="h-5 w-5" />
                </Link>
              )}
            </div>
          </div>

          <div className="w-full 2xl:w-auto 2xl:flex-1 flex flex-col gap-2 overflow-hidden">
            {system?.shipDate && (
              <KeyValue
                tooltip="The date the unit was shipped from Qnergy"
                label={getLabel('shipDate')}
                value={DateTime.fromISO(system?.shipDate as string).toFormat('DDD')}
                loading={isLoading}
                border={Border.none}
                data-pwid="ship-date"
                data-testid="ship-date"
                wrap
              />
            )}
            {system?.commissionDate && (
              <KeyValue
                tooltip="The date the unit was commissioned in the field"
                label={getLabel('commissionDate')}
                value={DateTime.fromISO(system?.commissionDate as string).toFormat('DDD')}
                loading={isLoading}
                border={Border.none}
                data-pwid="commission-date"
                data-testid="commission-date"
                wrap
              />
            )}
            <div className="flex gap-2 items-center">
              <KeyValue
                className="w-full"
                tooltip="The date the warranty expires"
                label="Warranty End"
                value={
                  DateTime.fromISO(system?.warrantyExpirationDate as string).isValid
                    ? DateTime.fromISO(system?.warrantyExpirationDate as string).toFormat('DD')
                    : system?.warrantyExpirationDate
                    ? `${(system?.warrantyExpirationDate as string)?.charAt(0).toUpperCase()}${
                        (system?.warrantyExpirationDate as string)?.slice(1) as string
                      }`
                    : 'Expired'
                }
                loading={isLoading}
                border={Border.none}
                data-pwid="warranty-expiration-date"
                data-testid="warranty-expiration-date"
                wrap
              />
              <WarrantyExpirationIndicator
                isLoading={isLoading}
                expirationDate={system?.warrantyExpirationDate as string}
              />
            </div>
            <div className="flex gap-2 items-center">
              <KeyValue
                className="w-full"
                tooltip="The date the subscription expires"
                label="Subscription End"
                value={
                  DateTime.fromISO(system?.subscriptionExpirationDate as string).isValid
                    ? DateTime.fromISO(system?.subscriptionExpirationDate as string).toFormat('DD')
                    : system?.subscriptionExpirationDate
                    ? `${(system?.subscriptionExpirationDate as string)?.charAt(0).toUpperCase()}${
                        (system?.subscriptionExpirationDate as string)?.slice(1) as string
                      }`
                    : 'Expired'
                }
                loading={isLoading}
                border={Border.none}
                data-pwid="subscription-expiration-date"
                data-testid="subscription-expiration-date"
                wrap
              />
              <SubscriptionExpirationIndicator
                isLoading={isLoading}
                status={system?.subscriptionStatus}
                expirationDate={system?.subscriptionExpirationDate as string}
              />
            </div>
            {system?.lastSeen && (
              <KeyValue
                tooltip=""
                label={getLabel('lastSeen')}
                value={getDisplayTime(
                  system?.lastSeen as string,
                  Api.isApiDown() ? undefined : Api.getServerTime(),
                  Duration.fromObject({ days: 1 }),
                )}
                loading={isLoading}
                border={Border.none}
                wrap
                valueColor={lastSeenColor}
                data-pwid="last-seen"
                data-testid="last-seen"
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
