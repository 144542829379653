import React, { useMemo } from 'react';

import { Metric, Tab, Text } from '../../ComponentLibrary/src';
import { CurrentSystemData } from '../../context/Systems';
import { GenStats, GenSystem } from '../../types';

interface Props {
  system?: GenSystem;
  isLoading: boolean;
  data: Record<
    string,
    {
      x?: string[];
      y?: number[];
    }
  >;
  currentSystemData?: CurrentSystemData;
}

export default function OutputData({ system, isLoading, data, currentSystemData }: Props): JSX.Element {
  const hasOutputData = (system?: GenSystem) => (system?.qecFwVer ? system?.qecFwVer >= '3.0.0' : false);

  const outputTabs = useMemo(
    () => [
      {
        label: 'Output 1',
        mobileIcon: <Text>1</Text>,
      },
      {
        label: 'Output 2',
        mobileIcon: <Text>2</Text>,
      },
      {
        label: 'Output 3',
        mobileIcon: <Text>3</Text>,
      },
    ],
    [],
  );

  if (!hasOutputData(system)) return <></>;

  return (
    <Tab
      data-pwid="system-output-data-tab"
      tabs={outputTabs.map((tab, i) => ({
        label: `${tab.label}${system?.qecOutConfig?.[i]?.aux ? ' (aux)' : ''}`,
        mobileIcon: tab.mobileIcon,
      }))}
      className="shadow"
      tabContents={outputTabs.map((tab, i) => (
        <div
          className="flex flex-row gap-2 flex-wrap items-center justify-between"
          key={`${tab.label}${system?.qecOutConfig?.[i]?.aux ? ' (aux)' : ''}`}
        >
          <div className="flex-col flex-wrap">
            <Metric
              className="system-metric"
              transparent
              // outEn could be null
              value={
                !isNaN(system?.stats?.[`outE${i + 1}` as keyof GenStats] as number) &&
                typeof system?.stats?.[`outE${i + 1}` as keyof GenStats] === 'number'
                  ? (system?.stats?.[`outE${i + 1}` as keyof GenStats] as number) * 1000
                  : undefined
              }
              units="Wh"
              description="Energy"
              loading={isLoading}
              dataIconSide="left"
              dataIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-speedometer2 w-full h-full"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                  <path
                    fillRule="evenodd"
                    d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
                  />
                </svg>
              }
              data-pwid={`system-output-${i + 1}-energy`}
            />
            <Metric
              className="system-metric"
              transparent
              value={currentSystemData?.[`outV${i + 1}` as keyof typeof currentSystemData]}
              units="V"
              description="Voltage"
              loading={isLoading}
              dataIconSide="left"
              dataIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plug w-full h-full"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 0a.5.5 0 0 1 .5.5V3h3V.5a.5.5 0 0 1 1 0V3h1a.5.5 0 0 1 .5.5v3A3.5 3.5 0 0 1 8.5 10c-.002.434-.01.845-.04 1.22-.041.514-.126 1.003-.317 1.424a2.083 2.083 0 0 1-.97 1.028C6.725 13.9 6.169 14 5.5 14c-.998 0-1.61.33-1.974.718A1.922 1.922 0 0 0 3 16H2c0-.616.232-1.367.797-1.968C3.374 13.42 4.261 13 5.5 13c.581 0 .962-.088 1.218-.219.241-.123.4-.3.514-.55.121-.266.193-.621.23-1.09.027-.34.035-.718.037-1.141A3.5 3.5 0 0 1 4 6.5v-3a.5.5 0 0 1 .5-.5h1V.5A.5.5 0 0 1 6 0zM5 4v2.5A2.5 2.5 0 0 0 7.5 9h1A2.5 2.5 0 0 0 11 6.5V4H5z" />
                </svg>
              }
              data-pwid={`system-output-${i + 1}-voltage`}
            />
          </div>
          <div className="">
            <Metric
              className="system-metric"
              transparent
              value={currentSystemData?.[`outI${i + 1}` as keyof typeof currentSystemData]}
              units="A"
              description="Current"
              loading={isLoading}
              dataIconSide="left"
              dataIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-lightning-charge w-full h-full"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z" />
                </svg>
              }
              data-pwid={`system-output-${i + 1}-current`}
            />
            <Metric
              className="system-metric"
              transparent
              value={system?.qecOutConfig?.[i]?.freq}
              units="Hz"
              description="Frequency"
              loading={isLoading}
              dataIconSide="left"
              dataIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-soundwave w-full h-full"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5zm-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zm-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5zm12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5z"
                  />
                </svg>
              }
              data-pwid={`system-output-${i + 1}-frequency`}
            />
          </div>

          <Metric
            className="system-metric system-chart-metric"
            transparent
            value={system?.latest?.[`outP${i + 1}` as keyof typeof currentSystemData]}
            units="W"
            description="Power"
            showPlaceholder
            chartData={{
              x: data[`outP${i + 1}` as keyof typeof data]?.x ?? [],
              y: data[`outP${i + 1}` as keyof typeof data]?.y ?? [],
            }}
            loading={isLoading}
            data-pwid={`system-output-${i + 1}-power`}
          />
        </div>
      ))}
    />
  );
}
