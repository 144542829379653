import { EditSystem, SystemForEdit } from '@/types';
import { toLocalTimezone, toUtc, warrantyToDropdownString } from '@/util';

import { EditSystemForm } from '.';

export function systemToForm(system: SystemForEdit): EditSystemForm {
  return {
    systemDescription: system?.systemDescription,
    model: system?.model,
    installDescription: system?.installDescription,
    shipDate: system?.shipDate ? toLocalTimezone(system.shipDate) : undefined,
    commissionDate: system?.commissionDate ? toLocalTimezone(system.commissionDate) : undefined,
    lastAnnualInspectionDate: toLocalTimezone(system?.lastAnnualInspectionDate),
    warrantySelection: warrantyToDropdownString(system?.warranty),
    warrantyExpirationDate: toLocalTimezone(system?.warrantyExpirationDate),
    subscriptionExpirationDate: toLocalTimezone(system?.subscriptionExpirationDate),
    overrideSubscription: !!system?.subscription,
    unlimited: toLocalTimezone(system?.subscriptionExpirationDate) === 'unlimited',
    distributorId: system?.distributor?._id,
    orgId: system?.org?._id,
    siteId: system?.site?._id,
    optConnectModemSN: system?.optConnectModemSN,
  };
}

export function formToSystemUpdate(
  sysId: string,
  system: SystemForEdit,
  {
    siteId,
    orgId,
    distributorId,
    subscriptionExpirationDate,
    warrantySelection,
    warrantyExpirationDate,
    shipDate,
    commissionDate,
    overrideSubscription,
    systemDescription,
    installDescription,
    lastAnnualInspectionDate,
    optConnectModemSN,
  }: EditSystemForm,
): EditSystem {
  const update: EditSystem = {
    sysId,
  };

  if (systemDescription !== system?.systemDescription) update.systemDescription = systemDescription;
  if (installDescription !== system?.installDescription) update.installDescription = installDescription;
  if (orgId !== system?.org?._id) update.orgId = orgId ?? null;
  if (distributorId !== system?.distributor?._id) update.distributorId = distributorId ?? null;
  if (siteId !== system?.site?._id) update.siteId = siteId ?? null;
  if (toUtc(shipDate) !== toUtc(system?.shipDate)) {
    update.shipDate = toUtc(shipDate);
  }
  if (toUtc(commissionDate) !== toUtc(system?.commissionDate)) {
    update.commissionDate = toUtc(commissionDate);
  }

  if (toUtc(lastAnnualInspectionDate) !== toUtc(system?.lastAnnualInspectionDate)) {
    update.lastAnnualInspectionDate = toUtc(lastAnnualInspectionDate) ?? null;
  }

  const warranty = warrantySelection === 'manual' ? warrantyExpirationDate : warrantySelection;

  if (
    (!warranty || typeof warranty === 'number' ? warranty ?? null : toUtc(warranty)) !==
    (!warranty || typeof system?.warranty === 'number' ? system?.warranty ?? null : toUtc(system?.warranty))
  ) {
    update.warranty = typeof warranty === 'number' ? warranty : toUtc(warranty) ?? null;
  }

  if (overrideSubscription) {
    if (toUtc(subscriptionExpirationDate) !== toUtc(system?.subscription)) {
      update.subscription = toUtc(subscriptionExpirationDate);
    }
  } else {
    if (system?.subscription) update.subscription = null;
  }

  if (optConnectModemSN !== system?.optConnectModemSN) {
    update.optConnectModemSN = optConnectModemSN ? optConnectModemSN : null;
  }

  return update;
}
